/* eslint-disable import/prefer-default-export */
export const colors: { [key: string]: string } = {
  yellow_3: '#ffd400',
  yellow_2: '#ffd000',
  yellow_1: '#FFF2B7',
  blue_8: '#213063',
  blue_7: '#1b1247',
  blue_6: '#99e5ea',
  blue_5: '#6094f1',
  blue_4: '#1a1446',
  blue_3: '#06748c',
  blue_2: '#28a3af',
  blue_1: '#f2fcfc',
  black: '#343741',
  gray_9: '#6c6d72',
  gray_8: '#464646',
  gray_7: 'rgba(230, 230, 230, 0.31)',
  gray_6: '#565656',
  gray_5: '#c0bfc0',
  gray_4: '#e6e6e6',
  gray_3: '#707070',
  gray_2: '#919191',
  gray_1: '#f5f5f5',
  white: '#ffffff',
  red_4: '#eb1c2e',
  red_3: '#d32f2f',
  red_2: '#e92243',
  red_1: '#fff4f5',
  green_aqua: '#78e1e1',
};

export const paletaCor: { [key: string]: Object } = {
  paletaCores: {
    primaria: {
      claro: "#FFFFFF",
      medio: "#343741",
      escuro: "#2C3A39",
      contrastante: "#FFC900"
    },
    secundaria: {
        claro: "#FFC900",
        medio: "#919191",
        escuro: "#FFC900",
        contrastante: '#2C3A39',
    },
    tooltipContrast: {
      claro: "#FFC900",
      medio: "#919191",
      escuro: "#c0bfc0",
      contrastante: '#e6e6e6',
  }
  }
}
