/* eslint-disable import/prefer-default-export */
import React from 'react';

export const SadEmojiSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="171"
      height="176"
      viewBox="0 0 171 176"
    >
      <g transform="translate(-550.245 -730.621)">
        <ellipse
          cx="85.5"
          cy="7.5"
          fill="#eff7f8"
          rx="85.5"
          ry="7.5"
          transform="translate(550.245 891.621)"
        />
        <circle
          cx="85.5"
          cy="85.5"
          r="85.5"
          fill="#feda02"
          transform="translate(550.245 730.621)"
        />
        <g opacity="0.3">
          <path
            fill="#fff"
            d="M561.136 824.156A79.136 79.136 0 0 1 703 775.915 79.142 79.142 0 1 0 571.652 863.6a78.77 78.77 0 0 1-10.516-39.444z"
          />
        </g>
        <g>
          <g>
            <g>
              <path
                d="M609.666 809.348c0 5.846-2.812 10.586-6.28 10.586s-6.281-4.74-6.281-10.586 2.812-10.586 6.281-10.586 6.28 4.738 6.28 10.586z"
                className="prefix__cls-5"
              />
            </g>
            <g>
              <path
                d="M672.47 809.348c0 5.846-2.812 10.586-6.28 10.586s-6.281-4.74-6.281-10.586 2.812-10.586 6.281-10.586 6.28 4.738 6.28 10.586z"
                className="prefix__cls-5"
              />
            </g>
          </g>
        </g>
        <path
          d="M607.067 847.85c10.128-9.315 28.372-7.106 34.491-5.909 5.98 1.159 11.814 2.577 16.881 6 5.376 3.671 9.548 6.057 12.4 11.035a2 2 0 0 1-2.619 2.788l-1.867-.923c-4.389-2.172-8.535-4.224-12.742-5.866a79.813 79.813 0 0 0-14.325-4.254 70.546 70.546 0 0 0-14.761-1.267 92.48 92.48 0 0 0-15.685 1.784 2 2 0 0 1-1.771-3.388z"
          className="prefix__cls-5"
        />
      </g>
    </svg>
  );
};
