import styled from 'styled-components';
import { mobileMinWidth, tabletMinWidth } from 'styles/variables';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 68px 0%;

  @media (max-width: ${tabletMinWidth}) {
    padding: 40px 3%;
  }

  @media (max-width: ${mobileMinWidth}) {
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 16px 41px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 504px;
  width: 100%;

  svg {
    align-self: center;
  }
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: flex-start;
  width: 100%;
  padding: 0 10px;

  svg {
    width: 100%;
    max-width: 576px;
  }

  @media (max-width: ${mobileMinWidth}) {
    padding: 34px 5px 0;
  }
`;

export const Title = styled.h1`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };

  @media (max-width: ${mobileMinWidth}) {
    font-size: 1.5rem;
  }
`;

export const Subtitle = styled.p`
  margin-top: 24px;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };

  @media (max-width: ${mobileMinWidth}) {
    margin-top: 21px;
    font-size: 1rem;
  }
`;

export const Buttons = styled.div`
  margin-top: 42px;

  button {
    width: 338px;
    margin-bottom: 24px;
    padding: 15px 60px;

    &:last-child {
      width: 222px;
    }

    @media (max-width: ${mobileMinWidth}) {
      padding: 15px 0px;
      margin-bottom: 16px;
      width: 100%;
      max-width: 328px;
    }
  }

  @media (max-width: ${tabletMinWidth}) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 21px;

    button:nth-child(2) {
      width: 100%;
      max-width: 218px;
      padding: 15px 30px;
    }
  }
`;
