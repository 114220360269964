import React from 'react';

export const ThinkingSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 246.206 335.787">
      <g data-name="IMG Man Thinking">
          <g data-name="Man Thinking">
              <g data-name="Balloon Thinking" transform="translate(118.439)">
                  <circle data-name="Ellipse 1" cx="5.967" cy="5.967" r="5.967" transform="translate(3.022 132.063)" style={{fill:'#FFC900'}}/>
                  <circle data-name="Ellipse 2" cx="9.706" cy="9.706" r="9.706" transform="translate(49.403 122.357)" style={{fill:'#FFC900'}}/>
                  <circle data-name="Ellipse 3" cx="8.513" cy="8.513" r="8.513" transform="translate(27.207 132.143)" style={{fill:'#FFC900'}}/>
                  <path data-name="Balloon Thinking-2" d="M422.965 47.833a20.355 20.355 0 0 0-20.343-20.366h-.9a16.537 16.537 0 0 0 .875-5.251A16.878 16.878 0 0 0 373.879 10.2a9.184 9.184 0 0 0-17.979-.874 16.878 16.878 0 0 0-29.357 4.3 21.221 21.221 0 0 0-5.967-.875 20.354 20.354 0 0 0-20.365 20.339v.023A19.92 19.92 0 0 0 303 43.295a9.161 9.161 0 0 0-2.546 17.344 20.121 20.121 0 0 0-5.251 13.6 20.354 20.354 0 0 0 20.343 20.366h.023a19.555 19.555 0 0 0 2.148-.079 17.328 17.328 0 0 0-.238 2.864 16.874 16.874 0 0 0 16.866 16.866 16.684 16.684 0 0 0 12.252-5.331v.557a9.228 9.228 0 0 0 18.457 0v-.4h.079a9.2 9.2 0 0 0 18.378-.557 9.4 9.4 0 0 0-.875-3.978 16.867 16.867 0 0 0 27.044-13.44 15.973 15.973 0 0 0-2.307-8.433h.636a9.215 9.215 0 0 0 4.774-17.1 20.5 20.5 0 0 0 10.182-17.741z" transform="translate(-295.198 -2.407)" style={{fill:'#FFC900'}}/>
                  <g data-name="Question Small" transform="translate(72.872 28.601)">
                      <path data-name="Path 3" d="M429.442 91.071a3.561 3.561 0 0 1-.8-3.5l.875-2.546a12.039 12.039 0 0 1 2.387-4.376 13.9 13.9 0 0 1 10.183-4.535 5.747 5.747 0 0 0 5.733-4.614 4.262 4.262 0 0 1 .239-.8 7.324 7.324 0 0 0-2.148-8.035 7.72 7.72 0 0 0-12.49 3.421 3.277 3.277 0 0 1-2.625 2.228 3.817 3.817 0 0 1-4.376-4.932 15.121 15.121 0 0 1 28.64 9.706 13.237 13.237 0 0 1-12.729 10.5c-4.853.239-5.728 3.341-5.728 3.341 0 .159-.079.239-.079.4l-.875 2.466a3.642 3.642 0 0 1-6.207 1.276z" transform="translate(-425.385 -53.244)" style={{fill:'#2C3A39'}}/>
                      <circle data-name="Ellipse 3-2" cx="4.137" cy="4.137" r="4.137" transform="translate(0 39.578)" style={{fill:'#2C3A39'}}/>
                  </g>
                  <g data-name="Question Big" transform="translate(18.587 28.457)">
                      <path data-name="Path 4" d="M358.655 105.837a4.557 4.557 0 0 1-3.977-2.7l-1.512-3.263a16.443 16.443 0 0 1-1.75-6.444c-.159-3.421.636-8.672 5.648-13.922 4.217-4.455 1.909-8.833 1.273-9.786a5.019 5.019 0 0 1-.557-.956 9.963 9.963 0 0 0-9.547-5.808 10.322 10.322 0 0 0-8.91 14.88 4.4 4.4 0 0 1-.477 4.613 5.068 5.068 0 0 1-8.751-.716 20.269 20.269 0 0 1 36.675-17.269 17.919 17.919 0 0 1-2.546 21.956c-4.534 4.774-2.466 8.513-2.466 8.592l.239.477 1.432 3.182a4.944 4.944 0 0 1-4.774 7.164z" transform="translate(-328.236 -52.988)" style={{fill:'#2C3A39'}}/>
                      <circle data-name="Ellipse 4" cx="5.489" cy="5.489" r="5.489" transform="translate(30.51 52.998)" style={{fill:'#2C3A39'}}/>
                  </g>
              </g>
              <g data-name="Man Thinking-2" transform="translate(0 57.824)">
                  <circle data-name="Ellipse 5" cx="15.047" cy="15.047" transform="translate(24.773 31.772)" r="15.047"/>
                  <circle data-name="Ellipse 6" cx="15.047" cy="15.047" transform="translate(92.998 31.772)" r="15.047"/>
                  <path data-name="Path 5" d="M233.051 510.794s10.263-58.872 10.5-71.28c.239-12.49-152.271 8.833-152.271 8.833l11.456 62.532h130.315z" transform="translate(-87.564 -249.708)" style={{fill:'#fffaef'}}/>
                  <path data-name="Path 6" d="M102.243 320.717s-14-63.725 24.9-75.417c10.872-3.268 22.919-12.168 22.919-12.168v-28.3s-13.445-5.571-13.925-12.945c-35.084-45.589 37.23-98.784 52.424-70.223 30.947 58.156.515 83.168.515 83.168v28.3a73.8 73.8 0 0 0 16.668 7.948c36.994 12.092 29.038 79.636 29.038 79.636z" transform="translate(-91.285 -108.877)" style={{fill:'#f3b79c'}}/>
                  <path data-name="Path 7" d="M147.811 326.037s-.231 22.288 19.409 22.037c18.759-.279 19.439-22.037 19.439-22.037s17.793 5.651 25.431 9.706c7.638 4.137 45.666 23.549 28.64 77.806H100s-12.013-28.879 0-54.814c13.523-29.118 40.174-26.413 47.811-32.698z" transform="translate(-89.041 -201.789)" style={{fill:'#FFC900'}}/>
                  <path data-name="Path 8" d="M166.37 384.44s21.322 82.321 84.091 27.617V505.3h-82.42z" transform="translate(-120.408 -227.335)" style={{fill:'#FFC900'}}/>
                  <path data-name="Path 9" d="M152.334 212.851c1.368 6.241 1.368 14.79-3.507 14.79-4.958 0-14.278-9.832-14.278-14.79a8.887 8.887 0 0 1 8.883-8.892h.01c4.874 0 7.781 4.104 8.892 8.892z" transform="translate(-110.035 -148.42)" style={{fill:'#f3b79c'}}/>
                  <path data-name="Path 10" d="M273.472 212.851c-1.368 6.241-1.368 14.79 3.507 14.79 4.958 0 14.278-9.832 14.278-14.79a8.887 8.887 0 0 0-8.883-8.892h-.01c-4.874 0-7.781 4.104-8.892 8.892z" transform="translate(-164.657 -148.42)" style={{fill:'#f3b79c'}}/>
                  <path data-name="Path 12" d="M45.636 35.448s-8.464 40.952 5.3 53.178 12.567-14.449 26.76-15.047c9.661-.342 13.85 24.708 28.3 8.977 9.4-10.259-.256-50.785-.256-50.785l8.122 5.386s9.661 71.3-35.651 74.637c-46.167 3.334-45.141-67.8-41.123-76-.003-.093 5.209-4.022 8.548-.346z"/>
                  <circle data-name="Ellipse 7" cx="3.163" cy="3.163" transform="translate(57.176 53.744)" r="3.163"/>
                  <circle data-name="Ellipse 8" cx="3.163" cy="3.163" transform="translate(93.34 52.376)" r="3.163"/>
                  <path data-name="Path 13" d="M219.638 216.888h-.256c-2.65-.6-4.958-3.847-5.215-5.044l1.71-.342c.6 2.565 2.565 2.822 3.507 3.591.513-1.881-.085-10.088-1.111-17.1l1.71-.256c.855 6.412 2.137 17.27.427 18.894a1.048 1.048 0 0 1-.772.257z" transform="translate(-141.517 -145.96)" style={{isolation: 'isolate', opacity:.15}}/>
                  <path data-name="Path 14" d="M147.885 227.159c-.513-7.436-2.052-8.892-2.736-9.233a1.672 1.672 0 0 0-1.282.171l-1.111-1.282a2.863 2.863 0 0 1 2.992-.427c2.137.941 3.334 4.445 3.762 10.687z" transform="translate(-113.28 -153.238)" style={{opacity:.1, isolation:'isolate'}}/>
                  <path data-name="Path 16" d="m283.9 227.179-1.71-.085c.427-6.241 1.624-9.746 3.762-10.687a3.062 3.062 0 0 1 2.992.427l-1.111 1.282a1.228 1.228 0 0 0-1.282-.171c-.603.255-2.142 1.796-2.651 9.234z" transform="translate(-168.413 -153.258)" style={{opacity:.1, isolation:'isolate'}}/>
                  <path data-name="Path 17" d="M45.632 35.448s12.567-21.2 23.681-17.783c11.114 3.334 28.47 28.982 43.089 21.032 0 0-1.028-40.353-38.644-38.644-37.616 1.624-38.726 48.475-28.126 35.395z"/>
                  <path data-name="Path 22" d="M68.119 49.212a15.273 15.273 0 0 1-6.5 2.479c-3.676.513-14.363.085-15.389.256-1.02.257 8.637-9.831 21.889-2.735z"/>
                  <path data-name="Path 23" d="M85.474 45.625s10.088-7.78 14.192-5.471c4.446 2.565 4.1 5.471 4.1 5.471S97.354 44 96.584 44.343c-3.589 1.536-8.032 3.673-11.11 1.282z"/>
                  <path data-name="Path 24" d="M226.043 258.838s-5.3-18.638-11.371-17.441-10.858 14.534-8.977 20.348c1.881 5.899 20.605 5.984 20.348-2.907z" transform="translate(-138.003 -163.202)" style={{fill:'#f3b79c'}}/>
                  <path data-name="Path 25" d="m211.773 256.673-1.282-1.111c.171-.256 5.13-5.643 9.575-5.643a4.745 4.745 0 0 1 3.676 1.881l-1.368 1.111a2.843 2.843 0 0 0-2.308-1.2h-.083c-2.995-.083-6.927 3.508-8.21 4.962z" transform="translate(-140.063 -166.593)" style={{opacity:.1, isolation: 'isolate'}}/>
                  <path data-name="Path 26" d="M223.859 260.134a8.448 8.448 0 0 0-5.471 0l-.688-1.534a10.007 10.007 0 0 1 6.669 0z" transform="translate(-142.916 -169.798)" style={{isolation:'isolate', opacity:.15}}/>
                  <path data-name="Path 30" d="M272.672 331.269s-2.069 139.713-1.034 150.772h41.449s13.366-95.48 8.91-114.176c-6.365-26.254-49.325-36.596-49.325-36.596z" transform="translate(-166.326 -204.078)" style={{fill:'#2C3A39'}}/>
                  <path data-name="Path 31" d="M134.533 332.825s7.479 138.838 6.444 149.9h-40.971s-19.174-95.48-14.639-114.175c6.206-26.258 49.166-35.725 49.166-35.725z" transform="translate(-84.673 -204.758)" style={{fill:'#2C3A39'}}/>
                  <path data-name="Path 33" d="M157.084 442.134c-10.183-21.64-16.23-48.53-16.309-48.768l.8-.159c.079.239 6.046 27.049 16.23 48.608z" transform="translate(-109.213 -231.17)" style={{fill:'#4d4b75'}}/>
                  <path data-name="Path 34" d="M211.232 480.741s-61.1 33.493-83.3 35.084-25.06-23.469-25.06-23.469 27.686-13.843 42.165-20.128c0 0 32.7-9.308 69.055-8.115.008.087 4.619 10.423-2.86 16.628z" transform="translate(-92.636 -262.139)" style={{fill:'#f3b79c'}}/>
                  <g data-name="Group 3">
                      <path data-name="Path 36" d="M287.487 289.848s-15.593-24.822-22.833-27.765c-7.239-3.023-19.73 1.671-24.662 6.365-1.114 1.034-1.591 6.526 2.227 5.57 7.876-2.069 20.685-12.57 25.856 0 0 0-12.649-4.057-17.821 1.75-5.489 6.046-5.251 10.581-4.613 16.627.318 2.466 6.762 4.137 6.444 1.273-.636-5.887-2.387-14.639 12.092-15.2 0 0-13.365 1.75-9.149 17.661.4 1.432 5.808 2.864 5.57-.159-.557-6.92-1.75-14.718 8.194-14.161 0 0-10.024 3.819-4.853 16.23.477 1.193 4.613.636 5.251-.477 1.432-2.466-4.376-9.865 1.432-11.533 0 0-.8 11.533 7.16 18.3 0 0-3.421 30.629-4.932 50.121-1.909 24.026-12.649 86.717 35.162 59.667.081-.001-6.921-102.152-20.525-124.269z" transform="translate(-151.7 -167.771)" style={{fill:'#f3b79c'}}/>
                  </g>
              </g>
          </g>
      </g>
  </svg>
)
