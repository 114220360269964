import styled from 'styled-components'
import { mobileMinWidth, tabletMinWidth } from 'styles/variables'

import { InformationSVG } from 'assets/img/InformationSVG'
import { IconIncendio } from '../../../components/CoverageComponent/assets/IconIncendio'
import { IconResponsabilidade } from '../../../components/CoverageComponent/assets/IconResponsabilidade'
import { IconRoubo } from '../../../components/CoverageComponent/assets/IconRoubo'
import { IconVendaval } from '../../../components/CoverageComponent/assets/IconVendaval'
import { IconVidros } from '../../../components/CoverageComponent/assets/IconVidros'
import { IconMorteSVG } from './assets/IconMorte'
import  {IconCarGlassSVG} from './assets/IconCarGlass'
import { IconAluguelSVG } from './assets/IconAluguel'
import { IconAcidenteSVG } from './assets/IconAcidente'
import { IconCarSVG } from './assets/IconCar'
import Illustration from './assets/illustration.png'

export const IncendioIcon = styled(IconIncendio)``
export const ResponsabilidadeIcon = styled(IconResponsabilidade)``
export const RouboIcon = styled(IconRoubo)``
export const VendavalIcon = styled(IconVendaval)``
export const VidrosIcon = styled(IconVidros)``
export const IconAcidente = styled(IconAcidenteSVG)``
export const IconCar = styled(IconCarSVG)``
export const IconMorte = styled(IconMorteSVG)``
export const IconCarGlass = styled(IconCarGlassSVG)`
  fill: #2C3A39;
  cursor: pointer;
  width: 16px;
  height: 16px;
  vertical-align: bottom;
`

export const IconAluguel = styled(IconAluguelSVG)``
export const InformationIcon = styled(InformationSVG)`
  fill: #78e1e1;
  cursor: pointer;
  width: 16px;
  height: 16px;
  vertical-align: bottom;
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 70px;

  @media (max-width: ${tabletMinWidth}) {
    flex-direction: column;
    height: auto;
  }

  @media (max-width: ${mobileMinWidth}) {
    width: 100%;
    padding: 0 0 33px 0;
  }
`

export const InfoContainer = styled.div`
  max-width: 582px;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: ${tabletMinWidth}) {
    align-self: start;
    margin-top: 12px;
  }
`

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: 300;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };

  @media (max-width: ${mobileMinWidth}) {
    font-size: 1.5rem;
    text-align: left;
    max-width: 85%;
  }
`

export const CoverageCard = styled.div`
  width: 178px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 26px;

  > svg {
    width: 60px;
    height: 60px;
  }

  @media (max-width: ${mobileMinWidth}) {
    max-width: 161px;
  }
`

export const CoverageContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const CoverageName = styled.div`
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  margin: 12px 0 8px;

  @media (max-width: ${mobileMinWidth}) {
  }
`

export const CoverageDescription = styled.div`
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  line-height: 1.29;

  @media (max-width: ${mobileMinWidth}) {
    font-size: 14px;
  }
`

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 90%;

  & > svg {
    width: 100%;
    max-width: 521px;
  }

  @media (max-width: ${tabletMinWidth}) {
    align-items: center;
    margin-top: 16px;
  }
`

export const CoverageText = styled.div`
  font-size: 0.75rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };

  > a {
    color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
    margin-right: 10px;
  }

  @media (max-width: ${mobileMinWidth}) {
    margin-top: 14px;

    > a {
      margin-right: 6px;
    }
  }
`

export const List = styled.ul`
  list-style-type: none;
  margin-top: 19px;

  li {
    display: flex;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
  }
`

export const CoverageImage = styled.img.attrs({
  src: `${Illustration}`
})`
  width: 530.9px;
  height: 423.3px;
  object-fit: contain;
`
