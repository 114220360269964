import React from 'react';
import { SVGProps } from './types';

export const CarNewSVG = ({ width, height }: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36.751 35.241"
    width={width}
    height={height}
  >
    <path
      d="M114.412 395.507H110.5l-1.312-3.7a3.818 3.818 0 0 0-3.453-2.592H90.111a3.856 3.856 0 0 0-3.511 2.708l-1.141 3.584h-3.925a1.942 1.942 0 0 0-2.013 1.954v2.02a1.937 1.937 0 0 0 2.013 1.945h.037a3.254 3.254 0 0 0-.79 2.062v12.376a2.052 2.052 0 0 0 2.185 1.922h4.179a1.957 1.957 0 0 0 2.071-1.922v-1.352h-2.143v1.13h-4.149v-6.662h4.707a1.9 1.9 0 0 0 1.844-1.418l.58-1.761.026-.094a2.117 2.117 0 0 0-.394-1.807 2.277 2.277 0 0 0-1.8-.838h-4.84a1.512 1.512 0 0 1 .349-.442l2.094-1.819h25.147l1.916 1.772a.977.977 0 0 1 .267.488h-4.74a2.274 2.274 0 0 0-1.8.838 2.121 2.121 0 0 0-.394 1.807l.027.094.578 1.761a1.9 1.9 0 0 0 1.846 1.418h4.542v6.665h-3.917l-.23-.045v-1.085h-2.144v1.352a2.2 2.2 0 0 0 2.374 1.922h4.18a1.82 1.82 0 0 0 1.883-1.922v-12.378a3.478 3.478 0 0 0-.625-2.062h.023a1.82 1.82 0 0 0 1.859-1.945v-2.02a1.826 1.826 0 0 0-1.859-1.954m-25.772-2.93a1.719 1.719 0 0 1 1.471-1.218h15.62a1.692 1.692 0 0 1 1.432 1.168l2.174 6.127H86.706zm-.647 12.644zm0 0-.532 1.615h-4.548V405.2h4.959l.106.017m-4.029-5.936h-2.294v-1.63h3.111l-.406 1.276zm27.3-1.632h2.873v1.632h-1.978l-.469-.434zm-2.755 9.183-.532-1.614.106-.018h4.795v1.632z"
      transform="translate(-79.521 -382.545)"      
    />
    <path
      transform="translate(4.335 28.342)"      
      d="M0 0h28.083v1.8H0z"
    />
    <path
      transform="translate(18.376 7.455)"
      style={{ strokeWidth: '2px' }}
      d="M0 0v22"
    />
    <path
      d="M-236.128 39.69s-12.245-1.4-9.136-4.8 9.136 4.8 9.136 4.8z"
      transform="translate(253.4 -33.02)"
      style={{ strokeWidth: '2px' }}
    />
    <path
      d="M-245.769 39.69s12.306-1.4 9.182-4.8-9.182 4.8-9.182 4.8z"
      transform="translate(265.2 -33.02)"
      style={{ strokeWidth: '2px' }}
    />
  </svg>
);
