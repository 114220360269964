import styled from 'styled-components';
import { mobileMinWidth, tabletMinWidth } from 'styles/variables';

import BGFamily from './assets/family.png';
import BGFamilyMobile from './assets/family_mobile.png';

export const Wrapper = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
  z-index: 2;

  @media (max-width: ${mobileMinWidth}) {
    max-width: 100% !important;
  }
`;

export const Title = styled.span`
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1.25;
  width: 100%;
  max-width: 600px;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };

  @media (max-width: ${mobileMinWidth}) {
    width: 100%;
    font-size: 1.5rem;
    max-width: 100%;
  }
`;

export const Subtitle = styled.span`
  width: 100%;
  font-size: 1.5rem;
  line-height: 1.33;
  font-weight: 300;
  margin-top: 24px;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };

  @media (max-width: 1200px) {
    font-size: 1.2rem;
  }

  @media (max-width: ${mobileMinWidth}) {
    font-size: 1rem;
    margin-top: 18px;
    max-width: 250px;
    width: 100%;
  }
`;

export const Banner = styled.div`
  display: flex;
  flex-direction: column;
  padding: 57px 0 64px 0%;

  button {
    margin-top: 43px;
    max-width: 198px;

    @media (max-width: ${tabletMinWidth}) {
      margin-top: 25px;
    }

    @media (max-width: ${mobileMinWidth}) {
      margin-top: 195px;
    }
  }

  @media (max-width: ${tabletMinWidth}) {
    width: 100%;
    padding-top: 22px;
  }

  @media (max-width: ${mobileMinWidth}) {
    padding-top: 22px;
  }
`;

export const BackgroundImage = styled.span`
  width: 100%;
  height: 412px;
  flex: 1;
  display: flex;
  justify-content: space-between;
  background: url(${BGFamily}) no-repeat;
  background-size: cover;
  background-position: right top;
  position: absolute;

  @media (max-width: ${tabletMinWidth}) {
    background: url(${BGFamily}) no-repeat;
    background-size: cover;
    background-position: right bottom;
    height: 380px;
  }

  @media (max-width: ${mobileMinWidth}) {
    background: url(${BGFamilyMobile}) no-repeat;
    background-size: cover;
    background-position: left center;
    top: 125px;
    height: 400px;
  }
`;
