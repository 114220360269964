export const eventTag = {
  linkEmail: 'linkEmail',
  linkSite: 'linkSite',
  linkInstagram: 'linkInstagram',
  linkFace: 'linkFace',
};

export const eventTagMap = {
  [eventTag.linkEmail]: {
    location: 'Broker',
    button: 'E-mail',
    event: 'confirmacao_link_email',
  },
  [eventTag.linkSite]: {
    location: 'Broker',
    button: 'Site',
    event: 'confirmacao_link_site',
  },
  [eventTag.linkInstagram]: {
    location: 'Broker',
    button: 'Instagram',
    event: 'confirmacao_btn_insta',
  },
  [eventTag.linkFace]: {
    location: 'Broker',
    button: 'Facebook',
    event: 'confirmacao_btn_fb',
  },
};
