import styled from 'styled-components';

import { mobileMinWidth } from 'styles/variables';

export const Link = styled.a.attrs({
  target: '_blank'
})`
  display: block;
  font-size: 16px;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro};  
  text-align: left;
  margin-top: 5px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s;

  @media (max-width: ${mobileMinWidth}) {
    margin-top: 10px;
  }

  &:hover {
    text-decoration: underline;
  }
`;
