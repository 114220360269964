/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */
/* eslint-disable import/no-useless-path-segments */

import { AxiosResponse } from 'axios';
import { LOCAL_CONTRACT_COMPLETED } from 'config';
import {
  ICreditLineRequest,
  ICreditLineResponse,
  ICustomer,
} from 'types/customer';

import api from '../../api';

interface ICustomerDataResponse extends ICustomer {}

export const validateCreditLine = async ({
  broker: { broker, brokerUser, brokerToken, commercialProductCode },
  ...data
}: ICreditLineRequest): Promise<AxiosResponse<ICreditLineResponse>> => {
  const response = await api.post('/customer/validate/document_number', {
    broker,
    commercialProductCode,
    brokerUser,
    brokerToken,
    ...data,
  });

  if (response.data && (response.data.isCpfOnBlackList || response.data.isCpfOnGreyList || response.data.sufficientCreditScore === false)) {
    window.localStorage.setItem(
      LOCAL_CONTRACT_COMPLETED,
      'true'
    )
  }

  return response;
};

export const customerData = async ({
  broker: { broker, brokerUser, brokerToken, commercialProductCode },
  documentNumber,
  telephone,
  socialName,
}: ICreditLineRequest): Promise<AxiosResponse<ICustomerDataResponse>> => {
  const response = await api.post('/customer/data', {
    broker,
    commercialProductCode,
    brokerUser,
    brokerToken,
    documentNumber,
    telephone,    
    ignorarLead: true,
  });
  return {...response, data: {...response.data, nomeSocial: socialName}};
};
