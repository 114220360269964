/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable one-var */
/* eslint-disable no-cond-assign */
/* eslint-disable no-alert */
/* eslint-disable no-useless-escape */
/* eslint-disable prefer-destructuring */
/* eslint-disable func-names */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSearchLogo } from 'hooks/useSearchLogo';
import { yupResolver } from '@hookform/resolvers/yup';

import {
  ADAPTATION_CODE,
  ESTADO_CIVIL,
  OWNER_RELATIONSHIP,
  OWNER_RELATIONSHIP_DRIVER,
  LO_JACK,
  LOCAL_CPF,
  LOCAL_CONTRACT_COMPLETED,
  LOCAL_BROKER,
} from 'config';

import Button from 'components/Button';
import { InputField } from 'components/Input/InputField';
import { InputMaskField } from 'components/Input/InputMaskField';
import { InputMoney } from 'components/Input/InputMoney';
import { Select } from 'components/Input/Select';
import ButtonGroup from 'components/ButtonGroup';

import { getPersonalDataLink } from 'helpers/links';
import { googleAnalyticsEvent } from 'helpers/googleTagManager';
import { Checkbox } from 'components/Input/Checkbox';
import { ErrorMessage } from 'components/Input/ErrorMessage';

import { promotionList } from 'api/services/promotion';
import { vehicle } from 'api/services/vehicle';
import { useBroker } from 'contexts/Broker';
import { useLoading } from 'contexts/Loading';
import axios, { AxiosResponse } from 'axios';
import { customerData, validateCreditLine } from 'api/services/customer';
import { ICustomer } from 'types/customer';
import { IVehicleResponse } from 'types/vehicle';
import {
  IQuotationRequest,
  IQuotationResponse,
  requestQuotation,
} from 'api/services/quotation';
import { isValidResponse } from 'helpers/validation';
import { useNavigate } from 'react-router-dom';
import { IQuestionnaireProps } from 'types/questionnaire';

import { INVALID_CEP, REQUIRED_FIELD } from 'helpers/messages';
import { IsMobile } from 'helpers/custom-hooks';
import { trackingLead } from 'api/services/lead';
import { onlyNumbers } from '@brazilian-utils/brazilian-utils';

import { sendBrokerLeadNotification } from 'api/services/broker';
import { formatCPF } from 'helpers/format';
import { mobileMinWidth, mobileMinWidthInt } from 'styles/variables';
import { detectBrowser } from 'helpers/detectBrowser';
import { validations, eventTagMap, eventTag, firstStepFields, searchCep } from './validations';
import * as S from './styles';
import Tooltip from '../../components/Tooltip'

export const Questionnaire = () => {
  const abortControllerRef = useRef<AbortController>(new AbortController());
  const controllerRef = abortControllerRef.current;

  const controller = new AbortController();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const [logo] = useSearchLogo();
  const { broker } = useBroker();
  const { showLoading } = useLoading();
  const redirect = useNavigate();
  const mobile = IsMobile();

  const [hasPotentialDriver, setHasPotentialDriver] = useState(false);
  const [step, setStep] = useState(1);

  const schema: any = step === 1 ? validations.pick(firstStepFields) : validations;
  const {
    control,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    getValues,
    setFocus,
    watch,
  } = useForm<IQuestionnaireProps>({
    resolver: yupResolver(schema),
  });

  const driverButtons = [
    {
      label: 'Segurado(a)',
      value: 'S',
      icon: <S.InsuredIcon width="25.423px" height="31.518px" />,
    },
    {
      label: 'Proprietário(a)',
      value: 'P',
      icon: <S.OwnerIcon width="30.251px" height="32.125px" />,
    },
    {
      label: 'Outra pessoa',
      value: 'O',
      icon: <S.AnotherPersonIcon width="36.695px" height="28.88px" />,
    },
  ];

  async function getVehicle(
    placaVeiculo?: string,
    chassi?: string
  ): Promise<IVehicleResponse | undefined | any> {
    if (!broker) return undefined;
    try {
      const response: any = await vehicle({
        broker,
        ...(placaVeiculo!! ? { placaVeiculo } : null),
        ...(chassi!! ? { chassi } : null),
      });

      return response.data;
    } catch (exVehicle) {
      if (axios.isAxiosError(exVehicle)) {
        const { detail } = exVehicle.response?.data as any;

        setError(
          !!chassi ? 'chassis' : 'plate',
          { message: detail[0] },
          { shouldFocus: true }
        );
      }
    }
    return undefined;
  }

  async function getCustomerData({
    phone,
    socialName,
    cpfSegurado,
    cpfCondutor,
    cpfProprietario,
    VehicleOwnerIsPolicyHolder,
    potentialDriver,
  }: IQuestionnaireProps) {
    if (!broker) return [];

    const requests = [
      customerData({
        broker,
        telephone: phone,
        documentNumber: cpfSegurado,
        socialName
      }),
    ];

    if (VehicleOwnerIsPolicyHolder !== 'T' && cpfProprietario) {
      requests.push(
        customerData({
          broker,
          telephone: phone,
          documentNumber: cpfProprietario,      
        })
      );
    }

    if (potentialDriver === 'O' && cpfCondutor) {
      requests.push(
        customerData({
          broker,
          telephone: phone,
          documentNumber: cpfCondutor,
        })
      );
    }

    try {
      const result = await Promise.all(requests);

      const customers: ICustomer[] = [];
      result.forEach(({ data }) => {
        customers.push(data);
      });

      return customers;
    } catch (ex) {}
    return [];
  }

  async function validateDocuments({
    phone,
    name,
    email,
    cpfSegurado,
    cpfCondutor,
    cpfProprietario,
    VehicleOwnerIsPolicyHolder,
    potentialDriver,
  }: IQuestionnaireProps): Promise<boolean> {
    if (!broker) return false;

    const requests = [
      validateCreditLine({
        broker,
        name,
        email,
        telephone: phone,
        documentNumber: cpfSegurado,
        ignorarLead: true,
      }),
    ];

    if (VehicleOwnerIsPolicyHolder !== 'T' && cpfProprietario) {
      requests.push(
        validateCreditLine({
          broker,
          telephone: phone,
          documentNumber: cpfProprietario,
          ignorarLead: true,
        })
      );
    }

    if (potentialDriver === 'O' && cpfCondutor) {
      requests.push(
        validateCreditLine({
          broker,
          telephone: phone,
          documentNumber: cpfCondutor,
          ignorarLead: true,
        })
      );
    }

    const result = await Promise.all(requests);
    const invalidDocument = result.filter(({ data }) => {
      const { isCpfOnBlackList, isCpfOnGreyList, sufficientCreditScore } = data;

      return isCpfOnBlackList || isCpfOnGreyList || !sufficientCreditScore;
    });

    return invalidDocument.length === 0;
  }

  const onSubmit = async (data: IQuestionnaireProps) => {
    if (!broker) return;

    handleSendEventData(step === 1 ? eventTag.btnContinue : eventTag.btnRequest)

    const customers = await getCustomerData(data)

    try {
      showLoading(true);
      if (!await searchCep(data.zipcode)) {
        setError('zipcode', {
          message: INVALID_CEP,
        });
        setFocus('zipcode');
        return
      }   
      if (step === 1) {     
        const response = await validateCreditLine({
          broker,
          name: customers[0].nome,
          email: data.email,
          telephone: data.phone,
          documentNumber: data.cpfSegurado,
        });


        const { isCpfOnBlackList, isCpfOnGreyList, sufficientCreditScore } =
          response.data;
        
        if (!isCpfOnBlackList && !isCpfOnGreyList && sufficientCreditScore) {
          window.localStorage.setItem(
            LOCAL_CONTRACT_COMPLETED,
            'false'
          )

          // if (watch('plate')) {
            // clearErrors('chassis')
          // }

          // Dados do Veiculo
          trackingLead({
            name: customers[0].nome,
            email: data.email,
            phone: data.phone,
            documentNumber: onlyNumbers(String(data.cpfSegurado)),
            commercialProductCode: broker.commercialProductCode,
            broker,
            step: {
              code: '14',
              situation: true,
            },
          });
          setStep(2);
        } else {
          redirect('/error');
        }
        
        showLoading(false);
        return;
      }

      if (data.HasClaim === 'T') {
        redirect('/error');
        return;
      }

      const { plate, chassis } = data;
      if (data.Is0KM === 'T' && (!chassis && !plate)) {
        const message = 'Por favor, preencha ao menos um dos campos ';
        setError('plate', { message });
        setError('chassis', { message });
        setFocus('plate');
        return;
      }
    
      const vehicleData = await getVehicle(plate, chassis);

      if (vehicleData) {
        const response = await Promise.all([
          promotionList(broker),
          validateDocuments(data),
        ]);

        if (isValidResponse(response[0].status) && response[1]) {
          const {
            listaPromocaoVendasAutoPorCorretor: { salesPromotions },
          } = response[0].data;

          const requests: Array<
            Promise<AxiosResponse<IQuotationResponse, any>>
          > = [];

          salesPromotions.forEach(salePromotion => {
            requests.push(
              requestQuotation({
                broker,
                salePromotion,
                vehicle: vehicleData as IVehicleResponse,
                data,
                customers,
              })
            );
          });

          const result = await Promise.all(requests);
          const hasRestriction = result.findIndex(
            ({ data: resultData }) => resultData.hasRestriction
          );

          if (hasRestriction >= 0) {
            redirect('/error');
          } else if (result[0].data) {
            redirect('/success');
          }
        } else {
          redirect('/error');
        }
      }
    } catch (ex) {
      redirect('/error', { state: { serverError: true } });
    } finally {
      showLoading(false);
    }
  };

  const onError = (errors: any) => {};

  const handleChangeValue = (fieldName: any, value: string) => {
    if (
      (fieldName === 'potentialDriver' && value === 'O') ||
      (fieldName === 'VehicleOwnerIsPolicyHolder' && value === 'F')
    ) {
      setHasPotentialDriver(true);
    }
    handleSendEventData(fieldName+value);
    setValue(fieldName, value);
    clearErrors(fieldName);
  };

  const handleSendEventData = (key: string) => {
    googleAnalyticsEvent({
      dataLayer: eventTagMap[key]
    });
  }

  const fetchSendBrokerLeadNotification = async () => {
    const brokerData = localStorage.getItem(LOCAL_BROKER)
    const brokerDataParsed = JSON.parse(brokerData!)
    const cpf = localStorage.getItem(LOCAL_CPF)

    const payload: any = {
      broker: {
        brokerCode: brokerDataParsed.broker.brokerCode,
        brokerBranchCode: brokerDataParsed.broker.brokerBranchCode,
        internalBranchCode: brokerDataParsed.broker.internalBranchCode
      },
      brokerUser: 'enim',
      brokerToken: brokerDataParsed.brokerToken,
      affinity: {
        affinityAccountCode:
          brokerDataParsed.affinity.affinityAccountCode,
        affinityAccountBranchCode:
          brokerDataParsed.affinity.affinityAccountBranchCode,
        affinityCommPlanCode:
          brokerDataParsed.affinity.affinityCommPlanCode
      },
      commercialProductCode: String(
        brokerDataParsed.commercialProductCode
      ),
      documentNumber: formatCPF(cpf!)
    }
    const response = await sendBrokerLeadNotification(payload, controller.signal, source.token)

    if (response.status === 200) {
      window.localStorage.removeItem(LOCAL_CONTRACT_COMPLETED)
      localStorage.getItem(LOCAL_CPF)
      source.cancel()
      controller.abort()
    }
  }

  const beforeUnload = useCallback((e?: any, isMobile?: boolean) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
      e.returnValue = ''
    }

    const contractCompleted = window.localStorage.getItem(
      LOCAL_CONTRACT_COMPLETED
    )

    if (contractCompleted === 'false' && !isMobile) {
      fetchSendBrokerLeadNotification()
    }

    if (contractCompleted === 'false' && isMobile) {
      fetchSendBrokerLeadNotification()
    }
  }, [])

  useEffect(() => {
    let sended = false
    const isSafari = detectBrowser().toLowerCase().includes('safari')

    if (!sended && window.innerWidth > mobileMinWidthInt) {
      sended = true
      window.addEventListener('beforeunload', (e) => beforeUnload(e, false))
    }

    if (!sended && window.innerWidth <= mobileMinWidthInt && !isSafari) {
      sended = true
      window.addEventListener('visibilitychange', (e) => beforeUnload(e, true))
    }

    if (!sended && window.innerWidth <= mobileMinWidthInt && isSafari) {
      sended = true
      window.addEventListener('blur', (e) => beforeUnload(e, true))
    }

    return () => {
      window.removeEventListener('beforeunload', (e) => beforeUnload(e, false))
      window.removeEventListener('blur', (e) => beforeUnload(e, true))
      // window.removeEventListener('visibilitychange', (e) => beforeUnload(e, true))
      source.cancel()
      controller.abort()
    }
  }, [])

  useEffect(() => {
    if(!watch('plate')) {
      setError('plate', {
        message: 'Por favor, preencha ao menos um dos campos'
      })
    }

    if(!watch('chassis')) {
      setError('chassis', {
        message: 'Por favor, preencha ao menos um dos campos'
      })
    }

    if(watch('plate')) {
      clearErrors('chassis')
    }

    if(watch('chassis')) {
      clearErrors('plate')
    }
  }, [getValues('Is0KM'), watch('plate'), watch('chassis')])

  return (
    <S.Container>
      <S.ContainerLogo>
        <S.Logo src={logo} alt="Imagem de um logo." />
      </S.ContainerLogo>
      <h1>Vamos cotar seu Seguro Auto?</h1>
      {!mobile ?
      <S.Form>
        <S.SocialNameSectionContainer>
          <Controller
            name="socialName"
            control={control}
            render={({ field: { ref, ...field }, fieldState }) => (
              <InputField
                onCustomBlur={() => handleSendEventData(eventTag.inputName)}
                label="Como gostaria de ser chamado?"
                placeholder="Como gostaria de ser chamado?"
                maxLength={45}
                fieldState={fieldState}
                {...field}
                inputRef={ref}
              />
            )}
          />
          <S.SocialNameTooltipContainer>
          <Tooltip
          style={{ margin: '16px 0 0 15px' }}
          description={
            <>
              É o nome pelo qual a pessoa se identifica se auto identifica e prefere ser chamada, 
              e pelo qual é socialmente reconhecida.
            </>
          }
          />
          </S.SocialNameTooltipContainer>
          <Controller
            name="cpfSegurado"
            control={control}
            render={({ field: { ref, ...field }, fieldState }) => (
              <InputMaskField
                onCustomBlur={() => handleSendEventData(eventTag.inputCPF)}
                label="CPF do(a) segurado(a)"
                placeholder="CPF do(a) segurado(a)"
                mask="999.999.999-99"
                fieldState={fieldState}
                onBlurCapture={() => {
                  window.localStorage.setItem(LOCAL_CPF, watch('cpfSegurado'))
                }}
                {...field}
                inputRef={ref}
              />
            )}
          />
          </S.SocialNameSectionContainer>
          <S.SectionContainer>
          <Controller
            name="maritalStatus"
            control={control}
            render={({ field: { ref, ...field }, fieldState }) => (
              <Select
                onCustomBlur={() => handleSendEventData(eventTag.inputMaritalStatus)}
                label="Estado civil"
                placeholder="Estado civil"
                fieldState={fieldState}
                options={ESTADO_CIVIL}
                {...field}
                inputRef={ref}
              />
            )}
          />

          <Controller
            name="zipcode"
            control={control}
            render={({ field: { ref, ...field }, fieldState }) => (
              <InputMaskField
                inputMode="numeric"
                data-test-id="zipcode"
                mask="99999-999"
                label="CEP de pernoite do veículo"
                placeholder="CEP de pernoite do veículo"
                fieldState={fieldState}
                {...field}
                inputRef={ref}
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            render={({ field: { ref, ...field }, fieldState }) => (
              <InputMaskField
                onCustomBlur={() => handleSendEventData(eventTag.inputTel)}
                inputMode="numeric"
                label="Telefone / Whatsapp"
                placeholder="Telefone / Whatsapp"
                mask="(99) 99999-9999"
                fieldState={fieldState}
                {...field}
                inputRef={ref}
              />
            )}
          />

          <Controller
            name="email"
            control={control}
            render={({ field: { ref, ...field }, fieldState }) => (
              <InputField
                onCustomBlur={() => handleSendEventData(eventTag.inputEmail)}
                label="E-mail"
                placeholder="E-mail"
                inputMode="email"
                maxLength={120}
                fieldState={fieldState}
                {...field}
                inputRef={ref}
              />
            )}
          />
        </S.SectionContainer>

        {step === 2 && (
          <>
            <S.Separator />
            <S.Section small>Seu veículo é:</S.Section>
            <S.SectionContainer>
              <Controller
                name="Is0KM"
                control={control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <S.FullRow>
                    <ButtonGroup
                      {...field}
                      fieldState={fieldState}
                      selectButton={handleChangeValue}
                      buttons={[
                        {
                          label: 'Usado',
                          value: 'F',
                          icon: <S.CarIcon width="36.8px" height="28.6px" />,
                        },
                        {
                          label: 'Novo',
                          value: 'T',
                          icon: <S.CarNewIcon width="36.8px" height="34.2px" />,
                        },
                      ]}
                    />
                  </S.FullRow>
                )}
              />
              <S.FullRow>
                <S.Section small>Preencha a placa ou chassi do veículo:</S.Section>
              </S.FullRow>
              <Controller
                name="plate"
                control={control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <InputField
                    onCustomBlur={() => {
                      const regexPlaca = /^[A-Z]{3}[0-9][0-9A-Z][0-9]{2}$/i;
                      const value = watch('plate')
                      if  (!value) {
                        return handleSendEventData(eventTag.inputPlaca)
                      }
                      if (value  && regexPlaca.test(value)) {
                        return handleSendEventData(eventTag.inputPlaca)
                      }
                      setError('plate', { message: 'A placa não é válida' })
                    }}
                    label="Placa do veículo"
                    placeholder="Placa do veículo"
                    maxLength={7}
                    fieldState={fieldState}
                    {...field}
                    inputRef={ref}
                  />
                )}
              />
              <Controller
                name="chassis"
                control={control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <InputField
                    onCustomBlur={() => {
                      /*
                      const regexPlaca = /^[A-Za-z0-9]{3,3}[A-Za-z0-9]{6,6}[A-Za-z0-9]{2,2}[A-Za-z0-9]{6,6}$/;
                      const value = watch('chassis')
                      if  (!value) {
                        return handleSendEventData(eventTag.inputChassi)
                      }
                      if (value  && regexPlaca.test(value)) {
                        return handleSendEventData(eventTag.inputChassi)
                      }
                      setError('chassis', { message: 'O chassi não é válido' }) */
                      return handleSendEventData(eventTag.inputChassi)
                    }}
                    label="Chassi do veículo (opcional)"
                    placeholder="Chassi do veículo (opcional)"
                    maxLength={20}
                    fieldState={fieldState}
                    {...field}
                    inputRef={ref}
                  />
                )}
              />
              <Controller
                name="AdaptationCode"
                control={control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <>
                    <Select
                      onCustomBlur={() => handleSendEventData(eventTag.inputAdaptation)}
                      label="O veículo possui alguma adaptação?"
                      placeholder="O veículo possui alguma adaptação?"
                      fieldState={fieldState}
                      options={ADAPTATION_CODE}
                      {...field}
                      inputRef={ref}
                    />

                    {(field.value === '9' || field.value === '99') && (
                      <Controller
                        name="kitGasValue"
                        control={control}
                        render={({
                          field: { ref: refInner, ...fieldInner },
                          fieldState: fieldStateInner,
                        }) => (
                          <InputMoney
                            inputMode="numeric"
                            label="Qual é o valor do Kit-gás?"
                            placeholder="Qual é o valor do Kit-gás?"
                            maxLength={12}
                            fieldState={fieldStateInner}
                            {...fieldInner}
                            inputRef={refInner}
                            onCustonChange={value =>
                              setValue('kitGasValue', value)
                            }
                          />
                        )}
                      />
                    )}

                    {(field.value === '3' || field.value === '99') && (
                      <Controller
                        name="blindagemValue"
                        control={control}
                        render={({
                          field: { ref: refInner, ...fieldInner },
                          fieldState: fieldStateInner,
                        }) => (
                          <InputMoney
                            inputMode="numeric"
                            label="Qual é o valor da blindagem?"
                            placeholder="Qual é o valor da blindagem?"
                            maxLength={12}
                            fieldState={fieldStateInner}
                            {...fieldInner}
                            onCustonChange={value =>
                              setValue('blindagemValue', value)
                            }
                            inputRef={refInner}
                          />
                        )}
                      />
                    )}
                  </>
                )}
              />

              <Controller
                name="LoJackCode"
                control={control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <Select
                    onCustomBlur={() => handleSendEventData(eventTag.inputLoJackCode)}
                    label="O veículo possui rastreador próprio?"
                    placeholder="O veículo possui rastreador próprio?"
                    fieldState={fieldState}
                    options={LO_JACK}
                    {...field}
                    inputRef={ref}
                  />
                )}
              />
            </S.SectionContainer>

            <S.Section>Qual a utilização do veículo?</S.Section>
            <S.SectionContainer>
              <S.FullRow>
                <Controller
                  name="usageCode"
                  control={control}
                  render={({ field, fieldState }) => (
                    <ButtonGroup
                      {...field}
                      fieldState={fieldState}
                      selectButton={handleChangeValue}
                      buttons={[
                        {
                          label: 'Particular',
                          value: '1',
                          icon: (
                            <S.PrivateUsageIcon
                              width="35.048px"
                              height="34.688px"
                            />
                          ),
                        },
                        {
                          label: 'Transporte de pessoas por aplicativo',
                          value: '37',
                          icon: (
                            <S.CommertialUsageIcon
                              width="23.511px"
                              height="32.546px"
                            />
                          ),
                        },
                      ]}
                    />
                  )}
                />
              </S.FullRow>
            </S.SectionContainer>

            <S.Section>Quem é o(a) proprietário(a) do veículo?</S.Section>
            <S.SectionContainer>
              <Controller
                name="VehicleOwnerIsPolicyHolder"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <S.FullRow>
                      <ButtonGroup
                        {...field}
                        fieldState={fieldState}
                        selectButton={handleChangeValue}
                        buttons={[
                          {
                            label: 'Segurado(a)',
                            value: 'T',
                            icon: (
                              <S.InsuredIcon
                                width="25.423px"
                                height="31.518px"
                              />
                            ),
                          },
                          {
                            label: 'Outra pessoa',
                            value: 'F',
                            icon: (
                              <S.AnotherPersonIcon
                                width="36.695px"
                                height="28.88px"
                              />
                            ),
                          },
                        ]}
                      />
                    </S.FullRow>

                    {field.value === 'F' && (
                      <>
                        <Controller
                          name="vinculoProprietario"
                          control={control}
                          render={({
                            field: { ref: refInner, ...fieldInner },
                            fieldState: fieldStateInner,
                          }) => (
                            <Select
                              label="Vínculo com o segurado"
                              placeholder="Vínculo com o segurado"
                              options={
                                hasPotentialDriver
                                  ? OWNER_RELATIONSHIP.filter((owner: any) => {
                                      if (owner.label !== 'Próprio segurado') {
                                        return owner;
                                      }
                                    })
                                  : OWNER_RELATIONSHIP
                              }
                              fieldState={fieldStateInner}
                              {...fieldInner}
                              inputRef={refInner}
                            />
                          )}
                        />

                        <Controller
                          name="maritalStatusProprietario"
                          control={control}
                          render={({
                            field: { ref: refInner, ...fieldInner },
                            fieldState: fieldStateInner,
                          }) => (
                            <Select
                              label="Estado civil do(a) proprietário(a)"
                              placeholder="Estado civil do(a) proprietário(a)"
                              options={ESTADO_CIVIL}
                              fieldState={fieldStateInner}
                              {...fieldInner}
                              inputRef={refInner}
                            />
                          )}
                        />

                        <Controller
                          name="cpfProprietario"
                          control={control}
                          render={({
                            field: { ref: refInner, ...fieldInner },
                            fieldState: fieldStateInner,
                          }) => (
                            <InputMaskField
                              label="CPF do(a) proprietário(a)"
                              placeholder="CPF do(a) proprietário(a)"
                              mask="999.999.999-99"
                              fieldState={fieldStateInner}
                              {...fieldInner}
                              inputRef={refInner}
                            />
                          )}
                        />
                      </>
                    )}
                  </>
                )}
              />
            </S.SectionContainer>

            <S.Section>Quem é o(a) principal condutor(a) do veículo?</S.Section>
            <S.SectionContainer>
              <Controller
                name="potentialDriver"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <S.FullRow>
                      <ButtonGroup
                        {...field}
                        fieldState={fieldState}
                        selectButton={handleChangeValue}
                        buttons={
                          getValues('VehicleOwnerIsPolicyHolder') === 'T'
                            ? driverButtons.filter(
                                button => button.value !== 'P'
                              )
                            : driverButtons
                        }
                      />
                    </S.FullRow>

                    {field.value === 'O' && (
                      <>
                        <Controller
                          name="vinculoCondutorProprietario"
                          control={control}
                          render={({
                            field: { ref: refInner, ...fieldInner },
                            fieldState: fieldStateInner,
                          }) => (
                            <Select
                              label="Vínculo com o segurado"
                              placeholder="Vínculo com o segurado"
                              options={
                                hasPotentialDriver
                                  ? OWNER_RELATIONSHIP_DRIVER.filter(
                                      (owner: any) => {
                                        if (
                                          owner.label !== 'Próprio segurado'
                                        ) {
                                          return owner;
                                        }
                                      }
                                    )
                                  : OWNER_RELATIONSHIP_DRIVER
                              }
                              fieldState={fieldStateInner}
                              {...fieldInner}
                              inputRef={refInner}
                            />
                          )}
                        />

                        <Controller
                          name="maritalStatusCondutor"
                          control={control}
                          render={({
                            field: { ref: refInner, ...fieldInner },
                            fieldState: fieldStateInner,
                          }) => (
                            <Select
                              label="Estado civil do(a) condutor(a)"
                              placeholder="Estado civil do(a) condutor(a)"
                              options={ESTADO_CIVIL}
                              fieldState={fieldStateInner}
                              {...fieldInner}
                              inputRef={refInner}
                            />
                          )}
                        />

                        <Controller
                          name="cpfCondutor"
                          control={control}
                          render={({
                            field: { ref: refInner, ...fieldInner },
                            fieldState: fieldStateInner,
                          }) => (
                            <InputMaskField
                              label="CPF do(a) condutor(a)"
                              placeholder="CPF do(a) condutor(a)"
                              mask="999.999.999-99"
                              fieldState={fieldStateInner}
                              {...fieldInner}
                              inputRef={refInner}
                            />
                          )}
                        />
                      </>
                    )}
                  </>
                )}
              />
            </S.SectionContainer>

            <Controller
              name="question148"
              control={control}
              render={({ field: { ref, ...field }, fieldState }) => (
                <>
                  <S.Section>
                    Reside(m) com pessoa(s) com idade entre 18 a 24 anos?
                  </S.Section>
                  <S.SectionContainer>
                    <S.FullRow>
                      <ButtonGroup
                        {...field}
                        fieldState={fieldState}
                        selectButton={handleChangeValue}
                        buttons={[
                          {
                            label: 'Sim',
                            value: 'T',
                            icon: <S.YesIcon />,
                          },
                          {
                            label: 'Não',
                            value: 'F',
                            icon: <S.NoIcon />,
                          },
                        ]}
                      />
                    </S.FullRow>
                  </S.SectionContainer>

                  {field.value === 'T' && (
                    <Controller
                      name="question149"
                      control={control}
                      render={({
                        field: fieldEstender,
                        fieldState: fieldStateEstender,
                      }) => (
                        <>
                          <S.Section>
                            Deseja estender a cobertura para a(s) pessoa(s)
                            residentes com idade entre 18 e 24 anos?
                          </S.Section>
                          <S.SectionContainer>
                            <S.FullRow>
                              <ButtonGroup
                                {...fieldEstender}
                                fieldState={fieldStateEstender}
                                selectButton={handleChangeValue}
                                buttons={[
                                  {
                                    label: 'Sim, limitada a 2 dias na semana',
                                    value: '1354',
                                    icon: <S.YesIcon />,
                                  },
                                  {
                                    label: 'Sim, superior a 2 dias na semana',
                                    value: '1355',
                                    icon: <S.YesIcon />,
                                  },
                                  {
                                    label: 'Não',
                                    value: '1356',
                                    icon: <S.NoIcon />,
                                  },
                                ]}
                              />
                            </S.FullRow>
                          </S.SectionContainer>
                        </>
                      )}
                    />
                  )}
                  {getValues('question148') === 'T' &&
                    (getValues('question149') === '1354' ||
                      getValues('question149') === '1355') && (
                      <>
                        <S.Section>
                          Qual(is) o(s) sexo(s) da(s) pessoa(s) residente(s) com
                          idade entre 18 e 24 anos?
                        </S.Section>
                        <S.SectionContainer>
                          <S.FullRow>
                            <Controller
                              name="question150"
                              control={control}
                              render={({
                                field: { ref: refGenero, ...fieldGenero },
                                fieldState: fieldStateGenero,
                              }) => (
                                <ButtonGroup
                                  {...fieldGenero}
                                  fieldState={fieldStateGenero}
                                  selectButton={handleChangeValue}
                                  buttons={[
                                    {
                                      label: 'Feminino',
                                      value: '1358',
                                      icon: (
                                        <S.WomanIcon
                                          width="26.108px"
                                          height="31.959px"
                                        />
                                      ),
                                    },
                                    {
                                      label: 'Masculino',
                                      value: '1359',
                                      icon: (
                                        <S.ManIcon
                                          width="25.423px"
                                          height="31.518px"
                                        />
                                      ),
                                    },
                                    {
                                      label: 'Ambos',
                                      value: '1360',
                                      icon: (
                                        <S.GroupIcon>
                                          <S.WomanIcon
                                            width="26.108px"
                                            height="31.959px"
                                          />
                                          <S.ManIcon
                                            width="25.423px"
                                            height="31.518px"
                                          />
                                        </S.GroupIcon>
                                      ),
                                    },
                                  ]}
                                />
                              )}
                            />
                          </S.FullRow>
                        </S.SectionContainer>
                      </>
                    )}
                </>
              )}
            />

            <Controller
              name="hasPreviousCoverage"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <S.Section>O veículo possui seguro atualmente?</S.Section>
                  <S.SectionContainer>
                    <S.FullRow>
                      <ButtonGroup
                        {...field}
                        fieldState={fieldState}
                        selectButton={handleChangeValue}
                        buttons={[
                          {
                            label: 'Sim',
                            value: 'T',
                            icon: <S.YesIcon />,
                          },
                          {
                            label: 'Não',
                            value: 'F',
                            icon: <S.NoIcon />,
                          },
                        ]}
                      />
                    </S.FullRow>
                  </S.SectionContainer>

                  {field.value === 'T' && (
                    <>
                      <S.SectionContainer>
                        <Controller
                          name="PolicyEndDate"
                          control={control}
                          render={({
                            field: { ref, ...fieldEndDate },
                            fieldState: fieldStateEndDate,
                          }) => (
                            <InputMaskField
                              label="Data de vencimento da apólice atual"
                              placeholder="Data de vencimento da apólice atual"
                              inputMode="numeric"
                              mask="99/99/9999"
                              fieldState={fieldStateEndDate}
                              {...fieldEndDate}
                              inputRef={ref}
                            />
                          )}
                        />
                        <Controller
                          name="BonusClassCode"
                          control={control}
                          render={({
                            field: { ref, ...fieldCategory },
                            fieldState: fieldStateCategory,
                          }) => (
                            <InputField
                              label="Qual a classe de bônus da apólice atual?"
                              placeholder="Qual a classe de bônus da apólice atual?"
                              inputMode="numeric"
                              maxLength={2}
                              fieldState={fieldStateCategory}
                              {...fieldCategory}
                              inputRef={ref}
                            />
                          )}
                        />
                      </S.SectionContainer>

                      <S.Section>O veículo sofreu um sinistro?</S.Section>
                      <S.SectionContainer>
                        <S.FullRow>
                          <Controller
                            name="HasClaim"
                            control={control}
                            render={({
                              field: { ref: refSinistro, ...fieldSinistro },
                              fieldState: fieldStateSinistro,
                            }) => (
                              <ButtonGroup
                                {...fieldSinistro}
                                fieldState={fieldStateSinistro}
                                selectButton={handleChangeValue}
                                buttons={[
                                  {
                                    label: 'Sim',
                                    value: 'T',
                                    icon: <S.YesIcon />,
                                  },
                                  {
                                    label: 'Não',
                                    value: 'F',
                                    icon: <S.NoIcon />,
                                  },
                                ]}
                              />
                            )}
                          />
                        </S.FullRow>
                      </S.SectionContainer>

                      <S.Section>
                        A apólice será renovada com o mesmo veículo?
                      </S.Section>
                      <S.SectionContainer>
                        <S.FullRow>
                          <Controller
                            name="IsVhclSubstitutionRenewal"
                            control={control}
                            render={({
                              field: fieldMesmoVeiculo,
                              fieldState: fieldStateMesmoVeiculo,
                            }) => (
                              <ButtonGroup
                                {...fieldMesmoVeiculo}
                                fieldState={fieldStateMesmoVeiculo}
                                selectButton={handleChangeValue}
                                buttons={[
                                  {
                                    label: 'Sim',
                                    value: 'F',
                                    icon: <S.YesIcon />,
                                  },
                                  {
                                    label: 'Não',
                                    value: 'T',
                                    icon: <S.NoIcon />,
                                  },
                                ]}
                              />
                            )}
                          />
                        </S.FullRow>
                      </S.SectionContainer>

                      <S.Section>
                        O veículo possui cobertura contra Roubo, Furto e
                        Colisão?
                      </S.Section>
                      <S.SectionContainer>
                        <S.FullRow>
                          <Controller
                            name="IsCvrgChangeRenewal"
                            control={control}
                            render={({
                              field: fieldCoberturaRoubo,
                              fieldState: fieldStateCoberturaRoubo,
                            }) => (
                              <ButtonGroup
                                {...fieldCoberturaRoubo}
                                fieldState={fieldStateCoberturaRoubo}
                                selectButton={handleChangeValue}
                                buttons={[
                                  {
                                    label: 'Sim',
                                    value: 'F',
                                    icon: <S.YesIcon />,
                                  },
                                  {
                                    label: 'Não',
                                    value: 'T',
                                    icon: <S.NoIcon />,
                                  },
                                ]}
                              />
                            )}
                          />
                        </S.FullRow>
                      </S.SectionContainer>
                    </>
                  )}
                </>
              )}
            />

            <S.Section>O segurado é funcionário?</S.Section>
            <S.SectionContainer>
              <S.FullRow>
                <Controller
                  name="PolicyHolderIsEmployee"
                  control={control}
                  render={({ field, fieldState }) => (
                    <ButtonGroup
                      {...field}
                      fieldState={fieldState}
                      selectButton={handleChangeValue}
                      buttons={[
                        {
                          label: 'Sim',
                          value: 'T',
                          icon: <S.YesIcon />,
                        },
                        {
                          label: 'Não',
                          value: 'F',
                          icon: <S.NoIcon />,
                        },
                      ]}
                    />
                  )}
                />
              </S.FullRow>
            </S.SectionContainer>
          </>
        )}

        <S.PersonalContainer>
          <div>
            <S.LockIcon />
          </div>
          <S.PersonalDataText>
            Seus dados estão protegidos e serão utilizados única e
            exclusivamente para iniciar um processo de cotação de seguro a seu
            favor.{' '}
            <a
              href={getPersonalDataLink()}
              onClick={() => handleSendEventData(eventTag.linkLgpd)}
              target="#"
            >
              {' '}
              Saiba mais
            </a>
            .
          </S.PersonalDataText>
        </S.PersonalContainer>

        <Controller
          name="agree"
          control={control}
          render={({ field: { ref, value, ...rest }, fieldState }) => {
            if (value !== undefined) {
              handleSendEventData(eventTag.btnContact)
            }
            return (
            <S.AgreeContainer>
              <div>
                <Checkbox
                  fieldState={fieldState}
                  {...rest}
                  inputRef={ref}
                  checked={!!value}
                />
              </div>
              <S.AgreeText>
                Aceito receber o contato do corretor de seguros ofertando o
                melhor produto de seguro.<span>*</span>
                {fieldState.error?.message && (
                  <ErrorMessage message={fieldState.error?.message} />
                )}
              </S.AgreeText>
            </S.AgreeContainer>
            )
          }}
        />

        <S.ButtonContainer>
          <Button type="submit" onClick={handleSubmit(onSubmit, onError)}>
            {step === 2 ? 'Solicitar cotação' : 'Continuar'}
          </Button>
        </S.ButtonContainer>
       </S.Form>
      :
      <S.Form>
        <S.SocialNameSectionContainer>
          <Controller
            name="socialName"
            control={control}
            render={({ field: { ref, ...field }, fieldState }) => (
              <InputField
                onCustomBlur={() => handleSendEventData(eventTag.inputName)}
                label="Como gostaria de ser chamado?"
                placeholder="Como gostaria de ser chamado?"
                maxLength={45}
                fieldState={fieldState}
                {...field}
                inputRef={ref}
              />
            )}
          />
          <S.SocialNameTooltipContainer>
          <Tooltip
          style={{ margin: '16px 0 0 15px' }}
          description={
            <>
              É o nome pelo qual a pessoa se identifica se auto identifica e prefere ser chamada, 
              e pelo qual é socialmente reconhecida.
            </>
          }
          />
          </S.SocialNameTooltipContainer>
          </S.SocialNameSectionContainer>
          <S.SectionContainer>
          <Controller
            name="cpfSegurado"
            control={control}
            render={({ field: { ref, ...field }, fieldState }) => (
              <InputMaskField
                onCustomBlur={() => handleSendEventData(eventTag.inputCPF)}
                label="CPF do(a) segurado(a)"
                placeholder="CPF do(a) segurado(a)"
                mask="999.999.999-99"
                fieldState={fieldState}
                onBlurCapture={() => {
                  window.localStorage.setItem(LOCAL_CPF, watch('cpfSegurado'))
                }}
                {...field}
                inputRef={ref}
              />
            )}
          />
          <Controller
            name="maritalStatus"
            control={control}
            render={({ field: { ref, ...field }, fieldState }) => (
              <Select
                onCustomBlur={() => handleSendEventData(eventTag.inputMaritalStatus)}
                label="Estado civil"
                placeholder="Estado civil"
                fieldState={fieldState}
                options={ESTADO_CIVIL}
                {...field}
                inputRef={ref}
              />
            )}
          />

          <Controller
            name="zipcode"
            control={control}
            render={({ field: { ref, ...field }, fieldState }) => (
              <InputMaskField
                inputMode="numeric"
                data-test-id="zipcode"
                mask="99999-999"
                label="CEP de pernoite do veículo"
                placeholder="CEP de pernoite do veículo"
                fieldState={fieldState}
                {...field}
                inputRef={ref}
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            render={({ field: { ref, ...field }, fieldState }) => (
              <InputMaskField
                onCustomBlur={() => handleSendEventData(eventTag.inputTel)}
                inputMode="numeric"
                label="Telefone / Whatsapp"
                placeholder="Telefone / Whatsapp"
                mask="(99) 99999-9999"
                fieldState={fieldState}
                {...field}
                inputRef={ref}
              />
            )}
          />

          <Controller
            name="email"
            control={control}
            render={({ field: { ref, ...field }, fieldState }) => (
              <InputField
                onCustomBlur={() => handleSendEventData(eventTag.inputEmail)}
                label="E-mail"
                placeholder="E-mail"
                inputMode="email"
                maxLength={120}
                fieldState={fieldState}
                {...field}
                inputRef={ref}
              />
            )}
          />
        </S.SectionContainer>

        {step === 2 && (
          <>
            <S.Separator />
            <S.Section small>Seu veículo é:</S.Section>
            <S.SectionContainer>
              <Controller
                name="Is0KM"
                control={control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <S.FullRow>
                    <ButtonGroup
                      {...field}
                      fieldState={fieldState}
                      selectButton={handleChangeValue}
                      buttons={[
                        {
                          label: 'Usado',
                          value: 'F',
                          icon: <S.CarIcon width="36.8px" height="28.6px" />,
                        },
                        {
                          label: 'Novo',
                          value: 'T',
                          icon: <S.CarNewIcon width="36.8px" height="34.2px" />,
                        },
                      ]}
                    />
                  </S.FullRow>
                )}
              />
              <S.FullRow>
                <S.Section small>Preencha a placa ou chassi do veículo:</S.Section>
              </S.FullRow>
              <Controller
                name="plate"
                control={control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <InputField
                    onCustomBlur={() => {
                      const regexPlaca = /^[A-Z]{3}[0-9][0-9A-Z][0-9]{2}$/i;
                      const value = watch('plate')
                      if  (!value) {
                        return handleSendEventData(eventTag.inputPlaca)
                      }
                      if (value  && regexPlaca.test(value)) {
                        return handleSendEventData(eventTag.inputPlaca)
                      }
                      setError('plate', { message: 'A placa não é válida' })
                    }}
                    label="Placa do veículo"
                    placeholder="Placa do veículo"
                    maxLength={7}
                    fieldState={fieldState}
                    {...field}
                    inputRef={ref}
                  />
                )}
              />
              <Controller
                name="chassis"
                control={control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <InputField
                    onCustomBlur={() => {
                      /*
                      const regexPlaca = /^[A-Za-z0-9]{3,3}[A-Za-z0-9]{6,6}[A-Za-z0-9]{2,2}[A-Za-z0-9]{6,6}$/;
                      const value = watch('chassis')
                      if  (!value) {
                        return handleSendEventData(eventTag.inputChassi)
                      }
                      if (value  && regexPlaca.test(value)) {
                        return handleSendEventData(eventTag.inputChassi)
                      }
                      setError('chassis', { message: 'O chassi não é válido' }) */
                      return handleSendEventData(eventTag.inputChassi)
                    }}
                    label="Chassi do veículo (opcional)"
                    placeholder="Chassi do veículo (opcional)"
                    maxLength={20}
                    fieldState={fieldState}
                    {...field}
                    inputRef={ref}
                  />
                )}
              />
              <Controller
                name="AdaptationCode"
                control={control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <>
                    <Select
                      onCustomBlur={() => handleSendEventData(eventTag.inputAdaptation)}
                      label="O veículo possui alguma adaptação?"
                      placeholder="O veículo possui alguma adaptação?"
                      fieldState={fieldState}
                      options={ADAPTATION_CODE}
                      {...field}
                      inputRef={ref}
                    />

                    {(field.value === '9' || field.value === '99') && (
                      <Controller
                        name="kitGasValue"
                        control={control}
                        render={({
                          field: { ref: refInner, ...fieldInner },
                          fieldState: fieldStateInner,
                        }) => (
                          <InputMoney
                            inputMode="numeric"
                            label="Qual é o valor do Kit-gás?"
                            placeholder="Qual é o valor do Kit-gás?"
                            maxLength={12}
                            fieldState={fieldStateInner}
                            {...fieldInner}
                            inputRef={refInner}
                            onCustonChange={value =>
                              setValue('kitGasValue', value)
                            }
                          />
                        )}
                      />
                    )}

                    {(field.value === '3' || field.value === '99') && (
                      <Controller
                        name="blindagemValue"
                        control={control}
                        render={({
                          field: { ref: refInner, ...fieldInner },
                          fieldState: fieldStateInner,
                        }) => (
                          <InputMoney
                            inputMode="numeric"
                            label="Qual é o valor da blindagem?"
                            placeholder="Qual é o valor da blindagem?"
                            maxLength={12}
                            fieldState={fieldStateInner}
                            {...fieldInner}
                            onCustonChange={value =>
                              setValue('blindagemValue', value)
                            }
                            inputRef={refInner}
                          />
                        )}
                      />
                    )}
                  </>
                )}
              />

              <Controller
                name="LoJackCode"
                control={control}
                render={({ field: { ref, ...field }, fieldState }) => (
                  <Select
                    onCustomBlur={() => handleSendEventData(eventTag.inputLoJackCode)}
                    label="O veículo possui rastreador próprio?"
                    placeholder="O veículo possui rastreador próprio?"
                    fieldState={fieldState}
                    options={LO_JACK}
                    {...field}
                    inputRef={ref}
                  />
                )}
              />
            </S.SectionContainer>

            <S.Section>Qual a utilização do veículo?</S.Section>
            <S.SectionContainer>
              <S.FullRow>
                <Controller
                  name="usageCode"
                  control={control}
                  render={({ field, fieldState }) => (
                    <ButtonGroup
                      {...field}
                      fieldState={fieldState}
                      selectButton={handleChangeValue}
                      buttons={[
                        {
                          label: 'Particular',
                          value: '1',
                          icon: (
                            <S.PrivateUsageIcon
                              width="35.048px"
                              height="34.688px"
                            />
                          ),
                        },
                        {
                          label: 'Transporte de pessoas por aplicativo',
                          value: '37',
                          icon: (
                            <S.CommertialUsageIcon
                              width="23.511px"
                              height="32.546px"
                            />
                          ),
                        },
                      ]}
                    />
                  )}
                />
              </S.FullRow>
            </S.SectionContainer>

            <S.Section>Quem é o(a) proprietário(a) do veículo?</S.Section>
            <S.SectionContainer>
              <Controller
                name="VehicleOwnerIsPolicyHolder"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <S.FullRow>
                      <ButtonGroup
                        {...field}
                        fieldState={fieldState}
                        selectButton={handleChangeValue}
                        buttons={[
                          {
                            label: 'Segurado(a)',
                            value: 'T',
                            icon: (
                              <S.InsuredIcon
                                width="25.423px"
                                height="31.518px"
                              />
                            ),
                          },
                          {
                            label: 'Outra pessoa',
                            value: 'F',
                            icon: (
                              <S.AnotherPersonIcon
                                width="36.695px"
                                height="28.88px"
                              />
                            ),
                          },
                        ]}
                      />
                    </S.FullRow>

                    {field.value === 'F' && (
                      <>
                        <Controller
                          name="vinculoProprietario"
                          control={control}
                          render={({
                            field: { ref: refInner, ...fieldInner },
                            fieldState: fieldStateInner,
                          }) => (
                            <Select
                              label="Vínculo com o segurado"
                              placeholder="Vínculo com o segurado"
                              options={
                                hasPotentialDriver
                                  ? OWNER_RELATIONSHIP.filter((owner: any) => {
                                      if (owner.label !== 'Próprio segurado') {
                                        return owner;
                                      }
                                    })
                                  : OWNER_RELATIONSHIP
                              }
                              fieldState={fieldStateInner}
                              {...fieldInner}
                              inputRef={refInner}
                            />
                          )}
                        />

                        <Controller
                          name="maritalStatusProprietario"
                          control={control}
                          render={({
                            field: { ref: refInner, ...fieldInner },
                            fieldState: fieldStateInner,
                          }) => (
                            <Select
                              label="Estado civil do(a) proprietário(a)"
                              placeholder="Estado civil do(a) proprietário(a)"
                              options={ESTADO_CIVIL}
                              fieldState={fieldStateInner}
                              {...fieldInner}
                              inputRef={refInner}
                            />
                          )}
                        />

                        <Controller
                          name="cpfProprietario"
                          control={control}
                          render={({
                            field: { ref: refInner, ...fieldInner },
                            fieldState: fieldStateInner,
                          }) => (
                            <InputMaskField
                              label="CPF do(a) proprietário(a)"
                              placeholder="CPF do(a) proprietário(a)"
                              mask="999.999.999-99"
                              fieldState={fieldStateInner}
                              {...fieldInner}
                              inputRef={refInner}
                            />
                          )}
                        />
                      </>
                    )}
                  </>
                )}
              />
            </S.SectionContainer>

            <S.Section>Quem é o(a) principal condutor(a) do veículo?</S.Section>
            <S.SectionContainer>
              <Controller
                name="potentialDriver"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <S.FullRow>
                      <ButtonGroup
                        {...field}
                        fieldState={fieldState}
                        selectButton={handleChangeValue}
                        buttons={
                          getValues('VehicleOwnerIsPolicyHolder') === 'T'
                            ? driverButtons.filter(
                                button => button.value !== 'P'
                              )
                            : driverButtons
                        }
                      />
                    </S.FullRow>

                    {field.value === 'O' && (
                      <>
                        <Controller
                          name="vinculoCondutorProprietario"
                          control={control}
                          render={({
                            field: { ref: refInner, ...fieldInner },
                            fieldState: fieldStateInner,
                          }) => (
                            <Select
                              label="Vínculo com o segurado"
                              placeholder="Vínculo com o segurado"
                              options={
                                hasPotentialDriver
                                  ? OWNER_RELATIONSHIP_DRIVER.filter(
                                      (owner: any) => {
                                        if (
                                          owner.label !== 'Próprio segurado'
                                        ) {
                                          return owner;
                                        }
                                      }
                                    )
                                  : OWNER_RELATIONSHIP_DRIVER
                              }
                              fieldState={fieldStateInner}
                              {...fieldInner}
                              inputRef={refInner}
                            />
                          )}
                        />

                        <Controller
                          name="maritalStatusCondutor"
                          control={control}
                          render={({
                            field: { ref: refInner, ...fieldInner },
                            fieldState: fieldStateInner,
                          }) => (
                            <Select
                              label="Estado civil do(a) condutor(a)"
                              placeholder="Estado civil do(a) condutor(a)"
                              options={ESTADO_CIVIL}
                              fieldState={fieldStateInner}
                              {...fieldInner}
                              inputRef={refInner}
                            />
                          )}
                        />

                        <Controller
                          name="cpfCondutor"
                          control={control}
                          render={({
                            field: { ref: refInner, ...fieldInner },
                            fieldState: fieldStateInner,
                          }) => (
                            <InputMaskField
                              label="CPF do(a) condutor(a)"
                              placeholder="CPF do(a) condutor(a)"
                              mask="999.999.999-99"
                              fieldState={fieldStateInner}
                              {...fieldInner}
                              inputRef={refInner}
                            />
                          )}
                        />
                      </>
                    )}
                  </>
                )}
              />
            </S.SectionContainer>

            <Controller
              name="question148"
              control={control}
              render={({ field: { ref, ...field }, fieldState }) => (
                <>
                  <S.Section>
                    Reside(m) com pessoa(s) com idade entre 18 a 24 anos?
                  </S.Section>
                  <S.SectionContainer>
                    <S.FullRow>
                      <ButtonGroup
                        {...field}
                        fieldState={fieldState}
                        selectButton={handleChangeValue}
                        buttons={[
                          {
                            label: 'Sim',
                            value: 'T',
                            icon: <S.YesIcon />,
                          },
                          {
                            label: 'Não',
                            value: 'F',
                            icon: <S.NoIcon />,
                          },
                        ]}
                      />
                    </S.FullRow>
                  </S.SectionContainer>

                  {field.value === 'T' && (
                    <Controller
                      name="question149"
                      control={control}
                      render={({
                        field: fieldEstender,
                        fieldState: fieldStateEstender,
                      }) => (
                        <>
                          <S.Section>
                            Deseja estender a cobertura para a(s) pessoa(s)
                            residentes com idade entre 18 e 24 anos?
                          </S.Section>
                          <S.SectionContainer>
                            <S.FullRow>
                              <ButtonGroup
                                {...fieldEstender}
                                fieldState={fieldStateEstender}
                                selectButton={handleChangeValue}
                                buttons={[
                                  {
                                    label: 'Sim, limitada a 2 dias na semana',
                                    value: '1354',
                                    icon: <S.YesIcon />,
                                  },
                                  {
                                    label: 'Sim, superior a 2 dias na semana',
                                    value: '1355',
                                    icon: <S.YesIcon />,
                                  },
                                  {
                                    label: 'Não',
                                    value: '1356',
                                    icon: <S.NoIcon />,
                                  },
                                ]}
                              />
                            </S.FullRow>
                          </S.SectionContainer>
                        </>
                      )}
                    />
                  )}
                  {getValues('question148') === 'T' &&
                    (getValues('question149') === '1354' ||
                      getValues('question149') === '1355') && (
                      <>
                        <S.Section>
                          Qual(is) o(s) sexo(s) da(s) pessoa(s) residente(s) com
                          idade entre 18 e 24 anos?
                        </S.Section>
                        <S.SectionContainer>
                          <S.FullRow>
                            <Controller
                              name="question150"
                              control={control}
                              render={({
                                field: { ref: refGenero, ...fieldGenero },
                                fieldState: fieldStateGenero,
                              }) => (
                                <ButtonGroup
                                  {...fieldGenero}
                                  fieldState={fieldStateGenero}
                                  selectButton={handleChangeValue}
                                  buttons={[
                                    {
                                      label: 'Feminino',
                                      value: '1358',
                                      icon: (
                                        <S.WomanIcon
                                          width="26.108px"
                                          height="31.959px"
                                        />
                                      ),
                                    },
                                    {
                                      label: 'Masculino',
                                      value: '1359',
                                      icon: (
                                        <S.ManIcon
                                          width="25.423px"
                                          height="31.518px"
                                        />
                                      ),
                                    },
                                    {
                                      label: 'Ambos',
                                      value: '1360',
                                      icon: (
                                        <S.GroupIcon>
                                          <S.WomanIcon
                                            width="26.108px"
                                            height="31.959px"
                                          />
                                          <S.ManIcon
                                            width="25.423px"
                                            height="31.518px"
                                          />
                                        </S.GroupIcon>
                                      ),
                                    },
                                  ]}
                                />
                              )}
                            />
                          </S.FullRow>
                        </S.SectionContainer>
                      </>
                    )}
                </>
              )}
            />

            <Controller
              name="hasPreviousCoverage"
              control={control}
              render={({ field, fieldState }) => (
                <>
                  <S.Section>O veículo possui seguro atualmente?</S.Section>
                  <S.SectionContainer>
                    <S.FullRow>
                      <ButtonGroup
                        {...field}
                        fieldState={fieldState}
                        selectButton={handleChangeValue}
                        buttons={[
                          {
                            label: 'Sim',
                            value: 'T',
                            icon: <S.YesIcon />,
                          },
                          {
                            label: 'Não',
                            value: 'F',
                            icon: <S.NoIcon />,
                          },
                        ]}
                      />
                    </S.FullRow>
                  </S.SectionContainer>

                  {field.value === 'T' && (
                    <>
                      <S.SectionContainer>
                        <Controller
                          name="PolicyEndDate"
                          control={control}
                          render={({
                            field: { ref, ...fieldEndDate },
                            fieldState: fieldStateEndDate,
                          }) => (
                            <InputMaskField
                              label="Data de vencimento da apólice atual"
                              placeholder="Data de vencimento da apólice atual"
                              inputMode="numeric"
                              mask="99/99/9999"
                              fieldState={fieldStateEndDate}
                              {...fieldEndDate}
                              inputRef={ref}
                            />
                          )}
                        />
                        <Controller
                          name="BonusClassCode"
                          control={control}
                          render={({
                            field: { ref, ...fieldCategory },
                            fieldState: fieldStateCategory,
                          }) => (
                            <InputField
                              label="Qual a classe de bônus da apólice atual?"
                              placeholder="Qual a classe de bônus da apólice atual?"
                              inputMode="numeric"
                              maxLength={2}
                              fieldState={fieldStateCategory}
                              {...fieldCategory}
                              inputRef={ref}
                            />
                          )}
                        />
                      </S.SectionContainer>

                      <S.Section>O veículo sofreu um sinistro?</S.Section>
                      <S.SectionContainer>
                        <S.FullRow>
                          <Controller
                            name="HasClaim"
                            control={control}
                            render={({
                              field: { ref: refSinistro, ...fieldSinistro },
                              fieldState: fieldStateSinistro,
                            }) => (
                              <ButtonGroup
                                {...fieldSinistro}
                                fieldState={fieldStateSinistro}
                                selectButton={handleChangeValue}
                                buttons={[
                                  {
                                    label: 'Sim',
                                    value: 'T',
                                    icon: <S.YesIcon />,
                                  },
                                  {
                                    label: 'Não',
                                    value: 'F',
                                    icon: <S.NoIcon />,
                                  },
                                ]}
                              />
                            )}
                          />
                        </S.FullRow>
                      </S.SectionContainer>

                      <S.Section>
                        A apólice será renovada com o mesmo veículo?
                      </S.Section>
                      <S.SectionContainer>
                        <S.FullRow>
                          <Controller
                            name="IsVhclSubstitutionRenewal"
                            control={control}
                            render={({
                              field: fieldMesmoVeiculo,
                              fieldState: fieldStateMesmoVeiculo,
                            }) => (
                              <ButtonGroup
                                {...fieldMesmoVeiculo}
                                fieldState={fieldStateMesmoVeiculo}
                                selectButton={handleChangeValue}
                                buttons={[
                                  {
                                    label: 'Sim',
                                    value: 'F',
                                    icon: <S.YesIcon />,
                                  },
                                  {
                                    label: 'Não',
                                    value: 'T',
                                    icon: <S.NoIcon />,
                                  },
                                ]}
                              />
                            )}
                          />
                        </S.FullRow>
                      </S.SectionContainer>

                      <S.Section>
                        O veículo possui cobertura contra Roubo, Furto e
                        Colisão?
                      </S.Section>
                      <S.SectionContainer>
                        <S.FullRow>
                          <Controller
                            name="IsCvrgChangeRenewal"
                            control={control}
                            render={({
                              field: fieldCoberturaRoubo,
                              fieldState: fieldStateCoberturaRoubo,
                            }) => (
                              <ButtonGroup
                                {...fieldCoberturaRoubo}
                                fieldState={fieldStateCoberturaRoubo}
                                selectButton={handleChangeValue}
                                buttons={[
                                  {
                                    label: 'Sim',
                                    value: 'F',
                                    icon: <S.YesIcon />,
                                  },
                                  {
                                    label: 'Não',
                                    value: 'T',
                                    icon: <S.NoIcon />,
                                  },
                                ]}
                              />
                            )}
                          />
                        </S.FullRow>
                      </S.SectionContainer>
                    </>
                  )}
                </>
              )}
            />

            <S.Section>O segurado é funcionário?</S.Section>
            <S.SectionContainer>
              <S.FullRow>
                <Controller
                  name="PolicyHolderIsEmployee"
                  control={control}
                  render={({ field, fieldState }) => (
                    <ButtonGroup
                      {...field}
                      fieldState={fieldState}
                      selectButton={handleChangeValue}
                      buttons={[
                        {
                          label: 'Sim',
                          value: 'T',
                          icon: <S.YesIcon />,
                        },
                        {
                          label: 'Não',
                          value: 'F',
                          icon: <S.NoIcon />,
                        },
                      ]}
                    />
                  )}
                />
              </S.FullRow>
            </S.SectionContainer>
          </>
        )}

        <S.PersonalContainer>
          <div>
            <S.LockIcon />
          </div>
          <S.PersonalDataText>
            Seus dados estão protegidos e serão utilizados única e
            exclusivamente para iniciar um processo de cotação de seguro a seu
            favor.{' '}
            <a
              href={getPersonalDataLink()}
              onClick={() => handleSendEventData(eventTag.linkLgpd)}
              target="#"
            >
              {' '}
              Saiba mais
            </a>
            .
          </S.PersonalDataText>
        </S.PersonalContainer>

        <Controller
          name="agree"
          control={control}
          render={({ field: { ref, value, ...rest }, fieldState }) => {
            if (value !== undefined) {
              handleSendEventData(eventTag.btnContact)
            }
            return (
            <S.AgreeContainer>
              <div>
                <Checkbox
                  fieldState={fieldState}
                  {...rest}
                  inputRef={ref}
                  checked={!!value}
                />
              </div>
              <S.AgreeText>
                Aceito receber o contato do corretor de seguros ofertando o
                melhor produto de seguro.<span>*</span>
                {fieldState.error?.message && (
                  <ErrorMessage message={fieldState.error?.message} />
                )}
              </S.AgreeText>
            </S.AgreeContainer>
            )
          }}
        />

        <S.ButtonContainer>
          <Button type="submit" onClick={handleSubmit(onSubmit, onError)}>
            {step === 2 ? 'Solicitar cotação' : 'Continuar'}
          </Button>
        </S.ButtonContainer>
       </S.Form>
      }
      </S.Container>
  )
}
