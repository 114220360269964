/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
/* eslint-disable func-names */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  useEffect,
} from 'react';

import api from 'api';
import { getToken } from 'api/services/security';
import { brokerData, brokerInfo } from 'api/services/broker';

import { useGetTokenInURL } from 'hooks/useGetTokenInURL';
import { IBrokerData } from 'types/broker';

import { LOCAL_BROKER } from 'config';
import { useNavigate } from 'react-router-dom';
import { useLoading } from './Loading';

export interface IBroker {
  token?: string;
  brokerCode: string;
  brokerBranchCode: string;
  internalBranchCode: string;
  BrokerLogoUrl: string;
}

interface BrokerContextData {
  broker?: IBrokerData;
  setBroker(broker: IBrokerData): void;
  getBrokerName(): string;
  currentBroker: any;
  isAffinity: any;
}

const BrokerContext = createContext<BrokerContextData>({} as BrokerContextData);

const BrokerProvider = ({ children }: any) => {
  const [currentBroker, setCurrentBroker] = useState({} as IBrokerData);
  const { showLoading } = useLoading();
  const redirect = useNavigate();

  useEffect(() => {
    searchToken();
  }, []);

  const setBroker = useCallback(
    (broker: any) => {
      setCurrentBroker(broker);
    },
    [setCurrentBroker]
  );

  const getBrokerName = () => {
    const { nomeCliente, contact } = currentBroker ?? {};
    return contact?.nome?.length ? contact.nome : nomeCliente;
  };

  const getBrokerContact = async (brokerDataParam: IBrokerData) => {
    const response = await brokerInfo(brokerDataParam);

    if (response.data) {
      const jwt = String(api.defaults.headers.common?.Authorization) ?? '';
      const brokerInfos = {
        ...brokerDataParam,
        jwt,
        token: true,
        contact: response.data.contato,
      };

      setCurrentBroker(brokerInfos);
      window.localStorage.setItem(LOCAL_BROKER, JSON.stringify(brokerInfos));
      // const [noToken, withToken] = window.location.href.split('/?token=');
      // window.location.href = noToken;
    }
  };

  const getBrokerData = async (tokenURL: string) => {
    showLoading(true);
    const response = await brokerData({ token: tokenURL });
    if (response.data) {
      const { logo, name, user, ...rest } = response.data;
      const brokerInfoData = {
        brokerToken: tokenURL,
        nomeCliente: name,
        logo,
        brokerUser: user,
        ...rest,
      } as unknown as IBrokerData;
      await getBrokerContact(brokerInfoData);
    } else {
      throw new Error('Não foi possivel localizar o broker.');
    }
    showLoading(false);
  };

  const searchToken = async () => {
    try {
      showLoading(true);
      const response: any = await getToken();
      const tokenURL = window.location.href.split('/?token=')[1];
      api.defaults.headers.common.Authorization = `Bearer ${response.data.access_token}`;
      if (response.data) {
        if (tokenURL) {
          const teste = await getBrokerData(tokenURL);
          redirect('/');
        } else {
          const brokerDataLocalStorage =
            window.localStorage.getItem(LOCAL_BROKER);
          const info = brokerDataLocalStorage
            ? JSON.parse(brokerDataLocalStorage)
            : {};
          setCurrentBroker(info);

          window.localStorage.setItem(
            LOCAL_BROKER,
            JSON.stringify({
              ...info,
              token: false,
              jwt: `Bearer ${response.data.access_token}`,
            })
          );
        }
      }
    } catch (ex) {
      redirect('/denied');
    } finally {
      showLoading(false);
    }
  };

  let isAffinity: any;

  return (
    <BrokerContext.Provider
      value={{
        broker: currentBroker,
        setBroker,
        getBrokerName,
        currentBroker,
        isAffinity,
      }}
    >
      {children}
    </BrokerContext.Provider>
  );
};

function useBroker() {
  const context = useContext(BrokerContext);
  if (!context)
    throw new Error(
      'O UseBrokerContext precisa ser utilizado dentro do useBroker'
    );

  return context;
}

export { BrokerProvider, useBroker };
