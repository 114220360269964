/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Card } from 'components/Card';

import { MailSVG } from 'assets/img/MailSVG';
import { PinSVG } from 'assets/img/PinSVG';
import { PhoneSVG } from 'assets/img/PhoneSVG';
import { InstagramSVG } from 'assets/img/InstagramSVG';
import { FacebookSVG } from 'assets/img/FacebookSVG';
import { WebsiteSVG } from 'assets/img/WebsiteSVG';

import { formatCEP } from '@brazilian-utils/brazilian-utils';
import { formatPhone } from 'helpers/format';
import { googleAnalyticsEvent } from 'helpers/googleTagManager';

import { addHttp } from 'helpers/links';
import { useSearchLogo } from 'hooks/useSearchLogo';
import { useBroker } from 'contexts/Broker';

import * as S from './styles';
import { eventTag, eventTagMap } from './helper';

const BrokerCard = () => {
  const { broker, getBrokerName } = useBroker();
  const [logo] = useSearchLogo();

  const { site, instagram, facebook, email, telefone } = broker?.contact || {};
  const showEmail = email?.length ? email : broker?.email;
  const showName = getBrokerName();
  const phone = formatPhone(telefone || (broker?.phone || ''));
  const { city, complement, district, number, state, street, type, zipcode } =
    broker?.address || {};

  const address = (
    <>
      {type} {street}
      {number ? `, ${number}` : ''}
      <br />
      {complement ? `, ${complement}` : ''}
      {district}, {city} -{state}
      <br />
      CEP: {formatCEP(zipcode ?? '')}
    </>
  );

  const handleSendEventData = (key: string) => {
    googleAnalyticsEvent({
      dataLayer: eventTagMap[key]
    });
  }

  return (
    <Card>
      {showName && showEmail && phone ? (<S.BrokerCard>
        <S.BrokerName>{showName}</S.BrokerName>
        <S.BrokerContact>
          <S.BrokerMail>
            <MailSVG />
            <a href={`mailto:${showEmail}`} onClick={() => handleSendEventData(eventTag.linkEmail)}>
              {showEmail}
            </a>
          </S.BrokerMail>
          <div>
            <PhoneSVG />
            <a
              href={`tel:${phone}`}              
            >
              {phone}
            </a>
          </div>
          <div>
            <PinSVG />
            <span>{address}</span>
          </div>
          {!!site && (
            <S.BrokerWebsite>
              <WebsiteSVG />
              <a href={addHttp(site)} target="blank" onClick={() => handleSendEventData(eventTag.linkSite)}>
                {site}
              </a>
            </S.BrokerWebsite>
          )}
          {(!!facebook || !!instagram) && (
            <S.ButtonContainer>
              {!!facebook && (
                <a
                  onClick={() =>
                    { 
                      handleSendEventData(eventTag.linkFace)
                      window.open(
                      `https://www.facebook.com/${facebook}`,
                      '__blank'
                      )
                    }
                  }
                >
                  <FacebookSVG />
                </a>
              )}
              {!!instagram && (
                <a
                  onClick={() => {
                      handleSendEventData(eventTag.linkInstagram)
                    window.open(
                      `https://www.instagram.com/${instagram}`,
                      '__blank'
                    )
                  }
                  }
                >
                  <InstagramSVG />
                </a>
              )}
            </S.ButtonContainer>
          )}
        </S.BrokerContact>
        <S.LogoArea data-test-id="broker-logo">
          <img src={logo} alt="Imagem de um logo." />
        </S.LogoArea>
      </S.BrokerCard>) :
      (<S.TextWithoutBroker>Sem informações a serem exibidas</S.TextWithoutBroker>)}
    </Card>
  );
};

export { BrokerCard };
