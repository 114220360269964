import React from 'react';
import { SVGProps } from './types';

export const PrivateUsageSVG = ({ width, height }: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 35.048 34.688"
    width={width}
    height={height}
  >
    <path
      d="M376.651 392.674v-5.963a.9.9 0 0 0-.9-.9h-3.186v1.8h2.285v3.265l-6.756-6.757a.924.924 0 0 0-1.272 0l-11.979 11.525 1.276 1.276 1.908-1.909v3.161h-5.342a2.733 2.733 0 0 0-2.608 1.9l-.735 2.275h-2.734a1.583 1.583 0 0 0-1.581 1.582v1.432a1.588 1.588 0 0 0 1.366 1.566 2.473 2.473 0 0 0-.353 1.274v8.772a1.584 1.584 0 0 0 1.582 1.582h2.962a1.583 1.583 0 0 0 1.581-1.582v-1.084h-1.8v.865h-2.522v-4.6h3.085a1.49 1.49 0 0 0 1.442-1.106l.411-1.252.021-.077a1.739 1.739 0 0 0-1.695-2.131h-2.922l1.459-1.286h17.334l1.458 1.286h-3.02a1.74 1.74 0 0 0-1.7 2.13l.022.08.408 1.244a1.494 1.494 0 0 0 1.444 1.112h3.185v4.6h-2.521v-.865h-1.8v1.084a1.583 1.583 0 0 0 1.581 1.582H369a1.583 1.583 0 0 0 1.581-1.582V408.2a2.467 2.467 0 0 0-.365-1.3 1.587 1.587 0 0 0 1.273-1.544v-1.432a1.584 1.584 0 0 0-1.582-1.582h-2.726l-.846-2.359a2.749 2.749 0 0 0-2.58-1.815h-3.925v-4.508l7.629-7.629 7.393 7.393V407.2h-2.237v1.8h3.138a.9.9 0 0 0 .9-.9v-13.324l2.144 2.144 1.279-1.28zm-25.635 16.715-.316.963h-2.859v-.963zm-2.495-4.5-.286.252h-1.408v-.992h1.933zm20.256 5.464h-2.958l-.311-.946 3.269-.014zm.908-6.2v.992h-1.3l-.3-.262-.262-.73zm-5.933-4.174a.942.942 0 0 1 .884.622l1.4 3.9h-15.5l1.251-3.874a.938.938 0 0 1 .894-.651z"
      transform="translate(-345.026 -383.866)"      
    />
    <path
      transform="translate(3.828 29.325)"      
      d="M0 0h18.905v1.801H0z"
    />
    <path
      transform="translate(25.015 10.437)"      
      d="M0 0h1.8v2.611H0z"
    />
    <path
      transform="translate(17.57 10.437)"      
      d="M0 0h1.8v2.611H0z"
    />
  </svg>
);
