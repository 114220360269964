import styled from 'styled-components'
import { mobileMinWidth, tabletMinWidth } from 'styles/variables'
import Slider from 'react-slick'

import { PriceSVG } from './assets/price'
import { HouseSVG } from './assets/house'
import { ToolsSVG } from './assets/tools'
import { ThinkingSVG } from './assets/thinking'
import { CarSVG } from './assets/CarSVG'
// import IconCar from './assets/icon_car.png';

export const PriceIcon = styled(PriceSVG)``
export const HouseIcon = styled(HouseSVG)``
export const ToolIcon = styled(ToolsSVG)`
  & circle {
    fill: ${props => props.theme.colors?.paletaCores?.secundaria?.medio};
  }
  & path {
    fill: ${props => props.theme.colors?.paletaCores?.secundaria?.escuro};
  }
`
export const ThinkingImage = styled(ThinkingSVG)`
  max-width: 246.2px;

  @media (max-width: ${mobileMinWidth}) {
    max-width: 77px;
    margin: 0 15% 0 10px;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 61px 0% 0;

  @media (max-width: 1170px) {
    padding: 70px 48px 0;
  }

  @media (max-width: ${tabletMinWidth}) {
    justify-content: center;
    padding: 28px 16px 0;
  }

  @media (max-width: ${mobileMinWidth}) {
    padding: 35px 0px 52px 0px;
  }
`

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 0 30px 45px;
`

export const ImageContainer = styled.div`
  display: flex;
  align-items: end;
  margin-right: 44.8px;

  & > svg {
    width: 100%;
    max-width: 246.2px;
  }
`

export const Title = styled.h1`
  font-size: 2rem;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
  font-weight: 300;

  @media (max-width: ${mobileMinWidth}) {
    font-size: 1.5rem;
    font-weight: 300;
    line-height: 1.33;
  }
`

export const ReasonContainer = styled.div`
  display: flex;
  margin-top: 34px;

  > div + div {
    margin-left: 16px;
  }
`

export const ReasonCard = styled.div`
  width: 100%;
  max-width: 275px;
  min-height: 200px;
  margin: 35px 0 18px;
  padding: 0 15px 25px;
  border-radius: 5px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);

  @media (max-width: ${mobileMinWidth}) {
    width: 100%;
    max-width: 98%;
    height: 100%;
    min-height: 180px;
  }

  @media (max-width: 450px) {
    min-height: 250px;
  }
  @media (max-width: 350px) {
    min-height: 300px;
  }
`

export const ReasonName = styled.div`
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
  font-weight: 700;
  font-size: 1.125rem;
  text-align: center;
  margin: 13px 0;
`

export const ReasonDescription = styled.div`
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
  text-align: center;
  font-weight: 300;
  font-size: 16px;
  line-height: 1.38;
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -35px;
`

export const ReasonSlider = styled(Slider)`
  li.slick-active div {
    background-color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
  }
`

export const SliderContainer = styled.div`
  width: 100%;
`

export const Bullet = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border: solid 1px ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 15% -35px 0;
  width: 100%;

  @media (max-width: ${mobileMinWidth}) {
    margin-bottom: -35px;

    & > svg {
      width: 100%;
      max-width: 80px;
    }
  }
`
export const IconCarPNG = styled(CarSVG)``

// export const IconCarPNG = styled.img.attrs({
//   src: `${IconCar}`
// })`
//   width: 70px;
//   height: 70px;
//   object-fit: cover;
// `;
