import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';
import { mobileMinWidth } from 'styles/variables';

interface ButtonGroupProps {
  qtdItems: number;
  isErrored: boolean;
}

interface ButtonProps {
  selected: boolean;
}

export const Container = styled.div<ButtonGroupProps>`
  width: 100%;
  display: flex;

  button + button {
    margin-left: 1rem;
  }

  @media (max-width: ${mobileMinWidth}) {
    button + button {
      margin-left: 0.5rem;
    }

    ${({ qtdItems }) =>
      qtdItems > 2 &&
      css`
        flex-direction: column;

        button + button {
          margin-left: 0;
          margin-top: 1rem;
        }
      `}
  }

  ${({ isErrored }) =>
    isErrored &&
    css`
      button {
        border: 2px solid ${colors.red_2};
      }
    `}
`;

export const Button = styled.button<ButtonProps>`
  width: 100%;
  font-size: 1rem;
  min-height: 48px;
  border: 0;
  border-radius: 3px;
  padding: 15px 10px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s;
  position: relative;

  background-color: ${colors.white};
  border: solid 2px rgba(27, 18, 71, 0.1);
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };

  &:hover {
    color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
    border: solid 2px ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
  }

  & > span {
    margin-right: 14.7px;
  }

  @media (max-width: ${mobileMinWidth}) {
    & > span {
      margin-right: 11px;
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
      border: solid 2px ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
      color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.contrastante };
      font-weight: 700;

      &:hover {
        background-color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
        border: solid 2px ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
        color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.contrastante };
      }
    `}

    svg {
      fill:  ${({ selected , theme}) => selected ? theme.colors?.paletaCores?.primaria?.contrastante : theme.colors?.paletaCores?.primaria?.escuro };
    }
`;
