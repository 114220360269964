import React from 'react';
import { SVGProps } from './types';

export const OwnerSVG = ({ width, height }: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 30.251 32.125"
    width={width}
    height={height}
  >
    <path
      d="M684.029 295.732a2.033 2.033 0 0 0-.315 1.064v7.271a1.378 1.378 0 0 0 1.465 1.309h2.455a1.341 1.341 0 0 0 1.4-1.309v-.794h-1.62v.483h-2.077V300.2h2.585a1.293 1.293 0 0 0 1.256-.962l.339-1.029.02-.072a1.423 1.423 0 0 0-.266-1.214 1.519 1.519 0 0 0-1.2-.56h-2.456l1.115-.968h14.63l1.048.968h-2.479a1.519 1.519 0 0 0-1.2.56 1.423 1.423 0 0 0-.266 1.214l.02.071.339 1.03a1.293 1.293 0 0 0 1.257.962h2.488v3.556h-2.077v-.484h-1.62v.794a1.47 1.47 0 0 0 1.575 1.309h2.455a1.241 1.241 0 0 0 1.286-1.309V296.8a2.29 2.29 0 0 0-.254-1.058 1.243 1.243 0 0 0 .993-1.3v-1.186a1.245 1.245 0 0 0-1.272-1.328h-2.174l-.729-2.055a2.425 2.425 0 0 0-2.2-1.641h-9.176a2.419 2.419 0 0 0-2.235 1.716l-.63 1.98h-2.173a1.331 1.331 0 0 0-1.363 1.328v1.186a1.3 1.3 0 0 0 1.054 1.29m3.657 2.849h-2.352v-.6h2.549zm12.624 0-.2-.6h2.453v.6zm2.995-5.033v.6h-.911l-.191-.177-.149-.421zm-14.621-3.106a.832.832 0 0 1 .692-.59h9.176a.822.822 0 0 1 .672.566l1.192 3.358h-12.791zm-4.089 3.106h1.4l-.148.466-.152.132h-1.1z"
      transform="translate(-681.925 -288.232)"      
    />
    <path
      transform="translate(4.263 12.567)"      
      d="M0 0h15.525v1.62H0z"
    />
    <path
      d="M711.366 305.377H706.6a.81.81 0 0 0-.81.81v1.649h-3.568a2.326 2.326 0 0 0-1.3.393l-4 2.667H692.8a1.934 1.934 0 0 0-1.9 1.529l-6.625-.843a1.8 1.8 0 0 0-2.348 1.7 1.773 1.773 0 0 0 1.083 1.635l11.122 4.706a6.9 6.9 0 0 0 2.7.547h.129a6.929 6.929 0 0 0 2.194-.356l6.638-2.236v1.973a.81.81 0 0 0 .81.81h4.765a.81.81 0 0 0 .81-.81v-13.36a.81.81 0 0 0-.81-.81m-.81 13.36h-3.145v-7.861h-1.62v4.984l-7.152 2.409a5.311 5.311 0 0 1-1.68.273h-.129a5.278 5.278 0 0 1-2.069-.42l-11.122-4.705-.094-.143a.174.174 0 0 1 .132-.155l.132.012.18.044 7.269.924a1.878 1.878 0 0 0 .229.319l.1.1.255.208.174.1.265.117.174.055a1.918 1.918 0 0 0 .475.063h7.652v-1.62h-7.652l-.108-.025-.073-.034-.04-.034-.041-.038-.031-.05-.137-.274a.331.331 0 0 1 .021-.329.322.322 0 0 1 .273-.146h4.218a1.316 1.316 0 0 0 .732-.222l4.078-2.718a.711.711 0 0 1 .4-.12h4.376a.81.81 0 0 0 .81-.81V307h3.145z"
      transform="translate(-681.925 -288.232)"      
    />
  </svg>
);
