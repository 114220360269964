/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import { onlyNumbers } from '@brazilian-utils/brazilian-utils';
import Yup from 'config/yup';
import { formatDate } from 'helpers/format';
import {
  INVALID_CEP,
  INVALID_CPF,
  INVALID_DATE,
  INVALID_PHONE,
  REQUIRED_FIELD,
  REQUIRED_GROUP_FIELD,
} from 'helpers/messages';
import { isValidDate } from 'helpers/validation';
import { validateCep, validateCPF, validatePhone } from 'validations-br';
import cep from 'cep-promise';

const emptyFieldsMask = {
  cpf: '___.___.___-__',
  cep: '_____-___',
  phone: '(__) _____-____',
  date: '__/__/____'
}

export const searchCep = async (value: string) => {
  try {
    await cep(value);
    return true
  } catch (error) {  
    return false
  }
}

const testFieldsMask = (value: string, isValid: boolean) => !value?.match(/[0-9]/) || isValid

export const validations = Yup.object().shape({
  socialName: Yup.string().min(5).required(),
  email: Yup.string().email().required(),
  maritalStatus: Yup.string().required(),
  Is0KM: Yup.string().required(),
  hasPreviousCoverage: Yup.string().required(REQUIRED_GROUP_FIELD),
  PolicyHolderIsEmployee: Yup.string().required(REQUIRED_GROUP_FIELD),
  AdaptationCode: Yup.string().required(),
  usageCode: Yup.string().required(REQUIRED_GROUP_FIELD),
  VehicleOwnerIsPolicyHolder: Yup.string().required(REQUIRED_GROUP_FIELD),
  potentialDriver: Yup.string().required(REQUIRED_GROUP_FIELD),
  agree: Yup.bool()
    .required('É obrigatório a seleção deste campo')
    .oneOf([true], 'É obrigatório a seleção deste campo'),
  plate: Yup.string().required('Por favor, preencha ao menos um dos campos'),
  chassis: Yup.string().when(['plate'], {
    is: (plate: string) => !plate,
    then: Yup.string()
      .required('Por favor, preencha ao menos um dos campos')
  }),
  cpfSegurado: Yup.string()
    .required()
    .when({
      is: (value: string) => value === emptyFieldsMask.cpf,
      then: Yup.string()
        .required()
        .test('is-cpf-empty', REQUIRED_FIELD, (value): boolean => 
          !(value === emptyFieldsMask.cpf)
        ),
    })
    .test('is-cpf', `${INVALID_CPF}`,
      value => testFieldsMask(value || '', validateCPF(value || ''))
    ),
  zipcode: Yup.string()
    .required()
    .when({
      is: (value: string) => value === emptyFieldsMask.cep,
      then: Yup.string()
        .required()
        .test('is-cep-empty', REQUIRED_FIELD, (value): boolean => 
          !(value === emptyFieldsMask.cep)
        ),
    })
    .test('zipcode ', `${INVALID_CEP}`,
      value => testFieldsMask(value || '', validateCep(value || ''))
    ),
  phone: Yup.string()
    .required()
    .when({
      is: (value: string) => value === emptyFieldsMask.phone,
      then: Yup.string()
        .required()
        .test('is-phone-empty', REQUIRED_FIELD, (value): boolean => 
          !(value === emptyFieldsMask.phone)
        ),
    })
    .test('is-phone', `${INVALID_PHONE}`, 
      value => testFieldsMask(value || '', validatePhone(value || ''))
    ),

  vinculoProprietario: Yup.string().when(['VehicleOwnerIsPolicyHolder'], {
    is: (VehicleOwnerIsPolicyHolder: string) =>
      VehicleOwnerIsPolicyHolder === 'F',
    then: Yup.string().required(),
    otherwise: Yup.string(),
  }),
  maritalStatusProprietario: Yup.string().when(['VehicleOwnerIsPolicyHolder'], {
    is: (VehicleOwnerIsPolicyHolder: string) =>
      VehicleOwnerIsPolicyHolder === 'F',
    then: Yup.string().required(),
    otherwise: Yup.string(),
  }),
  cpfProprietario: Yup.string()
  .when(['VehicleOwnerIsPolicyHolder'], {
    is: (VehicleOwnerIsPolicyHolder: string) =>
      VehicleOwnerIsPolicyHolder === 'F',
    then: Yup.string()
      .required()
      .test('is-cpf', `${INVALID_CPF}`,
        value => testFieldsMask(value || '', validateCPF(value || ''))
      ),
    otherwise: Yup.string(),
  })
  .test('is-cpf-empty', REQUIRED_FIELD, (value): boolean => 
    !(value === emptyFieldsMask.cpf)
  ),

  vinculoCondutorProprietario: Yup.string().when(['potentialDriver'], {
    is: (potentialDriver: string) => potentialDriver === 'O',
    then: Yup.string().required(),
    otherwise: Yup.string(),
  }),
  maritalStatusCondutor: Yup.string().when(['potentialDriver'], {
    is: (potentialDriver: string) => potentialDriver === 'O',
    then: Yup.string().required(),
    otherwise: Yup.string(),
  }),
  cpfCondutor: Yup.string().when(['potentialDriver'], {
    is: (potentialDriver: string) => potentialDriver === 'O',
    then: Yup.string()
      .required()
      .test('is-cpf', `${INVALID_CPF}`,
        value => testFieldsMask(value || '', validateCPF(value || ''))
      ),
    otherwise: Yup.string(),
  })
  .test('is-cpf-empty', REQUIRED_FIELD, (value): boolean => 
    !(value === emptyFieldsMask.cpf)
  ),

  HasClaim: Yup.string().when(['hasPreviousCoverage'], {
    is: (hasPreviousCoverage: string) => hasPreviousCoverage === 'T',
    then: Yup.string().required(REQUIRED_GROUP_FIELD),
    otherwise: Yup.string(),
  }),
  IsVhclSubstitutionRenewal: Yup.string().when(['hasPreviousCoverage'], {
    is: (hasPreviousCoverage: string) => hasPreviousCoverage === 'T',
    then: Yup.string().required(REQUIRED_GROUP_FIELD),
    otherwise: Yup.string(),
  }),
  IsCvrgChangeRenewal: Yup.string().when(['hasPreviousCoverage'], {
    is: (hasPreviousCoverage: string) => hasPreviousCoverage === 'T',
    then: Yup.string().required(REQUIRED_GROUP_FIELD),
    otherwise: Yup.string(),
  }),
  BonusClassCode: Yup.number().when(['hasPreviousCoverage'], {
    is: (hasPreviousCoverage: string) => hasPreviousCoverage === 'T',
    then: Yup.number().typeError('Informe um número').required().min(1).max(10),
    otherwise: Yup.number(),
  }),
  PolicyEndDate: Yup.string().when(['hasPreviousCoverage'], {
    is: (hasPreviousCoverage: string) => hasPreviousCoverage === 'T',
    then: Yup.string()
      .required()
      .test('is-date', `${INVALID_DATE}`, value => {
        if (onlyNumbers(value!).length !== 8) return false;

        return isValidDate(new Date(formatDate(value!)));
      }),
    otherwise: Yup.string(),
  })
  .test('is-date-empty', REQUIRED_FIELD, (value): boolean => 
    !(value === emptyFieldsMask.date)
  ),

  kitGasValue: Yup.string().when(['AdaptationCode'], {
    is: (AdaptationCode: string) =>
      AdaptationCode === '9' || AdaptationCode === '99',
    then: Yup.string().required(),
    otherwise: Yup.string(),
  }),

  blindagemValue: Yup.string().when(['AdaptationCode'], {
    is: (AdaptationCode: string) =>
      AdaptationCode === '3' || AdaptationCode === '99',
    then: Yup.string().required(),
    otherwise: Yup.string(),
  }),

  question148: Yup.string().required(REQUIRED_GROUP_FIELD),

  question149: Yup.string().when(['question148'], {
    is: (question148: string) => question148 === 'T',
    then: Yup.string().required(REQUIRED_GROUP_FIELD),
    otherwise: Yup.string(),
  }),
  question150: Yup.string().when(['question149'], {
    is: (question149: string) =>
      question149 === '1354' || question149 === '1355',
    then: Yup.string().required(REQUIRED_GROUP_FIELD),
    otherwise: Yup.string(),
  }),
});

export const firstStepFields = [
  'socialName',
  'email',
  'maritalStatus',
  'agree',
  'cpfSegurado',
  'zipcode',
  'phone',
];

export const eventTag = {
  inputName: 'inputName',
  inputCPF: 'inputCPF',
  inputCEP: 'inputCEP',
  inputMaritalStatus: 'inputMaritalStatus',
  inputTel: 'inputTel',
  inputEmail: 'inputEmail',
  inputPlaca: 'inputPlaca',
  inputChassi: 'inputChassi',
  inputAdaptation: 'inputAdaptation',
  inputLoJackCode: 'inputLoJackCode',
  linkLgpd: 'linkLgpd',
  btnContact: 'btnContact',
  btnContinue: 'btnContinue',
  btnRequest: 'btnRequest',
  btnUsedCar: 'Is0KMF',
  btnNewCar: 'Is0KMT',
  btnParticular: 'usageCode1',
  btnAppTransport: 'usageCode37',
  btnInsured: 'VehicleOwnerIsPolicyHolderT',
  btnAnotherPerson: 'VehicleOwnerIsPolicyHolderF',
  btnPotentialDriverInsured: 'potentialDriverS',
  btnPotentialDriverAnotherPerson: 'potentialDriverO',
  btnPotentialDriverOwner: 'potentialDriverP',
  btnQuestion148Yes: 'question148T',
  btnQuestion148No: 'question148F',
  btnQuestion149YesLimited: 'question1491354',
  btnQuestion149YesHigher: 'question1491355',
  btnQuestion149No: 'question1491356',
  btnQuestion150F: 'question1501358',
  btnQuestion150M: 'question1501359',
  btnQuestion150A: 'question1501360',
  btnHasPreviousCoverageYes: 'hasPreviousCoverageT',
  btnHasPreviousCoverageNo: 'hasPreviousCoverageF',
  btnPolicyHolderIsEmployeeYes: 'PolicyHolderIsEmployeeT',
  btnPolicyHolderIsEmployeeNo: 'PolicyHolderIsEmployeeF',
};

export const eventTagMap = {
  [eventTag.inputName]: {
    location: 'Questionario',
    input: 'Nome',
    event: 'questionario_input_nome',
  },
  [eventTag.inputCPF]: {
    location: 'Questionario',
    input: 'CPF do(a) segurado(a)',
    event: 'questionario_input_cpf',
  },
  [eventTag.inputCEP]: {
    location: 'Questionario',
    input: 'CEP de pernoite do veículo',
    event: 'questionario_input_cep',
  },
  [eventTag.inputMaritalStatus]: {
    location: 'Questionario',
    input: 'Estado civil',
    event: 'questionario_input_estadocivil',
  },
  [eventTag.inputTel]: {
    location: 'Questionario',
    input: 'Telefone / Whatsapp',
    event: 'questionario_input_tel',
  },
  [eventTag.inputEmail]: {
    location: 'Questionario',
    input: 'E-mail',
    event: 'questionario_input_email',
  },
  [eventTag.inputPlaca]: {
    location: 'Questionario',
    input: 'Placa do veículo',
    event: 'questionario_input_placa',
  },
  [eventTag.inputChassi]: {
    location: 'Questionario',
    input: 'Chassi do veículo',
    event: 'questionario_input_chassi',
  },
  [eventTag.inputAdaptation]: {
    location: 'Questionario',
    input: 'O veículo possui alguma adaptação?',
    event: 'questionario_input_adaptacao',
  },
  [eventTag.inputLoJackCode]: {
    location: 'Questionario',
    input: 'O veículo possui rastreador próprio?',
    event: 'questionario_input_rastreador',
  },
  [eventTag.linkLgpd]: {
    location: 'Questionario',
    button: 'Saiba mais',
    event: 'questionario_link_lgpd',
  },
  [eventTag.btnContact]: {
    location: 'Questionario',
    button: 'Aceito ...',
    event: 'questionario_btn_contato',
  },
  [eventTag.btnContinue]: {
    location: 'Questionario',
    button: 'Continuar',
    event: 'questionario_btn_continuar',
  },
  [eventTag.btnRequest]: {
    location: 'Questionario',
    button: 'Solicitar cotação',
    event: 'questionario_btn_solicitar',
  },
  [eventTag.btnUsedCar]: {
    location: 'Questionario',
    button: 'Usado',
    event: 'questionario_btn_usado',
  },
  [eventTag.btnNewCar]: {
    location: 'Questionario',
    button: 'Novo',
    event: 'questionario_btn_novo',
  },
  [eventTag.btnParticular]: {
    location: 'Questionario',
    button: 'Particular',
    event: 'questionario_btn_particular',
  },
  [eventTag.btnAppTransport]: {
    location: 'Questionario',
    button: 'Transporte de pessoas por aplicativo',
    event: 'questionario_btn_app',
  },
  [eventTag.btnInsured]: {
    location: 'Questionario',
    button: 'Segurado(a)',
    event: 'questionario_btn_segurado-proprietario',
  },
  [eventTag.btnAnotherPerson]: {
    location: 'Questionario',
    button: 'Outra pessoa',
    event: 'questionario_btn_outros-proprietario',
  },
  [eventTag.btnPotentialDriverInsured]: {
    location: 'Questionario',
    button: 'Segurado(a)',
    event: 'questionario_btn_condutor-segurado',
  },
  [eventTag.btnPotentialDriverAnotherPerson]: {
    location: 'Questionario',
    button: 'Outra pessoa',
    event: 'questionario_btn_condutor-outros',
  },
  [eventTag.btnPotentialDriverOwner]: {
    location: 'Questionario',
    button: 'Proprietário(a)',
    event: 'questionario_btn_condutor-proprietario',
  },
  [eventTag.btnQuestion148Yes]: {
    location: 'Questionario',
    button: 'Sim',
    event: 'questionario_btn_idade-sim',
  },
  [eventTag.btnQuestion148No]: {
    location: 'Questionario',
    button: 'Não',
    event: 'questionario_btn_idade-nao',
  },
  [eventTag.btnQuestion149YesLimited]: {
    location: 'Questionario',
    button: 'Sim, limitada a 2 dias na semana',
    event: 'questionario_btn_idade-sim-cobertura-2dias',
  },
  [eventTag.btnQuestion149YesHigher]: {
    location: 'Questionario',
    button: 'Sim, superior a 2 dias na semana',
    event: 'questionario_btn_idade-sim-cobertura-superior',
  },
  [eventTag.btnQuestion149No]: {
    location: 'Questionario',
    button: 'Não',
    event: 'questionario_btn_idade-sim-cobertura-nao',
  },
  [eventTag.btnQuestion150F]: {
    location: 'Questionario',
    button: 'Feminino',
    event: 'questionario_btn_idade-sim-fem',
  },
  [eventTag.btnQuestion150M]: {
    location: 'Questionario',
    button: 'Masculino',
    event: 'questionario_btn_idade-sim-masc',
  },
  [eventTag.btnQuestion150A]: {
    location: 'Questionario',
    button: 'Ambos',
    event: 'questionario_btn_idade-sim-ambos',
  },
  [eventTag.btnHasPreviousCoverageYes]: {
    location: 'Questionario',
    button: 'Sim',
    event: 'questionario_btn_possuiseguro-sim',
  },
  [eventTag.btnHasPreviousCoverageNo]: {
    location: 'Questionario',
    button: 'Não',
    event: 'questionario_btn_possuiseguro-nao',
  },
  [eventTag.btnPolicyHolderIsEmployeeYes]: {
    location: 'Questionario',
    button: 'Sim',
    event: 'questionario_btn_funcionario-sim',
  },
  [eventTag.btnPolicyHolderIsEmployeeNo]: {
    location: 'Questionario',
    button: 'Não',
    event: 'questionario_btn_funcionario-nao',
  },
};
