/* eslint-disable import/no-unresolved */
import styled from 'styled-components';
import { colors } from 'styles/colors';
import { Spinner } from '@chakra-ui/spinner'

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  height: 100vh;
  min-height: 380px;
  background-color: #343741d1;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  opacity: 0.9;

  span {
    color: ${colors.white};
    margin-top: 18px;
    width: 150px;
  }
`;

export const LoadingIcon = styled(Spinner as any)`
  color: ${colors.gray_6};
  font-size: 100px;
  height: 100px !important;
  width: 100px !important;
`

export const LoadingGif = styled.div`
  background: url('/img/loading.gif') no-repeat center;
  height: 100px;
  width: 100px;
`;

export default Wrapper;
