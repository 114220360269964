import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  disabled: boolean | undefined;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;
`;

export const Arrow = styled.div`
  position: absolute;
  width: 25px;
  height: 25px;
  background-image: url('img/arrow_down.png');
  background-repeat: no-repeat;
  right: 0;
  top: 12px;
`;

export const InputContainer = styled.div<ContainerProps>`
  background: transparent;
  border-radius: 3px;
  width: 100%;
  height: 50px;
  padding: 5px 8px;

  border: 1px solid ${colors.gray_2};

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  label {
    font-size: 0.75rem;
    margin-bottom: 4px;
    color: ${colors.gray_3};
  }

  select {
    width: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    appearance: none;
    background-color: ${colors.white};
    color: ${colors.gray_3};
    cursor: pointer;
    font-size: 16px;
    font-weight: 100;
    line-height: 19px;

    &:focus {
      border-color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
    }

    option {
      padding: 0 5px;
      font-size: 16px;
      line-height: 19px;
    }
  }

  ${props =>
    props.isFocused &&
    css`
      border: 2px solid ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
    `}

  ${props =>
    props.disabled &&
    css`
      border: 1px solid ${colors.gray_5};
      background-color: ${colors.gray_4};
    `}

  ${props =>
    props.isErrored &&
    css`
      border: 1px solid ${colors.red_2};
      background-color: ${colors.red_1};

      select {
        background-color: ${colors.red_1};
      }
    `}


  & + div {
    margin-top: 8px;
  }
`;
