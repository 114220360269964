import React from 'react';

export const IconIncendio = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60">
    <g data-name="Icon Angel">
      <circle data-name="Ellipse bg" cx="30" cy="30" r="30" style={{fill:'#00c3c1',opacity:.5}}/>
      <path data-name="Path 130" d="m578.042 584.613-11.526-4.89a7.146 7.146 0 0 0-2.808-.572h-.141a7.208 7.208 0 0 0-2.276.369l-6.852 2.3v-2.012a.865.865 0 0 0-.863-.865l-5.376-.005a.862.862 0 0 0-.864.863l-.014 13.851a.864.864 0 0 0 .863.865l5.376.005a.864.864 0 0 0 .864-.863v-1.685h3.675a2.436 2.436 0 0 0 1.357-.411l4.145-2.756h4.272a2.038 2.038 0 0 0 1.7-.909 2.017 2.017 0 0 0 .287-.672l6.844.878a1.943 1.943 0 0 0 .58.09 1.848 1.848 0 0 0 .583-.094 1.882 1.882 0 0 0 1.3-1.776 1.862 1.862 0 0 0-1.136-1.718m-.711 1.854-.121-.012-.193-.047-7.524-.965a2.234 2.234 0 0 0-.238-.33l-.1-.1a2.138 2.138 0 0 0-.269-.219l-.179-.1a2.042 2.042 0 0 0-.3-.13l-.163-.051a2.008 2.008 0 0 0-.5-.067l-7.933-.008v1.728l7.932.008.1.024.07.032.04.034.04.038.029.046.141.284a.318.318 0 0 1-.019.317.311.311 0 0 1-.263.141h-4.374a1.389 1.389 0 0 0-.772.233l-4.23 2.813a.715.715 0 0 1-.4.121l-4.54-.005a.863.863 0 0 0-.864.863v1.685h-3.648l.012-12.123h3.648l-.008 8.122h1.728l.005-5.15 7.4-2.485a5.465 5.465 0 0 1 1.728-.28h.14a5.471 5.471 0 0 1 2.135.435l11.526 4.891.083.125-.119.138" transform="translate(-534.251 -562.908)" style={{fill:'#0c1035'}}/>
      <path data-name="Path 131" d="M571.945 597.534c-.394-.475-.8-.965-1.19-1.458a7.684 7.684 0 0 1-1.471-3.568 1.431 1.431 0 0 0-.708-1.227 1.044 1.044 0 0 0-.446-.1 1.874 1.874 0 0 0-1.025.4 29.978 29.978 0 0 0-5.048 4.21 13.852 13.852 0 0 0-3.36 5.448 8.229 8.229 0 0 0 1.658 8.1 6.043 6.043 0 0 0 2.183 1.627 1.95 1.95 0 0 0 .693.169l.094-1.8-.055-.016a4.268 4.268 0 0 1-1.539-1.144 6.441 6.441 0 0 1-1.31-6.424 12.149 12.149 0 0 1 2.954-4.742 27.264 27.264 0 0 1 4.239-3.6 9.519 9.519 0 0 0 1.727 3.769c.4.505.816 1.007 1.226 1.5.457.549.928 1.117 1.374 1.693a6.332 6.332 0 0 1 1.442 5.117 4.919 4.919 0 0 1-4.394 3.845h-.017l-.024 1.8h.1a6.661 6.661 0 0 0 6.109-5.346 8.042 8.042 0 0 0-1.795-6.517c-.465-.6-.968-1.207-1.419-1.749" transform="translate(-534.251 -562.908)" style={{fill:'#0c1035'}}/>
      <path data-name="Path 132" d="m568.793 606.5.28 1.858a1.92 1.92 0 0 0 .484-.2 4.172 4.172 0 0 0 1.828-5.2 13.38 13.38 0 0 0-1.1-2.054c-.248-.387-.505-.787-1.08-.809a1.313 1.313 0 0 0-1.036.685c-.166.224-.32.428-.467.6a3.832 3.832 0 0 1-.168-.99v-.067a9.779 9.779 0 0 1 .227-2.238l.041-.16a1.073 1.073 0 0 0-.54-1.392 1.244 1.244 0 0 0-1.434.385l-.068.06c-1.423 1.247-3.122 2.938-3.628 4.963a6.727 6.727 0 0 0 1.228 5.771l1.459-1.055a4.937 4.937 0 0 1-.941-4.28 6.926 6.926 0 0 1 1.877-2.927c-.017.285-.019.545-.021.862v.067a5.127 5.127 0 0 0 .965 2.945l.473.535.652-.294a3.7 3.7 0 0 0 1.312-1.1 8.484 8.484 0 0 1 .566 1.109 2.357 2.357 0 0 1-.9 2.922" transform="translate(-534.251 -562.908)" style={{fill:'#0c1035'}}/>
    </g>
  </svg>
)

