/* eslint-disable import/no-unresolved */
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: ${colors.white};
  max-width: 336px;
`;
