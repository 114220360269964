import styled from 'styled-components';
import { mobileMinWidth } from 'styles/variables';
import { SadEmojiSVG } from 'assets/img/SadEmojiSVG';

export const Container = styled.div`
  flex: 1;
  padding: 52px 10% 50px;
  height: 100%;
  display: flex;
  flex-direction: column;

  > svg {
    align-self: center;
  }

  @media (max-width: ${mobileMinWidth}) {
    padding: 51px 2% 50px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  span {
    font-size: 2rem;
    font-size: 400;
    line-height: 1.2;
    text-align: center;
    color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
    margin: 21px 0 30px;
  }

  div {
    min-width: 120px;
  }

  @media (max-width: ${mobileMinWidth}) {
    flex-direction: column;

    span {
      font-size: 1.5rem;
    }
  }
`;

export const Message = styled.div`
  align-self: center;
  width: 100%;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 2rem;
  text-align: center;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };

  @media (max-width: ${mobileMinWidth}) {
  }
`;

export const CorretorContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 336px;
  align-self: center;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro};

  > div {
    padding: 32px;

    > div {
      width: 100%;
    }
  }
`;

export const SadEmoji = styled(SadEmojiSVG)``;
