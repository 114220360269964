/* eslint-disable import/no-unresolved */
import styled from 'styled-components';
import { colors } from 'styles/colors';
import { maxContainerResolution, mobileMinWidth } from 'styles/variables';

export const ContainerLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 70px;
  background-color: ${colors.white};
  border-top: 2px solid ${colors.gray_4};
`;

export const Logo = styled.img`
  height: 56px;
  object-fit: contain;
`;

export const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
  width: 100%;
  padding: 45px 70px;
  display: flex;
  color: ${colors.white};

  @media (max-width: ${mobileMinWidth}) {
    padding: 30px 16px;
  }
`;
export const LimitWrapper = styled.div`
  width: 100%;
  max-width: ${maxContainerResolution};
  display: flex;
  margin: 0 auto;
  justify-content: space-between;

  @media (max-width: ${mobileMinWidth}) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Content = styled.div`
  flex: 1;

  &:first-child {
    margin-right: 64px;

    @media (max-width: ${mobileMinWidth}) {
      margin-right: 0px;
    }
  }

  @media (max-width: ${mobileMinWidth}) {
    max-width: none;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const Title = styled.div`
  line-height: 18px;
  font-size: 16px;
  font-weight: 600;

  b {
    color: ${colors.yellow_3};
  }

  @media (max-width: ${mobileMinWidth}) {
    text-align: left;
    line-height: 20px;
  }
`;
export const Subtitle = styled.div`
  margin-top: 8px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 300;

  @media (max-width: ${mobileMinWidth}) {
    font-size: 14px;
    margin-top: 16px;
    text-align: left;
  }
`;

export const CompanyLawMessage = styled.div`
  font-size: 11px;

  a {
    color: ${colors.white};
  }

  @media (max-width: ${mobileMinWidth}) {
    text-align: left;
  }
`;

export const CompanyCopyright = styled(CompanyLawMessage)`
  margin: 70px 0 15px 0;
  font-weight: 300;
  font-size: 11px;

  @media (max-width: ${mobileMinWidth}) {
    text-align: left;
    margin: 52px 0 15px 0;
  }
`;

export const Lists = styled.div`
  display: flex;

  @media (max-width: ${mobileMinWidth}) {
    text-align: left;
    width: 100%;
    justify-content: space-between;
    margin-top: 18px;
  }
`;

export const List = styled.ul`
  margin-top: 6px;

  @media (max-width: ${mobileMinWidth}) {
    text-align: left;
  }
`;

export const ListTitle = styled.li`
  font-weight: 800;
  list-style: none;
  font-size: 0.875rem;
`;

export const ListItem = styled.li`
  font-size: 0.75rem;
  margin: 9px 55px 0 15px;

  @media (max-width: ${mobileMinWidth}) {
    margin: 8px 55px 0 15px;
  }
`;

export const FooterBottomMessage = styled(CompanyLawMessage)`
  margin-top: 26px;

  @media (max-width: ${mobileMinWidth}) {
    text-align: left;
    max-width: none;
    margin-top: 16px;
  }
`;
