/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable import/prefer-default-export */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from 'react';

import IInputProps from '../@types/IInputProps';
import * as S from './styles';
import { ErrorMessage } from '../ErrorMessage';

interface IMoneyProps extends IInputProps {
  value: string | number | undefined;
  onCustonChange?: (value: string) => void;
}

export const InputMoney = ({
  name,
  label,
  disabled,
  loading,
  fieldState,
  inputRef,
  onCustomBlur,
  onCustonChange,
  placeholder,
  ...rest
}: IMoneyProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const handleInputFocus = useCallback(() => setIsFocused(true), []);

  const handleInputBlur = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsFocused(false);
      onCustomBlur && onCustomBlur(e);
    },
    [onCustomBlur]
  );

  const handleChange = useCallback((value: string) => {
    const parsed = value ? formatValue(value) : '';
    setInputValue(parsed);
    onCustonChange && onCustonChange(parsed);
  }, []);

  const showLabel =
    isFocused ||
    (inputValue && String(inputValue).replace(/_/g, '').length > 1);

  const error = fieldState?.error?.message;

  const formatValue = (value: any) =>
    String((value?.replace(/\D/g, '') / 100).toFixed(2))
      .replace('.', ',')
      .replace(/(\d)(\d{3})(\d{3})(\d{3}),/g, '$1.$2.$3.$4,')
      .replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,')
      .replace(/(\d)(\d{3}),/g, '$1.$2,');

  return (
    <S.Container>
      <S.InputContainer
        isFocused={isFocused}
        isFilled={!!inputValue}
        isErrored={!!error}
        disabled={disabled}
      >
        {showLabel && <label>{label}</label>}
        <S.DisplayContainer>
          {showLabel && <S.RS />}
          <input
            name={name}
            disabled={disabled}
            {...rest}
            value={inputValue || ''}
            ref={inputRef}
            placeholder={isFocused ? '' : placeholder}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onChange={({ target }) => handleChange(target?.value)}
          />
        </S.DisplayContainer>
        {loading && (
          <img src="/img/loading.gif" alt="Ícone de carregamento da tela." />
        )}
      </S.InputContainer>
      {error && <ErrorMessage name={name} message={error} />}
    </S.Container>
  );
};
