import styled from 'styled-components';
import { mobileMinLimit } from 'styles/variables';
import { colors } from 'styles/colors';

interface TooltipProps {
  direction: string;
  maxWidth: number;
}

export const Container = styled.div<TooltipProps>`
  position: relative;
  cursor: pointer;

  span {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.2));
    width: ${({ maxWidth }) => `${maxWidth}px`};
    max-width: 330px;
    background: ${colors.white};
    color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
    padding: 12px;
    border-radius: 4px;
    font-size: 0.875rem;
    line-height: 1rem;
    font-weight: 500;
    transition: opacity 0.4s;
    position: absolute;
    bottom: calc(100% + 20px);
    transform: ${({ direction }) =>
      direction === 'left' ? 'translateX(-90%)' : 'translateX(-10%)'};
    left: 10px;
    z-index: 101;
    display: none;

    &::before {
      content: '';
      border-style: solid;
      border-color: ${colors.white} transparent;
      border-width: 13px 13px 0 13px;
      top: 100%;
      position: absolute;
      left: ${({ direction }) => (direction === 'left' ? '90%' : '10%')};
      transform: translateX(-50%);
    }

    @media (max-width: ${mobileMinLimit}) {
      width: 280px;
    }
  }

  &:hover span {
    display: block;
  }

  svg {
    fill: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
  }

  svg:hover {
    fill: ${({ theme }) => theme.colors?.paletaCores?.primaria?.contrastante };
  }

  strong {
    font-weight: 900;
  }
`;

export const ContainerToolTip = styled.div`
  display: flex;
  > div:first-child {
    width: 95%;
  }
`;
