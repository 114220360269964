import React from 'react';

export const LockSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9.342"
    height="11.985"
    viewBox="0 0 9.342 11.985"
  >
    <g>
      <path
        d="M294.57 403.716a.732.732 0 0 0-.341 1.38v1.8h.7v-1.813a.732.732 0 0 0-.357-1.372"
        transform="translate(-289.857 -396.77)"
        style={{ fill: '#2C3A39' }}
      />
      <path
        d="M291.366 398.853h-6.6a1.374 1.374 0 0 0-1.372 1.372v4.147a1.374 1.374 0 0 0 1.372 1.373h6.6a1.374 1.374 0 0 0 1.372-1.373v-4.147a1.374 1.374 0 0 0-1.372-1.372m.686 5.52a.687.687 0 0 1-.686.687h-6.6a.687.687 0 0 1-.686-.687v-4.147a.687.687 0 0 1 .686-.686h6.6a.687.687 0 0 1 .686.686z"
        transform="translate(-283.397 -393.761)"
        style={{ fill: '#2C3A39' }}
      />
      <path
        d="M288.239 388.149a1.97 1.97 0 0 1 1.967-1.967h.95a1.97 1.97 0 0 1 1.968 1.967v1.871h.686v-1.871a2.657 2.657 0 0 0-2.654-2.654h-.95a2.656 2.656 0 0 0-2.652 2.569v1.981h.686z"
        transform="translate(-285.969 -385.495)"
        style={{ fill: '#2C3A39' }}
      />
    </g>
  </svg>
);
