import React from 'react';

export const IconCarSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
    <g transform="translate(-515 -1319)">
      <circle data-name="Ellipse bg" cx="30" cy="30" r="30" transform="translate(515 1319)" style={{fill:'#FFC900'}}/>
      <g data-name="Group 35">
        <path data-name="Path 105" d="M376.417 495.153h-3.288l-1.1-3.107a3.2 3.2 0 0 0-2.9-2.176h-2.464a7.733 7.733 0 0 1-.475 1.8h2.939a1.422 1.422 0 0 1 1.2.981l1.825 5.144h-19l.908-2.852a7.9 7.9 0 0 1-1.427-1.455l-.53 1.665h-3.293a1.629 1.629 0 0 0-1.69 1.64v1.7a1.625 1.625 0 0 0 1.69 1.633h.031a2.722 2.722 0 0 0-.666 1.731v10.391a1.726 1.726 0 0 0 1.837 1.614h3.508a1.642 1.642 0 0 0 1.738-1.614v-1.138h-1.8v.949h-3.482v-5.593h3.952a1.594 1.594 0 0 0 1.549-1.191l.486-1.479.022-.079a1.776 1.776 0 0 0-.331-1.518 1.914 1.914 0 0 0-1.514-.7h-4.064a1.317 1.317 0 0 1 .292-.372l1.757-1.526h21.115l1.611 1.488a.807.807 0 0 1 .222.41H371.1a1.911 1.911 0 0 0-1.514.7 1.78 1.78 0 0 0-.332 1.518l.022.079.487 1.479a1.592 1.592 0 0 0 1.549 1.191h3.814v5.596h-3.287l-.194-.039v-.91h-1.8v1.135a1.849 1.849 0 0 0 1.994 1.614h3.509a1.529 1.529 0 0 0 1.58-1.614v-10.394a2.913 2.913 0 0 0-.525-1.731h.02a1.529 1.529 0 0 0 1.562-1.633v-1.7a1.533 1.533 0 0 0-1.562-1.64m-22.275 8.14.087.014-.445 1.356h-3.806v-1.37zm-2.95-5.269-.344.3h-1.926v-1.369h2.61zm23.93 6.639h-3.668l-.446-1.355.088-.015h4.026zm1.057-6.34h-1.661l-.394-.364-.357-1.006h2.412z" transform="translate(182.949 849.874)" style={{fill:'#2C3A39'}}/>
        <path data-name="Rectangle 28" transform="translate(534.404 1357.707)" style={{fill:'#2C3A39'}} d="M0 0h22.189v1.8H0z"/>
        <path data-name="Path 106" d="M358.9 482.393a6.353 6.353 0 1 0 6.353 6.353 6.353 6.353 0 0 0-6.353-6.353m3.882 7.031h-3.217v3.217h-1.337v-3.217h-3.217v-1.386h3.217v-3.218h1.337v3.218h3.217z" transform="translate(182.949 849.874)" style={{fill:'#2C3A39'}}/>
      </g>
    </g>
  </svg>
)
