/* eslint-disable no-unused-vars */
/* eslint-disable object-shorthand */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable default-case */
import api from 'api';
import { IBrokerData } from 'types/broker';
import { ICoverage, ISalePromotion } from 'types/plan';
import { IVehicle } from 'types/vehicle';
import { IQuestionnaireProps } from 'types/questionnaire';
import { onlyNumbers } from '@brazilian-utils/brazilian-utils';
import {
  EMPLOYEE_DATA,
  INSURER_SUSEP_CODE,
  POLICY_NUMBER,
  SALES_PARTNER_CODE,
} from 'config';
import { format } from 'date-fns';
import { ICustomer } from 'types/customer';

export interface IQuotationRequest {
  broker: IBrokerData;
  salePromotion: ISalePromotion;
  vehicle: IVehicle;
  data: IQuestionnaireProps;
  customers: ICustomer[];
}

export interface IQuotationResponse {
  hasRestriction: boolean;
}

interface IPotentialDriver {
  isPolicyHolder: boolean;
  birthDate: string;
  publicIdNumber: string;
  gender: string;
  name: string;
  isDriver: boolean;
  daysPerWeek: string;
  isReferenceDriver: boolean;
  maritalStatus: string;
  driverType: string;
  isIndividual: boolean;
  relationalTypeCode: string;
}

interface IQuestion {
  questionNumber: string;
  answerChoiceCode: string;
}

const MARITAL_STATUS_QUESTION: { [key: string]: string } = {
  M: '1323',
  D: '1326',
  S: '1324',
  W: '1325',
};

function parseCoverages(
  coverages: ICoverage[],
  { AdaptationCode, blindagemValue, kitGasValue }: IQuestionnaireProps
): Array<Partial<ICoverage>> {
  const parsed = coverages.map(({ coverageReference, deductible }) => {
    const parsedCoverage = {
      coverageCode: coverageReference.coverageCode,
      lineItemCode: coverageReference.lineItemCode,
    };

    return {
      coverageReference: parsedCoverage,
      itemDescription: '',
      insuredAmount: coverageReference.insuredAmount,
      deductible,
    };
  });

  if (AdaptationCode === '3' || AdaptationCode === '99') {
    const insuredAmount = blindagemValue!.replace(/\D/g, '');
    parsed.push({
      coverageReference: {
        coverageCode: 31003,
        lineItemCode: 'A62',
      },
      itemDescription: 'PVDC MMA BLINDAGEM',
      insuredAmount: Number(insuredAmount) / 100,
      deductible: {
        code: '',
        level: '',
      },
    });
  }

  if (AdaptationCode === '9' || AdaptationCode === '99') {
    const insuredAmount = kitGasValue!.replace(/\D/g, '');
    parsed.push({
      coverageReference: {
        coverageCode: 31003,
        lineItemCode: 'A61',
      },
      itemDescription: 'KIT GAS PVDC MMA',
      insuredAmount: Number(insuredAmount) / 100,
      deductible: {
        code: '',
        level: '',
      },
    });
  }

  return parsed;
}

function convertToPotential(
  customer: ICustomer,
  driverType: string,
  maritalStatus: string,
  relationalTypeCode: string,
  isPolicyHolder: boolean
): IPotentialDriver {
  const [day, month, year] = customer.dataNascimento.split('/');
  let parsed = {
    birthDate: `${year}-${month}-${day}`,
    publicIdNumber: customer.cpf,
    gender: customer.sexo,
    name: customer.nome,
    socialName: customer.nomeSocial,
    daysPerWeek: '',
    isIndividual: true,
    driverType,
    maritalStatus,
    relationalTypeCode,
    isPolicyHolder,
    isDriver: false,
    isReferenceDriver: false,
  };

  switch (driverType) {
    case '1':
      parsed = {
        ...parsed,
        isDriver: false,
      };
      break;
    case '2':
      parsed = {
        ...parsed,
        isDriver: false,
      };
      break;
    case '3':
      parsed = {
        ...parsed,
        isDriver: true,
      };
      break;
  }

  return parsed;
}

function parsePotentialDriver(
  customers: ICustomer[],
  data: IQuestionnaireProps
): Array<IPotentialDriver> {
  const list = [];

  const policyHolder = customers.find(
    customer => customer.cpf === onlyNumbers(data.cpfSegurado)
  );
  list.push(
    convertToPotential(policyHolder!, '1', data.maritalStatus, '1', true)
  );

  if (data.VehicleOwnerIsPolicyHolder !== 'T') {
    const owner = customers.find(
      customer => customer.cpf === onlyNumbers(data.cpfProprietario!)
    );

    list.push(
      convertToPotential(
        owner!,
        '2',
        data.maritalStatusProprietario!,
        data.vinculoProprietario!,
        false
      )
    );
  } else {
    list.push(
      convertToPotential(policyHolder!, '2', data.maritalStatus, '1', true)
    );
  }

  if (data.potentialDriver === 'O') {
    const driver = customers.find(
      customer => customer.cpf === onlyNumbers(data.cpfCondutor!)
    );

    list.push(
      convertToPotential(
        driver!,
        '3',
        data.maritalStatusCondutor!,
        data.vinculoCondutorProprietario!,
        false
      )
    );
  } else if (data.potentialDriver === 'P') {
    const owner = customers.find(
      customer => customer.cpf === onlyNumbers(data.cpfProprietario!)
    );

    list.push(
      convertToPotential(
        owner!,
        '3',
        data.maritalStatusProprietario!,
        data.vinculoProprietario!,
        false
      )
    );
  } else {
    list.push(
      convertToPotential(policyHolder!, '3', data.maritalStatus, '1', true)
    );
  }

  return list;
}

function parseQuestions(data: IQuestionnaireProps): IQuestion[] {
  const list = [];

  list.push({
    questionNumber: '143',
    answerChoiceCode: MARITAL_STATUS_QUESTION[data.maritalStatus],
  });

  list.push({
    questionNumber: '147',
    answerChoiceCode:
      (data.usageCode === '1' ? '1351' : '1350') ||
      (data.usageCode === '37' ? '1350' : '1351'),
  });

  list.push({
    questionNumber: '148',
    answerChoiceCode: data.question148 === 'T' ? '1352' : '1353',
  });

  const response149 = data.question148 === 'T' ? data.question149 : '1356';
  list.push({
    questionNumber: '149',
    answerChoiceCode: response149,
  });

  const response150 =
    data.question148 === 'T' && data.question149 !== '1356'
      ? data.question150
      : '1361';
  list.push({
    questionNumber: '150',
    answerChoiceCode: response150,
  });

  return list;
}

export const requestQuotation = async ({
  broker: { broker, brokerUser, brokerToken, affinity, commercialProductCode },
  salePromotion,
  data,
  vehicle,
  customers,
}: IQuotationRequest) => {
  const { cpfSegurado, phone, email, zipcode, PolicyHolderIsEmployee, socialName } = data;

  let previousPolicy = {};
  if (data.hasPreviousCoverage === 'T') {
    const [day, month, year] = data.PolicyEndDate!.split('/');

    previousPolicy = {
      startDate: `${Number(year) - 1}-${month}-${day}`,
      endDate: `${year}-${month}-${day}`,
      hasClaim: data.HasClaim === 'T',
      insurerSUSEPCode: INSURER_SUSEP_CODE,
      insurerSUSEPName: '',
      bonusClassCode: Math.min(10, data.BonusClassCode! + 1),
      policyNumber: POLICY_NUMBER,
      itemNumber: 1,
    };
  }

  const adaptationCode: Array<Number> = [];
  if (data.AdaptationCode !== '0') {
    if (data.AdaptationCode === '99') {
      adaptationCode.push(...[3, 9]);
    } else {
      adaptationCode.push(Number(data.AdaptationCode));
    }
  }

  const parsedCoverages = parseCoverages(
    salePromotion.salePromotion.coverages,
    data
  );
  const commissionPct =
    salePromotion.salePromotion.commissionPct > 0
      ? salePromotion.salePromotion.commissionPct
      : 15.0;
  const parsedPotentialDrivers = parsePotentialDriver(customers, data);

  const parsedQuestions = parseQuestions(data);

  const today = new Date();
  const todayDate = new Date().toISOString().slice(0, 10);
  const currentDay = new Date().getDay();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  const LoJackCode = data.LoJackCode === '0' ? undefined : data.LoJackCode;

  const parsedVehicle = {
    ...vehicle,
    financialStatementIndicator: vehicle.financialStatementIndicator,
    taxExemptionReasoningCode: vehicle.taxExemptionReasoningCode,
    taxExemptionStartDate: '',
    valueEstimationType: 'R',
    surchargeFactor: '0.00',
    adjustmentFactor: '1.00',
    isRgnDiscSupported: false,

    is0KM: data.Is0KM === 'T',
    dealerExitDateTime:
      data.Is0KM === 'T' ? format(new Date(), 'yyyy-MM-dd HH:mm') : '',
    isDealerOwnerVehicle: data.VehicleOwnerIsPolicyHolder === 'T',
    vehicleOwnerIsPolicyHolder: data.VehicleOwnerIsPolicyHolder === 'T',
    usageCode: data.usageCode,
    zipCode: onlyNumbers(zipcode).substring(0, 5),
    hasLoJack: !!LoJackCode,
    loJackCode: LoJackCode,
    adaptationCodes: {
      adaptationCode: adaptationCode,
    },

    renewal: {
      hasPreviousCoverage: data.hasPreviousCoverage === 'T',
      isVhclSubstitutionRenewal: data.IsVhclSubstitutionRenewal === 'T',
      isCvrgChangeRenewal: data.IsCvrgChangeRenewal === 'T',
      previousPolicy,
    },
    vehicleCoverages: {
      vehicleCoverage: parsedCoverages,
    },
    potentialDrivers: {
      potentialDriver: parsedPotentialDrivers,
    },
    referenceDriverProfile: {
      question: parsedQuestions,
    },
    regionalDiscountPct: '',
    promotionalDiscountVlr: '',
    sequenceNumber: '1',
  };

  const [year, month, day] = todayDate.split('-');

  const param = {
    cpf: onlyNumbers(cpfSegurado),
    telefone: onlyNumbers(phone),
    email: email,
    socialName: socialName,
    startDate: `${todayDate}`,
    endDate: `${currentYear + 1}-${
      currentMonth < 10 ? `0${currentMonth}` : currentMonth
    }-${day}`,
    referenceDate: `${todayDate}`,
    policyHolderIsEmployee: PolicyHolderIsEmployee === 'T',
    ignorarLead: false,
    promotionalCode: salePromotion.salePromotion.promotionalCode,
    commercialProductCode,

    broker,
    affinity,
    brokerToken,
    brokerUser,

    vehicles: {
      vehicle: [parsedVehicle],
    },

    customerTrackingName: '',
    commissionPct,
    salesPartnerCode: SALES_PARTNER_CODE,
    brokerProposalNumber: 0,
    automaticAdjustmentFactor: true,
    commissionParticipation: {
      brokerCommission: [
        {
          participationPct: 100.0,
          ...broker,
        },
      ],
    },
    dealer: {
      dealerCode: '',
      dealerBranchCode: '',
      internalBranchCode: '',
    },
    employeeData: EMPLOYEE_DATA,
    salesStructure: {
      publicIdNumber: '',
      customerTrackingName: '',
      levels: {
        level: [
          {
            code: '',
            value: '',
          },
          {
            code: '',
            value: '',
          },
        ],
      },
    },
  };

  return api.post<IQuotationResponse>('/quotation/vehicle', param);
};
