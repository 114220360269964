/* eslint-disable no-shadow */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, createContext, useContext, useCallback } from 'react';
import { IQuotation } from 'types/plan';

interface QuotationContextData {
  currentQuotation?: IQuotation;
  setQuotation(quotation: IQuotation): void;
  quotation: any;
  proposal: any;
  setProposal: any;
}

const QuotationContext = createContext<QuotationContextData>(
  {} as QuotationContextData
);

const QuotationProvider = ({ children }: any) => {
  const [currentQuotation, setCurrentQuotation] = useState<IQuotation>();
  const [proposal, setProposal] = useState<any>();
  let quotation: any;
  const setQuotation = useCallback(
    (quotation: any) => {
      setCurrentQuotation(quotation);
    },
    [setCurrentQuotation]
  );

  return (
    <QuotationContext.Provider
      value={{
        currentQuotation,
        setQuotation,
        quotation,
        proposal,
        setProposal,
      }}
    >
      {children}
    </QuotationContext.Provider>
  );
};

function useQuotation() {
  const context = useContext(QuotationContext);
  if (!context)
    throw new Error(
      'O UseQuotationContext precisa ser utilizado dentro do useQuotation'
    );

  return context;
}

export { QuotationProvider, useQuotation };
