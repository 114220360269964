import React from 'react';

export const IconMorteSVG = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
    <g data-name="Icon Angel">
        <circle data-name="Ellipse bg" cx="30" cy="30" r="30" style={{fill:'#FFC900'}}/>
    </g>
    <path data-name="Rectangle 7" transform="rotate(-20.635 54.745 -51.544)" style={{fill:'#2C3A39'}} d="M0 0h2.205v11.093H0z"/>
    <path data-name="Rectangle 8" transform="rotate(-69.382 34.662 -10.79)" style={{fill:'#2C3A39'}} d="M0 0h11.093v2.206H0z"/>
    <path data-name="Path 70" d="m172.822 397.684 7.479.528.136-1.928-7.479-.528a3.213 3.213 0 0 0-3.213 2.009l-2.332 5.789-2.157 1.647a2.91 2.91 0 0 0-1.138 2.11l-.4 5.683-.039.565-.043.61-.273 3.865a1.8 1.8 0 0 0 1.668 1.924l3.621.256h.129a1.8 1.8 0 0 0 1.794-1.673l.085-1.188-1.929-.137-.074 1.056-3.358-.237.265-3.737.152-2.161 3.909.277h.123a1.682 1.682 0 0 0 1.6-1.147l.609-1.493.029-.082a1.992 1.992 0 0 0-1.765-2.575l-3.908-.277.12-.114 2.077-1.586 11.25.8.136-1.928-10.289-.728 1.935-4.8a1.266 1.266 0 0 1 1.286-.8m-2.744 11.371.053.076-.55 1.348-3.732-.264.1-1.452z" transform="translate(-131.51 -372.441)" style={{fill:'#2C3A39'}}/>
    <path data-name="Rectangle 9" transform="rotate(-85.953 41.236 2.902)" style={{fill:'#2C3A39'}} d="M0 0h1.933v12.053H0z"/>
    <path data-name="Path 71" d="M162.726 407.312a2.91 2.91 0 0 0-1.138-2.11l-2.158-1.647-2.331-5.789a3.221 3.221 0 0 0-3.215-2.01l-7.479.529.136 1.928 7.479-.529a1.271 1.271 0 0 1 1.286.8l1.935 4.8-10.289.727.137 1.929 11.249-.8 2.077 1.587.121.114-3.908.276a2 2 0 0 0-1.766 2.575l.029.083.61 1.493a1.681 1.681 0 0 0 1.6 1.146h.121l3.908-.277.07.988.018.257.065.917.265 3.737-3.358.237-.075-1.056-1.928.137.083 1.186a1.8 1.8 0 0 0 1.794 1.674h.129l3.621-.257a1.8 1.8 0 0 0 1.669-1.923l-.357-5.042zm-5.463 3.168-.571-1.4 4.2-.315.1 1.447z" transform="translate(-135.333 -372.442)" style={{fill:'#2C3A39'}}/>
    <path data-name="Rectangle 10" transform="rotate(-4.046 611.383 -154.29)" style={{fill:'#2C3A39'}} d="M0 0h12.053v1.933H0z"/>
    <path data-name="Rectangle 11" transform="translate(29.073 12.223)" style={{fill:'#2C3A39'}} d="M0 0h2.206v15.188H0z"/>
</svg>
);
