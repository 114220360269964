import React from 'react';
import { SVGProps } from './types';

export const AnotherPersonSVG = ({ width, height }: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36.695 28.88"
    width={width}
    height={height}
  >
    <path
      d="M162.89 200.488a4.928 4.928 0 1 0 4.93 4.93 4.934 4.934 0 0 0-4.93-4.93m0 7.545a2.616 2.616 0 1 1 2.618-2.615 2.619 2.619 0 0 1-2.618 2.615"
      transform="translate(-148.63 -194.898)"      
    />
    <path
      d="M165.135 196.136a14.44 14.44 0 1 0 14.439 14.439 14.455 14.455 0 0 0-14.439-14.439m-6.369 24.776a6.2 6.2 0 0 1 12.392.211 12.049 12.049 0 0 1-12.392-.211m14.527-1.347a8.517 8.517 0 0 0-16.6-.262 12.162 12.162 0 1 1 16.6.262"
      transform="translate(-150.696 -196.136)"      
    />
    <path
      d="M178.669 203.032a3.958 3.958 0 0 0-6.594-2.953l-.1.1-.189.18.159.206a13.891 13.891 0 0 1 .917 1.351l.214.36.261-.328.2-.222a1.756 1.756 0 1 1 1.17 3.066l-.082-.008-.5-.049.113.4a13.775 13.775 0 0 1 .348 1.6l.039.257.259-.012a3.947 3.947 0 0 0 3.776-3.947"
      transform="translate(-144.697 -195.301)"      
    />
    <path
      d="m174.343 206.1-.333-.013.035.331c.046.46.071.929.071 1.395l-.009.473.274.018a4.488 4.488 0 0 1 4.179 4.465v.289h2.2v-.289a6.657 6.657 0 0 0-6.418-6.669"
      transform="translate(-144.065 -193.306)"      
    />
  </svg>
);
