/* eslint-disable import/no-unresolved */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable import/prefer-default-export */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import React, { Component, useState } from 'react';
import { useWindowSize } from 'helpers/custom-hooks';
import { Navigate, Link, useLocation } from 'react-router-dom';
import { MenuMobileSVG } from 'assets/img/MenuMobileSVG';
import { CloseIconSVG } from 'assets/img/CloseIconSVG';
import Button from 'components/Button';
import { googleAnalyticsEvent } from 'helpers/googleTagManager.js';

import { mobileMinWidth, tabletMinWidth } from 'styles/variables';
import CompanyLogo from 'assets/img/logo-mma-yelum.svg'
import appConfig from '../../../assets/settings/appConfig.PRD.json'

import * as S from './styles';

interface IMenu {
  link: string;
  label: string;
}

const menuList: IMenu[] = [
  // { link: '/', label: 'Como Funciona?' },
  { link: '/contact', label: 'Fale Conosco' },
  { link: '/faq', label: 'Dúvidas Frequentes' },
  // { link: '/', label: 'Recuperar Cotação' },
];

const eventTag = {
  [menuList[0].link]: {
    event: 'home_link_faleconosco',
    button: 'Fale Conosco'
  },
  [menuList[1].link]: {
    event: 'home_link_duvidas',
    button: 'Dúvidas Frequentes'
  },
  quotation: {
    event: 'home_btn_coteagora_menu',
    button: 'Cote agora'
  }
}

const NavBar = () => {
  const [width] = useWindowSize();
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const { pathname } = useLocation();
  const isActive = pathname !== '/denied';

  const handleClick = (url: any) => {
    setShowMobileMenu(false);
    sendEventDataGTM(url);
    return <Link to={url} />;
  };

  const handleNavigateHome = () => {
    if (pathname !== '/denied') {
      setShowMobileMenu(false);
      return <Link to="/" />;
    }

    return null;
  };

  const sendDataLayerGTM = () =>
    googleAnalyticsEvent({
      dataLayer: {
        location: 'Header',
        ...eventTag.quotation
      },
    });

  const sendEventDataGTM = (value: string) => {
    setShowMobileMenu(false)
    googleAnalyticsEvent({
      dataLayer: {
        location: 'Header',
        ...eventTag[value],
      },
    });
  }


  return (
    <S.Wrapper pathname={pathname}>
      <S.WrapperMaxContent>
        <S.Container>
          <Link
            to={isActive ? '/' : '#'}
            style={{ height: '64px', display: 'flex', alignItems: 'center' }}
          >
            <S.CompanyLogoPNG onClick={handleNavigateHome} src={CompanyLogo} />
          </Link>

          {isActive && (
            <>
              {width > 948 && (
                <S.NavButtons>
                  {menuList.map((item, index) => (
                    <S.NavButton key={index}>
                      <Link to={item.link} style={{ textDecoration: 'none' }}>
                        <span onClick={() => sendEventDataGTM(item.link)}>
                          {item.label}
                        </span>
                      </Link>
                    </S.NavButton>
                  ))}

                  <S.NavButton>
                    <Link to="/questionnaire" style={{ textDecoration: 'none' }}>
                      <Button
                        data-test-id="nav-questionnaire-button"
                        theme="secondary"
                        onClick={() => {
                          sendDataLayerGTM();
                          setShowMobileMenu(false);
                        }}
                      >
                        Cote agora
                      </Button>
                    </Link>
                  </S.NavButton>
                </S.NavButtons>
              )}

              {width <= 948 && (
                <S.MenuMobileContainer
                  data-test-id="mobile-menu-button"
                  onClick={() => setShowMobileMenu(!showMobileMenu)}
                >
                  <MenuMobileSVG />
                </S.MenuMobileContainer>
              )}
              {showMobileMenu ? (
                <>
                  <S.MenuMobile data-test-id="mobile-menu">
                    <S.MenuMobileHeader>
                      <S.Title>
                        <strong>{appConfig.COMPANY_FULL_NAME}</strong>
                      </S.Title>
                      <S.IconButton
                        data-test-id="mobile-close-menu-button"
                        onClick={() => setShowMobileMenu(!showMobileMenu)}
                      >
                        <CloseIconSVG />
                      </S.IconButton>
                    </S.MenuMobileHeader>
                    {menuList.map((item, index) => (
                      <S.MenuMobileButton key={index}>
                        <Link to={item.link} style={{ textDecoration: 'none' }}>
                          <p onClick={() => handleClick(item.link)}>
                            {item.label}
                          </p>
                        </Link>
                      </S.MenuMobileButton>
                    ))}

                    <Link
                      to="/questionnaire"
                      style={{ textDecoration: 'none' }}
                    >
                      <Button
                        theme="default"
                        data-test-id="mobile-menu-questionnaire-button"
                        onClick={() => {
                          setShowMobileMenu(false);
                          sendDataLayerGTM();
                        }}
                      >
                        Cote agora
                      </Button>
                    </Link>
                  </S.MenuMobile>
                  <S.AppFade />
                </>
              ) : (
                ''
              )}
            </>
          )}
        </S.Container>
      </S.WrapperMaxContent>
    </S.Wrapper>
  );
};

export { NavBar };
