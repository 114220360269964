/* eslint-disable import/no-unresolved */
/* eslint-disable spaced-comment */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { LOCAL_BROKER } from 'config';

import { useBroker } from '../contexts/Broker';

import CompanyLogoSVG from '../assets/img/Yelum_RGB_Endosso_Cinza.svg';

export const useSearchLogo = () => {
  const { broker } = useBroker();

  const [logo, setLogo] = useState('');

  useEffect(() => {
    const brokerDataLocalStorage = window.localStorage.getItem(LOCAL_BROKER);

    const brokerDataParsed = brokerDataLocalStorage
      ? JSON.parse(brokerDataLocalStorage)
      : {};

    setLogo(
      brokerDataParsed?.logo
        ? `data:image/png;base64, ${brokerDataParsed.logo}`
        : `${CompanyLogoSVG}`
    );
  }, [broker?.logo]);

  return [logo];
};
