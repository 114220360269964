/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React, { ButtonHTMLAttributes } from 'react';

import * as S from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  theme?:
    | 'default'
    | 'primary'
    | 'secondary'
    | 'outline'
    | 'aqua_blue'
    | 'remove';
  onHover?: any;
  tooltipContent?: string;
  disabled?: boolean;
  tooltipWidth?: string;
}

const Button = ({
  children,
  theme = 'default',
  onHover,
  tooltipContent = '',
  tooltipWidth = '100%',
  disabled,
  ...rest
}: ButtonProps) => (
  <S.Button disabled={disabled} type="button" variant={theme} {...rest}>
    {children}
    {disabled && tooltipContent.length > 0 && (
      <S.Tooltip tooltipWidth={tooltipWidth}>{tooltipContent}</S.Tooltip>
    )}
  </S.Button>
);

export default Button;
