/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unknown-property */
/* eslint-disable import/extensions */
import React from 'react';
import { SVGProps } from './types';

export const AgreementSVG = ({ width, height, style }: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 272 258.16"
    width={width}
    height={height}
    style={style}
  >
    <defs>
      <linearGradient
        id="fc00f0zdda"
        x1=".168"
        y1=".758"
        x2=".829"
        y2=".253"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="1" stopColor="#f5f5f5" />
      </linearGradient>
    </defs>
    <g data-name="IMG Contract Agreement" transform="translate(-7.895 -59.825)">
      <path
        data-name="Path 531"
        d="m139.637 242.154-59.229 14.013s-9.232-32.463 25.91-37.209l28.786 2.669z"
        transform="translate(-50.325 -125.097)"
        style={{ fill: '#e7eaeb' }}
      />
      <path
        data-name="Path 532"
        d="M68.219 312.094 8.99 326.106S-.241 293.643 34.9 288.9l28.787 2.668z"
        transform="translate(0 -174.38)"
        style={{ fill: '#e7eaeb' }}
      />
      <path
        data-name="Path 533"
        d="m164.051 179.19 13.981-4.278c16.871-5.163 34.083 6.209 35.035 23.147l.29 5.16 7.866 122.73c.718 14.051 4.239 46.715 21.2 45.308l-118.238 38.364c-22.545 6.556-35.367-7.783-37.9-43.694l-6.84-121.72-.604-10.807c-1.134-12.844-5.634-23.875-21.4-22.567z"
        transform="translate(-34.912 -93.176)"
        style={{ fill: '#f5f5f5' }}
      />
      <path
        data-name="Path 534"
        d="M832.831 502.007s-3.759 11.883-3.292 15.757 7.495 2.488 7.495 2.488l.928-17.5z"
        transform="translate(-578.941 -324.546)"
        style={{ fill: '#e9b58e' }}
      />
      <path
        data-name="Path 535"
        d="m235.469 109.25 13.98-4.278c16.871-5.163 34.083 6.209 35.035 23.147l.29 5.161 7.866 122.73c.719 14.051 4.239 46.715 21.2 45.308L195.6 339.681c-22.545 6.556-35.367-7.783-37.9-43.694l-6.84-121.72-.608-10.812c-1.134-12.844-5.634-23.875-21.4-22.568z"
        transform="translate(-85.237 -43.893)"
        style={{ fill: 'url(#fc00f0zdda)' }}
      />
      <path
        data-name="Path 536"
        d="M504.578 628.021c-6.529.178-12.508-2.585-18.372-5.094A20.347 20.347 0 0 1 484 621.9a11.4 11.4 0 0 1-1.765-1.279 7.408 7.408 0 0 1-2.081-3.117 6.64 6.64 0 0 1 1.026-5.979 9.773 9.773 0 0 1 5.569-3.764 10.32 10.32 0 0 1 7.331.9 13.526 13.526 0 0 1 5.736 6.56 27.171 27.171 0 0 1 1.866 5.908 45.97 45.97 0 0 1 .575 3.959 8.641 8.641 0 0 1-.343 4.274 6.616 6.616 0 0 1-1.552 2.427 4.73 4.73 0 0 1-1.089.818 14.449 14.449 0 0 1-1.412.576c-1.072.389-2.159.737-3.244 1.085-3.131 1-6.276 1.962-9.423 2.915q-3.406 1.031-6.817 2.044l-.347.1a2.607 2.607 0 1 1-1.386-5.027q3.111-.922 6.218-1.861c3.565-1.076 7.13-2.156 10.675-3.3 1.2-.385 2.419-.745 3.579-1.233l-.623.263a2.186 2.186 0 0 0 .5-.3l-.528.408a3.82 3.82 0 0 0 .58-.586l-.407.528a4.351 4.351 0 0 0 .511-.871l-.263.623a5.035 5.035 0 0 0 .341-1.25l-.093.693a14.147 14.147 0 0 0-.231-3.5l.093.693a27.725 27.725 0 0 0-1.8-7.037l.263.623a15.107 15.107 0 0 0-1.911-3.346l.408.528a10.354 10.354 0 0 0-1.8-1.826l.528.408a8.315 8.315 0 0 0-1.829-1.076l.623.263a7.585 7.585 0 0 0-1.9-.519l.693.093a7.6 7.6 0 0 0-1.966 0l.693-.093a7.826 7.826 0 0 0-1.978.538l.623-.263a7.606 7.606 0 0 0-1.667.968l.528-.408a6.624 6.624 0 0 0-1.187 1.178l.407-.528a5.352 5.352 0 0 0-.685 1.164l.263-.623a4.368 4.368 0 0 0-.3 1.091l.093-.693a3.861 3.861 0 0 0 0 .987l-.093-.693a3.874 3.874 0 0 0 .261.96l-.263-.623a4.467 4.467 0 0 0 .583.988l-.408-.528a6.064 6.064 0 0 0 1.075 1.054l-.528-.408a9.364 9.364 0 0 0 1.747 1.04c1.461.681 2.967 1.283 4.452 1.91l-.623-.263a41.828 41.828 0 0 0 10.438 3.228l-.693-.093a22.234 22.234 0 0 0 3.543.194 2.63 2.63 0 0 1 2.607 2.607 2.658 2.658 0 0 1-2.607 2.607z"
        transform="translate(-325.579 -395.512)"
        style={{ fill: '#1e194d' }}
      />
      <g data-name="Group 5">
        <g data-name="Group 4">
          <path
            data-name="Path 537"
            d="M773.3 506.234a2.35 2.35 0 0 1 .318-3.129l35-29.308a11.456 11.456 0 0 0 4.012-7.146 12.206 12.206 0 0 0-2.147-8.832l-9.037-12.606 3.2-2.68 8.765 12.226a17.814 17.814 0 0 1 3.393 11.619 15.442 15.442 0 0 1-5.357 10.776l-35.265 29.529a1.96 1.96 0 0 1-2.882-.449z"
            transform="translate(-539.026 -282.638)"
            style={{ fill: '#1e194d' }}
          />
        </g>
        <path
          data-name="Path 538"
          d="m663.064 424.058-83.078 76c-3.688 3.244-4.954 7.04-1.221 10.627 3.689 3.544 7.511 3 11.155-.2l85.906-72.916c6.848-5.509-5.913-19.016-12.762-13.511z"
          transform="translate(-400.677 -268.708)"
          style={{ fill: '#1e194d' }}
        />
        <path
          data-name="Path 539"
          d="M577.509 701.384s-4.419 3.08-13.272 5.951c-1.668.541-4.8-2.869-4.126-4.384 1.7-3.841 3.369-7.478 6.026-10.181.906 6.643 5.069 9.03 11.372 8.614z"
          transform="translate(-389.053 -458.967)"
          style={{ fill: '#6c7da7' }}
        />
        <path
          data-name="Path 540"
          d="m560.544 730.837-2.349.438-2.615.465a.654.654 0 0 1-.772-.8l.787-2.3.948-2.516c-.189 2.769 1.475 4.225 4.001 4.713z"
          transform="translate(-385.359 -482.47)"
          style={{ fill: '#1e194d' }}
        />
        <path
          data-name="Path 541"
          d="M892.317 423.974c-.017.016-.036.03-.053.045l-5.173 4.707c-1.442 1.313-4.457.495-6.734-1.827s-2.953-5.268-1.51-6.58l5.173-4.707.049-.049c1.443-1.313 4.457-.5 6.734 1.826s2.957 5.272 1.514 6.585z"
          transform="translate(-613.181 -263.195)"
          style={{ fill: '#6c7da7' }}
        />
      </g>
      <g data-name="Path 542" style={{ fill: '#1e194d' }}>
        <path
          d="M450.242 521.061c-1.033 0-1.829-.723-1.981-1.8-.195-1.377.762-2.874 2.088-3.27l38.95-11.641c.22-.066.442-.1.66-.1 1.032 0 1.828.724 1.98 1.801.195 1.376-.761 2.874-2.088 3.27l-38.949 11.641c-.22.066-.443.1-.66.1z"
          style={{ stroke: 'none' }}
          transform="translate(-309.932 -325.775)"
        />
        <path
          d="M450.242 520.561c.169 0 .343-.026.517-.078l38.949-11.64c1.103-.33 1.898-1.576 1.736-2.722-.116-.82-.713-1.37-1.486-1.37-.168 0-.342.026-.517.078l-38.949 11.64c-1.103.33-1.898 1.576-1.736 2.722.116.82.713 1.37 1.486 1.37m0 1c-1.244 0-2.285-.882-2.476-2.23-.23-1.63.867-3.35 2.44-3.82l38.949-11.64c1.573-.47 3.049.48 3.28 2.11.23 1.63-.868 3.35-2.441 3.82l-38.949 11.64c-.272.082-.542.12-.803.12z"
          style={{ fill: '#f5f5f5', stroke: 'none' }}
          transform="translate(-309.932 -325.775)"
        />
      </g>
      <g data-name="Path 543" style={{ fill: '#1e194d' }}>
        <path
          d="M323.25 442.077c-1.032 0-1.828-.723-1.98-1.8-.195-1.377.762-2.874 2.088-3.27l35.352-10.566.029-.009.027-.012c.106-.046.2-.08.287-.107L398 414.673c.22-.066.443-.1.66-.1 1.033 0 1.829.724 1.981 1.801.195 1.376-.761 2.874-2.088 3.27l-35.352 10.566-.028.009-.028.011c-.108.048-.2.081-.285.107l-38.95 11.64c-.22.067-.443.1-.66.1z"
          style={{ stroke: 'none' }}
          transform="translate(-220.448 -262.584)"
        />
        <path
          d="M323.25 441.577c.17 0 .344-.026.518-.078l38.95-11.641c.067-.02.14-.047.23-.086l.054-.024.057-.017 35.352-10.565c1.103-.33 1.898-1.576 1.736-2.722-.116-.82-.713-1.37-1.486-1.37-.169 0-.342.026-.517.078l-38.948 11.64c-.068.02-.144.05-.231.087l-.055.024-.057.017-35.351 10.565c-1.104.33-1.899 1.576-1.737 2.722.116.82.713 1.37 1.486 1.37m0 1c-1.244 0-2.285-.882-2.476-2.23-.23-1.631.867-3.35 2.44-3.82l35.352-10.565c.112-.049.225-.093.342-.128l38.949-11.64c1.573-.47 3.048.48 3.28 2.11.23 1.63-.868 3.35-2.44 3.82l-35.353 10.565a3.017 3.017 0 0 1-.342.127l-38.949 11.64c-.272.082-.542.121-.803.121z"
          style={{ fill: '#f5f5f5', stroke: 'none' }}
          transform="translate(-220.448 -262.584)"
        />
      </g>
      <g data-name="Path 544" style={{ fill: '#1e194d' }}>
        <path
          d="M316.125 385.015c-1.033 0-1.829-.723-1.981-1.8-.195-1.377.761-2.874 2.088-3.271l35.352-10.565.028-.009.027-.012c.107-.046.2-.08.287-.107l38.949-11.64c.22-.066.443-.1.66-.1 1.032 0 1.828.724 1.98 1.801.195 1.376-.76 2.874-2.088 3.27l-35.351 10.566-.029.009-.027.011c-.106.047-.2.081-.286.107l-38.95 11.64c-.22.067-.442.1-.66.1z"
          style={{ stroke: 'none' }}
          transform="translate(-215.427 -222.376)"
        />
        <path
          d="M316.125 384.515c.168 0 .342-.026.517-.078l38.95-11.641c.066-.02.14-.048.228-.086l.055-.024.058-.017 35.351-10.565c1.103-.33 1.899-1.577 1.737-2.722-.116-.82-.713-1.37-1.486-1.37-.169 0-.343.026-.517.078l-38.95 11.64c-.066.02-.142.049-.23.087l-.054.024-.057.017-35.352 10.565c-1.103.33-1.898 1.576-1.736 2.721.116.82.713 1.371 1.486 1.371m0 1c-1.244 0-2.286-.882-2.476-2.23-.231-1.631.867-3.35 2.44-3.82L351.44 368.9c.112-.049.226-.093.343-.128l38.948-11.64c1.574-.47 3.05.48 3.28 2.11.23 1.63-.868 3.35-2.44 3.82l-35.352 10.565a3.025 3.025 0 0 1-.342.127l-38.949 11.64c-.273.082-.543.121-.803.121z"
          style={{ fill: '#f5f5f5', stroke: 'none' }}
          transform="translate(-215.427 -222.376)"
        />
      </g>
      <g data-name="Path 545" style={{ fill: '#1e194d' }}>
        <path
          d="M308.998 327.953c-1.033 0-1.829-.724-1.981-1.8-.195-1.377.762-2.875 2.088-3.271l35.352-10.565.029-.009.027-.012c.109-.047.2-.08.285-.106l38.95-11.641c.22-.066.443-.1.66-.1 1.033 0 1.829.724 1.981 1.801.195 1.376-.761 2.874-2.088 3.27l-35.352 10.566-.028.008-.027.012c-.11.047-.201.081-.288.107l-38.948 11.64c-.22.067-.443.1-.66.1z"
          style={{ stroke: 'none' }}
          transform="translate(-210.405 -182.167)"
        />
        <path
          d="M308.998 327.453c.169 0 .343-.026.517-.078l38.948-11.64c.067-.02.142-.049.233-.088l.054-.023.056-.017 35.352-10.565c1.103-.33 1.898-1.577 1.736-2.722-.116-.82-.713-1.37-1.486-1.37-.168 0-.342.026-.517.078l-38.95 11.64c-.066.02-.14.048-.23.087l-.054.024-.057.017-35.352 10.565c-1.103.33-1.898 1.576-1.736 2.721.116.82.713 1.371 1.486 1.371m0 1c-1.244 0-2.285-.883-2.476-2.23-.23-1.631.867-3.35 2.44-3.82l35.352-10.565c.112-.049.225-.093.342-.128l38.949-11.64c1.573-.47 3.048.48 3.28 2.11.23 1.63-.868 3.35-2.44 3.82l-35.353 10.565a3.146 3.146 0 0 1-.342.127l-38.949 11.64c-.272.082-.542.121-.803.121z"
          style={{ fill: '#f5f5f5', stroke: 'none' }}
          transform="translate(-210.405 -182.167)"
        />
      </g>
      <g data-name="Group 6" transform="translate(88.895 200.846)">
        <path
          data-name="Path 546"
          d="M282.32 617.577c-1.383-9.811 5.463-20.915 15.291-24.8a16.115 16.115 0 0 1 12.412-.2l.038.019a12.975 12.975 0 0 1 1.38.705l8.316 4.547-4.214-.689a14.964 14.964 0 0 1 2.365 6.348c1.383 9.811-5.463 20.915-15.291 24.8a18.642 18.642 0 0 1-5.883 1.32l-.911 1.909-5.8-3.1c-.32-.145-.631-.306-.937-.475l-.1-.053a13.638 13.638 0 0 1-6.666-10.331z"
          transform="translate(-282.143 -588.403)"
          style={{ fill: '#277f98' }}
        />
        <ellipse
          data-name="Ellipse 1"
          cx="20.675"
          cy="16.141"
          rx="20.675"
          ry="16.141"
          transform="rotate(-52.32 33.41 16.214)"
          style={{ fill: '#71cdd5' }}
        />
        <path
          data-name="Path 547"
          d="M354.773 630.383a3.378 3.378 0 0 0-2.017-1.473 3.343 3.343 0 0 0-2.544.4 3.657 3.657 0 0 0-1.473 2.017c-.591 1.87-1.478 5.216-2.07 7.086l-1.769 5.577c-1.9-1.88-1.445-1.4-3.349-3.278l-1.336-1.32a3.313 3.313 0 1 0-4.553 4.815l3.667 3.621c1.947 1.923 1.532 1.483 3.479 3.406l1.337 1.32a3.317 3.317 0 0 0 3.206.772 3.4 3.4 0 0 0 2.285-2.378l1.774-5.609c.938-2.965 2.171-7.407 3.109-10.372l.646-2.042a3.368 3.368 0 0 0-.392-2.542z"
          transform="translate(-319.145 -614.752)"
          style={{ fill: '#fff' }}
        />
      </g>
      <path
        data-name="Path 548"
        d="m775.3 656.093-13.051 2.277v-13.63l13.051-2.277z"
        transform="translate(-531.551 -423.518)"
        style={{ fill: '#4d4a73' }}
      />
      <path
        data-name="Path 549"
        d="M723.075 851.486c6.009-1.9 16.118-1.828 22.579.161a16.042 16.042 0 0 1 2.77 1.108 32.365 32.365 0 0 1 13.678.636c4.54 1.4 4.8 3.613.576 4.948-4.058 1.283-10.769 1.282-15.317.04-.28.106-.573.21-.887.309-6.009 1.9-16.118 1.828-22.58-.161s-6.828-5.141-.819-7.041z"
        transform="translate(-500.936 -569.833)"
        style={{ opacity: 0.1 }}
      />
      <path
        data-name="Path 550"
        d="m740.707 853.274-4.6.77c-3.366-.456-5.053-1.051-6.145.107l-.31.328c-.406.806.233 2.1 1.469 2.579l9.151 3.654c1.787.424 3.664-.193 3.674-1.792 0 0-.01-4.47-.826-4.648-1.633-.355-.984-.659-2.413-.998z"
        transform="translate(-508.502 -572.065)"
        style={{ fill: '#1e194d' }}
      />
      <path
        data-name="Path 551"
        d="M759.1 846.02c.022 1.874 4.58 1.819 4.558-.055l-.073-6.09-4.558.055z"
        transform="translate(-529.283 -562.624)"
        style={{ fill: '#e9b58e' }}
      />
      <path
        data-name="Path 552"
        d="M742.107 687.4c1.593 2.155 5.545 2.12 6.4.659.494-8.615 3.4-17.788.079-29.632l3.852-35.928-16.055 3.209 3.564 37.929z"
        transform="translate(-513.324 -409.45)"
        style={{ fill: '#4d4a73' }}
      />
      <path
        data-name="Path 553"
        d="M851.76 817.437c1.021 1.365 4.839-1.131 3.818-2.5l-3.317-4.435-3.818 2.5z"
        transform="translate(-592.29 -541.93)"
        style={{ fill: '#e9b58e' }}
      />
      <path
        data-name="Path 554"
        d="M798.666 687.792c2.238 1.337 5.256-2.41 5.565-3.932-2.694-7.533-4.464-18.411-11.493-22.706l-2.986-20.254c.255-3.108-.322-5.579-1.6-10.127l-11.535 6.792 10.283 30.57a7.79 7.79 0 0 0 .735 1.578z"
        transform="translate(-541.674 -415.283)"
        style={{ fill: '#4d4a73' }}
      />
      <path
        data-name="Path 555"
        d="M748.066 461.749c.1 3.69 3.467 5.879 6.209 4.193s4.881-6.045 4.778-9.735-2.409-5.313-5.151-3.626-5.939 5.478-5.836 9.168z"
        transform="translate(-521.558 -289.248)"
        style={{ fill: '#e9b58e' }}
      />
      <path
        data-name="Path 556"
        d="m766.4 495.072.377-.1a2.4 2.4 0 0 0 1.717-2.916l-1.859-7.184a2.4 2.4 0 0 0-2.916-1.717l-.377.1a2.4 2.4 0 0 0-1.717 2.916l.771 7.166c.331 1.274 2.73 2.063 4.004 1.735z"
        transform="translate(-531.06 -311.21)"
        style={{ fill: '#e9b58e' }}
      />
      <path
        data-name="Path 557"
        d="M762.172 463.05s-.688 2.487-5.218 1.977c-3.419-.384-.966-6.249-4.1-7.7-4.232-1.964-2.562-6.107-.69-7.46s4.248-.571 7.176.408c3.102 1.036 7.32 3.433 2.832 12.775z"
        transform="translate(-523.07 -287.295)"
        style={{ fill: '#80653d' }}
      />
      <circle
        data-name="Ellipse 2"
        cx="1.625"
        cy="1.625"
        r="1.625"
        transform="translate(229.998 170.277)"
        style={{ fill: '#e9b58e' }}
      />
      <path
        data-name="Path 558"
        d="M757.984 546.45c-.391-9.059-1.752-14.977-1.31-22.809-.171-14.966-2.041-15.606-5.342-16.554l-3.3-.368c-1.025.2-1.974.667-2.988.915-4.244 1.038-6.965 1.269-10.013 2.92-2.715 1.47-2.872 5.4-2.866 8.69-.733 9.646-.927 24.167-1.064 28.929 1.543 6.817 24.299 2.892 26.883-1.723z"
        transform="translate(-509.604 -327.866)"
        style={{ fill: '#6dd9da' }}
      />
      <path
        data-name="Path 560"
        d="M769.945 505.862a2.274 2.274 0 0 1-1.762 2.977c-2.555.807-6.777-.614-6.777-.614s2.811-2.946 8.539-2.363z"
        transform="translate(-530.959 -327.209)"
        style={{ fill: '#fff' }}
      />
      <path
        data-name="Path 561"
        d="M761.863 476.88a.544.544 0 0 0 .609-.465l.961-3.447c.04-.3-.73-.121-1.025-.16-.3-.04-1.141-.308-1.18-.012l.17 3.476a.544.544 0 0 0 .465.608z"
        transform="translate(-530.834 -303.866)"
        style={{ fill: '#80653d' }}
      />
      <path
        data-name="Path 562"
        d="M655.087 614.316a2.728 2.728 0 0 1-.854 3.751l-3.063 1.927a2.729 2.729 0 0 1-3.752-.855 2.729 2.729 0 0 1 .855-3.751l3.063-1.927a2.728 2.728 0 0 1 3.751.855z"
        transform="translate(-450.346 -402.789)"
        style={{ fill: '#e9b58e' }}
      />
      <path
        data-name="Path 563"
        d="M668.35 603.686s12.4-1.215 15.783-3.168-.659-7.869-.659-7.869l-16.459 6.028z"
        transform="translate(-464.447 -388.417)"
        style={{ fill: '#e9b58e' }}
      />
      <path
        data-name="Path 564"
        d="m721.832 534.23-8.725 15.375c-1.587 3.936-11.688.158-9.321-4.34.149-.284 9.972-19.39 9.972-19.39s7.68 2.672 8.074 8.355z"
        transform="translate(-490.106 -341.365)"
        style={{ fill: '#6dd9da' }}
      />
      <path
        data-name="Path 565"
        d="m785.344 513.277 12.814 12.179c3.437 2.49-2.669 11.381-6.463 8-.239-.213-16.409-14.359-16.409-14.359s4.447-6.809 10.058-5.82z"
        transform="translate(-540.74 -332.418)"
        style={{ fill: '#6dd9da' }}
      />
      <path
        data-name="Path 566"
        d="m853.008 826.813-.065 4.224c-.958 2.928-3.153 3.274-2.3 4.437l.722.63c.723.733 3.134.507 3.633-.136l4.24-6.425a4.067 4.067 0 0 0-.934-4.462s-1.257-1.248-1.894-1.265c-1.518-.041-2.866 1.777-3.402 2.997z"
        transform="translate(-593.704 -551.308)"
        style={{ fill: '#1e194d' }}
      />
      <g data-name="Group 9">
        <g data-name="Group 8">
          <g data-name="Group 7">
            <path
              data-name="Path 567"
              d="m700.565 404.089.1-.059c.044-.01.087-.017.13-.027z"
              transform="translate(-488.088 -255.488)"
              style={{ fill: '#1737a2' }}
            />
          </g>
          <path
            data-name="Path 568"
            d="m599.67 174.866 22.9-19.169a4.7 4.7 0 0 1 3.241-1.214l24.672 1.708a7.711 7.711 0 0 1 2.912 1.424c1.987 1.317 6.844 3.965 6.844 3.965l-8.98 27.909c.669 14.181-6.55 35.635-20.689 43.942-14.271 3.171-28.65-11.046-29.319-25.216l-4.434-26.607a7.7 7.7 0 0 1 2.853-6.742z"
            transform="translate(-414.931 -79.659)"
            style={{ fill: '#d6a503' }}
          />
        </g>
      </g>
      <g data-name="Group 10">
        <path
          data-name="Path 569"
          d="m653.845 243.789-.228.085c14.189-8.288 24.519-24.556 23.849-38.77l2.592-28.722c.229-2.545-1.152-4.423-3.368-4.576l-24.672-1.706a4.7 4.7 0 0 0-3.24 1.213l-22.9 19.169a7.7 7.7 0 0 0-2.855 6.742l4.435 26.607c.671 14.213 12.054 23.213 26.387 19.958z"
          transform="translate(-433.399 -90.662)"
          style={{ fill: '#fff' }}
        />
        <path
          data-name="Path 570"
          d="m673.077 255.031-.183.068a38.673 38.673 0 0 0 19.073-31.007l1.763-26.466-23.437-1.622-19.577 16.389 1.258 26.675c1.257 11.255 9.639 18.567 21.103 15.963z"
          transform="translate(-452.962 -108.923)"
          style={{ fill: '#feda02' }}
        />
        <path
          data-name="Path 571"
          d="m666.034 255.031-.183.068-2.6-59.095-22.529 18.86 4.21 24.2c.535 11.373 9.638 18.571 21.102 15.967z"
          transform="translate(-445.919 -108.923)"
          style={{ fill: '#efcd00' }}
        />
      </g>
      <path
        data-name="Path 572"
        d="M705.327 263.735a2.792 2.792 0 0 0 1.574-2.956c-.262-2.875-1.862-4.3-3.038-4.562-.471-.1-.957-.084-1.439-.172-.267-.049-.539-.1-.808-.131h-.025a4.6 4.6 0 0 0-.629-.041h-.075v.009l-.313-.014v.005l-.334-.237c-.031-.022-.149-.144-.159-.1 0-.059.008-.151.01-.2l.044-1.733a.078.078 0 0 0-.005-.022 5.376 5.376 0 0 0 .639-1.374 6.657 6.657 0 0 0 .224-1.088c.722-.335.933-1.553 1.032-2.372.079-.657.212-2.273-.55-1.955.015-.1.03-.192.046-.277a9.93 9.93 0 0 0 .2-1.634 2.835 2.835 0 0 0-.274-1.406c-.069-.123-.173-.232-.3-.192a1.259 1.259 0 0 0 .079-.467 1.566 1.566 0 0 0-1.664-1.621 4.455 4.455 0 0 0-2.948.827 6.689 6.689 0 0 0-2.353 3.5 1.8 1.8 0 0 0-.093.6 1.222 1.222 0 0 0-.2.347 4.335 4.335 0 0 0-.284 1.628 7.347 7.347 0 0 0 .193 1.476c.016.075.031.16.046.248-.617.391-.532 1.777-.472 2.332.084.782.278 1.9 1.075 1.56a2.683 2.683 0 0 0 .9 1.852l-.043 1.688c0 .08.008.178 0 .266a1.375 1.375 0 0 1-.123.139l-.526.6-1.179 1.08-.585.507c-.584.507-1.187 1.032-1.65 1.394a6.879 6.879 0 0 0-1.37 1.306 12.813 12.813 0 0 0-1.375 3.794c-.405 1.7 1.506 3.532 3.034 2.919zm-11.7-11.4a13.504 13.504 0 0 1-.024-.2c.013.065.021.136.029.197z"
        transform="translate(-479.596 -140.754)"
        style={{ fill: '#fff' }}
      />
    </g>
  </svg>
);
