/* eslint-disable react/require-default-props */
import React from 'react';
import { useModal } from 'contexts/Modal';
import * as S from './styles';

const Modal = ({ children, removePadding, className }: S.IModalProps) => {
  const { hideModal } = useModal();
  return (
    <>
      <S.Fade onClick={() => hideModal()} />
      <S.Container className={className} data-test-id="tooltip-modal">
        <S.Modal removePadding={removePadding}>{children}</S.Modal>
      </S.Container>
    </>
  );
};

export { Modal };
