import { onlyNumbers } from '@brazilian-utils/brazilian-utils';
import api from 'api';
import { IBrokerData } from 'types/broker';

interface ITracking {
  documentNumber: string;
  name?: string;
  email?: string;
  phone: string;
  commercialProductCode: number;
  brokerProposalNumber?: number;
  broker: IBrokerData;
  step: {
    code: string;
    situation: boolean;
  };
}

interface ITrackingResponse {
  salvarRastreamentoNavegacao: boolean;
}

export const trackingLead = ({
  documentNumber,
  commercialProductCode,
  broker: { broker, brokerUser, brokerToken },
  ...rest
}: ITracking) =>
  api.post<ITrackingResponse>('/broker/tracking', {
    ...rest,
    documentNumber: onlyNumbers(documentNumber),
    commercialProductCode,
    broker,
    brokerUser,
    brokerToken,
  });
