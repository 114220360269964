import styled from 'styled-components';
import { maxContainerResolution, mobileMinWidth } from 'styles/variables';

import illustration from './assets/illustration.png';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 25px 0;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: ${maxContainerResolution};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h1`
  font-size: 26px;
  line-height: 36px;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro};
  font-weight: 300;

  @media (max-width: ${mobileMinWidth}) {
    font-size: 24px;
    text-align: center;
  }
`;

export const Subtitle = styled.h1`
  font-size: 26px;
  line-height: 36px;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro};
  font-weight: 500;
  margin-top: 41px;
  text-align: center;

  @media (max-width: ${mobileMinWidth}) {
    font-size: 18px;
    line-height: 21px;
    margin-top: 28px;
    text-align: center;
  }
`;

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 87.9px;

  @media (max-width: ${mobileMinWidth}) {
    margin-top: 58.9px;
    flex-direction: column;
  }
`;

export const Illustration = styled.img.attrs({
  src: `${illustration}`,
})`
  min-width: 400px;
  max-width: 643px;
  height: auto;
  object-fit: cover;

  @media (max-width: ${mobileMinWidth}) {
    min-width: 300px;
    max-width: 328px;
    height: auto;
    padding: 0 8px;
    margin-right: 0;
  }
`;

export const BrokerContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-width: 328px;
  width: 328px;
  align-self: center;
  margin: 0 0 1rem;

  > div {
    padding: 32px;
    margin-bottom: 50px;
  }

  @media (max-width: ${mobileMinWidth}) {
    margin: 31px 0 0;
  }
`;
