/* eslint-disable import/prefer-default-export */
import React from 'react';

export const MenuMobileSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Menu"
      width="50"
      height="50"
      viewBox="0 0 50 50"
    >
      <path id="Rectangle_1405" fill="none" d="M0 0H50V50H0z" />

      <rect
        id="Rectangle_1406"
        width="1"
        height="1"
        fill="#fff"
        rx=".5"
        transform="translate(25 25)"
      />
      <g id="Group_4484" transform="translate(-1016.973 -1001.72)">
        <path
          id="Rectangle_182"
          d="M0 0H20.436V1.8H0z"
          transform="translate(1031.973 1018.72)"
        />
        <path
          id="Rectangle_183"
          d="M0 0H20.436V1.8H0z"
          className="cls-3"
          transform="translate(1031.973 1026.319)"
        />
        <path
          id="Rectangle_184"
          d="M0 0H20.436V1.8H0z"
          className="cls-3"
          transform="translate(1031.973 1033.918)"
        />
      </g>
    </svg>
  );
};
