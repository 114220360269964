/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
import React, { useState, createContext, useContext, useCallback } from 'react';

interface ModalContextData {
  isModalVisible: boolean;
  hideModal(): void;
  showModal(
    content: React.ReactNode,
    removePadding?: boolean,
    className?: string
  ): void;
  ModalContent: React.ReactNode;
  removePadding: boolean;
  className?: string;
}

const ModalContext = createContext<ModalContextData>({} as ModalContextData);

const ModalProvider = ({ children }: any) => {
  const [removePadding, setRemovePadding] = useState(false);
  const [className, setClassName] = useState<string | undefined>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [ModalContent, setModalContent] = useState<React.ReactNode>(null);

  const showModal = useCallback(
    (content: React.ReactNode, removePadding: boolean, className?: string) => {
      setRemovePadding(removePadding);
      setClassName(className);
      setModalContent(content);
      setIsModalVisible(true);
    },
    []
  );

  const hideModal = useCallback(() => {
    setModalContent(null);
    setIsModalVisible(false);
  }, []);

  return (
    <ModalContext.Provider
      value={{
        showModal,
        isModalVisible,
        hideModal,
        ModalContent,
        removePadding,
        className,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

function useModal() {
  const context = useContext(ModalContext);
  if (!context)
    throw new Error('O useModal precisa ser utilizado dentro do AppProvider');

  return context;
}

export { ModalProvider, useModal };
