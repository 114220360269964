/* eslint-disable spaced-comment */
const PREFIX = '@mma/';

const LOCAL_STORAGE_IDENTIFIER = {
  LOCAL_PLAN_LIST: `${PREFIX}plan-list`,
  LOCAL_BROKER: `${PREFIX}broker`,
  LOCAL_QUOTATION: `${PREFIX}quotation`,
  LOCAL_OCCUPATION: `${PREFIX}occupation`,
  LOCAL_CONTRACT_COMPLETED: `${PREFIX}contract-completed`,
  LOCAL_CPF: `${PREFIX}cpf`
};

export const {
  LOCAL_PLAN_LIST,
  LOCAL_BROKER,
  LOCAL_QUOTATION,
  LOCAL_OCCUPATION,
  LOCAL_CONTRACT_COMPLETED,
  LOCAL_CPF,
} = LOCAL_STORAGE_IDENTIFIER;

export const PROPERTY_TYPE: { [key: number]: string } = {
  1: 'Casa',
  2: 'Apartamento',
  3: 'Casa (condomínio)',
};

export const GENDER: { [key: string]: string } = {
  F: 'Feminino',
  M: 'Masculino',
};

export const BOOLEAN: { [key: string]: string } = {
  S: 'Sim',
  N: 'Não',
};

export const INSURER_SUSEP_CODE = '660';
export const SALES_PARTNER_CODE = 63;
export const POLICY_NUMBER = '12345678';
export const COVERAGE_CODE_IGNORE = 12010;

export const FAIXA_RENDA = [
  { value: 1, label: 'ATÉ 1 SALÁRIO' },
  { value: 2, label: 'ATÉ 2 SALÁRIOS' },
  { value: 3, label: 'ATÉ 3 SALÁRIOS' },
  { value: 4, label: 'ATÉ 5 SALÁRIOS' },
  { value: 5, label: 'ATÉ 10 SALÁRIOS' },
  { value: 6, label: 'ATÉ 20 SALÁRIOS' },
  { value: 7, label: 'ACIMA DE 20 SALÁRIOS' },
  { value: 8, label: 'SEM RENDA' },
];

export const TIPO_RELACIONAMENTO = [
  { value: 6, label: 'Avós' },
  { value: 1, label: 'Cônjuge' },
  { value: 2, label: 'Filhos' },
  { value: 5, label: 'Irmãos' },
  { value: 4, label: 'Outros' },
  { value: 3, label: 'Pais' },
];

export const ESTADO_CIVIL = [
  { value: 'M', label: 'Casado(a) / União Estável' },
  { value: 'S', label: 'Solteiro(a)' },
  { value: 'W', label: 'Viúvo(a)' },
  { value: 'D', label: 'Divorciado(a) / Separado(a)' },
];

export const OWNER_RELATIONSHIP = [
  { value: '2', label: 'Cônjuge do segurado' },
  { value: '3', label: 'Filho(a) do segurado' },
  { value: '4', label: 'Outra pessoa física' },
  { value: '5', label: 'Outra pessoa jurídica' },
  { value: '1', label: 'Próprio segurado' },
];

export const OWNER_RELATIONSHIP_DRIVER = [
  { value: '1', label: 'Próprio segurado' },
  { value: '2', label: 'Cônjuge do segurado' },
  { value: '3', label: 'Filho(a) do segurado' },
  { value: '4', label: 'Outras pessoas' },
  { value: '5', label: 'Motorista contratado' },
  { value: '6', label: 'A pessoa mais jovem' },
];

export const ADAPTATION_CODE = [
  { value: '0', label: 'Não' },
  { value: '9', label: 'Possui Kit-gás' },
  { value: '3', label: 'Possui blindagem' },
  { value: '99', label: 'Possui Kit-gás e blindagem' },
  { value: '2', label: 'Possui adaptação para deficiente físico' },
];

export const EMPLOYEE_DATA = {
  // titleCode: '',
  // atençao ao campo relationType, precisaremos enviar um valor que seja aceito pelo sistema e não quebre o processo
  //relationType: null,
  employeeName: '',
  publicIdNumber: '',
  employeeCode: '',
};

export const LO_JACK = [
  { value: '0', label: 'Não' },
  { value: '1', label: '3T Systems - 3Tmv10 - Rastreador' },
  { value: '2', label: 'AJ Sat - Rastreador' },
  { value: '3', label: 'VOLVO ON CALL' },
  { value: '5', label: 'Almeida e Bertoluci-Autocargo Gprs-Rastr' },
  { value: '6', label: 'Alvosat - Altosat Drive - Rastreador' },
  { value: '7', label: 'Ariasat TCA - Rastreador' },
  { value: '8', label: 'Arisat - Rastreador' },
  { value: '10', label: 'AutoTrack Rastreador' },
  { value: '11', label: 'OnStar' },
  { value: '12', label: 'Blockauto - GPS - Rastreador' },
  { value: '13', label: 'Blockcell Localizador - GP' },
  { value: '15', label: 'Bysat - Kit Bysat - Rastreador' },
  { value: '16', label: 'BySat - MTC 400 - Rastreador' },
  { value: '17', label: 'Car System  - Rastreador' },
  { value: '18', label: 'Celtec - Autocargo Gprs - Rastreador' },
  { value: '19', label: 'Cerruns - Scorpion - Rastreador' },
  { value: '20', label: 'ChevyStar - Rastreador' },
  { value: '21', label: 'Cielo Cel Rastreador' },
  { value: '22', label: 'Commander - MTC-400 - Rastreador' },
  { value: '23', label: 'Condor - Rastreador' },
  { value: '25', label: 'Control Loc - Rastreador' },
  { value: '26', label: 'Controle - Rastreador' },
  { value: '27', label: 'Controlsat-Controlcell 4000-Rastreador' },
  { value: '28', label: 'Coral Sat - AVL MTC 300 - Rastreador' },
  { value: '32', label: 'Ebr - Renatrack - Rastreador' },
  { value: '33', label: 'Engecarga - Autocargo Gprs - Rastreador' },
  { value: '34', label: 'EPS - Rastreador ' },
  { value: '35', label: 'Fenix Sat - Autocargo Gprs - Rastreador' },
  { value: '36', label: 'Globaltech - Gt Gprs Ka - Rastreador' },
  { value: '38', label: 'Graber - Celllocator - Rastreador' },
  { value: '40', label: 'Guard One Rastreador' },
  { value: '43', label: 'Huntersat - Hunter Locate - Localizador' },
  { value: '48', label: 'Limger - Autocargo Gprs - Rastreador' },
  { value: '49', label: 'Lock System - Rastreador' },
  { value: '50', label: 'LOG SAT - DMR 200C - Rastreador' },
  { value: '51', label: 'Log Sat Rastreador - Logus Sat' },
  { value: '52', label: 'Logikos LR 800 Rastreador' },
  { value: '54', label: 'Macor - Macor Personal - Rastreador' },
  { value: '55', label: 'Macor - Rastreador' },
  { value: '56', label: 'Maxcel Localizador' },
  { value: '57', label: 'Mobilicom - MTCH 300 - Rastreador' },
  { value: '59', label: 'Omnilink Rastreador' },
  { value: '61', label: 'Onsat - Rastreador' },
  { value: '63', label: 'Plantão - Autocargo Gprs - Rastreador' },
  { value: '64', label: 'Polsatcom Rastreador' },
  { value: '65', label: 'Positron - Rastreador' },
  { value: '67', label: 'Prisma Sat - Assist Car - Rastreador' },
  { value: '68', label: 'Protesat-Rastreador Protesat Prt-25-Rast' },
  { value: '69', label: 'Protesim - ProteSat - Rastreador' },
  { value: '70', label: 'PrSat - DMR 200C D+ InmarSat - Rastr.' },
  { value: '71', label: 'Radar - Radarcel - Rastreador' },
  { value: '72', label: 'Rodosis - JUE-610DT - Rastreador' },
  { value: '73', label: 'SafeTrack - MTC300 - Rastreador' },
  { value: '74', label: 'Sascar Gps Rastreador' },
  { value: '75', label: 'Sascar GSM Rastreador' },
  { value: '76', label: 'Sascar Localizador' },
  { value: '77', label: 'SAT Company - Bloqueador' },
  { value: '78', label: 'SAT Company - Rastreador' },
  { value: '79', label: 'Sat Net-Rastreador Sat Net Ns-Rastreador' },
  { value: '80', label: 'SATCAR - Rastreador' },
  { value: '82', label: 'SEGSAT - Rastreador' },
  { value: '84', label: 'Servipa - Rastreador' },
  { value: '85', label: 'Servnac - Autocargo Gprs - Rastreador' },
  { value: '86', label: 'Siemens - Mtc-400 - Rastreador' },
  { value: '87', label: 'Sim - Sim Gps/Gsm - Rastreador' },
  { value: '88', label: 'Skytrack Rastreador' },
  { value: '89', label: 'Spysat - Rastreador' },
  { value: '90', label: 'Staytrack - Staytrack 1 - Rastreador' },
  { value: '91', label: 'STI sat II Rastreador' },
  { value: '93', label: 'SysLock Rastreador - Pollus Sat' },
  { value: '95', label: 'Techall Rastreador' },
  { value: '96', label: 'TecnoSat - MTCH-300 - Rastreador' },
  { value: '97', label: 'Tele-Alarme - Autocargo Gprs-Rastreador' },
  { value: '99', label: 'Telecom Track - Telecom Track-Rastreador' },
  { value: '101', label: 'Teletrim-Teletrim Rastreador-Rastreador' },
  { value: '102', label: 'Tracsat Gps Rastreador' },
  { value: '105', label: 'Veltec Rastreador' },
  { value: '106', label: 'Via Alfa Satcom - Alfa Track-Rastreador' },
  { value: '107', label: 'Wise Track - Fms-W - Rastreador' },
  { value: '108', label: 'Ituran Proprio' },
  { value: '109', label: 'Lojack Próprio' },
  { value: '62', label: 'Outros' },
];

export const MOCK_CREDIT_CARD_OPTIONS = [
  { value: 0, label: 'À vista (R$ 00,00)' },
  { value: 1, label: '2x sem juros (parcelas de R$ 00,00)' },
  { value: 2, label: '3x sem juros (parcelas de R$ 00,00)' },
  { value: 3, label: '4x sem juros (parcelas de R$ 00,00)' },
  { value: 4, label: '5x sem juros (parcelas de R$ 00,00)' },
];

export const STREET_TYPES = [
  { value: 'Acesso', label: 'Acesso' },
  { value: 'Alameda', label: 'Alameda' },
  { value: 'Alto', label: 'Alto' },
  { value: 'Avenida', label: 'Avenida' },
  { value: 'Estrada', label: 'Estrada' },
  { value: 'Ladeira', label: 'Ladeira' },
  { value: 'Largo', label: 'Largo' },
  { value: 'Parque', label: 'Parque' },
  { value: 'Praca', label: 'Praca' },
  { value: 'Quadra', label: 'Quadra' },
  { value: 'Rodovia', label: 'Rodovia' },
  { value: 'Rua', label: 'Rua' },
  { value: 'Travessa', label: 'Travessa' },
  { value: 'Vale', label: 'Vale' },
  { value: 'Via', label: 'Via' },
  { value: 'Viaduto', label: 'Viaduto' },
  { value: 'Viela', label: 'Viela' },
  { value: 'Vila', label: 'Vila' },
];

export const NUMBER_OF_CHILDREN = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
];

export const BANKS = [
  { value: '041', label: 'BANCO BANRISUL S.A.' },
  { value: '237', label: 'BANCO BRADESCO S.A.' },
  { value: '745', label: 'BANCO CITIBANK S.A.' },
  { value: '756', label: 'BANCO COOPERATIVO DO BRASIL S.A. - BANCOOB' },
  { value: '748', label: 'BANCO COOPERATIVO SICREDI S.A.' },
  { value: '001', label: 'BANCO DO BRASIL S.A.' },
  { value: '047', label: 'BANCO DO ESTADO DE SERGIPE S.A.' },
  { value: '004', label: 'BANCO DO NORDESTE DO BRASIL S.A.' },
  { value: '077', label: 'BANCO INTER S.A' },
  { value: '341', label: 'BANCO ITAÚ UNIBANCO S.A.' },
  { value: '212', label: 'BANCO ORIGINAL S.A' },
  { value: '422', label: 'BANCO SAFRA S.A.' },
  { value: '033', label: 'BANCO SANTANDER (BRASIL) S.A.' },
  { value: '104', label: 'CAIXA ECONÔMICA FEDERAL' },
  { value: '085', label: 'COOPERATIVA CENTRAL DE CRÉDITO - AILOS' },
  { value: '098', label: 'CREDIALIANÇA' },
  { value: '010', label: 'CREDICOAMO COOPERATIVA' },
  {
    value: '099',
    label: 'UNIPRIME CENTRAL',
  },
  {
    value: '084',
    label: 'UNIPRIME NORTE DO PARANÁ',
  },
];

export const CAIXA_ACCOUNT_TYPES = [
  { value: '01', label: 'Conta Corrente de Pessoa Física' },
  { value: '03', label: 'Conta Corrente de Pessoa Jurídica' },
  { value: '23', label: 'Conta Fácil Caixa' },
  { value: '02', label: 'Conta Simples de Pessoa Física' },
  { value: '07', label: 'Depósitos Instituições Financeiras' },
  { value: '43', label: 'Depósitos Lotéricos' },
  { value: '06', label: 'Entidades Públicas' },
  { value: '28', label: 'Poupança de Crédito Imobiliário' },
  { value: '13', label: 'Poupança de Pessoa Física' },
  { value: '22', label: 'Poupança de Pessoa Jurídica' },
];

export const CARGO = [
  { value: 1, label: 'ADVOGADO' },
  { value: 2, label: 'ANALISTA' },
  { value: 3, label: 'ASSISTENTE' },
  { value: 4, label: 'ATENDENTE' },
  { value: 5, label: 'AUXILIAR' },
  { value: 6, label: 'COMPRADOR' },
  { value: 7, label: 'CONTINUO/OFFICE-BOY' },
  { value: 8, label: 'CONTROLLER' },
  { value: 9, label: 'COORDENADOR' },
  { value: 10, label: 'DIRETOR' },
  { value: 11, label: 'EXECUTIVO DE CONTAS' },
  { value: 12, label: 'GERENTE' },
  { value: 22, label: 'MEDICO' },
  { value: 21, label: 'OPERADOR' },
  { value: 20, label: 'OUTROS' },
  { value: 13, label: 'PRESIDENTE' },
  { value: 14, label: 'PROMOTOR DE VENDAS' },
  { value: 15, label: 'SECRETARIA' },
  { value: 16, label: 'SUPERINTENDENTE' },
  { value: 17, label: 'SUPERVISOR' },
  { value: 18, label: 'TECNICO' },
  { value: 19, label: 'VICE PRESIDENTE' },
];

export const RELATION_TYPE_AFFINITY = [
  { value: 4, label: 'Cônjuge' },
  { value: 5, label: 'Filha' },
  { value: 3, label: 'Filho' },
  { value: 2, label: 'Mãe' },
  { value: 1, label: 'Pai' },
];

export const CONTRACT_RULES = [
  '- Não aceitamos imóveis que não são de alvenaria (tijolos / bloco) ou imóveis misto (parte tijolo e parte madeira, por exemplo);',
  '- Não aceitamos imóveis tombados;',
  '- Não aceitamos imóveis em reconstrução ou reforma;',
  '- Não aceitamos casas de veraneio (imóveis não habituais);',
  '- Não aceitamos imóveis desocupados.',
];
