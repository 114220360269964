const variables = {
  mobileMinWidthInt: 768,
  tabletMinWidthInt: 1000,
  mobileMinWidth: '768px',
  navbarPaddingReduceWidth: '900px',
  mobileMinLimit: '350px',
  tabletMinWidth: '1000px',
  maxContainerResolution: '1200px',
};

export const {
  mobileMinWidthInt,
  mobileMinWidth,
  navbarPaddingReduceWidth,
  mobileMinLimit,
  tabletMinWidth,
  tabletMinWidthInt,
  maxContainerResolution,
} = variables;
