/* eslint-disable import/prefer-default-export */
import React from 'react';

export const WebsiteSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22.518" height="19.622">
      <path
        data-name="Path 235"
        d="M566.012 976.81a.692.692 0 1 0-.692.692.692.692 0 0 0 .692-.692"
        transform="translate(-552.404 -970.921)"
        style={{ fill: '#565656' }}
      />
      <path
        data-name="Path 236"
        d="M563.6 993.283h-1.26v1.144h-4.56v1.261h10.693v-1.261H563.6z"
        transform="translate(-550.351 -976.065)"
        style={{ fill: '#565656' }}
      />
      <path
        data-name="Path 237"
        d="M570.357 972.737h-13.25V974h12.621v11.009H552.76v-2.39h-1.26v3.02a.63.63 0 0 0 .63.63h18.227a.63.63 0 0 0 .63-.63v-12.272a.63.63 0 0 0-.63-.63"
        transform="translate(-548.469 -969.907)"
        style={{ fill: '#565656' }}
      />
      <path
        data-name="Path 238"
        d="M554.8 979.845v-10.519a.63.63 0 0 0-.63-.63h-6.37a.63.63 0 0 0-.63.63v10.518a.63.63 0 0 0 .63.63h6.365a.63.63 0 0 0 .63-.63m-1.26-.63h-5.1v-9.258h5.1z"
        transform="translate(-547.172 -968.696)"
        style={{ fill: '#565656' }}
      />
      <path
        data-name="Path 239"
        d="M552.416 981.8a.525.525 0 1 0-.525-.524.524.524 0 0 0 .525.524"
        transform="translate(-548.586 -972.308)"
        style={{ fill: '#565656' }}
      />
    </svg>
  );
};
