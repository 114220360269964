/* eslint-disable no-self-assign */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback, useEffect } from 'react';
import InputMask from 'react-input-mask';
import Calendar from 'react-calendar';

import IInputProps from '../@types/IInputProps';

import { ErrorMessage } from '../ErrorMessage';

import * as S from './styles';

interface IMaskProps extends IInputProps {
  mask: string;
  Icon?: any;
}

const InputMaskField = ({
  Icon,
  mask,
  name,
  label,
  disabled,
  value,
  loading,
  fieldState,
  inputMode,
  onCustomBlur,
  ...rest
}: IMaskProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [calendarFocused, setCalendarFocused] = useState(false);
  const [customDate, setCustomDate] = useState('');
  const [calendarValue, setCalendarValue] = useState(new Date());

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setIsFocused(false);
      onCustomBlur && onCustomBlur(e);
    },
    [onCustomBlur]
  );

  const showLabel = fieldState?.isTouched || isFocused || value;
  const error = fieldState?.error?.message;

  const changeDate = (e: any) => {
    setCalendarValue(new Date(e));
    setCustomDate(String(new Date(e).toLocaleDateString()));
    setTimeout(() => {
      setCalendarFocused(!calendarFocused);
    }, 250);
  };

  return (
    <S.Container>
      <S.InputContainer
        isFocused={isFocused}
        isFilled={!!value}
        isErrored={!!error}
        disabled={disabled}
      >
        {showLabel && <label>{label}</label>}
        <S.ContainerRow isFocused={isFocused}>
          <InputMask
            name={name}
            mask={mask}
            disabled={disabled}
            value={value || ''}
            {...rest}
            inputMode={inputMode}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
          />
          <S.ContainerIcon
            onClick={() => setCalendarFocused(!calendarFocused)}
            isFocused={isFocused}
          >
            {Icon && <Icon className="icon" />}
          </S.ContainerIcon>
        </S.ContainerRow>
        {loading && (
          <img src="/img/loading.gif" alt="Ícone de carregamento da página." />
        )}
      </S.InputContainer>
      {calendarFocused && mask === '99/99/9999' && (
        <Calendar
          onChange={changeDate}
          // value={calendarValue}
          locale="pt-BR"          
        />
      )}
      {error && <ErrorMessage name={name} message={error} />}
    </S.Container>
  );
};

export { InputMaskField };
