/* eslint-disable no-unused-vars */
import styled, { css } from 'styled-components';
import {
  maxContainerResolution,
  mobileMinWidth,
  tabletMinWidth,
} from 'styles/variables';

import { colors } from 'styles/colors';

import { CarSVG } from 'assets/img/CarSVG';
import { CarNewSVG } from 'assets/img/CarNewSVG';
import { PrivateUsageSVG } from 'assets/img/PrivateUsageSVG';
import { CommertialUsageSVG } from 'assets/img/CommertialUsageSVG';
import { OwnerSVG } from 'assets/img/OwnerSVG';
import { InsuredSVG } from 'assets/img/InsuredSVG';
import { AnotherPersonSVG } from 'assets/img/AnotherPersonSVG';
import { YesSVG } from 'assets/img/YesSVG';
import { NoSVG } from 'assets/img/NoSVG';
import { ManSVG } from 'assets/img/ManSVG';
import { WomanSVG } from 'assets/img/WomanSVG';
import { LockSVG } from 'assets/img/LockSVG';

interface SessionProps {
  small?: boolean;
}

export const LockIcon = styled(LockSVG)``;
export const CarIcon = styled(CarSVG)``;
export const CarNewIcon = styled(CarNewSVG)``;
export const PrivateUsageIcon = styled(PrivateUsageSVG)``;
export const CommertialUsageIcon = styled(CommertialUsageSVG)``;
export const OwnerIcon = styled(OwnerSVG)``;
export const InsuredIcon = styled(InsuredSVG)``;
export const AnotherPersonIcon = styled(AnotherPersonSVG)``;
export const YesIcon = styled(YesSVG).attrs({
  width: '31.174px',
  height: '31.171px',
})``;
export const NoIcon = styled(NoSVG).attrs({
  width: '31.174px',
  height: '31.171px',
})``;
export const ManIcon = styled(ManSVG)``;
export const WomanIcon = styled(WomanSVG)``;

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    text-align: center;
    color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
    font-size: 1.625rem;

    @media (max-width: ${mobileMinWidth}) {
      font-size: 1.125rem;
    }
  }
`;

export const Form = styled.form`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  padding: 37px 58px;

  @media (max-width: ${tabletMinWidth}) {
    padding: 37px 25px;
  }

  @media (max-width: ${mobileMinWidth}) {
    padding: 25px 16px 31px 16px;
  }
`;

export const ContainerLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 35.5px 0 32.5px;

  @media (max-width: ${mobileMinWidth}) {
    padding: 20.5px 0 23.5px;
  }
`;

export const Logo = styled.img`
  height: 56px;
  object-fit: contain;
`;

export const SectionContainer = styled.div`
  width: 100%;
  display: grid;
  gap: 1rem 3rem;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;

  & + & {
    margin-top: 1.5rem;
  }

  @media (max-width: ${mobileMinWidth}) {
    display: flex;
    flex-direction: column;
    align-items: initial;

    & + & {
      margin-top: 1rem;
    }
  }
`;

export const Separator = styled.span`
  border-top: 1px solid ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
  margin: 2.5rem 0;
`;

export const Section = styled.span<SessionProps>`
  margin: 2.5rem 0 0.69rem;
  align-self: start;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };

  ${({ small }) =>
    small &&
    css`
      margin: 0rem 0 0.69rem;
    `}
`;

export const FullRow = styled.span`
  grid-column: span 2;
`;

export const GroupIcon = styled.span`
  svg + svg {
    margin-left: 4.3px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 46px;

  > button {
    max-width: 200px;
    width: 100%;
  }

  @media (max-width: ${mobileMinWidth}) {
    margin-top: 24px;

    > button {
      max-width: initial;
    }
  }
`;

export const PersonalContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 16px 0 16px;

  @media (max-width: ${mobileMinWidth}) {
    margin: 32px 0 16px;
  }
`;

export const PersonalDataText = styled.div`
  font-size: 0.75rem;
  line-height: 1.17;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
  margin-left: 6.7px;
  align-item: center;

  a {
    color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
  }
`;
export const AgreeText = styled.div`
  font-size: 1rem;
  line-height: 1.19;
  letter-spacing: normal;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };

  span {
    color: ${({ theme }) => theme.colors.red_4};
  }
`;

export const AgreeContainer = styled.div`
  display: flex;

  * > label {
    margin: 0;
  }
`;

export const SocialNameSectionContainer = styled.div`
width: 100%;
display: grid;
gap: 1rem 0.4rem;
grid-template-columns: 1fr 0.065fr 1fr;
align-items: flex-start;
margin-bottom: 1rem;

& + & {
  margin-top: 1.5rem;
}

@media (max-width: ${mobileMinWidth}) {
  display: grid;
  grid-template-columns: 1fr 0.02fr;
  gap: 1rem 0.7rem
}
`

export const SocialNameTooltipContainer = styled.div `
  align-items: center;
  justify-content: center;
  position: relative;
  left: -40%;

  @media (max-width: ${mobileMinWidth}) {
    position: relative;
    left: -40%;
  }
`