/* eslint-disable import/prefer-default-export */
import React from 'react';

import { BrokerProvider } from './Broker';
import { LoadingProvider } from './Loading';
import { ModalProvider } from './Modal';
import { QuotationProvider } from './Quotation';

export const AppProvider = ({ children }: any) => (
  <LoadingProvider>
    <ModalProvider>
      <QuotationProvider>
        <BrokerProvider>{children}</BrokerProvider>
      </QuotationProvider>
    </ModalProvider>
  </LoadingProvider>
);
