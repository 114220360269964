import styled from 'styled-components'
import { mobileMinWidth, tabletMinWidth } from 'styles/variables'

import { InformationSVG } from 'assets/img/InformationSVG'
import { CheckSVG } from 'assets/img/CheckSVG'
import Button from 'components/Button'
import { AssistancySVG } from './assets/assistancy'
import Illustration from './assets/illustration.png';

export const AssistancyImage = styled(AssistancySVG)``
export const InformationIcon = styled(InformationSVG)`
  fill: #78e1e1;
  cursor: pointer;
  width: 16px;
  height: 16px;
  vertical-align: bottom;
`

export const CheckIcon = styled(CheckSVG)`
  fill: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
  margin-top: 4.5px;
  width: 28px;
  height: 28px;

  @media (max-width: ${mobileMinWidth}) {
    width: 20px;
    height: 20px;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0%;
  margin: 49px 0 33px;

  @media (max-width: ${tabletMinWidth}) {
    flex-direction: column;
    margin: 31px 0 0px;
  }
`

export const InfoContainer = styled.div`
  width: 100%;
  max-width: 690px;
  display: flex;
  align-self: flex-start;
  align-items: flex-start;
  flex-direction: column;

  @media (max-width: ${tabletMinWidth}) {
    align-self: center;
  }
`

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 300;
  line-height: 1.2;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };

  @media (max-width: ${tabletMinWidth}) {
    font-size: 1.5rem;
  }
`

export const ImageContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: start;
  margin-right: 30.3px;

  & > svg {
    width: 100%;
    max-width: 412.1px;
  }

  @media (max-width: ${tabletMinWidth}) {
    margin-top: 0;
    width: 100%;
    margin-right: 0;
    align-items: center;
  }
`

export const AssistancyContainer = styled.div`
  margin-top: 1.5rem;
`

export const AssistancyCard = styled.div`
  display: flex;
  margin-bottom: 14px;
`

export const AssistancyInfocontainer = styled.div`
  margin-left: 16.7px;
`

export const AssistancyTitle = styled.div`
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
  font-weight: 700;
  line-height: 1.13;
`

export const AssistancyDescription = styled.div`
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
  font-weight: 300;
  line-height: 1.13;
`

export const AssistancyText = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
  margin-top: 40.2px;
  line-height: 1rem;

  > a {
    color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
    margin-right: 10px;
  }

  @media (max-width: ${tabletMinWidth}) {
    margin-top: 14px;

    > a {
      margin-right: 6px;
    }
  }
`

export const List = styled.ul`
  list-style-type: none;
  margin-top: 19px;

  li {
    display: flex;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
  }
`

export const QuotationContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 40px;

  @media (max-width: ${tabletMinWidth}) {
    flex-direction: column;
    margin-top: 29px;

    > button {
      align-self: center;
      margin-top: 29px;
      max-width: 278px;
      width: 100%;
    }
  }
`

export const QuotationText = styled.div`
  font-size: 18px;
  color: #2C3A39;
  font-weight: 700;
`

export const QuotationButton = styled(Button)`
  max-width: 211px;
  width: 100%;
  margin-left: 30px;

  @media (max-width: ${mobileMinWidth}) {
    margin-left: 0px;
  }
`

export const IllustrationPNG = styled.img.attrs({
  src: `${Illustration}`
})`
  width: 453.7px;
  height: 383px;
  object-fit: cover;

  @media (max-width: ${mobileMinWidth}) {
    margin-left: 0px;
    width: 100%;
    object-fit: contain;
  }
`;
