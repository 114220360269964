/* eslint-disable import/no-unresolved */
/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { colors } from 'styles/colors';
import { maxContainerResolution, mobileMinWidth } from 'styles/variables';

interface ContainerProps {
  gray?: boolean;
  height?: string;
}

export const Container = styled.div<ContainerProps>`
  height: ${({ height }) => height};
  display: flex;
  align-items: center;
  background: ${({ gray }) => (gray ? colors.gray_1 : colors.white)} no-repeat;
  font-weight: 300;
  flex-direction: column;
  padding: 0 25px;

  @media (max-width: ${mobileMinWidth}) {
    padding: 0 16px;
  }

  > div {
    max-width: ${maxContainerResolution};
  }
`;
