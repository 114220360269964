/* eslint-disable react/require-default-props */
/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react';
import { HelpSVG } from 'assets/img/HelpSVG';
import { useWindowSize } from 'helpers/custom-hooks';

import * as S from './styles';

interface TooltipProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  description?: JSX.Element;
  direction?: string;
  showIcon?: any;
}

const Tooltip: React.FC<TooltipProps> = ({ title, description, ...rest }) => {
  const inputEl = useRef<HTMLDivElement>(null);
  const [width] = useWindowSize();
  const [direction, setDirection] = useState('right');
  const [maxWidth, setMaxWidth] = useState(330);

  useEffect(() => {
    const { left } = inputEl?.current?.getBoundingClientRect() as DOMRect;
    const position = width - left;

    const middlePosition = width / 2;
    setDirection(position <= middlePosition ? 'left' : 'right');
    setMaxWidth(Math.min(330, left + 30));
  }, [inputEl, width]);

  return (
    <S.Container
      direction={direction}
      maxWidth={maxWidth}
      {...rest}
      ref={inputEl}
    >
      <HelpSVG width="22" height="22" />
      <span>
        {title && (
          <>
            <strong>{title}</strong> <br />
          </>
        )}
        {description}
      </span>
    </S.Container>
  );
};

export default Tooltip;
