/* eslint-disable import/no-unresolved */
/* eslint-disable no-unused-vars */
import React from 'react';
import { useLoading } from 'contexts/Loading';
import { useModal } from 'contexts/Modal';
import { Loading } from 'components/Loading';
import { Modal } from 'components/Modal';
import { useLocation } from 'react-router-dom';

import * as S from './styles';

interface MainProps {
  embbed: boolean;
  children: any;
}

export const Main = ({ children, embbed }: MainProps) => {
  const { isModalVisible, ModalContent, removePadding, className } = useModal();
  const { loading } = useLoading();
  const { pathname } = useLocation();

  return (
    <>
      {loading && <Loading />}
      {isModalVisible && (
        <Modal className={className} removePadding={removePadding}>
          {ModalContent}
        </Modal>
      )}
      <S.Main embbed={embbed} pathname={pathname}>{children}</S.Main>
    </>
  );
};
