import React from 'react';

export const BadEmojiSVG = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="99"
      height="102"
      viewBox="0 0 99 102"
    >
      <g transform="translate(-550.246 -730.65)">
        <ellipse
          cx="49.5"
          cy="4.5"
          fill="#eff7f8"
          rx="49.5"
          ry="4.5"
          transform="translate(550.246 823.65)"
        />
        <circle
          cx="49.5"
          cy="49.5"
          r="49.5"
          fill="#feda02"
          transform="translate(550.246 730.65)"
        />
        <g opacity="0.3" transform="translate(553.144 733.867)">
          <path
            fill="#fff"
            d="M558.66 787.2a45.876 45.876 0 0 1 82.24-27.968 45.88 45.88 0 1 0-76.146 50.832 45.664 45.664 0 0 1-6.094-22.864z"
            transform="translate(-555.246 -736.221)"
          />
        </g>
        <g transform="translate(577.411 770.123)">
          <g>
            <g>
              <path
                d="M604.387 804.9c0 3.389-1.63 6.137-3.641 6.137s-3.641-2.748-3.641-6.137 1.63-6.137 3.641-6.137 3.641 2.746 3.641 6.137z"
                style={{ fill: '#303030' }}
                transform="translate(-597.105 -798.762)"
              />
            </g>
            <g transform="translate(36.408)">
              <path
                d="M667.191 804.9c0 3.389-1.63 6.137-3.641 6.137s-3.641-2.748-3.641-6.137 1.63-6.137 3.641-6.137 3.641 2.746 3.641 6.137z"
                style={{ fill: '#303030' }}
                transform="translate(-659.909 -798.762)"
              />
            </g>
          </g>
        </g>
        <path
          d="M606.818 844.9c5.872-5.4 16.448-4.119 19.995-3.426 3.467.672 6.849 1.494 9.786 3.478 3.117 2.128 5.535 3.511 7.187 6.4a1.159 1.159 0 0 1-1.518 1.616l-1.082-.535c-2.544-1.259-4.948-2.449-7.387-3.4a46.271 46.271 0 0 0-8.3-2.466 40.9 40.9 0 0 0-8.557-.734 53.613 53.613 0 0 0-9.093 1.034 1.159 1.159 0 0 1-1.027-1.964z"
          style={{ fill: '#303030' }}
          transform="translate(-23.632 -46.316)"
        />
      </g>
    </svg>
  );
};
