import React from 'react';
import { SVGProps } from './types';

export const BackIconSVG = ({
  width = '24',
  height = '24',
  fill = '#343741',
}: SVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
    >
      <g>
        <path
          fill="none"
          d="M0 0H24V24H0z"
          transform="translate(-7590 -2181.833) translate(7590 2181.833)"
        />
        <path
          style={{ fill }}
          d="M504.452 705.428l-14.884-11.239a1.034 1.034 0 0 1 .01-1.659l14.884-10.935 1.226 1.667-13.764 10.113 13.775 10.4z"
          transform="translate(-7590 -2181.833) translate(7104.248 1500.405)"
        />
      </g>
    </svg>
  );
};
