import React from 'react';
import { SVGProps } from './types';

export const YesSVG = ({ width, height }: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 31.174 31.171"
    width={width}
    height={height}
  >
    <path
      d="M627.525 795.406a1.554 1.554 0 0 0 1.251-.627l9.537-12.864-1.445-1.072-9.392 12.668-5.1-3.733-1.062 1.453 5.3 3.877a1.544 1.544 0 0 0 .914.3"
      transform="translate(-614.563 -773.289)"      
    />
    <path
      d="M641.177 777.849a15.594 15.594 0 0 0-22.054 22.051c.226.226.458.44.693.649l1.277-1.276a15.576 15.576 0 0 1-.7-.645 13.795 13.795 0 1 1 2.251 1.821l-1.3 1.3a15.593 15.593 0 0 0 19.826-23.9"
      transform="translate(-614.563 -773.289)"      
    />
  </svg>
);
