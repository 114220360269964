import React from 'react';

export const ToolsSVG = () =>  (
  <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
      <g transform="translate(-794 -666)">
          <circle data-name="Ellipse 324" cx="35" cy="35" r="35" transform="translate(794 666)" style={{fill:'#FFC900'}}/>
          <path data-name="Path 258" d="M314.218 1093.432a2.613 2.613 0 0 0-1.931-.812.857.857 0 0 1-.624-.285l-6.124-5.954 4.558-4.557.082.025a7.11 7.11 0 0 0 8.837-9.14 1.159 1.159 0 0 0-1.912-.437l-2.288 2.29-1.638-.439-.441-1.638 2.262-2.262a1.16 1.16 0 0 0-.451-1.918 7.2 7.2 0 0 0-2.276-.371 7.093 7.093 0 0 0-6.791 9.179l.025.081-5.789 5.789-5.418-5.419.308-.308a1 1 0 0 0 .258-.955l-.724-2.742-.106-.253a2.755 2.755 0 0 1-.358-.954c1.056-.933 2.933-.91 4.345-.72l.264-1.968c-2.769-.373-4.829.093-6.118 1.382-.971.968-.217 2.591.084 3.142l.546 2.067-1.32 1.321-2.212-.671a1.988 1.988 0 0 0-2.925 2.11.521.521 0 0 1-.141.45l-1.124 1.124-2.691-2.694 1.123-1.123a.55.55 0 0 1 .5-.133 1.964 1.964 0 0 0 1.733-.555 1.993 1.993 0 0 0 .546-1.784.519.519 0 0 1 .141-.448l3.141-3.141a8.924 8.924 0 0 1 9.032-2.111.889.889 0 0 1 .317.161l1.4-1.4a2.815 2.815 0 0 0-1.088-.639 10.915 10.915 0 0 0-11.069 2.588l-3.141 3.141a2.488 2.488 0 0 0-.694 2.18l.008.053h-.005a2.52 2.52 0 0 0-2.229.686l-1.825 1.825a.992.992 0 0 0 0 1.4l4.1 4.1a1 1 0 0 0 1.4 0l1.825-1.825a2.491 2.491 0 0 0 .695-2.178l.023-.04.254.118 2.9.88a.989.989 0 0 0 .989-.248l.433-.432 5.418 5.418-9.042 9.044a3.26 3.26 0 1 0 4.61 4.61l7.54-7.539 6.133 5.963a.913.913 0 0 1 .312.566 2.7 2.7 0 0 0 .774 1.98l.385.385a2.105 2.105 0 0 0 1.435.622h.058a1.974 1.974 0 0 0 1.412-.582l2.715-2.714a1.98 1.98 0 0 0 .579-1.473 2.1 2.1 0 0 0-.622-1.433zm-21.741 3.2a1.279 1.279 0 0 1-.9.362 1.267 1.267 0 0 1-.9-2.166l17.182-17.184a5.055 5.055 0 0 1-.695-2.306v-.141a5.158 5.158 0 0 1 1.5-3.778 5.093 5.093 0 0 1 3.489-1.5l.355-.009-1.575 1.575a.989.989 0 0 0-.257.957l.736 2.745a1 1 0 0 0 .7.7l2.744.735a.99.99 0 0 0 .959-.257l1.62-1.619v.346a5.145 5.145 0 0 1-7.779 4.367zm18.055 1.394-.1-.039-.385-.385a.731.731 0 0 1-.2-.464 2.743 2.743 0 0 0-.9-2.091l-6.09-5.921 1.312-1.312 6.1 5.93a2.827 2.827 0 0 0 2.132.854.69.69 0 0 1 .414.234l.385.385.043.1z" transform="translate(528.797 -382.1)" style={{fill:'#2C3A39'}}/>
      </g>
  </svg>
)


