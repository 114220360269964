import styled from 'styled-components';

export const BrokerCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro};
`;
export const BrokerName = styled.div`
  font-size: 1.5rem;
  text-align: center;
  font-weight: 700;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro};
`;
export const LogoArea = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 32px 0 0 0;

  > img {
    height: 56px;
    object-fit: contain;
  }
`;
export const BrokerContact = styled.div`
  width: 100%;
  margin-top: 42px;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 28px;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro};

    &:last-child {
      margin-bottom: 0px;
    }
  }

  span,
  a {
    margin-left: 12px;
    max-width: 85%;
    color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro};
  }
  svg {
    align-self: flex-start;
  }
`;

export const BrokerMail = styled.div`
  a {
    color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro};
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }
`;
export const BrokerWebsite = styled.div`
  a {
    color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro} ;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;
  }
`;

export const ButtonContainer = styled.div`
  justify-content: center;
  margin-top: 22px;

  svg {
    cursor: pointer;
  }

  svg + svg {
    margin-left: 24px;
  }
`;

export const TextWithoutBroker = styled.h4`
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
  font-size: 27px;
  text-align: center;
`
