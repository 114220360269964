/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useCallback } from 'react';
import { getAssistancyDetailLink } from 'helpers/links';
import { IsMobile } from 'helpers/custom-hooks';
import { Alert } from 'components/Modal/Alert';
import { CONTRACT_RULES } from 'config';
import { useModal } from 'contexts/Modal';
import { mobileMinWidthInt } from 'styles/variables';
import { googleAnalyticsEvent } from 'helpers/googleTagManager';
import appConfig from '../../../assets/settings/appConfig.PRD.json'

import * as S from './styles';

const coverages = [
  {
    icon: <S.IconMorte />,
    title: <>Em caso de colisão com o seu carro</>,
    description: (
      <>Garantimos o reparo em&nbsp; oficinas credenciadas e referenciadas.</>
    ),
  },
  {
    icon: <S.RouboIcon />,
    title: (
      <>
        Se o seu carro {window.innerWidth > mobileMinWidthInt && <br />}
        for roubado ou furtado
      </>
    ),
    description: (
      <>
        Haverá a reposição do valor&nbsp; do veículo, de acordo com
        a&nbsp;tabela FIPE.
      </>
    ),
  },
  {
    icon: <S.IconCarGlass/>,
    title: (
      <>
        Se o vidro do seu <br />
        carro quebrar
      </>
    ),
    description: (
      <>O pacote de serviços de vidros da {appConfig.COMPANY_NAME} está pronto para atendê-lo</>
    ),
  },
  {
    icon: <S.IconAluguel />,
    title: (
      <>
        Se causou prejuízos <br />a terceiros
      </>
    ),
    description: (
      <>Nossas coberturas de Responsabilidade Civil vão te indenizar</>
    ),
  },
  {
    icon: <S.IconAcidente />,
    title: (
      <>
        Se sofrer um <br />
        acidente grave
      </>
    ),
    description: (
      <>Garanta a indenização ao seus beneficiários em caso de falecimento</>
    ),
  },
  {
    icon: <S.IconCar />,
    title: (
      <>
        Se o seu veículo <br />
        ficar na oficina
      </>
    ),
    description: (
      <>Um carro reserva vai te ajudar enquanto ele não fica pronto</>
    ),
  },
];

const Coverages = () => {
  const isMobile = IsMobile();
  const { showModal } = useModal();

  const handleCoverageDetail = useCallback(() => {
    googleAnalyticsEvent({
      dataLayer: {
        location: 'Home',
        button: 'Clique aqui',
        event: 'home_link_regrascoberturas',
      },
    });
  }, []);

  const showRules = () =>
    showModal(
      <Alert
        title=""
        subtitle="Regras de Aceitação"
        description={
          <S.List>
            {CONTRACT_RULES.map((item: any, id: any) => (
              <li key={id}>
                <div>{item}</div>
              </li>
            ))}
          </S.List>
        }
      />,
      false,
      'rules'
    );

  return (
    <S.Wrapper>
      <S.InfoContainer>
        <S.Title>
          O seu automóvel protegido de acordo com suas necessidades
        </S.Title>
        <S.CoverageContainer>
          {coverages.map((coverage, index) => (
            <S.CoverageCard key={index}>
              {coverage?.icon}
              <S.CoverageName>{coverage.title}</S.CoverageName>
              <S.CoverageDescription>
                {coverage.description}
              </S.CoverageDescription>
            </S.CoverageCard>
          ))}
        </S.CoverageContainer>
      </S.InfoContainer>
      {isMobile ? (
        <S.CoverageText>
          * Veja todas as regras e excessões das coberturas em:{' '}
          <a
            href={getAssistancyDetailLink()}
            target="#"
            onClick={handleCoverageDetail}
          >
            clique&nbsp;aqui
          </a>
        </S.CoverageText>
      ) : (
        <S.ImageContainer>
          <S.CoverageImage />
          <S.CoverageText>
            * Veja todas as regras e excessões das coberturas em:{' '}
            <a
              href={getAssistancyDetailLink()}
              target="#"
              onClick={handleCoverageDetail}
            >
              clique&nbsp;aqui
            </a>
          </S.CoverageText>
        </S.ImageContainer>
      )}
    </S.Wrapper>
  );
};

export default Coverages;
