/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */

import api from 'api';
import { AxiosResponse } from 'axios';

import {
  IBrokerData,
  IBrokerDataRequest,
  IBrokerDataResponse,
  IContactResponse,
} from '../../types/broker';

interface IBrokerLogo {
  urlLogo: string;
  image: string;
}

export interface ISendBrokerLeadNotificationRequest {
  product_code: string
  broker_code: string
  broker_branch_code: string
  broker_internal_branch_code: string
  client_cpf: string
}

export const getBrokerLogo = (cliCod: string) =>
  api.post<IBrokerLogo>(/* BROKER_LOGO */ '', {
    cli_cod: cliCod,
  });

export const brokerData = async ({
  token,
}: IBrokerDataRequest): Promise<AxiosResponse<IBrokerDataResponse>> => {
  try {
    const response = await api.post('/broker/data', {
      brokerToken: token,
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const brokerInfo = async ({
  broker,
  brokerUser,
  brokerToken,
  commercialProductCode,
  isAffinity,
  affinity,
}: IBrokerData): Promise<AxiosResponse<IContactResponse>> => {
  try {
    const response = await api.post('/broker/broker-info/', {
      broker,
      brokerUser,
      brokerToken,
      commercialProductCode,
      affinity: isAffinity ? affinity : null,
    });
    return response;
  } catch (error: any) {
    return error;
  }
};

export const sendBrokerLeadNotification = (
  payload: ISendBrokerLeadNotificationRequest,
  signal?: any,
  cancelToken?: any,
) => {
  return api.post('/broker/broker-lead-notifier/', payload, { signal, cancelToken })
}
