/* eslint-disable import/no-unresolved */
import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  disabled: boolean | undefined;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const InputContainer = styled.div<ContainerProps>`
  background: transparent;
  border-radius: 3px;
  padding: 5px 8px;
  width: 100%;
  height: 50px;

  border: 1px solid ${colors.gray_2};

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  ${props =>
    props.isFocused &&
    css`
      border: 2px solid${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
    `}

  ${props =>
    props.disabled &&
    css`
      border: 1px solid ${colors.gray_5};
      background-color: ${colors.gray_4};
    `}

  ${props =>
    props.isErrored &&
    css`
      border: 1px solid ${colors.red_2};
      background-color: ${colors.red_1};
    `}


  & + div {
    margin-top: 8px;
  }

  label {
    font-size: 0.75rem;
    margin-bottom: 4px;
    color: ${colors.gray_3};
    font-weight: 100;
  }

  input {
    width: 100%;
    background: transparent;
    border: 0;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro};
    font-weight: 100;

    &::placeholder {
      color: ${colors.gray_3};
    }
  }
`;
