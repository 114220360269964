import React from 'react';
import { SVGProps } from './types';

export const WomanSVG = ({ width, height }: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26.108 31.959"
    width={width}
    height={height}
  >
    <path
      d="M228.75 694.876a13.069 13.069 0 0 0-13.05 13.054h1.751a11.313 11.313 0 0 1 6.2-10.083l3.121 3.121a2.837 2.837 0 0 0 4.012 0l3.1-3.1a11.313 11.313 0 0 1 6.168 10.064h1.75a13.069 13.069 0 0 0-13.054-13.054m.8 4.855a1.113 1.113 0 0 1-1.536 0l-2.6-2.6a11.305 11.305 0 0 1 6.724.013z"
      transform="translate(-215.696 -675.971)"
      style={{ fill: '#2C3A39' }}
    />
    <path
      d="M221 688.719a8.663 8.663 0 0 0 15.21.146c.438.909.98 1.519 1.674 1.519a4.033 4.033 0 0 0 1.521-.31c-1.175-.366-1.806-2.614-1.907-4.975 0-.205 0-.416-.015-.63v-.047a9.074 9.074 0 0 0-.31-1.9 8.027 8.027 0 0 0-1.253-2.585 8.651 8.651 0 0 0-14-.751 7.762 7.762 0 0 0-2 3.441 9.006 9.006 0 0 0-.309 1.9v.047a9.587 9.587 0 0 0-.016.629c-.1 2.361-.732 4.61-1.906 4.976a4.024 4.024 0 0 0 1.52.31c.757 0 1.335-.722 1.792-1.771m1.791-5.034a22.68 22.68 0 0 0 .641-3 10.746 10.746 0 0 0 5.215 1.1 11.22 11.22 0 0 0 5.042-1 23.671 23.671 0 0 0 .612 2.8 22.854 22.854 0 0 0 1.01 2.9 6.912 6.912 0 0 1-13.388-.23 23.773 23.773 0 0 0 .868-2.563"
      transform="translate(-215.696 -675.971)"
      style={{ fill: '#2C3A39' }}
    />
  </svg>
);
