import React from 'react';

export const IconAcidenteSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
    <g data-name="Icon Vidro">
      <circle data-name="Ellipse bg" cx="30" cy="30" r="30" style={{fill:'#FFC900'}}/>
    </g>
    <g data-name="Icon Angel">
      <path data-name="Path 555" d="M166.375 13.313c3.168 0 6.375-.912 6.375-2.656S169.543 8 166.375 8 160 8.912 160 10.656s3.207 2.657 6.375 2.657zm0-4.25c3.194 0 5.313.959 5.313 1.594s-2.119 1.594-5.313 1.594-5.313-.959-5.313-1.594 2.119-1.594 5.313-1.594z" transform="translate(-136.375 5.226)" style={{fill:'#1a1446'}}/>
      <path data-name="Path 556" d="M41.007 117.043H29.838a7.048 7.048 0 0 0-2.5-1.68 5.952 5.952 0 1 0-5.134 0 7.048 7.048 0 0 0-2.5 1.68H8.541a.542.542 0 0 0-.541.543v1.087a2.719 2.719 0 0 0 2.164 2.662v.6a2.719 2.719 0 0 0 2.164 2.662v.6a2.715 2.715 0 0 0 2.705 2.717h2.707v2.717a.542.542 0 0 0 .541.543h12.986a.542.542 0 0 0 .541-.543v-2.717h2.705a2.715 2.715 0 0 0 2.705-2.717v-.6a2.719 2.719 0 0 0 2.164-2.662v-.6a2.719 2.719 0 0 0 2.164-2.662v-1.087a.542.542 0 0 0-.539-.543zm-11.4-7.647a4.886 4.886 0 0 1-4.254-4.272 4.887 4.887 0 0 1 4.253 4.276zm-5.407-4.271a4.886 4.886 0 0 1-4.254 4.272 4.887 4.887 0 0 1 4.254-4.272zm-4.263 5.368a5.961 5.961 0 0 0 4.842-3.473 5.962 5.962 0 0 0 4.842 3.473 4.867 4.867 0 0 1-9.685 0zm-2.197 11.441v4.891h-2.706a1.629 1.629 0 0 1-1.623-1.63v-.543h2.164v-1.087H12.87a1.629 1.629 0 0 1-1.623-1.63v-.543h3.247V120.3h-3.789a1.629 1.629 0 0 1-1.623-1.63v-.543h9.774a7.04 7.04 0 0 0-1.116 3.807zm12.986.543v7.608h-11.9v-8.152a5.972 5.972 0 0 1 5.952-5.978 5.885 5.885 0 0 1 2.476.549l.969.564a6.01 6.01 0 0 1 .913.807l.667.867a5.959 5.959 0 0 1 .927 3.191zm9.74-3.8a1.629 1.629 0 0 1-1.623 1.63h-3.788v1.087H38.3v.543a1.629 1.629 0 0 1-1.623 1.63h-2.704v1.087h2.164v.543a1.629 1.629 0 0 1-1.623 1.63h-2.706v-4.891a7.04 7.04 0 0 0-1.116-3.8h9.774z" transform="translate(5.226 -84.399)" style={{fill:'#1a1446'}}/>
    </g>
  </svg>
)
