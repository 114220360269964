import React from 'react';
import { SVGProps } from './types';

export const InsuredSVG = ({ width, height }: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 25.423 31.518"
    width={width}
    height={height}
  >
    <path
      d="M429.348 208.535a8.458 8.458 0 1 0-8.458-8.455 8.468 8.468 0 0 0 8.458 8.455m0-15.116a6.658 6.658 0 1 1-6.658 6.661 6.668 6.668 0 0 1 6.658-6.661"
      transform="translate(-416.738 -191.619)"      
    />
    <path
      d="M429.45 210.425a12.727 12.727 0 0 0-12.712 12.712h1.8a10.919 10.919 0 0 1 5.956-9.716l3 3a2.807 2.807 0 0 0 3.963 0l2.983-2.983a10.92 10.92 0 0 1 5.92 9.7h1.8a12.726 12.726 0 0 0-12.711-12.712m.735 4.724a1 1 0 0 1-1.417 0l-2.461-2.461a10.9 10.9 0 0 1 6.327.012z"
      transform="translate(-416.738 -191.619)"      
    />
  </svg>
);
