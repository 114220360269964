/* eslint-disable import/no-unresolved */
import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  disabled: boolean | undefined;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;

  .icon {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 15px;
  }
`;

export const ContainerIcon = styled.span<Partial<ContainerProps>>`
  margin-top: ${({ isFocused }) => (isFocused ? '-1px' : '6px')};

  > svg {
    position: relative;
    bottom: ${({ isFocused }) => isFocused && '8px'};
    left: ${({ isFocused }) => isFocused && '2px'};
  }
`;

export const InputContainer = styled.div<ContainerProps>`
  background: transparent;
  border-radius: 3px;
  padding: 5px 8px;
  width: 100%;
  height: 50px;

  border: 1px solid ${colors.gray_2};
  outline: 1px solid transparent;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  ${props =>
    props.isFocused &&
    css`
      border: 2px solid ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
      outline: 0;
    `}

  ${props =>
    props.disabled &&
    css`
      border: 1px solid ${colors.gray_5};
      background-color: ${colors.gray_4};
    `}

  ${props =>
    props.isErrored &&
    css`
      border: 1px solid ${colors.red_2};
      background-color: ${colors.red_1};
    `}


  & + div {
    margin-top: 8px;
  }

  label {
    width: 100%;
    text-align: left;
    font-size: 0.75rem;
    margin-bottom: 4px;
    color: ${colors.gray_3};
  }

  input {
    width: 100%;
    outline: none;
    background: transparent;
    border: 0;
    font-size: 1rem;
    font-weight: 100;
    color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro};

    &::placeholder {
      color: ${colors.gray_3};
    }
  }

  img {
    position: absolute;
    width: 40px;
    height: 40px;
    align-self: flex-end;
    opacity: 0.5;
  }
`;

export const ContainerRow = styled.div<Partial<ContainerProps>>`
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: space-between;

  > svg {
    position: relative;
    bottom: ${({ isFocused }) => (isFocused ? '10px' : '0px')};
    left: ${({ isFocused }) => (isFocused ? '1px' : '0px')};
  }
`;
