import React from 'react';

export const IconCarGlassSVG = () => (
  <svg data-name="Icon RC" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
    <circle data-name="Ellipse bg" cx="30" cy="30" r="30" style={{fill:'#FFC900'}}/>
    <g data-name="Group 24">
      <path data-name="Path 75" d="M309.517 396.865h-3.288l-1.1-3.107a3.2 3.2 0 0 0-2.9-2.176h-2.5l-.346 1.8h2.844a1.422 1.422 0 0 1 1.2.981l1.825 5.144h-13.239l.736-2.727 4.821 1.3.73-3.877.154-.817.339-1.8.254-1.349.176-.935 4.4-.67-.271-1.779-5.665.862-.475 2.522-.254 1.349-.339 1.8-.153.817-.308 1.634-4.681-1.258-1.331 4.932h-3.895l1.624-5.1a1.442 1.442 0 0 1 1.234-1.023h6.563l.345-1.8h-6.908a3.24 3.24 0 0 0-2.948 2.273l-.958 3.01h-3.294a1.629 1.629 0 0 0-1.69 1.64v1.7a1.626 1.626 0 0 0 1.69 1.634h.031a2.721 2.721 0 0 0-.665 1.731v10.391a1.724 1.724 0 0 0 1.835 1.614h3.51a1.642 1.642 0 0 0 1.738-1.614v-1.135h-1.8v.949h-3.483v-5.594h3.952a1.592 1.592 0 0 0 1.548-1.191l.487-1.479.022-.079a1.777 1.777 0 0 0-.331-1.517 1.912 1.912 0 0 0-1.514-.7h-4.064a1.286 1.286 0 0 1 .292-.372l1.758-1.526h21.114l1.61 1.488a.808.808 0 0 1 .223.41H304.2a1.915 1.915 0 0 0-1.515.7 1.777 1.777 0 0 0-.331 1.517l.022.079.487 1.479a1.592 1.592 0 0 0 1.549 1.191h3.814v5.6h-3.288l-.194-.038v-.911h-1.8v1.135a1.849 1.849 0 0 0 1.994 1.614h3.509a1.528 1.528 0 0 0 1.58-1.614v-10.407a2.92 2.92 0 0 0-.524-1.731h.019a1.53 1.53 0 0 0 1.562-1.634v-1.7a1.533 1.533 0 0 0-1.562-1.64m-22.182 8.156zm-.005 0-.446 1.356h-3.806v-1.37h4.164l.088.014m-3.382-4.984h-1.926v-1.369h2.611l-.341 1.071zm25.331-1.37v1.37h-1.661l-.394-.364-.357-1.006m-2.671 6.34h4.026v1.37h-3.669" transform="translate(-265.651 -371.21)" style={{fill:'#2C3A39'}}/>
      <path data-name="Rectangle 13" transform="translate(18.905 38.334)" style={{fill:'#2C3A39'}} d="M0 0h22.189v1.8H0z"/>
    </g>
  </svg>
)
