import React from 'react';
import styled from 'styled-components';
import { mobileMinWidth } from 'styles/variables';
import { colors } from 'styles/colors';

export interface IModalProps {
  removePadding?: boolean;
  className?: string;
  children: React.ReactNode;
}

export const Fade = styled.div`
  background-color: #343741d1;
  width: 100%;
  height: 100vh;
  z-index: 100;
  position: fixed;
  cursor: pointer;
`;

export const Container = styled.div<IModalProps>`
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 30vh;
  z-index: 101;
  max-height: 50vh;
  width: 90vw;

  &.payment-screen {
    margin-top: 25vh;
    max-width: 764px;

    & iframe {
      border: 0;
    }
  }

  &.bank {
    max-width: 608px;
  }

  @media (max-width: ${mobileMinWidth}) {
    & > div {
      padding: 8px;
    }

    width: 95vw;
  }
`;

export const Modal = styled.div<IModalProps>`
  width: 100%;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: ${colors.white};
  padding: ${({ removePadding }) => (removePadding ? '0px' : '32px')};
`;
