import React from 'react';

export const TalkToUsSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 320.231">
      <g data-name="Img Co-workers">
          <path data-name="Path 637" d="M542.375 265.289h15.794a54.877 54.877 0 0 1 54.716 54.717 54.878 54.878 0 0 1-54.716 54.717H125.485a88.862 88.862 0 0 1-88.6-88.6 88.86 88.86 0 0 1 88.6-88.6 37.572 37.572 0 0 0 37.46-37.463 37.571 37.571 0 0 0-37.46-37.462h-8.758c-21.424 0-37.548-6.393-37.548-20.527 0-34.5 52.387-25.207 115.867-25.337 98.105-.2 219.847 0 219.847 0 45.433 0 50.059 13.928 59.393 41.629S532.1 148.3 532.1 148.3h13.691a29.9 29.9 0 0 1 29.818 29.816 29.9 29.9 0 0 1-29.818 29.817h-3.414a28.765 28.765 0 0 0-28.678 28.68 28.765 28.765 0 0 0 28.676 28.676z" transform="translate(-36.884 -54.525)" style={{fillRule:'evenodd',fill:'#fff'}}/>
          <g data-name="Group 9">
              <path data-name="Path 638" d="M427.772 226.016c1.857-6.094 1.883-9.606 1.264-15.622l26.926.353-35.58-19.919s-6.942-10.449-9.732-20.319 9.655-33.06 14.03-38.688 12.16-3.962 17.028-.21c0 0 8.932-10.973 18.3-10.848s38.648 5.729 45.113 28.247-9.459 42.841 2.185 66.492a5.581 5.581 0 0 1-1.085 5.141c-2.026 2.2-26.3 25.469-74.959 10.388 0 0-3.867-1.307-3.49-5.015z" transform="translate(-92.224 -50.713)" style={{fillRule:'evenodd'}}/>
              <path data-name="Path 640" d="M430.818 239.834c2.522-8.283 1.668-11.794.4-23.31l43.151-2.539 18.219 5.412a125.149 125.149 0 0 0 4.232 23.627c-11.992 5.9-32.66 11.077-62.512 1.825 0 0-3.867-1.307-3.49-5.015z" transform="translate(-95.27 -64.531)" style={{fillRule:'evenodd'}}/>
              <path data-name="Path 641" d="M414.233 265.6s-4.7 2.213-9.938 8.335c-3.734 4.365-19.238 45.63-28.589 71.639-2.314 6.436-11.37 28.876 6.64 41.63l3.88 3.474c5.965-12.381 20.887-45.311 20.887-45.311z" transform="translate(-86.463 -72.182)" style={{fill:'#2e8ea4', fillRule:'evenodd'}}/>
              <path data-name="Path 642" d="M530.354 265.6s4.7 2.213 9.938 8.335c3.735 4.365 19.238 45.63 28.59 71.639 2.314 6.436 11.371 28.876-6.639 41.63l-3.878 3.474c-5.967-12.381-20.889-45.311-20.889-45.311z" transform="translate(-110.027 -72.182)" style={{fill:'#2e8ea4', fillRule:'evenodd'}}/>
              <path data-name="Path 643" d="M465.867 251.241s44.436 10.513 46.484 12.424 11.306 47.688 11.329 52.157c.02 4.129-10.68 58.288-12.422 73.778h-90.783c-1.745-15.487-12.445-69.646-12.422-73.775.023-4.469 9.283-50.246 11.33-52.157s46.484-12.427 46.484-12.427z" transform="translate(-91.899 -70.053)" style={{fill:'#2e8ea4', fillRule:'evenodd'}}/>
              <path data-name="Path 644" d="M498.321 256.932c12.122 3.03 25.495 6.549 26.6 7.577 1.341 1.253 5.789 21.382 8.694 36.344-1.408 2.11-2.28 3.389-2.28 3.389s-7.863-28.737-10.572-30.636-27.115-9.284-27.926-9.421a51.451 51.451 0 0 1 5.484-7.253z" transform="translate(-104.465 -70.896)" style={{fill:'#2e8ea4', fillRule:'evenodd'}}/>
              <path data-name="Path 645" d="m490.693 256.294 2.656.665 3.417 2.11s-4.324 23.815-6.424 25.509-4.339-.2-4.339-.2l-11-11.188-16.35-15.635-.016-2.229-6.523 3.746s4.547 23.815 6.759 25.509 4.562-.2 4.562-.2l11.568-11.188 15.676-15.635z" transform="translate(-99.929 -71.693)" style={{fill:'#2aa2b2', fillRule:'evenodd'}}/>
              <path data-name="Rectangle 452" transform="translate(372.951 196.204)" style={{fill:'#fff'}} d="M0 0h2.034v34.16H0z"/>
              <path data-name="Path 646" d="M472.51 212.162h.3c8.738-.106 15.972 7.292 16.074 16.439l.216 19.54c.1 9.146-6.969 16.715-15.706 16.821l-.3.007c-8.738.106-15.973-7.293-16.072-16.439l-.217-19.541c-.105-9.151 6.965-16.721 15.705-16.827z" transform="translate(-99.125 -64.26)" style={{fill:'#d18b78', fillRule:'evenodd'}}/>
              <path data-name="Path 647" d="m399.784 385.105-1.716 1.638 24.5 13.069-5.479 8.571h-27.93s-6.376-2.835-5.87-5.768 6.576-19.331 16.495-17.51z" transform="translate(-88.224 -89.873)" style={{fill:'#d18b78', fillRule:'evenodd'}}/>
              <path data-name="Path 648" d="M437.371 400.715s-4.709-3.152-7.248-4.674a6.664 6.664 0 0 0-6.811.073 107.726 107.726 0 0 0-9.711 7.764c-1.085 1.151 5.22 3.686 5.871 3.686s17.899-6.849 17.899-6.849z" transform="translate(-92.703 -91.381)" style={{fill:'#ef8871', fillRule:'evenodd'}}/>
              <path data-name="Path 649" d="m545.421 385.105 1.715 1.638-24.5 13.069 5.479 8.571h27.932s6.376-2.835 5.869-5.768-6.576-19.331-16.495-17.51z" transform="translate(-108.883 -89.873)" style={{fill:'#d18b78', fillRule:'evenodd'}}/>
              <path data-name="Path 650" d="M510.516 400.715s4.71-3.152 7.247-4.674a6.667 6.667 0 0 1 6.813.073 108 108 0 0 1 9.712 7.764c1.085 1.151-5.222 3.686-5.871 3.686s-17.901-6.849-17.901-6.849z" transform="translate(-107.086 -91.381)" style={{fill:'#ef8871', fillRule:'evenodd'}}/>
              <path data-name="Path 651" d="m423.855 202.037-3.131-1.753a94.951 94.951 0 0 1-8.091-15.511c.632 1.559 4.907 11.073 12.989 6.3 8.789-5.189 8.192 4.33 8.192 4.33s-5.511 5.152-8.522 6.188c-.39.135-.879.285-1.437.445zm-11.3-17.467v-.007z" transform="translate(-92.566 -60.169)" style={{fill:'#050a26', fillRule:'evenodd'}}/>
              <path data-name="Path 652" d="M428.9 199.083a9.4 9.4 0 1 1-9.342 9.455 9.4 9.4 0 0 1 9.342-9.455z" transform="translate(-93.605 -62.322)" style={{fill:'#d18b78', fillRule:'evenodd'}}/>
              <path data-name="Path 653" d="M505.908 198.755c.233.033.462.076.689.125h.007a9.4 9.4 0 1 1-7.869 1.786c-.275 2.772.12 6.211 3.657 6.6l-.032-5.419a3.229 3.229 0 0 1 3.548-3.092z" transform="translate(-104.808 -62.273)" style={{fill:'#d18b78', fillRule:'evenodd'}}/>
              <path data-name="Path 654" d="M459.9 143.078a32.285 32.285 0 0 1 32.38 32l.173 29.254a32.288 32.288 0 0 1-32 32.381 32.285 32.285 0 0 1-32.378-32l-.173-29.255a32.287 32.287 0 0 1 31.998-32.38z" transform="translate(-94.841 -54.021)" style={{fill:'#d18b78', fillRule:'evenodd'}}/>
              <path data-name="Path 656" d="m434.542 169.9 4.967 2.708a15.369 15.369 0 0 1 10.615-.661s11.783-3.071 17.582-.152 3.076-5.8 2-6.274-12.416-2.313-17.493-1.843-12.688.911-12.688.911z" transform="translate(-95.825 -57.063)" style={{fillRule:'evenodd'}}/>
              <path data-name="Path 657" d="m503.187 196.536-3.124.241s-3.079 9.5 2.94 10.164l-.031-5.419a3 3 0 0 1 .163-1.022z" transform="translate(-105.42 -61.944)" style={{fill:'#050a26', fillRule:'evenodd'}}/>
              <path data-name="Path 663" d="M448.231 168.1c5.3-5.862 13.076-6.406 18.16 3.049 4.644 8.638 11.347 23.46 25.045 23.639l.091-.242h-.027l.12-9.129s1.113.411 2.471.873a58.874 58.874 0 0 0 2.055-16.179c-.464-11.53-14.669-29.7-32.462-32.078s-31.748 6.967-34.897 12.762-7.014 19.269-7.014 19.269l2.7 4.8 2.512 9.261c8.993-7.187 8.887-14.824 12.83-18.283 2.136-1.874 5.027-1.599 8.416 2.258z" transform="translate(-93.933 -53.217)" style={{fillRule:'evenodd'}}/>
              <g data-name="Group 7" transform="translate(343.153 143.006)">
                  <circle data-name="Ellipse 64" cx="2.53" cy="2.53" r="2.53" style={{fill:'#030404'}}/>
                  <circle data-name="Ellipse 65" cx="2.53" cy="2.53" r="2.53" transform="translate(29.598)" style={{fill:'#030404'}}/>
              </g>
              <path data-name="Path 712" d="M162.907 301.095h157.035a3.573 3.573 0 0 1 3.608 4.246l-12 79.713a5.13 5.13 0 0 1-4.886 4.246H176.189a5.132 5.132 0 0 1-4.886-4.246l-12-79.713a3.573 3.573 0 0 1 3.604-4.246z" transform="translate(132.356 -77.442)" style={{fill:'#dcdfe5', fillRule:'evenodd'}}/>
              <path data-name="Path 713" d="M167.038 305.517h150.049a3.415 3.415 0 0 1 3.446 4.058l-11.468 76.168a4.906 4.906 0 0 1-4.665 4.057H179.727a4.906 4.906 0 0 1-4.669-4.058l-11.469-76.168a3.415 3.415 0 0 1 3.449-4.057z" transform="translate(131.72 -78.098)" style={{fillRule:'evenodd', fill:'#fff'}}/>
              <path data-name="Path 714" d="m312.007 366.243-2.942 19.535a4.906 4.906 0 0 1-4.669 4.058H179.727a4.906 4.906 0 0 1-4.669-4.058l-11.469-76.168a3.416 3.416 0 0 1 2.221-3.857c17.173 29.63 59.381 78.159 146.197 60.49z" transform="translate(131.72 -78.132)" style={{fill:'#dce0e8', fillRule:'evenodd'}}/>
              <path data-name="Path 715" d="M252.822 375.845c9.192 0 16.69-6.256 16.69-13.931s-7.5-13.93-16.69-13.93-16.691 6.259-16.691 13.93 7.498 13.931 16.691 13.931z" transform="translate(120.961 -84.392)" style={{fill:'#dcdfe5', fillRule:'evenodd'}}/>
              <path data-name="Path 716" d="M253.374 351.088a15.622 15.622 0 0 0-6.418 1.348 6.087 6.087 0 0 1 4.436 5.531c0 3.307-3.211 5.985-7.168 5.985a8.079 8.079 0 0 1-4.367-1.238c.215 6.076 6.185 10.947 13.517 10.947 7.467 0 13.522-5.054 13.522-11.289s-6.055-11.284-13.522-11.284z" transform="translate(120.409 -84.852)" style={{fillRule:'evenodd', fill:'#fff'}}/>
              <path data-name="Path 717" d="M322.256 403.789H159.245a4.567 4.567 0 0 0-4.554 4.553 4.564 4.564 0 0 0 4.554 4.549h163.011a4.565 4.565 0 0 0 4.552-4.549 4.569 4.569 0 0 0-4.552-4.553z" transform="translate(133.032 -92.664)" style={{fill:'#d3d7de', fillRule:'evenodd'}}/>
              <path data-name="Path 677" d="m491.978 253.174-.018 2.227-15.677 15.635 11 11.19s2.238 1.9 4.339.2 6.424-25.512 6.424-25.512z" transform="translate(-102.012 -70.339)" style={{fillRule:'evenodd', fill:'#fff'}}/>
              <path data-name="Path 678" d="m455.935 253.174.018 2.227 16.347 15.636-11.568 11.19s-2.353 1.9-4.56.2-6.76-25.512-6.76-25.512z" transform="translate(-98.029 -70.339)" style={{fillRule:'evenodd', fill:'#fff'}}/>
              <path data-name="Path 737" d="M412.317 202.1a8.334 8.334 0 0 1-14.817 0z" transform="translate(-45.046 -40.213)" style={{fill:'#fff'}}/>
          </g>
          <g data-name="Group 16">
              <g data-name="Group 15" transform="translate(90.589 66.345)">
                  <path data-name="Path 679" d="m278.764 178.9 7.145 16.008s25.731 27.016-.713 52.315-62.323 5.864-62.323 5.864l-22.727-7.58s-5.718-13.149.572-26.871c0 0-8.185-17.691 5.143-32.3s72.903-7.436 72.903-7.436z" transform="translate(-151.319 -125.413)" style={{fill:'#a16852', fillRule:'evenodd'}}/>
                  <path data-name="Path 680" d="m335.94 167.107.349 8.275s22.892 58.482 21.72 60.836-41.732-.264-81.766-4.314c-3.025-.44-1.567 1.072-3.1 2.548s11.749 11.9 11.749 11.9l24.651 25.971c18.9-1.967 50.284-7.766 62.357-16.472 10.528-7.589 9.384-21.208 3.8-31.365-8.87-16.124-28.175-57.389-28.175-57.389z" transform="translate(-162.473 -123.925)" style={{fill:'#1f1a4e', fillRule:'evenodd'}}/>
                  <path data-name="Path 681" d="M316.294 287.141c19.059-2.2 50.05-8.062 61.6-16.39a19.7 19.7 0 0 0 3.418-3.115c-.082.083-5.009 5.242-23.529 8.3s-42.68.048-43.75-.072-.633 6.469 2.261 11.277z" transform="translate(-168.474 -138.828)" style={{fill:'#1f1a4e', fillRule:'evenodd'}}/>
                  <path data-name="Path 682" d="m346.951 168.376.3 7.006s12.887 32.92 18.828 50.356-12.18-40.531-11.1-44.5c.6-2.187 3.819-5.248 6.6-7.56-1.917-4.05-3.1-6.582-3.1-6.582l-7.859.009c-1.371.439-2.61.878-3.669 1.271z" transform="translate(-173.432 -123.925)" style={{fill:'#1f1a4e', fillRule:'evenodd'}}/>
                  <path data-name="Path 683" d="M332.937 165.193c-3.542-2.507-10.287-3.156-11.681-3.208-1.214-.045-.848-3.055 1.305-4.257s9.525 1.555 10.457-1.682c.388-1.36-10.314-15.226-11.589-16.846a2.161 2.161 0 0 1 1.189-3.41c.858-.024 2.676 2.346 5.181 5.189s5.357 6.008 5.849 6.056 1.119-.554.353-2.647-5.42-13.463-5.083-14.577 2.405-1.665 3.614-.165S338 142.8 339.023 144.12s2.15.123 2.056-1.041-2.932-14.454-2.657-15.506 2.41-1.541 3.262.336 4.1 14.481 4.79 15.135 2.1.309 2.576-2.221.877-7.876 1.413-8.444 2.927.437 2.909 3.1.177 9.29.329 10.266c.8 5.16 2.951 9.606 3.022 13.715a9.958 9.958 0 0 1-1.042 4.43 14.271 14.271 0 0 1-8.642 7.346c-6.439 1.814-9.23-2.592-14.102-6.043z" transform="translate(-169.521 -117.93)" style={{fill:'#f3b79c', fillRule:'evenodd'}}/>
                  <path data-name="Path 684" d="M332.961 169.505c-3.544-2.507-10.152-3.088-11.547-3.139-.629-.024-.833-.847-.583-1.8 6.778-2.544 14.551 5.86 19.677 6.588 6.618.94 12.528-4.83 13.848-6.912 1.352-2.131 1.192-6.036.7-8.5a32.687 32.687 0 0 1 1.692 8.042 9.96 9.96 0 0 1-1.042 4.43 14.276 14.276 0 0 1-8.642 7.347c-6.436 1.802-9.231-2.605-14.103-6.056z" transform="translate(-169.545 -122.242)" style={{fill:'#e7aa8a', fillRule:'evenodd'}}/>
                  <path data-name="Path 685" d="M189.105 252.121s-5.03 2.369-10.635 8.919c-4 4.672-20.593 48.837-30.6 76.678-2.474 6.887-12.168 30.909 7.106 44.557l4.152 3.716c6.389-13.25 22.359-48.5 22.359-48.5z" transform="translate(-143.237 -136.528)" style={{fill:'#1f1a4e', fillRule:'evenodd'}}/>
                  <path data-name="Path 686" d="M242.36 237.748c-10.048 2.661-40.168 10.742-41.821 12.445-1.984 2.047-10.959 51.044-10.982 55.825-.021 4.42 10.349 65.124 12.041 81.7h88.03c1.693-16.575 12.062-77.279 12.041-81.7-.021-4.781-9.163-53.951-10.982-55.825s-18.709-5.214-28.756-7.875l-12.662 16.628-1.8-.015-1.855 3.244-1.855-3.244-1.8.015z" transform="translate(-150.102 -134.398)" style={{fill:'#1f1a4e', fillRule:'evenodd'}}/>
                  <path data-name="Path 688" d="M241 237.748c-11.094 2.661-44.355 10.742-46.18 12.445-2.192 2.047-12.1 51.044-12.128 55.825-.009 1.591 1.47 10.469 3.451 21.952 17 4.384 30.877 7.311 30.877 7.311s-27.984-32.134-25.554-46.257 4.158-27.218 6.506-28.663 17.241-6.769 21.664-7.853 5.054 2.166 5.054 2.166 3.248 11.2 3.792 13.405 1.669 3.205 3.021 2.574 8.261-6.005 8.712-5.733 3.353 5.416 3.353 5.416l2.046-2.482 1.735-4.423s7.808 6.227 9.026 7.131a2.18 2.18 0 0 0 3.386-.813c.36-1.263 3.8-17.678 3.8-17.678l-2.459-11.179c-5.229-1.291-9.9-2.418-12.921-3.144l.445 21.2-1.987-.015-2.049 3.244-2.049-3.244-1.985.015z" transform="translate(-149.086 -134.398)" style={{fill:'#1f1a4e', fillRule:'evenodd'}}/>
                  <path data-name="Path 689" d="m256.213 264.525-1.024 1.621-1.022-1.621V294.8a1.023 1.023 0 1 0 2.046 0z" transform="translate(-159.679 -138.367)" style={{fill:'#f9d306', fillRule:'evenodd'}}/>
                  <path data-name="Path 690" d="M253.333 196.827h-.28c-8.077-.121-14.761 8.385-14.855 18.906L238 238.2c-.06 6.775 2.627 12.8 6.707 16.26v-1.578h6.046l2.04 3.23 2.039-3.23h4.931v2.481c4.624-3.169 7.819-9.463 7.885-16.716l.2-22.472c.094-10.521-6.44-19.225-14.515-19.348z" transform="translate(-157.283 -128.332)" style={{fill:'#f3b79c', fillRule:'evenodd'}}/>
                  <path data-name="Path 691" d="m173.64 380.03-1.836 1.749 26.221 13.99-5.864 9.174h-29.9s-6.824-3.032-6.284-6.174 7.048-20.69 17.663-18.739z" transform="translate(-145.121 -155.465)" style={{fill:'#f4ad85', fillRule:'evenodd'}}/>
                  <path data-name="Path 692" d="M213.872 396.735s-5.041-3.372-7.757-5a7.131 7.131 0 0 0-7.291.078 115.175 115.175 0 0 0-10.394 8.31c-1.163 1.232 5.586 3.947 6.282 3.947s19.16-7.335 19.16-7.335z" transform="translate(-149.915 -157.078)" style={{fill:'#ef8871', fillRule:'evenodd'}}/>
                  <path data-name="Path 693" d="M237.371 240.539s3.24 7.776 4.331 9.748 8.309 8.743 8.309 8.743.227.587 0 .9-11.056 9.206-11.056 9.206-.9.632-1.579-.767-4.015-13.226-4.287-16.2 1.355-12.186 1.355-12.186l2.824-1.579z" transform="translate(-156.55 -134.495)" style={{fill:'#f9d306', fillRule:'evenodd'}}/>
                  <path data-name="Path 694" d="M270.4 240.539s-3.24 7.776-4.33 9.748-8.312 8.743-8.312 8.743-.226.587 0 .9 11.056 9.206 11.056 9.206.9.632 1.582-.767 4.014-13.226 4.285-16.2-.858-10.742-.858-10.742L270.5 238.4z" transform="translate(-160.196 -134.495)" style={{fill:'#f9d306', fillRule:'evenodd'}}/>
                  <path data-name="Path 695" d="M273.584 148.665s-5.252-13.232-11-16.631-29.668-6.268-37.412-4.029-22.2 27.137-22.759 44.4 9.4 24.741 18.544 26.225 52.627-49.965 52.627-49.965z" transform="translate(-152.005 -118.014)" style={{fill:'#a16852', fillRule:'evenodd'}}/>
                  <path data-name="Path 696" d="M291.828 147.736s12.048 9.865 13.146 13.33-.609 19.233-6.559 26.274-20.912 5.406-21.241 2.474-.589-28.285.982-30.357 13.672-11.721 13.672-11.721z" transform="translate(-163.052 -121.056)" style={{fill:'#a16852', fillRule:'evenodd'}}/>
                  <g data-name="Group 10">
                      <path data-name="Path 697" d="M294.9 190.059c.223.049.442.1.657.17h.006a9.121 9.121 0 1 1-7.742 1.175c-.461 2.663-.319 6.019 3.073 6.647l.353-5.249a3.133 3.133 0 0 1 3.653-2.743z" transform="translate(-164.073 -127.325)" style={{fill:'#f3b79c', fillRule:'evenodd'}}/>
                      <path data-name="Path 698" d="M218.331 183.442c-.229.012-.454.03-.678.057h-.007a9.121 9.121 0 1 0 7.426 2.479c0 2.7-.71 5.986-4.163 6.024l.549-5.23a3.135 3.135 0 0 0-3.127-3.33z" transform="translate(-153.078 -126.348)" style={{fill:'#f3b79c', fillRule:'evenodd'}}/>
                  </g>
                  <path data-name="Path 699" d="M256.594 133.448a31.317 31.317 0 0 1 28.437 33.782l-2.431 28.295a31.313 31.313 0 0 1-33.782 28.434 31.317 31.317 0 0 1-28.433-33.783l2.433-28.294a31.313 31.313 0 0 1 33.776-28.434z" transform="translate(-154.654 -118.921)" style={{fill:'#f3b79c', fillRule:'evenodd'}}/>
                  <path data-name="Path 700" d="M257.464 133.448A31.319 31.319 0 0 1 285.9 167.23l-2.436 28.3a31.315 31.315 0 0 1-33.782 28.432c27.908-.432 29.392-33.815 29.392-33.815-2.614-4.833-4.166-16.655-4.571-20.789s-4.473-3.661-4.473-3.661a64.656 64.656 0 0 1-27.11-2.372 28.479 28.479 0 0 1-10.233 11.575l-2.379-1.457c-15.794-27.409 18.3-39.419 18.741-39.573l.049-.009c.2-.03 1.211-.173 2.826-.4a31.158 31.158 0 0 1 5.54-.016zm-7.78 90.511" transform="translate(-155.524 -118.921)" style={{fill:'#f3b79c', fillRule:'evenodd'}}/>
                  <g data-name="Group 11">
                      <path data-name="Path 701" d="M262.663 213.585a8.079 8.079 0 0 1-16.141-.75h.013a1.4 1.4 0 0 1 1.459-1.328l13.328.619a1.4 1.4 0 0 1 1.33 1.457z" transform="translate(-158.545 -130.508)" style={{fillRule:'evenodd', fill:'#fff'}}/>
                      <path data-name="Path 702" d="M259.974 220.7a8.083 8.083 0 0 1-9.358.135 5.033 5.033 0 0 1 4.755-2.763 5.1 5.1 0 0 1 4.603 2.628z" transform="translate(-159.153 -131.482)" style={{fillRule:'evenodd', fill:'#fff'}}/>
                      <path data-name="Path 703" d="m248.032 211.513 12.525.582c.138 1.394-.572 2.763-1.516 3.026-1.152.316-10.205-.341-10.814-1.083a4.826 4.826 0 0 1-.195-2.525z" transform="translate(-158.762 -130.509)" style={{fillRule:'evenodd', fill:'#fff'}}/>
                  </g>
                  <path data-name="Path 708" d="m283.486 185.507-.376 4.381c-1.48-.127-3.089-2.434-2.923-4.365s.347-4.011.347-4.011-3-5.81-2.507-11.6-.555-9.36-5.514-9.786-10.146 1.742-18.276 1.043-12.869-3.721-17.827-4.147-6.6 2.908-7.1 8.7-4.451 11-4.451 11-.176 2.081-.342 4.011-2.147 3.93-3.625 3.8l.377-4.382c.279-3.023-.731-4.223-.731-4.223s.014-.149 1.119-13.019c3.811-44.318 68.2-38.783 64.388 5.537l-1.119 13.019s-1.198 1.014-1.44 4.042z" transform="translate(-154.694 -118.77)" style={{fill:'#a16852', fillRule:'evenodd'}}/>
                  <g data-name="Computer">
                      <path data-name="Path 712" d="M162.907 301.095h157.035a3.573 3.573 0 0 1 3.608 4.246l-12 79.713a5.13 5.13 0 0 1-4.886 4.246H176.189a5.132 5.132 0 0 1-4.886-4.246l-12-79.713a3.573 3.573 0 0 1 3.604-4.246z" transform="translate(-145.611 -143.787)" style={{fill:'#dcdfe5', fillRule:'evenodd'}}/>
                      <path data-name="Path 713" d="M167.038 305.517h150.049a3.415 3.415 0 0 1 3.446 4.058l-11.468 76.168a4.906 4.906 0 0 1-4.665 4.057H179.727a4.906 4.906 0 0 1-4.669-4.058l-11.469-76.168a3.415 3.415 0 0 1 3.449-4.057z" transform="translate(-146.247 -144.443)" style={{fillRule:'evenodd', fill:'#fff'}}/>
                      <path data-name="Path 714" d="m312.007 366.243-2.942 19.535a4.906 4.906 0 0 1-4.669 4.058H179.727a4.906 4.906 0 0 1-4.669-4.058l-11.469-76.168a3.416 3.416 0 0 1 2.221-3.857c17.173 29.63 59.381 78.159 146.197 60.49z" transform="translate(-146.247 -144.477)" style={{fill:'#dce0e8', fillRule: 'evenodd'}}/>
                      <path data-name="Path 715" d="M252.822 375.845c9.192 0 16.69-6.256 16.69-13.931s-7.5-13.93-16.69-13.93-16.691 6.259-16.691 13.93 7.498 13.931 16.691 13.931z" transform="translate(-157.006 -150.737)" style={{fill:'#dcdfe5', fillRule:'evenodd'}}/>
                      <path data-name="Path 716" d="M253.374 351.088a15.622 15.622 0 0 0-6.418 1.348 6.087 6.087 0 0 1 4.436 5.531c0 3.307-3.211 5.986-7.168 5.986a8.079 8.079 0 0 1-4.367-1.238c.215 6.076 6.185 10.947 13.517 10.947 7.467 0 13.522-5.054 13.522-11.289s-6.055-11.285-13.522-11.285z" transform="translate(-157.558 -151.197)" style={{fillRule:'evenodd', fill:'#fff'}}/>
                      <path data-name="Path 717" d="M322.256 403.789H159.245a4.567 4.567 0 0 0-4.554 4.553 4.564 4.564 0 0 0 4.554 4.549h163.011a4.565 4.565 0 0 0 4.552-4.549 4.569 4.569 0 0 0-4.552-4.553z" transform="translate(-144.935 -159.008)" style={{fill:'#d3d7de', fillRule:'evenodd'}}/>
                  </g>
                  <path data-name="Path 718" d="M26.58 15.478a12.182 12.182 0 1 0-13.466 10.745A12.181 12.181 0 0 0 26.58 15.478zm1.9-6.12c1.2 1.693 2.021 1.041 3.679 1.216 1.387.157 2.121 1.014 3.681-.39 4.1-3.685 7.652-7 14.18-6.261a14.036 14.036 0 0 1 7.52 3.231 15.029 15.029 0 0 1 5.046 12.176c-.583 2.217-1.441 5.638-3.3 7.629a14.1 14.1 0 0 1-24.248-4.68c-1.914-4.591-.339-8.084-3.411-8.487-2.718-.357-2.552 4.234-4.984 7.476l-.053.062a14.1 14.1 0 0 1-24.683-.814C.607 18.286.3 14.674 0 12.59l.049-.009A14.314 14.314 0 0 1 3.164 4.82L8 1.589a14.083 14.083 0 0 1 17.512 3.753C26.61 6.628 27.528 8 28.484 9.358zm7.861 7.215A12.181 12.181 0 1 0 49.81 5.828a12.182 12.182 0 0 0-13.465 10.745z" transform="translate(66.058 51.549)" style={{fillRule:'evenodd'}}/>
                  <path data-name="Path 720" d="M202.014 172.756c29.526 17.41 34.987-12.329 39.063-20.576s10.97-5.271 11.836-.491a14.379 14.379 0 0 1-1.765 9.071 26.508 26.508 0 0 0 4.392.123s6.227-5.416 7.627-10.873-6.187-15.492-10.977-18.066-10.361-4.109-15.8 1.125-21.847 20.387-29.469 19.811l1.4-3.2s-4.7 1.018-6.721 3.576-3.165 14.544.414 19.5z" transform="translate(-151.606 -118.364)" style={{fill:'#a16852', fillRule:'evenodd'}}/>
                  <path data-name="Path 721" d="M288.193 145.52a30.4 30.4 0 0 1-2.946 2.819c-.319.267-.66.543-1.014.816-2.071 1.593-4.718 3.166-7.341 3.457a59.26 59.26 0 0 1-3.277.25l-.5.024c-.928.043-1.974.074-3.117.086l-3.763-.757a15.2 15.2 0 0 0 1.876-4.154c1.261-4.923-4.789-13.573-9.505-17.109a11.658 11.658 0 0 0-1.472-.958c-4.787-2.572-10.35-4.106-15.784 1.11a.161.161 0 0 1-.017.018 193.87 193.87 0 0 1-7.159 6.554 22.532 22.532 0 0 1-.6-11.9c5.394-6.965 11.5-11.117 22.151-8.634 21.3 4.968 35.183 19.174 34.305 23.978a15.572 15.572 0 0 1-1.837 4.4z" transform="translate(-156.552 -116.413)" style={{fill:'#a16852', fillRule:'evenodd'}}/>
                  <circle data-name="Ellipse 62" cx="2.53" cy="2.53" r="2.53" transform="translate(78.844 64.199)" style={{fill:'#030404'}}/>
                  <circle data-name="Ellipse 63" cx="2.53" cy="2.53" r="2.53" transform="translate(110.802 67.314)" style={{fill:'#030404'}}/>
              </g>
              <path data-name="Path 722" d="M333.559 155.75c-.234-.383-.5-.8-.79-1.236.25-.918 1.4-2.327 4.166-4.474 4.878-3.8 12.4-3.118 14.367-2.44s3.865 2.045-1.017.925c-4.845-1.112-12.764 1.58-16.726 7.225z" transform="translate(-80.74 -54.613)" style={{fill:'#e7aa8a', fillRule:'evenodd'}}/>
          </g>
          <g data-name="Group 18">
              <path data-name="Path 723" d="M128.622 54.362h60.269a7.984 7.984 0 0 1 7.96 7.961v56.146a.889.889 0 0 1-1.518.628l-13.563-13.562h-53.148a7.982 7.982 0 0 1-7.96-7.961V62.323a7.982 7.982 0 0 1 7.96-7.961z" transform="translate(-49.302 -54.362)" style={{fill:'#6cdede', fillRule:'evenodd'}}/>
              <g data-name="Group 17">
                  <path data-name="Path 724" d="M179.65 97.113A15.014 15.014 0 1 0 164.633 82.1a15.044 15.044 0 0 0 15.017 15.013z" transform="translate(-55.82 -56.248)" style={{fillRule:'evenodd', fill:'#fff'}}/>
                  <path data-name="Path 725" d="M177.976 82.216a9.936 9.936 0 0 0-7.275 8.306 12.382 12.382 0 0 0 19.694 0 9.939 9.939 0 0 0-7.274-8.306 4.846 4.846 0 1 0-5.145 0z" transform="translate(-56.719 -57.164)" style={{ opacity: 0.2, fill:'#001657', fillRule:'evenodd'}}/>
                  <path data-name="Path 726" d="M155.939 96.086h-5.263a1.115 1.115 0 1 1 0-2.23h5.263a1.115 1.115 0 1 1 0 2.23z" transform="translate(-53.585 -60.216)" style={{opacity:.2, fillRule:'evenodd'}}/>
                  <path data-name="Path 727" d="M154.919 88.713h-11.127a1.115 1.115 0 1 1 0-2.23h11.127a1.115 1.115 0 1 1 0 2.23z" transform="translate(-52.565 -59.123)" style={{opacity:.2, fillRule:'evenodd'}}/>
                  <path data-name="Path 728" d="M155.939 78.819h-5.263a1.115 1.115 0 1 1 0-2.229h5.263a1.115 1.115 0 1 1 0 2.229z" transform="translate(-53.585 -56.158)" style={{opacity:.2, fillRule:'evenodd'}}/>
                  <path data-name="Path 729" d="M153.171 72.358h-21.177a1.114 1.114 0 0 1 0-2.228h21.177a1.114 1.114 0 1 1 0 2.228z" transform="translate(-50.817 -56.699)" style={{opacity:.2, fillRule:'evenodd'}}/>
              </g>
          </g>
          <g data-name="Group 20">
              <path data-name="Path 730" d="M614.129 114.2h-60.268a7.982 7.982 0 0 0-7.961 7.959v56.147a.889.889 0 0 0 1.517.629l13.563-13.564h53.149a7.983 7.983 0 0 0 7.959-7.959V122.16a7.982 7.982 0 0 0-7.959-7.96z" transform="translate(-112.331 -49.74)" style={{fill:'#feda02', fillRule:'evenodd'}}/>
              <g data-name="Group 19">
                  <path data-name="Path 731" d="M604.787 154.541a13.905 13.905 0 1 1 13.907-13.905 13.932 13.932 0 0 1-13.907 13.905z" transform="translate(-144.413 -51.597)" style={{fillRule:'evenodd', fill:'#fff'}}/>
                  <path data-name="Path 732" d="M608.007 140.744a9.2 9.2 0 0 1 6.735 7.692 11.467 11.467 0 0 1-18.239 0 9.211 9.211 0 0 1 6.738-7.692 4.487 4.487 0 1 1 4.766 0z" transform="translate(-145.248 -52.445)" style={{opacity:.2, fill:'#001657', fillRule:'evenodd'}}/>
                  <path data-name="Path 733" d="M574.575 154.3h7.756a1.032 1.032 0 0 0 0-2.065h-7.756a1.032 1.032 0 1 0 0 2.065z" transform="translate(-94.31 -55.377)" style={{opacity:.2, fillRule:'evenodd'}}/>
                  <path data-name="Path 734" d="M569.633 148.376H581.6a1.032 1.032 0 0 0 0-2.064h-11.967a1.032 1.032 0 1 0 0 2.064z" transform="translate(-89.368 -54.499)" style={{opacity:.2, fillRule:'evenodd'}}/>
                  <path data-name="Path 735" d="M576.463 142.094h6.149a1.033 1.033 0 0 0 0-2.066h-6.149a1.033 1.033 0 1 0 0 2.066z" transform="translate(-96.198 -53.568)" style={{opacity:.2, fillRule:'evenodd'}}/>
                  <path data-name="Path 736" d="M563.909 134.867h16.841a1.033 1.033 0 0 0 0-2.066h-16.841a1.033 1.033 0 1 0 0 2.066z" transform="translate(-83.644 -52.497)" style={{opacity:.2, fillRule:'evenodd'}}/>
              </g>
          </g>
      </g>
  </svg>
)
