import React from 'react';

export const HouseSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
      <g transform="translate(-503 -666)">
          <circle data-name="Ellipse 323" cx="35" cy="35" r="35" transform="translate(503 666)" style={{fill:'#78e1e1'}}/>
          <path data-name="Path 536" d="m445.93 985.718-4.61-4.61v-8.09a1.159 1.159 0 0 0-1.158-1.157h-4.269v2.313h3.115v4.619l-9.236-9.235a1.184 1.184 0 0 0-1.636 0l-16.077 16.077-.081.082 1.634 1.636 2.656-2.658v17.836a1.159 1.159 0 0 0 1.157 1.157h9.589v-2.313h-8.432v-18.992l10.371-10.371 10.054 10.053v19.31h-6.725v-5.84a1.158 1.158 0 0 0-1.157-1.156h-4.342v2.314h3.185v5.839a1.159 1.159 0 0 0 1.157 1.157h9.036a1.16 1.16 0 0 0 1.158-1.157v-18.154l2.975 2.975z" transform="translate(103.59 -288.459)" style={{fill:'#0c1035'}}/>
          <path data-name="Rectangle 185" transform="translate(526.488 697.135)" style={{fill:'#0c1035'}} d="M0 0h2.314v3.84H0z"/>
          <path data-name="Rectangle 186" transform="translate(537.177 697.135)" style={{fill:'#0c1035'}} d="M0 0h2.314v3.84H0z"/>
          <path data-name="Path 537" d="M444.048 991.021a6.081 6.081 0 0 0-3.178.9 6.121 6.121 0 0 0-5.353-3.945v2.317a3.807 3.807 0 0 1 3.423 3.491 6.072 6.072 0 0 0-1.005 3.351v.116h2.314v-.116a3.8 3.8 0 0 1 7.6 0v.116h2.314v-.116a6.12 6.12 0 0 0-6.113-6.112" transform="translate(110.272 -283.138)" style={{fill:'#0c1035'}}/>
      </g>
  </svg>
)
