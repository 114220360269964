/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback } from 'react';

import IInputProps from '../@types/IInputProps';

import * as S from './styles';
import { ErrorMessage } from '../ErrorMessage';

const InputField = ({
  label,
  disabled,
  value,
  fieldState,
  name,
  className,
  inputRef,
  placeholder,
  onCustomBlur,
  style,
  ...rest
}: IInputProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback((event: any) => {
    setIsFocused(false);

    onCustomBlur && onCustomBlur(event);
  }, []);

  const showLabel = fieldState?.isTouched || value || isFocused;
  const error = fieldState?.error?.message;

  return (
    <S.Container style={style}>
      <S.InputContainer
        isFocused={isFocused}
        isFilled={!!value}
        isErrored={!!error}
        disabled={disabled}
        className={className}
      >
        {showLabel && <label>{label}</label>}
        <input
          name={name}
          value={value}
          disabled={disabled}
          placeholder={!isFocused ? placeholder : ''}
          {...rest}
          ref={inputRef}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
      </S.InputContainer>

      {error && <ErrorMessage name={name} message={error} />}
    </S.Container>
  );
};

export { InputField };
