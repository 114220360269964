/* eslint-disable prefer-destructuring */
/* eslint-disable func-names */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useCallback } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import 'react-calendar/dist/Calendar.css';

import CustomRoutes from 'routes';

import { NavBar } from 'components/Layouts/Navbar';
import Footer from 'components/Layouts/Footer';
import { Main } from 'components/Layouts/Main';
import FooterEmbbed from 'components/Layouts/FooterEmbbed';

import GlobalStyles from 'styles/global';

import { AppProvider } from 'contexts';

import GoogleTagManager from 'helpers/googleTagManager.js';
import { sendBrokerLeadNotification } from 'api/services/broker';
import { formatCPF } from 'helpers/format';
import { LOCAL_BROKER, LOCAL_CONTRACT_COMPLETED, LOCAL_CPF } from 'config';
import axios from 'axios';
import theme from './styles/theme';

GoogleTagManager();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AppProvider>
          {!window.location.href.includes('embbed') && <NavBar />}
          <Main embbed={window.location.href.includes('embbed')}>
            <CustomRoutes />
          </Main>
          {!window.location.href.includes('embbed') ? (
            <Footer />
          ) : (
            <FooterEmbbed />
          )}
        </AppProvider>
      </BrowserRouter>
      <GlobalStyles />
    </ThemeProvider>
  );
}

export default App;
