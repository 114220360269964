/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable import/no-useless-path-segments */
/* eslint-disable import/no-unresolved */
import { AxiosResponse } from 'axios';

import { GetTokenResponse } from 'types/security';

import api from '../../api';
import appConfig from '../../assets/settings/appConfig.PRD.json'

export const getToken = async (): Promise<AxiosResponse<GetTokenResponse>> => {
  try {
    return api.post('/auth/login', {
      client_id: appConfig.REACT_APP_CLIENT_ID,
      client_secret: appConfig.REACT_APP_CLIENT_SECRET,
    });
  } catch (error: any) {
    return error;
  }
};
