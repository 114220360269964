import React from 'react';

export const PriceSVG = () => (
<svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70">
    <g transform="translate(-1085 -666)">
        <circle data-name="Ellipse 325" cx="35" cy="35" r="35" transform="translate(1085 666)" style={{fill:'#FFC900'}}/>
        <path data-name="Path 979" d="m101.838 399.608.594-1.657-3.171-1.136.586-1.634-1.657-.593-.586 1.633-.17-.06a3.9 3.9 0 0 0-2.632 7.347l.17.06-1.445 4.032-2.933-1.051-.594 1.657 2.932 1.051-.785 2.189 1.657.593.785-2.189.337.122a3.9 3.9 0 0 0 2.632-7.347l-.337-.121 1.445-4.032zm-4.872 4.674a2.141 2.141 0 0 1-1.444 4.032l-.337-.122 1.444-4.031zm-1.4-2.372-.17-.061a2.141 2.141 0 1 1 1.445-4.031l.17.06z" transform="translate(1023.973 299.012)" style={{fill:'#2C3A39'}}/>
        <path data-name="Path 980" d="M100.77 390.971a1.153 1.153 0 1 1-1.262-1.033 1.153 1.153 0 0 1 1.262 1.033" transform="translate(1024.962 298.468)" style={{fill:'#2C3A39'}}/>
        <path data-name="Path 981" d="m101.8 420.883-.479-.16 7.572-22.731-2.567-8.165-7.756-2.583-7.389 4.872-6.908 20.739 16.254 5.415-.635 1.909-17.209-5.732a1.007 1.007 0 0 1-.637-1.273l7.261-21.8a1.72 1.72 0 0 1 .807-.969l7.595-4.985a1.731 1.731 0 0 1 1.391-.12l7.995 2.664a1.722 1.722 0 0 1 1.055.988l2.654 8.381a1.729 1.729 0 0 1 .039 1.2l-7.128 21.4a1.507 1.507 0 0 1-1.906.954" transform="translate(1023.037 297.916)" style={{fill:'#2C3A39'}}/>
    </g>
</svg>
)

