/* eslint-disable no-template-curly-in-string */
import * as Yup from 'yup';

import { REQUIRED_FIELD } from '../helpers/messages';

Yup.setLocale({
  mixed: {
    required: `${REQUIRED_FIELD}`,
  },
  string: {
    email: 'Preencha um e-mail válido',
    min: 'Deve conter no mínimo ${min} letras',
    max: 'Deve conter no máximo ${max} letras',
  },
  number: {
    min: 'Valor mínimo ${min} permitido',
    max: 'Valor máximo ${max} permitido',
  },
});

export default Yup;
