import React from 'react';
import { SVGProps } from './types';

export const CommertialUsageSVG = ({ width, height }: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23.511 32.546"
    width={width}
    height={height}
  >
    <path
      d="M428.494 605.793a1.032 1.032 0 1 0 1.031-1.031 1.031 1.031 0 0 0-1.031 1.031"
      transform="translate(-417.694 -578.777)"      
    />
    <path
      d="M439.855 578.794h-.449v30.54l-19.911.181v-28.938h17.777v-1.8h-18.228a1.351 1.351 0 0 0-1.35 1.35v30.3a.9.9 0 0 0 .9.9h.009l21.252-.193a1.351 1.351 0 0 0 1.35-1.349v-29.641a1.352 1.352 0 0 0-1.35-1.35"
      transform="translate(-417.694 -578.777)"      
    />
    <g>
      <path
        d="M361.8 201.494h-1.648L359.6 200a1.6 1.6 0 0 0-1.453-1.048h-1.235a3.608 3.608 0 0 1-.238.867h1.473a.707.707 0 0 1 .6.472l.915 2.476h-9.522l.455-1.373a3.86 3.86 0 0 1-.715-.7l-.266.8h-1.651a.8.8 0 0 0-.847.79v.816a.8.8 0 0 0 .847.786h.016a1.283 1.283 0 0 0-.333.833v5a.853.853 0 0 0 .92.777h1.758a.81.81 0 0 0 .871-.777v-.546h-.9v.457h-1.745v-2.693h1.98a.793.793 0 0 0 .776-.573l.244-.712.011-.038a.829.829 0 0 0-.166-.731.975.975 0 0 0-.759-.338H348.6a.638.638 0 0 1 .146-.179l.881-.735h10.58l.807.716a.385.385 0 0 1 .111.2h-1.994a.974.974 0 0 0-.759.338.831.831 0 0 0-.166.731l.012.038.244.712a.794.794 0 0 0 .776.573h1.911v2.694H359.5l-.1-.019v-.438h-.9v.546c0 .48.519.777 1 .777h1.758a.75.75 0 0 0 .792-.777v-5a1.368 1.368 0 0 0-.263-.833h.01a.75.75 0 0 0 .782-.786v-.816a.752.752 0 0 0-.782-.79m-11.163 3.919.044.007-.224.653h-1.907v-.66zm-1.478-2.537-.172.144h-.965v-.659h1.308zm11.992 3.2h-1.838l-.223-.652.044-.007h2.017zm.53-3.052h-.832l-.2-.175-.179-.484h1.209z"
        transform="translate(-343.098 -189.708)"        
      />
      <path
        transform="translate(6.195 17.891)"        
        d="M0 0h11.119v.867H0z"
      />
      <path
        d="M359.421 198.735h-.044v.856h.045a.452.452 0 0 0 .314-.125.417.417 0 0 0 0-.606.453.453 0 0 0-.315-.126"
        transform="translate(-349.211 -189.596)"        
      />
      <path
        d="M357.674 196.133a.421.421 0 0 0-.131.3.438.438 0 0 0 .445.429v-.856a.493.493 0 0 0-.316.124"
        transform="translate(-348.297 -188.182)"        
      />
      <path
        d="M355.779 191.817a3.061 3.061 0 1 0 3.184 3.058 3.123 3.123 0 0 0-3.184-3.058m.907 4.385a.932.932 0 0 1-.648.257h-.045v.468h-.474v-.468h-.666V196h.666v-.856a.9.9 0 0 1-.918-.885.861.861 0 0 1 .271-.624.921.921 0 0 1 .651-.257v-.518H356v.518h.723v.455H356v.856h.044a.931.931 0 0 1 .649.26.859.859 0 0 1 0 1.249"
        transform="translate(-345.829 -186.008)"
      />
    </g>
  </svg>
);
