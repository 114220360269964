import React from 'react';

export const CheckSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 16 16"
  >
    <path
      data-name="Rectangle 1816"
      style={{ fill: 'none' }}
      d="M0 0h16v16H0z"
    />
    <g data-name="Group 5229">
      <path
        data-name="Path 56"
        d="M489.671 305.9a7.642 7.642 0 1 1 4.374-13.909l.412.288-.577.825-.412-.288a6.637 6.637 0 1 0 2.838 5.443 6.692 6.692 0 0 0-.155-1.432l-.108-.491.983-.216.108.491a7.648 7.648 0 0 1-7.464 9.29"
        transform="translate(-482.029 -290.615)"
        style={{ fill: '#FFC900' }}
      />
      <path
        data-name="Path 57"
        d="M492.814 302.16a.5.5 0 0 1-.354-.146l-3.477-3.443.707-.715 3.088 3.058 7.549-9.032.771.645-7.9 9.452a.5.5 0 0 1-.363.18h-.023"
        transform="translate(-485.099 -291.175)"
        style={{ fill: '#FFC900' }}
      />
    </g>
  </svg>
);
