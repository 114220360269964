/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import styled from 'styled-components';
import { colors } from 'styles/colors';
import {
  maxContainerResolution,
  mobileMinWidth,
  navbarPaddingReduceWidth,
  tabletMinWidth,
} from 'styles/variables';

interface INavBar {
  pathname: string;
}

export const WrapperMaxContent = styled.div`
  width: 100%;
  display: flex;
  max-width: ${maxContainerResolution};
`;

export const Wrapper = styled.nav<INavBar>`
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
  color: ${({ theme }) => theme.colors?.paletaCores?.secundaria?.escuro };
  width: 100%;
  height: 108px;
  padding: 0 25px;
  display: flex;
  position: ${({ pathname }) => (pathname === '/' ? 'fixed' : 'static')};
  z-index: 101;

  svg {
    cursor: pointer;
    align-self: center;
  }

  @media (max-width: ${mobileMinWidth}) {
    height: 64px;
    padding: 0 16px;
    svg {
      margin-right: 0;
    }
  }
`;

export const NavButtons = styled.ul`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  margin: 0 0 0 60px;
  flex: 0.8;

  li + li {
    margin-left: 3.5rem;
  }

  li:nth-child(2) {
    margin-left: 0;
  }

  @media (max-width: ${tabletMinWidth}) {
    justify-content: flex-end;

    li + li {
      margin-left: 1rem;
    }

    & > li:not(:last-child) {
      a {
        width: 130px;

        span {
          white-space: break-spaces;
        }
      }
    }
  }
`;

export const NavButton = styled.li`
  flex: 1;
  display: flex;
  justify-content: center;
  list-style: none;

  &:last-child {
    justify-content: end;
  }

  & + & {
    padding-left: 1rem;
  }

  span {
    font-weight: 700;
    color: ${({ theme }) => theme.colors?.paletaCores?.secundaria?.escuro };
    font-size: 0.875rem;
    white-space: nowrap;
  }

  & span:hover {
    text-decoration: underline;
  }

  span:focus {
    border: solid 2px ${colors.blue_5};
  }

  a {
    > button {
      width: 200px;

      @media (max-width: ${tabletMinWidth}) {
        width: 150px;
      }
    }
  }
`;

export const MobileView = styled.div`
  display: none;
  @media (max-width: ${tabletMinWidth}) {
    display: block;
  }
`;

export const MenuMobileContainer = styled(MobileView)`
  cursor: pointer;
  display: flex;
  justify-content: end;
  flex: 1;
`;

export const MenuMobile = styled(MobileView)`
  height: 100vh;
  width: 90%;
  position: fixed;
  right: 0;
  top: 0;
  background: ${colors.white};
  z-index: 100;
  font-size: 1.25rem;

  button {
    max-width: 78%;
    margin: 25px auto;
  }
`;

export const AppFade = styled(MobileView)`
  width: 100vw;
  height: 100vh;
  background: ${colors.black};
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0.7;
`;

export const MenuMobileHeader = styled.div`
  height: 65px;
  padding: 10px 15px 0 22px;
  border-bottom: solid 2px ${colors.gray_4};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.colors?.paletaCores?.secundaria?.escuro };
  font-weight: 900;
`;

export const MenuMobileButton = styled.div`
  border-bottom: solid 2px ${colors.gray_4};
  height: 64px;
  display: flex;
  align-items: center;
  padding-left: 22px;

  > a {
    color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
  }
`;

export const IconButton = styled.div``;

export const CompanyLogoPNG = styled.img`
  height: 70px;
  object-fit: contain;

  @media (max-width: ${mobileMinWidth}) {
    height: 30.9px;
  }
`;

export const Header = styled.div`
  display: flex;
`;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
