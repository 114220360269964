/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */

import api from 'api';
import { AxiosResponse } from 'axios';
import { ISalePromotion } from 'types/plan';

import { IBrokerData, IBrokerDataResponse } from '../../types/broker';

export interface IRetrievePromotionListResponse {
  listaPromocaoVendasAutoPorCorretor: {
    broker: IBrokerDataResponse;
    commercialProductCode: Number;
    salesPromotions: Array<ISalePromotion>;
  };
}

export const promotionList = async ({
  brokerUser,
  brokerToken,
  commercialProductCode,
}: IBrokerData): Promise<AxiosResponse<IRetrievePromotionListResponse>> => {
  try {
    const response = await api.post('/quotation/promotion_list', {
      brokerUser,
      brokerToken,
      commercialProductCode,
    });
    return response;
  } catch (error: any) {
    return error;
  }
};
