import React from 'react';
import Button from 'components/Button';

import { useNavigate } from 'react-router-dom';
import * as S from './styles';

const Personalize = () => {
  const navigate = useNavigate();

  return (
    <S.Wrapper>
      <S.Content>
        <S.IconContainer>
          <S.IconAgreementSVG />
        </S.IconContainer>
        <S.Title>
          No Meu Momento Auto, você pode <span>personalizar seu seguro</span>{' '}
          com as coberturas que deseja!
        </S.Title>
        <Button theme="default" onClick={() => navigate('/questionnaire')}>
          Cote o seguro agora
        </Button>
      </S.Content>
    </S.Wrapper>
  );
};

export default Personalize;
