import React from 'react';

import IInputProps from '../@types/IInputProps';
import { ErrorMessage } from '../ErrorMessage';
import * as S from './styles';

const Checkbox = ({
  name,
  label,
  required,
  value,
  checked,
  showError,
  fieldState,
  ...rest
}: IInputProps) => {
  const error = fieldState?.error?.message;

  return (
    <>
      <S.LabelContainer required={required} error={error}>
        {label}
        <S.CheckInput name={name} value={value} checked={checked} {...rest} />
        <span />
      </S.LabelContainer>

      {showError && error && <ErrorMessage message={error} />}
    </>
  );
};

export { Checkbox };
