/* eslint-disable import/no-unresolved */
/* eslint-disable camelcase */
/* eslint-disable import/no-useless-path-segments */

import { AxiosResponse } from 'axios';
import { IVehicleRequest, IVehicleResponse } from 'types/vehicle';

import api from '../../api';

export const vehicle = async ({
  broker: { brokerToken, brokerUser },
  ...data
}: IVehicleRequest): Promise<AxiosResponse<IVehicleResponse>> => {
  const response = await api.post('/customer/vehicle', {
    brokerToken,
    brokerUser,
    ...data,
  });
  return response;
};
