/* eslint-disable no-undef */
/* eslint-disable import/prefer-default-export */
import React from 'react';

export const MailSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <path
        data-name="Rectangle 1946"
        style={{ fill: 'none' }}
        d="M0 0h24v24H0z"
      />
      <path
        data-name="Path 4471"
        d="M637.457 390.694h-22.65a.676.676 0 0 0-.675.675v14.466a.676.676 0 0 0 .675.675h22.65a.676.676 0 0 0 .675-.675v-14.466a.676.676 0 0 0-.675-.675m-.675 2.211v11.637l-5.19-6.3-1.042.859 4.994 6.059h-19.085l5.051-6.129-1.041-.858-4.986 6.049v-11.18l10.583 8.678.064.052zm-20.389-.861h19.314l-9.584 7.978z"
        transform="translate(-614.132 -386.694)"
        style={{ fill: '#565656' }}
      />
    </svg>
  );
};
