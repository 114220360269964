import React from 'react';
import { SVGProps } from './types';

export const HelpSVG = ({ width, height }: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20.052 20.052"
    width={width}
    height={height}
  >
    <g>
      <g>
        <g>
          <path
            d="M10.026 0A10.026 10.026 0 1 1 0 10.026 10.026 10.026 0 0 1 10.026 0z"
            transform="translate(-137.292 -848.289) translate(137.292 848.289)"            
          />
          <text
            fill="#fff"
            fontFamily="Roboto-Medium, Roboto"
            fontSize="16px"
            fontWeight="500"
            transform="translate(-137.292 -848.289) translate(137.292 848.289) translate(6.026 15.658)"
          >
            <tspan x="0" y="0">
              ?
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
);
