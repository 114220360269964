import styled from 'styled-components';

export const Title = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro};
`;

export const Subtitle = styled.div`
  font-size: 1.25rem;
  font-weight: 300;
  height: 1.5rem;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro};
`;

export const Description = styled.div`
  font-size: 1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro};
`;

export const Button = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  margin-top: 25px;
  > button {
    width: 100%;
    max-width: 165px;
  }
`;
