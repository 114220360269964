import React from 'react';

export const AssistancySVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 412.123 347.62">
      <path data-name="Path 5153" d="M188.392 105.209c.8-.7.45-1.645.45-2.474q0-25.345-.056-50.7c0-.8.056-1.617.084-2.418l.394.042c.084.1.155.211.239.309a10.681 10.681 0 0 1 .337 4.006c.028 1.237.534 1.87 1.813 1.87.815 0 1.617.014 2.432.014h136.324c4.807 0 4.175-.042 4.231-4.147a5.274 5.274 0 0 1 .169-1.406 1.676 1.676 0 0 1 .408-.675 3.7 3.7 0 0 1 .394-.028c.211 2.9.028 5.791.028 8.687q0 124.551.014 249.1c0 .689-.323 1.5.45 2.052.52 1.841.2 3.711.2 5.567.014 3.261.183 6.536-.112 9.812a3.734 3.734 0 0 0 .014 2.474 151.6 151.6 0 0 1-.084 16.573c-.534 3.219-.127 6.452-.239 9.671-.028.8-.028 1.617-.014 2.418 0 1.518-.731 2.193-2.235 2.207-2.362.014-4.709 0-7.071.014-5.187 0-10.36 0-15.547.014q-28.887-.021-57.76-.014c-1.125 0-2.305.239-3.331-.464-.886-1.11-.633-2.446-.647-3.7q-.021-32.556-.014-65.112c0-3.88 0-3.88-3.852-3.88h-32.349c-2.854 0-2.938.1-2.938 2.966q-.021 32.956-.014 65.913c0 1.265.225 2.572-.562 3.725a2.874 2.874 0 0 1-2.094.534c-5.454.014-10.922.014-16.376 0a2.9 2.9 0 0 1-1.926-.45c-.717-1.026-.478-2.207-.478-3.332q-.021-38.312-.014-76.638V122.232a11.169 11.169 0 0 0-.211-3.416 4.8 4.8 0 0 1-.141-.759 103.072 103.072 0 0 1 .084-12.848z" transform="translate(-10.452 -11.625)" style={{fill:'#85d1d6'}}/>
      <path data-name="Path 5154" d="M223.985 108.387c.056.183.112.351.169.534.042.872.014 1.757.014 2.629v233.808c0 3.135 0 3.149-3.093 3.149q-8.5.021-16.995 0a7.7 7.7 0 0 1-2.4-.2 1.329 1.329 0 0 1-.619-.492 7.757 7.757 0 0 1-.394-3.584q-.021-27.516-.014-55.033v-1.42c-.028-2.952-.169-3.121-3.064-3.135a62.02 62.02 0 0 0-6.86.169c2.052.183 4.119.112 6.185.155 2.741.07 3.36.647 3.4 3.346.056 3.107.056 6.2 0 9.306-.042 2.685-.52 3.177-3.247 3.191-9.713.042-19.427.014-29.14.014-5.44 0-10.88-.014-16.671.014a5.081 5.081 0 0 0 2.474.3q20.748.021 41.482.014c.872 0 1.757-.014 2.629.056a2.324 2.324 0 0 1 2.418 2.446c.127 3.711.112 7.422 0 11.119a2.036 2.036 0 0 1-2.151 2.179c-.815.056-1.617.056-2.432.056h-65.352c-4.414 0-4.554-.141-4.554-4.526 0-2.769-.042-5.524.014-8.294.056-2.488.534-2.966 2.98-3.036 2.882-.084 5.777.042 8.673-.169-2.8-.239-5.609-.112-8.42-.169-2.727-.056-3.191-.506-3.233-3.191a358.15 358.15 0 0 1 0-9.5c.042-2.615.492-3.093 3.078-3.135 4.245-.07 8.49-.014 12.75-.028 11.934-.014 23.869.014 36.295-.014a5.1 5.1 0 0 0-2.305-.3q-23.363-.021-46.739 0c-.6 0-1.209.014-1.813.056a1.439 1.439 0 0 0-1.532 1.5c-.056.815-.056 1.617-.056 2.432v41.271a11.184 11.184 0 0 1-.239 3.416 1.239 1.239 0 0 1-1.7.647c-3.641-1.082-6.9.014-10.107 1.6-1.532-.169-.843-1.307-.731-2 .267-1.827 1.139-3.514 1.673-5.707a17.955 17.955 0 0 0-4.6 6.48c-.689 1.532-1.237 3.107-1.785 4.7-.225.661-.323 1.476-1.223 1.687-.773-.464-.633-1.237-.7-1.954.267-6.2 1.968-12.075 4.133-17.923a7.346 7.346 0 0 0-3.233 2.825 59.031 59.031 0 0 0-3.346 5.763c-.351.6-.857 2.179-1.926.436a11.483 11.483 0 0 1-.2-3.022q0-60.262.014-120.524c0-1.251-.309-2.586.661-3.669.028-.745.1-1.476.1-2.221v-89.564c0-.675-.028-1.349-.056-2.024.169-.942.984-1.251 1.659-1.645 15.6-8.954 31.164-17.979 46.8-26.891 3.683-2.094 7.324-4.287 11.021-6.382a2.989 2.989 0 0 1 3.36.042C183.825 84.8 202.6 95.679 221.455 106.4a5.859 5.859 0 0 1 2.53 1.987z" transform="translate(-45.624 -1.955)" style={{fill:'#fed007'}}/>
      <path data-name="Path 5155" d="M114.992 173.514c.028.942.1 1.884.1 2.825q0 61.211.014 122.407c0 .942.056 1.884.084 2.825.534 1.6-.52 2.84-1.04 4.161-.323.829-.942.6-1.392.014a7.652 7.652 0 0 1-.857-2.038 36.915 36.915 0 0 0-6.86-11.555 1.519 1.519 0 0 0-1.237-.675c1.968 5.834 3.8 11.611 4.048 17.726-.07.717.07 1.518-.717 1.982-1.139-.2-1.1-1.237-1.378-2.01-1.364-3.753-2.9-7.394-6.115-10.627 1.42 4.99 2.994 9.5 2.938 14.324-.07.717.07 1.518-.731 1.954-.956-.3-.984-1.195-1.209-1.926a22.844 22.844 0 0 0-3.5-6.775 55.646 55.646 0 0 1 1.631 8 9 9 0 0 1-.211 4.568 1.687 1.687 0 0 1-1.645.436c-4.147-.394-8.308-.1-12.454-.169-2.685-.042-5.356 0-8.041-.014a2.717 2.717 0 0 1-2.263-.59c-2.516-4.329-2.516-8.575.506-12.665a10.125 10.125 0 0 1 5.834-3.753c.689-.183 1.195-.323 1.335-1.209a18.349 18.349 0 0 1 2.713-6.874c-.155-.689-.759-.759-1.195-1.026-10.023-6.059-14.24-15.027-13.368-26.553.478-6.34 2.657-12.2 5.173-17.965 1.617-3.7 3.669-7.2 5.159-10.964a53.7 53.7 0 0 0 3.786-18.092 36.286 36.286 0 0 0-1.757-11.541 54.254 54.254 0 0 1-2.362-10.008 30.243 30.243 0 0 1 1.167-12.581 20.219 20.219 0 0 1 3.331-5.96 29.1 29.1 0 0 0 5.51-11.147 23.127 23.127 0 0 0 .576-6.213 22.538 22.538 0 0 1 .984-8.378 11.864 11.864 0 0 1 6.775-7.591 5.587 5.587 0 0 1 7.169 1.9 16.355 16.355 0 0 1 2.249 4.273 59.489 59.489 0 0 1 1.968 8.237 40.462 40.462 0 0 0 5.282 13.267z" transform="translate(-58.434 27.183)" style={{fill:'#1bb798'}}/>
      <path data-name="Path 5156" d="M188.808 22.8a3.965 3.965 0 0 1 1.982-.183q71.205-.021 142.424 0a3.965 3.965 0 0 1 1.982.183c.084 12.272.014 24.557.042 36.829a1.3 1.3 0 0 1-.225.942c-.956.886-2.137.689-3.275.689q-14.043.021-28.1.014-55.391 0-110.782-.014c-1.321 0-2.727.309-3.88-.689a1.269 1.269 0 0 1-.225-.942c.043-12.283-.042-24.554.057-36.829z" transform="translate(-10.207 -22.585)" style={{fill:'#191445'}}/>
      <path data-name="Path 5157" d="M225.85 111.879c-7.127-4.076-14.268-8.125-21.395-12.215Q185.542 88.8 166.643 77.889a2.513 2.513 0 0 0-2.91 0q-29.033 16.763-58.112 33.469c-.52.3-1.012.647-1.518.97-.6.281-1.209.548-1.954.886-1.785-3.093-3.542-6.171-5.3-9.207a3.7 3.7 0 0 1 1.532-1.125q32.577-18.745 65.126-37.518a2.978 2.978 0 0 1 3.444.028q28.339 16.383 56.72 32.654c.576.337 1.181.633 1.771.956a1.9 1.9 0 0 1 .52 1.237c-.056 3.908-.084 7.774-.112 11.64z" transform="translate(-47.489 -5.447)" style={{fill:'#191445'}}/>
      <path data-name="Path 5158" d="M293.165 254.412c-.014-4.625-.028-9.235-.056-13.86a3.74 3.74 0 0 1 .464-1.912c.422-.52.872-1.026 1.251-1.574a5.445 5.445 0 0 0 .97-4.583c-.2-.787-.366-1.574-.492-2.362-.506-3.177 1.082-5.342 4.259-5.862a7.413 7.413 0 0 1 2.994.112 4.07 4.07 0 0 1 3.107 2.854 7.549 7.549 0 0 1 .323 3.585c-.07.534-.155 1.068-.225 1.6a5.711 5.711 0 0 0 2 5.412 10.761 10.761 0 0 1 3.4 5.82 7.857 7.857 0 0 1-1.082 6.551c-.7 1.04-1.6 2.024-1.518 3.43a32.744 32.744 0 0 0 4.161 6.382 10.861 10.861 0 0 1 2.39 8.308 6.136 6.136 0 0 1-3.346 5.173 173.139 173.139 0 0 1-18.119 0 1.427 1.427 0 0 1-.633-.436c.253-3.6.056-7.2.112-10.8.028-2.066.028-4.133.042-6.2a3.819 3.819 0 0 1-.002-1.638z" transform="translate(32.091 59.191)" style={{fill:'#1bb798'}}/>
      <path data-name="Path 5159" d="M439.99 259.2c.014 3.838.014 7.689.028 11.527.014 1.884.014 1.9 1.884 1.9q14.76 0 29.534.014a10.957 10.957 0 0 1 1.8.112.75.75 0 0 1 .59.914c-.056.394-.38.534-.731.548-.675.014-1.349.014-2.024.014H72.712c-3.57 0-7.155-.014-10.725-.028-.323-.745-.337-1.321.689-1.406a3.94 3.94 0 0 1 .408 0c12.215.07 24.417-.394 36.632-.183a2.876 2.876 0 0 1 .59.1 53.513 53.513 0 0 1 7.352-.309c3.528-.211 7.085.225 10.613-.3 2.193-.183 4.386-.056 6.579-.112q19.947 0 39.908-.014c10.543-.155 21.085-.056 31.642-.056 6.044 0 12.089-.127 18.133.07a7.967 7.967 0 0 0 2.882.492v.183c7.689.239 15.392.056 23.081.1 6.733-.028 13.481.127 20.214-.084a6.442 6.442 0 0 1 2.586-.281h35.283a6.442 6.442 0 0 1 2.586.281 9.025 9.025 0 0 0 2.221.084h58.856c24.684-.056 49.354-.618 74.038-.379.8.014 1.631.141 2.263-.534.647-.984.337-2.08.408-3.121.211-3.121-.436-6.269.408-9.348a.5.5 0 0 1 .631-.184z" transform="translate(-61.712 73.391)" style={{fill:'#c0bfc0'}}/>
      <path data-name="Path 5160" d="M341.722 264.909a1.547 1.547 0 0 1-.253.028 9.524 9.524 0 0 1-2.6.281c-9.292-.014-18.583 0-27.875-.014-1.111 0-2.319.253-3.233-.7 4.147-4.5 2.629-9.713-.815-13.8a24.707 24.707 0 0 1-1.982-2.544 4.228 4.228 0 0 1-.9-3.022 12.982 12.982 0 0 1 9.573 2.98c.773.661 1.125.225 1.673-.2a12.365 12.365 0 0 1 11.6-2.235 11.817 11.817 0 0 1 8.35 8.547 2.779 2.779 0 0 0 1.954 2.207 6.488 6.488 0 0 1 4.526 6.466c-.004.657-.018 1.332-.018 2.006z" transform="translate(36.557 67.684)" style={{fill:'#85d1d6'}}/>
      <path data-name="Path 5162" d="M100.525 269.221q-17.965.021-35.929.028c-.534 0-1.082.014-1.617 0-.857-.014-.97.534-.956 1.209-.492-1.406-.436-1.588.815-1.6 2.629-.042 5.257-.014 7.872-.014 1.687 0 3.36.014 5.046 0 .59 0 1.237.155 1.673-.422 2.347.239 4.695.084 7.043.1 4.7.028 9.39 0 14.1.014.647 0 1.349-.2 1.94.267.1.1.141.183.1.281-.012.081-.045.137-.087.137z" transform="translate(-61.735 77.148)" style={{fill:'#d7d7d7'}}/>
      <path data-name="Path 5163" d="M292.8 60.515c.028-.8.1-1.617.1-2.418q0-16.679.014-33.357c0-.675.056-1.335.084-2.01.408.464.211 1.026.211 1.546q.021 17.712.014 35.437c0 .267-.042.534-.056.8l-.183.014z" transform="translate(32.006 -22.526)" style={{fill:'#29a3af'}}/>
      <path data-name="Path 5164" d="M188.868 22.74c.028.675.084 1.335.084 2.01q0 16.679.014 33.357c0 .8.07 1.617.1 2.418l-.183.014-.183-.014c-.014-5.567-.042-11.147-.056-16.714q-.021-9.573.014-19.131c-.001-.647-.226-1.336.21-1.94z" transform="translate(-10.267 -22.522)" style={{fill:'#29a3af'}}/>
      <path data-name="Path 5165" d="M237.145 169.812h2.08a3.525 3.525 0 0 1 2.516-.506q13.832-.021 27.664 0a3.717 3.717 0 0 1 2.53.464c.576 0 1.139 0 1.715.014a2.456 2.456 0 0 1 1.7-.464c5.2.014 10.4-.211 15.6-.042 4.414-.07 8.814-.07 13.228.014a3.531 3.531 0 0 1 2.6.52c1.279.014 2.558.07 3.838.028 1.012-.042 1.434.309 1.434 1.378-.014 1.856.028 1.884-1.856 1.94-1.757.056-1.771.056-1.771 1.8q-.021 12.229 0 24.473c0 1.631.028 1.631 1.687 1.715 1.9.084 1.912.084 1.926 1.94q.021 4.449 0 8.9a10.038 10.038 0 0 1-9.221 10.191 35.262 35.262 0 0 1-4.034.211q-42.466 0-84.932-.014c-2.7 0-5.384-.155-7.731-1.827a9.965 9.965 0 0 1-4.5-8.167c-.1-3.163 0-6.34-.028-9.5-.014-.97.056-1.8 1.335-1.729 1.11.056 1.1-.7 1.1-1.476-.014-4.723 0-9.432 0-14.155 0-3.57-.014-7.141 0-10.711.014-.984-.042-1.771-1.349-1.645-.97.1-1.04-.562-1.054-1.307-.056-1.912-.028-1.954 1.8-2 .337 0 .675-.028 1.012-.042a3.081 3.081 0 0 1 2.277-.576c9.517-.014 19.047.112 28.564.084a2.534 2.534 0 0 1 1.87.489z" transform="translate(-5.002 36.901)" style={{fill:'#09748c'}}/>
      <path data-name="Path 5166" d="M237.172 93.844c.689 0 1.364 0 2.052.014a3.436 3.436 0 0 1 2.5-.534q13.853-.021 27.706 0a3.6 3.6 0 0 1 2.516.492c.562 0 1.125 0 1.687.014a3.33 3.33 0 0 1 2.305-.506c9.32-.126 18.654-.1 27.973-.084.984 0 2.038-.155 2.868.619 1.279.014 2.558.07 3.838.014 1.04-.042 1.42.337 1.42 1.392-.014 1.827.014 1.87-1.884 1.94-1.729.056-1.757.056-1.757 1.827-.014 8.026 0 16.039 0 24.065 0 1.588-.38 2.123 1.94 2.08 1.631-.028 1.659.07 1.673 1.757.014 2.9-.1 5.806.028 8.7a10.313 10.313 0 0 1-10.262 10.739c-.408.014-.815 0-1.209 0h-87.56c-3.374 0-6.382-.787-8.743-3.4a9.785 9.785 0 0 1-2.643-6.4c-.1-3.3 0-6.607-.042-9.91-.014-.97.239-1.588 1.335-1.532s1.11-.689 1.11-1.476c-.014-4.175 0-8.364 0-12.539 0-4.119-.028-8.223.014-12.342.014-1.012-.127-1.743-1.392-1.617-.647.056-.942-.253-1.026-.942-.239-2-.028-2.277 2.024-2.376a3.315 3.315 0 0 0 .394-.056 2.983 2.983 0 0 1 2.08-.548c2.938-.014 5.862-.07 8.8.028 4.048-.07 8.083-.1 12.131.014 2.615.042 5.229 0 7.844.028a3.008 3.008 0 0 1 2.28.539z" transform="translate(-5.015 6.065)" style={{fill:'#09748c'}}/>
      <path data-name="Path 5167" d="M203.356 289.767c-.028-.8-.056-1.617-.056-2.418v-69.16c.042-1.5.042-1.532 1.6-1.546q18.6-.021 37.209 0c1.574 0 1.617.028 1.617 1.518q0 35.592-.028 71.184c0 .141-.014.267-.014.408-.1.084-.2.183-.281.267-13.115.07-26.244.014-39.359.028a.736.736 0 0 1-.688-.281z" transform="translate(-4.303 56.138)" style={{fill:'#191445'}}/>
      <path data-name="Path 5168" d="M236.449 179.892q-16.362 0-32.739.014a3.065 3.065 0 0 1-.408-2.137v-31.361c0-.337.014-.675.014-1.012 0-.647.281-1.012.97-1.012.337 0 .675-.014 1.012-.014h29.14c.408 0 .815 0 1.209.014a.8.8 0 0 1 .886.872c.014.408.014.815.028 1.209 0 2.094.042 4.189 0 6.269-.2 8.223.464 16.433.281 24.656-.014.7.281 1.49-.351 2.109a3.09 3.09 0 0 1-.534-2.291c-.014-2.08 0-4.161-.014-6.255-.014-2.193 0-2.179-1.855-1.181-5.538 3.022-10.978 6.241-16.615 9.207q8.181-.021 16.292 0a3.734 3.734 0 0 1 2.7.534 3.567 3.567 0 0 0-.016.379z" transform="translate(-4.306 26.822)" style={{fill:'#f1f1f1'}}/>
      <path data-name="Path 5169" d="M188.941 49.622c.6-.014 1.209-.042 1.813-.042h142.382c.6 0 1.209.028 1.813.042.014 1.884.014 3.767.056 5.665.028.942-.351 1.378-1.307 1.307-.464-.028-.942 0-1.42 0h-140.7c-2.966 0-2.7.267-2.713-2.713.035-1.433.062-2.839.076-4.259z" transform="translate(-10.158 -11.633)" style={{fill:'#29a3af'}}/>
      <path data-name="Path 5170" d="m214.17 103.908-10.838-.042-.042-33.751c0-1.715.028-1.743 1.856-1.743 7.816-.014 15.631 0 23.447 0 2.094 0 4.175-.014 6.269 0 1.631.014 1.659.042 1.673 1.743.014 5.665-.127 11.316.042 16.967.141 4.779.3 9.545.239 14.324-.014.717.253 1.49-.337 2.123l-.211-.211c-.647-2.249-.155-4.54-.309-6.818a13.742 13.742 0 0 0-.239-3.627c-3.88 2.263-7.97 3.964-11.456 6.733-1.054.169-1.068-.562-1.04-1.265a5.635 5.635 0 0 0-.169-2 3.662 3.662 0 0 0-3.781-2.685 3.577 3.577 0 0 0-3.163 3.627c.224 2.548-1.351 4.417-1.941 6.625z" transform="translate(-4.307 -4.012)" style={{fill:'#f1f1f1'}}/>
      <path data-name="Path 5171" d="M263.437 216.956c.014 4.85.042 9.685.042 14.535q.021 12.82 0 25.64c0 .408.014.8 0 1.209-.056 1.378-.084 1.392-1.546 1.448h-20.4c-1.982 0-2-.014-2-2.01.014-4.976.042-9.952.056-14.942.38-1.926 2-3.008 3.163-4.316 4.737-5.3 9.362-10.711 14.155-15.969 1.448-1.588 2.84-3.233 4.287-4.807.654-.661 1.217-1.322 2.243-.788z" transform="translate(10.399 56.19)" style={{fill:'#fff'}}/>
      <path data-name="Path 5172" d="M259.71 256.634q0-19.841.014-39.669a1.7 1.7 0 0 1 1.167-.2c6.944 0 13.888.028 20.818-.028 1.293-.014 1.757.3 1.715 1.673-.1 3.5-.1 7.014 0 10.515a5.443 5.443 0 0 1-1.532 4.02c-5.735 6.34-11.386 12.764-17.079 19.145a40.118 40.118 0 0 1-3.078 3.486c-.577.524-1.055 1.283-2.025 1.058z" transform="translate(18.582 56.182)" style={{fill:'#fff'}}/>
      <path data-name="Path 5173" d="M261.192 103.888c-2.221.028-4.442.07-6.663.07q-13.031 0-26.061-.042a1.6 1.6 0 0 1-.028-.365c.914-.787 2.038-.59 3.092-.59 8.631-.014 17.262 0 25.893-.014 2.713 0 2.811-.1 2.811-2.868 0-8.026.014-16.053 0-24.065 0-2.235-.126-2.319-2.095-1.237-8.926 4.906-17.74 9.994-26.68 14.858a4.141 4.141 0 0 0-2.446 4.358c.127 2.348.042 4.723.028 7.085a3.4 3.4 0 0 1-.59 2.488c-.576-.548-.351-1.265-.351-1.912q-.021-15.456 0-30.925c0-.408 0-.815.014-1.209a1.063 1.063 0 0 1 1.153-1.125c.408-.014.815-.014 1.209-.014h28.493c.408 0 .815.014 1.209 0 .787-.014 1.082.408 1.054 1.139v.408l-.042 33.96z" transform="translate(5.741 -4.006)" style={{fill:'#f1f1f1'}}/>
      <path data-name="Path 5174" d="M261.184 179.861c-2.629.028-5.243.07-7.872.07-8.279 0-16.559-.028-24.839-.028a2.823 2.823 0 0 1-.014-.351c.857-.731 1.912-.576 2.9-.576 8.631-.014 17.262 0 25.893-.014 2.924 0 2.98-.056 2.98-2.9 0-8.026.014-16.039 0-24.065 0-2.207-.211-2.305-2.109-1.265-8.786 4.864-17.5 9.868-26.329 14.633-2.024 1.1-2.98 2.446-2.8 4.793.183 2.277.056 4.583.028 6.874a3.445 3.445 0 0 1-.576 2.5c-.562-.464-.365-1.125-.365-1.7q-.021-15.666 0-31.319c0-2.08.056-2.137 2.165-2.137h29.1c1.813 0 1.856.028 1.856 1.532.024 11.308-.002 22.624-.018 33.953z" transform="translate(5.736 26.824)" style={{fill:'#f1f1f1'}}/>
      <path data-name="Path 5175" d="M259.716 256.692a15.624 15.624 0 0 0 3.374-3.43c.97-1.364 2.235-2.488 3.317-3.767 2.629-3.135 5.5-6.059 8.209-9.109 2.671-3.008 5.257-6.073 7.914-9.081a2.786 2.786 0 0 0 .689-2.024c-.014-3.5 0-7-.014-10.5-.014-1.715-.042-1.743-1.87-1.743-7.2-.014-14.394 0-21.605 0 .436-.633 1.1-.394 1.673-.408q10.205-.021 20.4 0c1.771 0 1.8.028 1.8 1.8q0 19.694-.014 39.373c0 1.982-.07 2.052-2.038 2.066-6.522.014-13.059 0-19.581 0-2.263 0-2.277-.014-2.263-2.362a7.831 7.831 0 0 1 .009-.815z" transform="translate(18.576 56.124)" style={{fill:'#f5f5f5'}}/>
      <path data-name="Path 5176" d="M263.429 217.014a1.69 1.69 0 0 0-1.448.534c-3.008 3.528-6.143 6.93-9.207 10.4-3.121 3.514-6.213 7.042-9.32 10.571a49.518 49.518 0 0 0-3.837 4.386c-.014-8.279-.014-16.573-.056-24.853 0-1.012.323-1.434 1.392-1.434 7.211.028 14.422.014 21.634.014a.793.793 0 0 1 .842.382z" transform="translate(10.407 56.133)" style={{fill:'#f5f5f5'}}/>
      <path data-name="Path 5177" d="M286 103.922q-16.573-.021-33.146-.028-.021-11.323-.056-22.646 0-5.356.014-10.725c0-2.123.014-2.137 2.08-2.137q12.736-.021 25.485-.014c1.42 0 2.825-.014 4.245 0 1.743.014 1.757.028 1.757 1.841.014 5.735 0 11.456 0 17.192 0 4.723 0 9.432-.014 14.155a3.908 3.908 0 0 1-.365 2.362z" transform="translate(15.779 -4.012)" style={{fill:'#f1f1f1'}}/>
      <path data-name="Path 5178" d="M285.988 179.9c-.857-.014-1.7-.042-2.558-.056a3.918 3.918 0 0 1-2.868-3.022 4.938 4.938 0 0 0-5.553-3.585 4.711 4.711 0 0 0-4.273 4.723c-.042.661.042 1.364-.52 1.87-5.777.155-11.569.127-17.346.028-.028-1.082-.07-2.151-.07-3.233q0-15.16.014-30.335c0-1.9.028-1.926 1.884-1.926q14.971-.021 29.927 0c1.743 0 1.771.028 1.771 1.827v31.755a3.352 3.352 0 0 1-.408 1.954z" transform="translate(15.779 26.815)" style={{fill:'#f1f1f1'}}/>
      <path data-name="Path 5179" d="M195.557 338.093c-7.042 0-14.085.014-21.127 0-.647 0-1.35.183-1.94-.3a6.479 6.479 0 0 1 2.207-.141c6.2-.014 12.412 0 18.611-.014 1.884 0 1.884-.014 1.9-1.87V101.3c0-.872.07-1.743.112-2.615a4.562 4.562 0 0 1 .2 2.376q.021 116.792.014 233.583c0 1.012.028 2.024.042 3.036.127.1.169.2.127.281s-.103.132-.146.132z" transform="translate(-16.802 8.29)" style={{fill:'#d7d4c8'}}/>
      <path data-name="Path 5180" d="M352.889 272.692c-.661.731-1.518.394-2.277.394q-33.947.021-67.909 0h-7.071q-.232-.105-.169-.253c.042-.1.084-.141.126-.141l21.142-.042c.464 0 .942.014 1.406 0 1.673-.042 1.7-.042 1.715-1.687.014-3.36 0-6.719.014-10.065a3.384 3.384 0 0 1 .239-1.968.363.363 0 0 1 .183.112c.745.759.6 1.743.618 2.671.042 2.685.056 5.384 0 8.069-.042 1.659.647 2.263 2.263 2.263 5.51-.028 11.035-.014 16.545-.014q14.928 0 29.871.014c1.14 0 2.334-.183 3.304.647z" transform="translate(24.968 73.298)" style={{fill:'#888b9b'}}/>
      <path data-name="Path 5181" d="M293.235 268.757c0 .126.014.253.028.394h-61.134a.314.314 0 0 1-.169-.309c.028-.112.056-.183.084-.183v-.028c.942.028 1.884.084 2.825.084q29.184.041 58.366.042z" transform="translate(7.323 77.233)" style={{fill:'#d0d4d5'}}/>
      <path data-name="Path 5182" d="M188.9 269.142c0-.141.014-.281.014-.422q10.142-.042 20.284-.07v.028a.291.291 0 0 1-.056.464z" transform="translate(-10.145 77.241)" style={{fill:'#d0d4d5'}}/>
      <path data-name="Path 5183" d="M229.823 93.977c-3.374.014-6.747.014-10.121.028a.482.482 0 0 1 .239-.647c3.3-.492 6.607-.857 9.868.225l.042.042c-.014.113-.014.225-.028.352z" transform="translate(2.334 5.933)" style={{fill:'#f1f1f1'}}/>
      <path data-name="Path 5184" d="M293.248 245.68a1.393 1.393 0 0 1 0 1.631.972.972 0 0 1 0-1.631z" transform="translate(32.008 67.922)" style={{fill:'#b1e1e4'}}/>
      <path data-name="Path 5185" d="M153.059 223.235q-16.679.021-33.371.028c-.548-.576-.267-1.279-.281-1.926-.028-6.2-.014-12.412-.014-18.611a2.945 2.945 0 0 1 1.223-2.418q14.928-14.928 29.857-29.843c.675-.675 1.335-1.378 2.432-1.153 1 .59.9 1.6.9 2.516q.063 24.5.014 49a2.782 2.782 0 0 1-.76 2.407z" transform="translate(-38.362 36.923)" style={{fill:'#fff'}}/>
      <path data-name="Path 5186" d="M152.739 168.994c-.675.028-1.335.1-2.01.1h-28.873c-.478 0-.942-.028-1.42 0-.815.056-1.04-.394-1.026-1.11q.042-10.1.1-20.186a.984.984 0 0 1 .042-.2 10.409 10.409 0 0 1 1.8-2.418q14.022-14 28.044-28.029a10.929 10.929 0 0 1 1.335-1.223 1.92 1.92 0 0 1 1.7-.45c.787.267.492 1.026.492 1.574q.021 25.387.014 50.774a1.7 1.7 0 0 1-.198 1.168z" transform="translate(-38.337 15.074)" style={{fill:'#fff'}}/>
      <path data-name="Path 5187" d="M181.354 142.169v24.459c0 .478-.028.942 0 1.42.042.773-.323 1.11-1.082 1.068-.408-.028-.815 0-1.209 0h-29.309c-.408 0-.815-.014-1.209 0-.787.042-1.082-.323-1.054-1.082.028-.942 0-1.884 0-2.825v-48.118c.028-1.743.028-1.757 1.8-1.771h30.321c1.743 0 1.771.028 1.771 1.785q-.021 12.525-.028 25.063z" transform="translate(-26.945 15.037)" style={{fill:'#f5f5f5'}}/>
      <path data-name="Path 5188" d="M181.623 169.486q-.021 9.4-.056 18.808-.021 16.172 0 32.359c0 .478-.014.942 0 1.42.028.773-.365 1.1-1.11 1.082-.534-.014-1.082 0-1.617 0h-28.522c-.745 0-1.476.042-2.221.07-1.392-.7-1.11-2.052-1.11-3.2-.042-6.916-.014-13.846-.014-20.776 0-8.8-.014-17.613.014-26.413 0-1.125-.169-2.305.534-3.317.7-.422.8.211.928.6a9.046 9.046 0 0 1 .169 2.615q0 21.528.014 43.056a4.066 4.066 0 0 0 .2 2.165c.647-.183.984-.731 1.406-1.139 9.488-9.474 18.949-18.977 28.479-28.423a6.053 6.053 0 0 0 2-4.822c-.112-3.838-.042-7.675-.028-11.527a4.587 4.587 0 0 1 .394-2.558c.146-.211.343-.211.54 0z" transform="translate(-27.158 36.947)" style={{fill:'#f5f5f5'}}/>
      <path data-name="Path 5189" d="M194.567 287.09c-.956 1.223-1.054 1.223-1.968 0-.647-2.868-.211-5.763-.281-8.645-.1-4.667.422-4.386-4.372-4.386-22.575-.028-45.137.028-67.712-.028a1.824 1.824 0 0 1-2.151-1.04v-46.542c0-.534-.028-1.082 0-1.617.07-1.307.267-1.5 1.546-1.588.408-.028.815 0 1.209 0h49.368c.6 0 1.209.014 1.813.056a.643.643 0 0 1 .6.675c.028.464-.169.8-.661.829-.6.042-1.209.042-1.813.042H122.2c-2.685 0-2.516-.155-2.516 2.53 0 3.5-.014 7.014.014 10.515.014 1.434.084 1.49 1.49 1.5 2.966.028 5.932.014 8.9.014h1.42c.59.014 1.111.112 1.054.871-.042.633-.506.717-1.012.731-1.153.014-2.291.014-3.444.014-2.362 0-4.723.028-7.085-.014-.914-.014-1.364.3-1.349 1.265.028 4.048.014 8.1 0 12.145 0 .886.436 1.195 1.251 1.167.478-.014.942 0 1.42 0h67.979c2.8 0 2.572.211 2.572-2.657v-9.517c0-2.348-.042-2.39-2.376-2.39H145.4c-.6 0-1.209 0-1.827-.014-.351-.014-.689-.155-.731-.548a.761.761 0 0 1 .6-.914 11.977 11.977 0 0 1 2.01-.126h37.434c2.839 0 5.665.014 8.5-.014 1.434-.014 1.5-.084 1.5-1.49.028-3.781.028-7.549 0-11.33-.014-1.5-.239-1.715-1.813-1.729-2.362-.028-4.723 0-7.085-.014a11.761 11.761 0 0 1-1.406-.07c-.394-.056-.534-.365-.534-.731a.659.659 0 0 1 .534-.731 5.959 5.959 0 0 1 1-.07c3.036 0 6.073-.028 9.109 0 1.574.014 1.757.225 1.813 1.729.028.534 0 1.082 0 1.617q.07 30.262.063 60.505z" transform="translate(-38.88 58.815)" style={{fill:'#dbb327'}}/>
      <path data-name="Path 5190" d="M152.727 169.031q0-25.281-.028-50.563c0-.886.548-2.151-.422-2.6-1.026-.478-1.56.815-2.179 1.434q-14.486 14.422-28.915 28.873a6.336 6.336 0 0 1-1.659 1.462c-.028-.745-.084-1.476-.084-2.221v-28.1c.014-1.982.014-2 1.982-2h29.927c1.771 0 1.785.014 1.785 1.757q0 25.281-.014 50.549c.014.478.296 1.1-.393 1.409z" transform="translate(-38.324 15.037)" style={{fill:'#f5f5f5'}}/>
      <path data-name="Path 5191" d="M106.666 276.918a1.527 1.527 0 0 1-1.307.38l-9.263-.042c-.984-.52-2.643-.239-2.53-2.094-.267-1.476.731-2.91.183-4.4a43.437 43.437 0 0 0-2.783-12.918c-.141-.38-.323-.745-.45-1.125-.084-.253-.169-.534.07-.745.323-.281.647-.112.9.112a16.884 16.884 0 0 1 2.586 3.064 32.22 32.22 0 0 1 4.034 8.729c.141.45.3.886.45 1.321a3.877 3.877 0 0 1 .773 2.291c-.07.619.337 1.251.127 1.856a10.161 10.161 0 0 0-.436-3.486 6.567 6.567 0 0 0-.042-2.769 56.516 56.516 0 0 0-3.753-16.236c-.183-.506-.394-1-.59-1.5a.551.551 0 0 1 .211-.7.679.679 0 0 1 .759 0 7.157 7.157 0 0 1 1.645 1.476 37.049 37.049 0 0 1 6.958 11.962 4.858 4.858 0 0 0 1.026 2.151c.562-1.6 1.082-3.079 1.588-4.569 1.026-1.884 2-3.8 3.064-5.637a21.205 21.205 0 0 1 3.753-4.7 3.862 3.862 0 0 1 .928-.745c.548-.225.97.028.872.647a6.242 6.242 0 0 1-.478 1.321 57.732 57.732 0 0 0-3.95 16.953c-.422 1.364.155 2.769-.155 3.908.028.492.464.253.2.3-.056.014-.07-.028-.1-.1a2.051 2.051 0 0 1 .45-2.024 46.005 46.005 0 0 1 2.938-7.7 21.047 21.047 0 0 1 3.556-5.1c.408-.408.815-1.209 1.42-.872.675.38.07 1.054-.112 1.574a40.378 40.378 0 0 0-1.8 5.96c-.056.323-.253.633.056.928a.566.566 0 0 1-.07.562c-3.388 3.092-4.5 7.028-4.217 11.47a.632.632 0 0 1-.562.253c-1.957.16-3.967-.234-5.949.244z" transform="translate(-50.094 69.072)" style={{fill:'#cceaee'}}/>
      <path data-name="Path 5192" d="M106.683 272.611c-1.04-3.2-.028-6.073 1.448-8.856a9.738 9.738 0 0 1 2.966-3.233c3.627-2.713 7.591-2.98 11.794-1.673a4.422 4.422 0 0 0 1.631.8c2.109.436 3.247 2.094 4.3 3.683a3.315 3.315 0 0 0 3.036 1.771 17.52 17.52 0 0 0 5.117-.478c5.159-1.378 9.741 2 9.868 7.028-.2 1.378-1.251 1.167-2.179 1.167h-23.657c-4.1 0-8.223.014-12.328-.014a3.571 3.571 0 0 1-1.996-.195z" transform="translate(-43.673 72.957)" style={{fill:'#fff'}}/>
      <path data-name="Path 5193" d="m192.881 169.587-.394-.042a3.786 3.786 0 0 1-2.685.647q-13.916.021-27.833 0a3.682 3.682 0 0 1-2.671-.647c-.6.45-.337 1.111-.337 1.673q-.021 25.113 0 50.225c0 .661-.169 1.363.408 1.9-2.08 0-4.175-.014-6.255-.014.042-.675.112-1.335.112-2.01v-49.8c0-.408-.014-.8 0-1.209.014-.9-.52-.956-1.209-.787a5.324 5.324 0 0 1-3.3.675h-25.474c-2.868 0-2.938.07-2.938 2.938q-.021 12.736 0 25.471a5.3 5.3 0 0 1-.675 3.3 24.235 24.235 0 0 0-.112 4.4c-.028 4.948-.014 9.9 0 14.858a4.61 4.61 0 0 0 .239 2.179c-.8-.52-.478-1.335-.478-2.038-.028-4.779-.014-9.573-.014-14.366v-35.61c.014-2.207.014-2.221 2.207-2.221q34.685 0 69.371.014c.674.014 1.475-.281 2.038.464z" transform="translate(-38.416 36.845)" style={{fill:'#fed007'}}/>
      <path data-name="Path 5194" d="M105.606 264.12c-.155.815-.309 1.645-.464 2.46a7.577 7.577 0 0 1 .056-4.54c.225.675-.238 1.49.408 2.08z" transform="translate(-44.254 74.56)" style={{fill:'#f5d532'}}/>
      <path data-name="Path 5195" d="M90.875 272.313a45.363 45.363 0 0 0-2.305-13.593c3.261 2.572 4.484 6.213 5.51 9.966a4.668 4.668 0 0 1 .548 2.713c-.477 1.561-1.518 1.814-3.753.914z" transform="translate(-50.848 73.213)" style={{fill:'#cae9ed'}}/>
      <path data-name="Path 5196" d="M90.571 268.765c.843.014 1.687 0 2.53.056.942.056 1.04-.548 1.068-1.237.112-.169.225-.155.337.014 0 .731.2 1.293 1.1 1.237.506-.028.9.141.928.731l-7.211.042v-.422c.477.014.967.099 1.248-.421z" transform="translate(-50.544 76.76)" style={{fill:'#72d0c2'}}/>
      <path data-name="Path 5197" d="M96.646 261.74c.562.9.267 1.94.422 2.91.014.576.633 1.307-.436 1.588l-.422-2.39c.675-.604.225-1.419.436-2.108z" transform="translate(-47.748 74.438)" style={{fill:'#34bea4'}}/>
      <path data-name="Path 5198" d="M93.241 268.866h-.169l-.169-.014c-.014-.829-.534-1.588-.408-2.446.633-.59.169-1.392.422-2.066.605 1.476.31 3.008.324 4.526z" transform="translate(-49.263 75.493)" style={{fill:'#4cc5b0'}}/>
      <path data-name="Path 5199" d="M345.737 272.712q-25.324 0-50.647.014c-.2 0-.408-.014-.6 0-1.026.112-1.42-.281-1.406-1.349.056-4.1.042-8.209.056-12.314q7.021-.042 14.043-.1c1.673-.014 3.346-.056 5.018-.084.8.028 1.6.1 2.4.1q14.486 0 28.985.014c.773 0 1.617-.281 2.319.337-.028 3.6-.056 7.183-.1 10.782.003.871-.04 1.742-.068 2.6z" transform="translate(32.12 73.278)" style={{fill:'#191445'}}/>
      <path data-name="Path 5200" d="M141.141 270.6c-.492-4.554-5.791-7.717-10.641-5.384a2.489 2.489 0 0 1-2.263.239 2.73 2.73 0 0 0-1.771-.253c-1.729.52-2.5-.3-3.2-1.715-.872-1.785-2.488-2.952-4.02-4.161.534-.6 1.251-.379 1.9-.379q34.664-.021 69.315 0c1.982 0 2 .014 2 2.01.014 3.233 0 6.466.014 9.7a3.959 3.959 0 0 1-.351 1.968q-20.01.021-40.034.028c-3.5 0-7.014.014-10.515.014-.659-.567-.013-1.429-.434-2.067z" transform="translate(-38.405 73.29)" style={{fill:'#fed007'}}/>
      <path data-name="Path 5201" d="M146.326 267.23c.52.6.183 1.392.436 2.066l-33.1.042c-2.348 0-4.709-.014-7.057-.028-.127-.07-.169-.155-.127-.225s.084-.112.127-.112l.014-.07h37.954c1.486.014 1.486-.014 1.753-1.673z" transform="translate(-43.591 76.665)" style={{fill:'#fff0b1'}}/>
      <path data-name="Path 5202" d="M203.29 269.138c.014-.155.042-.309.056-.464q20.179 0 40.343-.014c.028.155.056.323.07.478z" transform="translate(-4.307 77.245)" style={{fill:'#c3c6ca'}}/>
      <path data-name="Path 5203" d="M108.408 268.5a1.354 1.354 0 0 1 0 .337c-2.151 0-4.287.014-6.438.014.309-.464.8-.408 1.251-.408a270.56 270.56 0 0 1 5.187.057z" transform="translate(-45.412 77.155)" style={{fill:'#e5e5aa'}}/>
      <path data-name="Path 5204" d="M211.729 186.142c0-4.175-.014-8.35 0-12.525 0-1.532.042-1.546 1.588-1.574 1.813-.014 3.641-.014 5.454 0 1.645.014 1.673.028 1.673 1.673.014 7.281 0 14.549 0 21.83 0 1.139-.028 2.291 0 3.43.028.787-.337 1.082-1.082 1.082-2.094-.014-4.175-.042-6.269.028-1.068.028-1.42-.351-1.392-1.406.056-2.488.014-4.99.014-7.478.014-1.702.014-3.373.014-5.06z" transform="translate(-.896 38.044)" style={{fill:'#f7f8f8'}}/>
      <path data-name="Path 5205" d="M204.458 186.018c0-4.048-.014-8.083 0-12.131 0-1.813.028-1.813 1.912-1.827 1.757-.014 3.5.028 5.257-.028 1-.042 1.448.225 1.448 1.335q-.042 12.736 0 25.485c0 1.012-.422 1.265-1.321 1.237a125.465 125.465 0 0 0-6.073 0c-1 .028-1.251-.394-1.251-1.321.042-4.259.014-8.5.028-12.75z" transform="translate(-3.845 38.042)" style={{fill:'#f8f8f8'}}/>
      <path data-name="Path 5206" d="M219.171 198.238c-.028-3.275-.07-6.551-.084-9.84-.014-4.681-.014-9.376 0-14.057a5.982 5.982 0 0 1 .127-2c2.4-.436 4.821-.084 7.225-.2.829-.042 1.012.408 1.012 1.125-.014 6.593 0 13.2-.014 19.792a1.346 1.346 0 0 1-.365 1.125c-2.419 1.764-4.781 3.647-7.901 4.055z" transform="translate(2.092 38.08)" style={{fill:'#f8f8f8'}}/>
      <path data-name="Path 5207" d="M249.523 176.426c.028 1.687.1 3.36.1 5.046q.021 8.5 0 16.995c0 1.56-.028 1.574-1.546 1.588-1.884.028-3.781-.028-5.665.014-1.012.028-1.518-.281-1.406-1.378a19.42 19.42 0 0 0 .014-2.024c0-7.689-.014-15.364 0-23.053 0-1.546.028-1.574 1.546-1.588q2.931-.021 5.862 0c.38 0 .829-.155 1.054.337-1.6 1.026-3.388.436-5.089.534-2.249.126-2.418.183-2.39 2.418.014 1.392-.633 3.486.281 3.992 1.139.647 2.474-1.1 3.781-1.659 1.11-.462 2.035-1.548 3.458-1.222z" transform="translate(10.987 38.033)" style={{fill:'#f1f1f1'}}/>
      <path data-name="Path 5208" d="M275.722 199.911c-1.49.633-3.05.281-4.583.3-.619.014-.942-.351-.914-1.054.028-.731-.1-1.462-.1-2.207q-.021-11.576-.014-23.166c0-.576-.211-1.223.281-1.729.056.042.141.084.141.126.38 2.755 2.221 3.135 4.526 2.84a2.943 2.943 0 0 1 2.094.464 1.819 1.819 0 0 1 .281 2.39 7.151 7.151 0 0 0-.675 3.7c0 5.089.014 10.177-.014 15.266.017 1.13.2 2.353-1.023 3.07z" transform="translate(22.784 38.051)" style={{fill:'#393356'}}/>
      <path data-name="Path 5209" d="M233.885 172.382c.183-.38.534-.337.857-.337 2.094 0 4.175.042 6.269-.014 1.026-.028 1.434.3 1.406 1.364-.056 3.163-.028 6.326-.028 9.5 0 5.187.014 10.374 0 15.561 0 1.574-.028 1.6-1.532 1.617-1.813.028-3.641.014-5.454 0-1.659-.014-1.673-.028-1.687-1.673q-.042-9.488-.07-18.991c0-1.813-.014-3.641 0-5.454.014-.548-.141-1.111.239-1.574a3.332 3.332 0 0 1 .576 2.488c.014 2.952-.028 5.9.014 8.87.028 1.757.337 1.912 1.884 1.082a2.318 2.318 0 0 0 .351-.211c1.434-1.082 3.739-1.237 4.358-3.036.8-2.348.281-4.92.253-7.394-.014-.97-.745-1.167-1.546-1.195-1.209-.042-2.418 0-3.627-.028a3.176 3.176 0 0 1-2.263-.575z" transform="translate(8 38.043)" style={{fill:'#f1f1f1'}}/>
      <path data-name="Path 5210" d="M270.58 174.657a31.057 31.057 0 0 1 .112 4.428q.021 9.172 0 18.372c0 1.743-.576 2.207-2.165 1.926a1.673 1.673 0 0 1-1.462-1.855 512.84 512.84 0 0 0-.112-4.639c-.8-1.307-.45-2.755-.464-4.133-.042-3.556.1-7.113-.084-10.669a2.229 2.229 0 0 0-.365-.576 2.881 2.881 0 0 1-.492-.914 1.783 1.783 0 0 1 .857-2.123 5.441 5.441 0 0 1 4.175.183z" transform="translate(20.925 38.903)" style={{fill:'#393456'}}/>
      <path data-name="Path 5211" d="M255.739 180.872c-.028-2.558-.042-5.117-.1-7.675-.014-.872.309-1.195 1.181-1.167 2.081.042 4.175.042 6.269 0 .872-.014 1.181.323 1.167 1.181-.042 3.36-.056 6.733-.07 10.093-.225.787-.309 2.01-1.56 1.195-2.192-1.434-4.681-2.277-6.887-3.627z" transform="translate(16.931 38.043)" style={{fill:'#f1f1f1'}}/>
      <path data-name="Path 5212" d="M255.741 178.32c2.5.323 4.554 1.673 6.635 2.952 1.04.647 1.04.647 1.8-.52.014 3.022.014 6.044.084 9.067.028 1.082-.422 1.364-1.434 1.364a30.981 30.981 0 0 0-5.82.225c.52-1.125.52-1.125 2.151-.59a8.987 8.987 0 0 1-3.43-6.016 52.459 52.459 0 0 1 .014-6.482z" transform="translate(16.928 40.595)" style={{fill:'#bce0e5'}}/>
      <path data-name="Path 5213" d="M267.017 177.636a.433.433 0 0 1 .408.351c-.169 1.237.394 2.474.056 3.528.506-.956-.492-2.263.267-3.374.38.183.365.52.365.857-.028 4.92-.07 9.84-.1 14.76-1.349 0-2.7-.028-4.034-.014-.773.014-1.082-.337-1.068-1.1.028-1.349.014-2.7 0-4.034-.014-4.85-.042-9.685-.042-14.535 0-2.109.014-2.109 2.094-2.066a5.2 5.2 0 0 1 .8.1.468.468 0 0 1-.183.506 2.074 2.074 0 0 0-1.785 2.572c.1 1.054 0 2.123.028 3.374 1.015-.701 1.858-1.909 3.194-.925z" transform="translate(19.864 38.032)" style={{fill:'#bbdfe4'}}/>
      <path data-name="Path 5214" d="M248.571 199.949c-.548-.394-.351-.984-.351-1.49q-.021-12.314-.014-24.642c0-1.841.028-1.841 1.94-1.856 1.813-.014 3.627 0 5.454.014 1.448.014 1.532.042 1.532 1.434q.021 12.736.014 25.457c0 .7.028 1.406-.97 1.406-2.418-.014-4.836-.042-7.267-.07-.112 0-.211-.155-.323-.225a2.726 2.726 0 0 1 2.066-.661c1.293 0 1.785-.661 1.771-1.9-.056-7.942.169-15.884-.126-23.826a1.89 1.89 0 0 0-2.53-.394c-1 .534-.492 1.673-.534 2.572.07 7.113.028 14.226.028 21.324a3.826 3.826 0 0 1-.69 2.857z" transform="translate(13.906 38.013)" style={{fill:'#8ad3d7'}}/>
      <path data-name="Path 5215" d="M265.228 171.7a19.544 19.544 0 0 1-.126-2.01 5.478 5.478 0 0 1 4.554-5.173c2.727-.422 5.51.872 6.171 3.374a7.328 7.328 0 0 0 2.615 3.809q-6.621.207-13.214 0z" transform="translate(20.767 34.962)" style={{fill:'#fbd108'}}/>
      <path data-name="Path 5216" d="M274.09 199.907a5.459 5.459 0 0 0 .436-2.755V179.96c0-.843-.155-1.673.787-2.305.787-.534.365-1.321-.042-1.968a4.944 4.944 0 0 1 1-3.57c.45-.056 1-.225 1.068.492.042.464.028.942.028 1.406v23.967c0 .337-.028.675 0 1.012.056.787-.309 1.1-1.068 1.068a8.986 8.986 0 0 1-2.209-.155z" transform="translate(24.416 38.055)" style={{fill:'#eeefef'}}/>
      <path data-name="Path 5217" d="M234.9 199.966c-1.687.661-3.444.267-5.159.267-1.04 0-2.4.45-3.107-.253-.787-.8-.267-2.179-.3-3.3a20.353 20.353 0 0 1 .1-4.034c.759.07.52.661.534 1.068.028 1.813.028 3.641.056 5.454-.351-8.462-.211-16.91-.239-25.373a2.259 2.259 0 0 0-.309-1.349.519.519 0 0 1 .576-.436c2.291-.014 4.583.014 6.874-.056 1.068-.028 1.392.408 1.392 1.434-.028 8.49-.014 16.995-.028 25.485 0 .394.07.829-.351 1.082a3 3 0 0 1-.675-2.235c0-3.121-.084-6.241.042-9.362-.07-3.95-.014-7.9-.028-11.85-.014-3.613-.745-4.147-4.344-3.107-.661.576-.52 1.349-.534 2.066q-.042 10.669.07 21.352c.028 2.263.169 2.39 2.39 2.474 1.032.04 2.14-.143 3.04.673z" transform="translate(4.987 38.011)" style={{fill:'#8cd3d8'}}/>
      <path data-name="Path 5218" d="M249.4 176.328c-2.586 1.406-5.159 2.825-7.956 4.358a65.226 65.226 0 0 1-.1-7.563c.014-.872.731-.829 1.335-.829 2.221-.014 4.456 0 6.677-.014a13.468 13.468 0 0 1 .044 4.048z" transform="translate(11.107 38.145)" style={{fill:'#f7f8f8'}}/>
      <path data-name="Path 5219" d="M219.142 198.282c2.348-1.307 4.667-2.643 7.028-3.908a1.775 1.775 0 0 0 1.054-1.757c-.014-6.255 0-12.511-.014-18.766 0-1.364-.084-1.42-1.56-1.434-2.151-.028-4.3-.014-6.452-.014.281-.59.815-.337 1.251-.337 1.954-.028 3.908.042 5.862-.028 1.068-.042 1.392.365 1.378 1.392-.042 3.711-.014 7.422-.014 11.133q0 6.979-.014 13.958c0 1.518-.028 1.546-1.574 1.56-1.827.014-3.641.014-5.468 0-1.505-.042-1.505-.056-1.477-1.799z" transform="translate(2.121 38.036)" style={{fill:'#f1f1f1'}}/>
      <path data-name="Path 5220" d="M277.816 172.132a5.749 5.749 0 0 1-1 3.57c-1.715-.042-3.444-.127-5.159-.084-1.012.014-1.5-.281-1.406-1.349.07-.731.056-1.448.084-2.179 2.505-.056 4.993-.141 7.481.042z" transform="translate(22.854 38.04)" style={{fill:'#f8cf09'}}/>
      <path data-name="Path 5221" d="M255.726 182.94c.675 1.279 1.307 2.572 2.01 3.838a4.6 4.6 0 0 0 1.715 1.926c-.787.661-1.982-.281-2.46.843a30.932 30.932 0 0 0 .745 5.735c-.351.408-.773.323-1.167.337-.7.042-.942-.309-.928-.97.014-1.209-.014-2.432 0-3.641.028-2.684.059-5.383.085-8.068z" transform="translate(16.93 42.469)" style={{fill:'#eeeff0'}}/>
      <path data-name="Path 5222" d="M264.91 172.4a.506.506 0 0 0 .1-.309c1.617-.014 3.219-.028 4.836-.056.759-.014 1.11.309 1.082 1.068-.028.8-.028 1.617-.042 2.418-1.406.042-2.811.07-4.2.112-1.481-.595-2.338-1.522-1.776-3.233z" transform="translate(20.621 38.043)" style={{fill:'#f4ce0c'}}/>
      <path data-name="Path 5223" d="M226.388 172.3c.351.028.689.127.745.534a11.454 11.454 0 0 1 .042 1.209q0 12.04-.014 24.079a1.8 1.8 0 0 1-.365 1.49c-.253-1.743-.056-3.36-.141-4.948-.042-.717.211-1.49-.3-2.151q.026-10.12.033-20.213z" transform="translate(5.052 38.153)" style={{fill:'#f7f7f7'}}/>
      <path data-name="Path 5224" d="M271.676 110.1c0 4.119.014 8.223 0 12.342 0 1.631-.028 1.645-1.715 1.673-1.884.014-3.781-.028-5.665.014-1.026.028-1.42-.323-1.406-1.392.056-3.444.028-6.874.028-10.318 0-4.92-.014-9.84 0-14.774 0-1.532.028-1.546 1.588-1.56 1.884-.014 3.781-.028 5.665 0 1.448.014 1.49.042 1.49 1.476.029 4.175.015 8.349.015 12.539z" transform="translate(19.872 7.227)" style={{fill:'#f0f1f1'}}/>
      <path data-name="Path 5225" d="M278.917 110.2c0 4.1.014 8.223 0 12.328 0 1.462-.042 1.49-1.434 1.518-1.954.028-3.908-.042-5.862.028-1.054.028-1.406-.38-1.392-1.406.042-2.769.014-5.524.014-8.294 0-5.524-.014-11.049 0-16.573 0-1.687.028-1.7 1.659-1.715 1.884-.014 3.781.042 5.665-.028 1.082-.042 1.378.38 1.363 1.42-.03 4.234-.013 8.479-.013 12.722z" transform="translate(22.85 7.222)" style={{fill:'#f0f1f1'}}/>
      <path data-name="Path 5226" d="M255.642 110.13c0-4.1-.014-8.209 0-12.314 0-1.715.028-1.729 1.8-1.743s3.5-.014 5.257 0c1.5.014 1.532.042 1.532 1.6.014 4.3 0 8.617 0 12.918v11.513c0 1.926-.014 1.926-2.01 1.94-1.687 0-3.36.014-5.046 0-1.49-.014-1.518-.042-1.532-1.6-.015-4.105-.001-8.209-.001-12.314z" transform="translate(16.929 7.224)" style={{fill:'#f1f1f1'}}/>
      <path data-name="Path 5227" d="M249.523 100.408c.028 3.767.07 7.535.1 11.3q.021 5.356 0 10.7c-.014 1.617-.028 1.631-1.7 1.645-1.884.014-3.767-.028-5.651.014-.97.028-1.265-.365-1.265-1.307q.042-12.714-.014-25.443c0-.984.351-1.293 1.307-1.265 2.151.042 4.3.014 6.466.014a.716.716 0 0 1 .689.3c-1.617 1.026-3.416.422-5.131.534-2.151.127-2.333.211-2.333 2.319a21.4 21.4 0 0 0 .155 4.512c1.757-.773 3.219-1.715 4.779-2.488.812-.413 1.557-1.102 2.598-.835z" transform="translate(10.987 7.219)" style={{fill:'#f1f1f1'}}/>
      <path data-name="Path 5228" d="M219.172 122.242c-.112-5.356-.2-10.7.042-16.053 1.56-2.53 2.854-5.131 2.038-8.265a1.757 1.757 0 0 1 .239-1.532c1.631-.534 3.317-.127 4.976-.239.829-.056.984.45.984 1.153-.014 6.579 0 13.143-.014 19.722a1.988 1.988 0 0 1-.815 1.757 39.117 39.117 0 0 1-6.508 3.6.925.925 0 0 1-.942-.143z" transform="translate(2.091 7.243)" style={{fill:'#f8f8f8'}}/>
      <path data-name="Path 5229" d="M207.857 123.616c-.857.815-1.9.365-2.854.394-.394.014-.534-.337-.534-.7-.014-.408-.014-.8-.014-1.209V98.5a8.1 8.1 0 0 0 0-1.012c-.112-1.04.3-1.434 1.364-1.406 2.08.056 4.175-.014 6.255-.028.6.464.422 1.1.267 1.645-.661 2.362-.1 4.6.576 6.846a25.667 25.667 0 0 1 .042 5.595c-1.406.337-2.376-.717-3.556-1.125-2.207-.773-2.643-.45-2.277 1.912a18.022 18.022 0 0 0 2.474 6.986c.309.492.815.942.52 1.631-.759.8-2.305-.155-2.727 1.04-.323.914.562 1.841.534 2.839a1.1 1.1 0 0 1-.07.193z" transform="translate(-3.842 7.219)" style={{fill:'#f7f8f8'}}/>
      <path data-name="Path 5230" d="M233.894 96.372c.267-.422.717-.3 1.1-.309q2.425-.021 4.85 0c2.825.014 2.53-.267 2.53 2.432q0 9.088-.014 18.19c0 1.954.014 3.908 0 5.862-.014 1.462-.042 1.49-1.434 1.5-2.024.028-4.034-.014-6.059.014-.872.014-1.195-.337-1.181-1.195.07-3.964-.127-7.942.112-11.906.6-1.588 2.221-1.855 3.458-2.586 4.1-2.4 4.133-2.376 4.119-7.155s.436-4.231-4.4-4.3c-1.029-.013-2.167.269-3.081-.547z" transform="translate(8.019 7.22)" style={{fill:'#f1f1f1'}}/>
      <path data-name="Path 5231" d="M211.923 118.043c-.408-3.149-.112-6.312-.169-9.474a8.353 8.353 0 0 1 1.617-3.725 22.558 22.558 0 0 1 3.177-4.1c1.6-1.518 2.9-1.082 3.4 1.1.3 1.237.056 2.53.365 3.767a54.789 54.789 0 0 1-.211 6.789c-.3 2.319-.984 4.414-3.022 5.834-1.727.329-3.47.61-5.157-.191z" transform="translate(-.894 8.757)" style={{fill:'#fbce08'}}/>
      <path data-name="Path 5232" d="M233.915 96.284c2.207 0 4.428.028 6.635-.014 1.012-.014 1.462.323 1.434 1.392-.042 2.418-.056 4.822.014 7.239a2.13 2.13 0 0 1-1.349 2.193c-2.305 1.209-4.554 2.516-6.832 3.781a33.957 33.957 0 0 1-.155-5.609 249.551 249.551 0 0 1 0-7.239c.014-.59-.197-1.227.253-1.743z" transform="translate(7.998 7.308)" style={{fill:'#f5f7f7'}}/>
      <path data-name="Path 5233" d="m223.247 96.421-.042.394q-6.094-.021-12.173-.028a5.333 5.333 0 0 1 1.209-4.147 1.357 1.357 0 0 0 .253-1.476 3.878 3.878 0 0 1 1.687-4.414 3.8 3.8 0 0 1 4.554-.07 3.951 3.951 0 0 1 1.827 4.358c-.127.661-.267 1.223.633 1.335a3.1 3.1 0 0 1 2.052 4.048z" transform="translate(-1.169 3.123)" style={{fill:'#1db092'}}/>
      <path data-name="Path 5234" d="M220.1 106.972c-.239-1.307-.436-2.629-.745-3.922-.351-1.49-1.139-1.729-2.207-.661a48.694 48.694 0 0 0-3.627 4.526 1.347 1.347 0 0 1-1.841.014c-.014-3.022-.042-6.03-.028-9.053.014-1.673.2-1.841 1.856-1.87a140.21 140.21 0 0 1 5.426 0c1.307.028 1.56.253 1.574 1.532.014 3.149-.042 6.3-.056 9.446a.235.235 0 0 1-.352-.012z" transform="translate(-.917 7.191)" style={{fill:'#1daf92'}}/>
      <path data-name="Path 5235" d="M248.558 123.948c-.534-.394-.337-.984-.337-1.49q-.021-12.314-.014-24.628c0-1.8.028-1.813 1.771-1.827q2.825-.021 5.651 0c1.42.014 1.5.07 1.5 1.462.028 3.5.014 7 .014 10.5 0 4.92.014 9.826 0 14.746 0 1.49-.141 1.687-1.617 1.546-2.024-.2-4.02.169-6.03.028a1.2 1.2 0 0 1-.914-.309c.956-1.04 2.46-.267 3.472-1.082a2.45 2.45 0 0 0 .436-1.912V99.573a2.532 2.532 0 0 0-.534-2.08c-2.193-.689-2.685-.394-2.7 1.855-.042 7.183 0 14.366-.028 21.549.004 1.041.172 2.166-.67 3.051z" transform="translate(13.906 7.196)" style={{fill:'#8ad2d7'}}/>
      <path data-name="Path 5236" d="M234.354 123.826a23.434 23.434 0 0 1-6.115-.028 3.7 3.7 0 0 1-.647-2.671q-.021-11.112 0-22.21a3.346 3.346 0 0 1 .661-2.657c1.378.3.914 1.462.942 2.263.084 4.076.028 8.167.042 12.244 0 .534 0 1.068.014 1.6.014 1.153.492 1.42 1.5.857a11.447 11.447 0 0 1 2.7-1.321.8.8 0 0 1 1.04.351c-.052 3.854.187 7.72-.137 11.572z" transform="translate(5.548 7.304)" style={{fill:'#f1f1f1'}}/>
      <path data-name="Path 5237" d="M229.183 96.486c-.675.59-.351 1.392-.351 2.08-.028 7.717-.014 15.42-.014 23.138 0 .731-.028 1.476-.042 2.207-2.347.38-2.544 1-2.488-2.094a42.835 42.835 0 0 1 .112-5.2c.872.127.52.829.52 1.279.042 1.743.014 3.486.07 5.243-.337-8.251-.225-16.489-.253-24.74a3.348 3.348 0 0 0-.3-1.954c.07-.183.169-.38.394-.394 2.418-.028 4.836-.028 7.253-.07.886-.014 1.167.422 1.153 1.237-.014 2.08 0 4.175 0 6.255 0 6.115.014 12.244-.028 18.358 0 .7.309 1.49-.337 2.094-.028-3.922-.07-7.844-.1-11.766a3.977 3.977 0 0 1-.576-2.7c-.014-3.36.014-6.719-.014-10.079-.028-2.123-.2-2.277-2.305-2.362a4.329 4.329 0 0 1-2.694-.532z" transform="translate(5.013 7.191)" style={{fill:'#8cd3d8'}}/>
      <path data-name="Path 5238" d="M209.477 116.035a18.557 18.557 0 0 1-3.514-8.7 8.585 8.585 0 0 1-.112-1.209c-.042-1.56.492-1.968 1.94-1.49a39.682 39.682 0 0 1 4.6 2.08c.169 3.135.155 6.269.014 9.418a2.366 2.366 0 0 1-2.928-.099z" transform="translate(-3.269 10.625)" style={{fill:'#fcce09'}}/>
      <path data-name="Path 5239" d="M249.4 100.318c-2.572 1.42-5.145 2.825-8.055 4.428-.028-1.841-.07-3.4-.056-4.976.042-4.062-.548-3.43 3.57-3.486 1.476-.014 2.966-.014 4.442-.014a11.531 11.531 0 0 1 .099 4.048z" transform="translate(11.107 7.308)" style={{fill:'#f7f7f7'}}/>
      <path data-name="Path 5240" d="M219.146 122.263c2.474-1.054 4.709-2.558 7.085-3.781a1.9 1.9 0 0 0 1-1.856c-.014-6.269-.028-12.539 0-18.808 0-1.012-.337-1.56-1.392-1.42-1.434.2-2.952-.492-4.3.436-.562-.1-.745-.309-.253-.759 1.687 0 3.36.056 5.032-.014 1.082-.042 1.378.38 1.363 1.406-.042 3.556-.014 7.127-.014 10.683 0 4.765 0 9.545-.014 14.31 0 1.631-.028 1.645-1.687 1.659-1.743.014-3.5.014-5.243 0-1.647-.029-1.647-.029-1.577-1.856z" transform="translate(2.117 7.222)" style={{fill:'#edefef'}}/>
      <path data-name="Path 5241" d="M211.947 112.856a614.8 614.8 0 0 0 5.257-.141c.112 0 .211-.014.323-.014 2.572-.2 2.671-.127 2.8 1.87-.239 1.012-.042 2.263-1.462 2.615-1.968.6-3.992.267-5.988.323-.7.014-1.181-.323-1.181-1.068.026-1.21-.241-2.433.251-3.585z" transform="translate(-.917 13.944)" style={{fill:'#1a1747'}}/>
      <path data-name="Path 5242" d="M209.4 112.69c.97.028 1.94.07 2.91.1 0 1.195 0 2.39.028 3.585.014.745-.323 1.1-1.068 1.1-1.378-.014-2.8.211-4.062-.619a6.951 6.951 0 0 1-.492-.633 3.63 3.63 0 0 1-.576-2.882c.38-1.139 1.645-.323 2.488-.548.241-.061.522-.061.772-.103z" transform="translate(-3.195 13.97)" style={{fill:'#1c1746'}}/>
      <path data-name="Path 5243" d="M221.281 96.069c0 .281-.042.576.253.759a15.6 15.6 0 0 1 .506 3.528 7.265 7.265 0 0 1-2.839 5.862c-.028-2.741-.056-5.482-.07-8.209-.014-2.038 0-2.038 2.15-1.94z" transform="translate(2.118 7.214)" style={{fill:'#1eb093'}}/>
      <path data-name="Path 5244" d="M226.412 96.31c.351.042.689.127.731.548.042.464.042.942.042 1.42q0 11.723-.028 23.447a2.327 2.327 0 0 1-.492 2c0-1.884.028-3.486-.014-5.075-.014-.717.225-1.49-.281-2.151q0-10.099.042-20.189z" transform="translate(5.056 7.325)" style={{fill:'#f7f7f7'}}/>
      <path data-name="Path 5245" d="M218.5 103.8a.967.967 0 0 1 .351.028v12.4a5.232 5.232 0 0 1-3.078.127l-.169-.056a15.047 15.047 0 0 0 2.9-9.868c-.022-.872-.004-1.744-.004-2.631z" transform="translate(.687 10.362)" style={{fill:'#92cece'}}/>
      <path data-name="Path 5246" d="M210.878 104.542a7.39 7.39 0 0 1-1.167-6.579c.141-.633.59-1.209.323-1.912.942-.07.886.619.886 1.223 0 2.418-.028 4.836-.042 7.268z" transform="translate(-1.816 7.218)" style={{fill:'#2ab498'}}/>
      <path data-name="Path 5247" d="M211.7 103.784c.618 0 1.223-.014 1.841-.014-.59 1.068-1.167 2.137-1.757 3.2a13.5 13.5 0 0 1-.084-3.186z" transform="translate(-.92 10.351)" style={{fill:'#8ec6bf'}}/>
      <path data-name="Path 5248" d="M216.78 116.6a8.4 8.4 0 0 0 1.279-2.432c.127-.183.253-.183.365.014a15.241 15.241 0 0 1 .028 1.6c-.07 1.213-.152 1.241-1.672.818z" transform="translate(1.166 14.515)" style={{fill:'#6cafbc'}}/>
      <path data-name="Path 5249" d="m218.855 114.734-.183-.014h-.183c-.112-1.631-.112-1.631-1.631-1.715-.394-.028-.815.07-1.139-.239l3.078-.127c.03.704.044 1.393.058 2.095z" transform="translate(.736 13.949)" style={{fill:'#5895a7'}}/>
      <path data-name="Path 5250" d="M232.688 172.778H212.53c3.078-2.376 6.536-4.048 9.868-5.946 3.3-1.884 6.635-3.739 10.3-5.791v11.723z" transform="translate(-.559 33.585)" style={{fill:'#f7f8f8'}}/>
      <path data-name="Path 5251" d="M220.345 96.767a5.62 5.62 0 0 0-2.052-4.048c-.2-.408-.155-.647.267-.984a26.828 26.828 0 0 1 3.3-1.912c2.7-1.6 5.482-3.05 8.533-4.723v11.625z" transform="translate(1.733 2.777)" style={{fill:'#f8f8f8'}}/>
      <path data-name="Path 5252" d="M228.334 102.243c0-3.711.056-7.408-.014-11.119a2.585 2.585 0 0 1 1.588-2.671c7.408-4.091 14.76-8.28 22.14-12.426a670.264 670.264 0 0 1 7.408-4.1c.928-.506 1.195-.365 1.265.661.028.534.014 1.082.014 1.617v25.269c0 .478-.028.942 0 1.42.056.914-.281 1.42-1.265 1.349-.464-.028-.942 0-1.42 0q-14.865-.021-29.716 0z" transform="translate(5.847 -2.685)" style={{fill:'#f8f8f8'}}/>
      <path data-name="Path 5253" d="M228.349 178.217c0-3.641.042-7.267-.028-10.908a2.7 2.7 0 0 1 1.588-2.882c4.512-2.376 8.926-4.934 13.382-7.436q7.633-4.3 15.252-8.631a1.161 1.161 0 0 0 .183-.1c1.8-1.012 1.982-.914 2 1.11.014 6.059 0 12.117 0 18.19v8.49c0 2.165-.014 2.165-2.263 2.165q-15.055.021-30.11 0z" transform="translate(5.847 28.145)" style={{fill:'#f8f8f8'}}/>
      <path data-name="Path 5254" d="M253.1 87.236c0-4.793.028-9.573-.014-14.366a2.692 2.692 0 0 1 1.434-2.586 10.465 10.465 0 0 1 6.283-1.673c7.478.084 14.971.028 22.463.028.534 0 1.082.014 1.617.014a.876.876 0 0 1 1 .984c0 .337.014.675.014 1.012 0 5.328-.028 10.655.014 15.983a2.477 2.477 0 0 1-1.42 2.586c-4.569 2.446-9.053 5.018-13.579 7.549-3.528 1.968-7.071 3.894-10.557 5.932a4.916 4.916 0 0 1-2.839.759c-.942-.042-1.884.014-2.825-.014-1.532-.042-1.574-.056-1.574-1.631-.027-4.864-.017-9.713-.017-14.577z" transform="translate(15.896 -3.913)" style={{fill:'#f8f8f8'}}/>
      <path data-name="Path 5255" d="M253.109 163.2c0-4.723.056-9.446-.028-14.155a2.86 2.86 0 0 1 1.617-2.924 10.027 10.027 0 0 1 5.131-1.5c7.816.1 15.645.042 23.461.042.534 0 1.082.014 1.617.014a.876.876 0 0 1 .984 1c0 .337.014.675.014 1.012 0 5.328-.042 10.655.028 15.983a2.586 2.586 0 0 1-1.616 2.657c-5.257 2.868-10.43 5.862-15.645 8.786-2.811 1.574-5.679 3.064-8.476 4.667-2.038 1.167-4.217.562-6.34.619-.717.014-.717-.675-.731-1.223-.014-1.813 0-3.641 0-5.454-.031-3.181-.016-6.358-.016-9.524z" transform="translate(15.892 26.923)" style={{fill:'#f8f8f8'}}/>
      <path data-name="Path 5256" d="M119.526 201.81c-.028-2.629-.07-5.243-.084-7.872-.014-7.408 0-14.816 0-22.224 0-.478.014-.942 0-1.42-.014-.675.3-1 .97-.97.267.014.534-.014.8-.014H150.9c.337 0 .675.056 1 .084a4.419 4.419 0 0 1-1.167 1.378l-29.857 29.857a3.912 3.912 0 0 1-1.35 1.181z" transform="translate(-38.326 36.94)" style={{fill:'#f5f5f5'}}/>
      <path data-name="Path 5257" d="M147.73 165.366v-47.358c0-2.572-.141-2.446 2.39-2.446h28.493c.337 0 .675.028 1.012 0 .97-.07 1.307.394 1.293 1.321-.028 2.7-.014 5.384-.014 8.083 0 2.094-.042 4.175.014 6.269a3.113 3.113 0 0 1-.984 2.516q-15.6 15.54-31.164 31.136a1.057 1.057 0 0 1-1.04.479z" transform="translate(-26.848 15.132)" style={{fill:'#fff'}}/>
      <path data-name="Path 5258" d="M147.73 169.444a15.147 15.147 0 0 1 1.588-.141q15.013-.021 30.011 0a3.789 3.789 0 0 1 1.588.155c0 5.187-.028 10.388.014 15.575a3.409 3.409 0 0 1-1.054 2.685Q164.4 203.131 148.953 218.6a1.644 1.644 0 0 1-1.223.7v-49.856z" transform="translate(-26.847 36.933)" style={{fill:'#fff'}}/>
      <path data-name="Path 5259" d="M155.818 247.421h33.961c.478 0 .942.014 1.42 0 .8-.028 1.265.253 1.265 1.153-.014 4.1-.014 8.223 0 12.328a.957.957 0 0 1-1.11 1.1c-.478-.014-.942 0-1.42 0h-68.12c-2.8 0-2.572.211-2.572-2.657 0-3.3-.014-6.607 0-9.91 0-1.982.014-2 2.024-2q17.269-.042 34.552-.014z" transform="translate(-38.407 68.627)" style={{fill:'#fed007'}}/>
      <path data-name="Path 5260" d="M96.52 265.4c.576-.408.239-1.068.436-1.588a5.389 5.389 0 0 1 .028 3.669 64.724 64.724 0 0 1-.464-2.081z" transform="translate(-47.623 75.278)" style={{fill:'#87d6cd'}}/>
      <path data-name="Path 5261" d="M233.81 172.282c2.221 0 4.442.028 6.663 0 1.012-.014 1.462.323 1.448 1.392a266.3 266.3 0 0 0 0 7.478 1.508 1.508 0 0 1-.8 1.49 77.7 77.7 0 0 1-7.085 3.852 24.651 24.651 0 0 1-.2-4.709c-.042-1.617 0-3.233-.014-4.85-.014-1.56-.014-3.107-.014-4.653z" transform="translate(8.074 38.143)" style={{fill:'#f5f7f7'}}/>
      <path data-name="Path 5262" d="M265.542 172.29a5.345 5.345 0 0 0 1.771 3.219 1.84 1.84 0 0 0-.422 2.01c-1.8-.365-2.488 1.139-3.711 2.558v-6.8a.862.862 0 0 1 .97-.984z" transform="translate(19.99 38.149)" style={{fill:'#eceeee'}}/>
      <path data-name="Path 5263" d="M266.557 176.411v3.964c-.52-1.139-.337-2.348-.731-3.472-.1-.267.183-.464.408-.633a.337.337 0 0 1 .323.141z" transform="translate(21.055 39.763)" style={{fill:'#d8e9eb'}}/>
      <path data-name="Path 5264" d="M265.98 184.49c.056-.689.112-1.378.155-2.08a1.713 1.713 0 0 1-.155 2.08z" transform="translate(21.126 42.254)" style={{fill:'#d8e9eb'}}/>
      <path data-name="Path 5265" d="m248.46 199.744.042-21.9c0-.942.028-1.87.042-2.811 1.068-1.335 2.643-1.856 4.091-2.572a2.76 2.76 0 0 1 .323 1.94q.021 11.681 0 23.377c0 1.9-.014 1.9-1.855 1.926-.872.014-1.743.042-2.615.07z" transform="translate(14.018 38.218)" style={{fill:'#eff0f0'}}/>
      <path data-name="Path 5266" d="M252.8 185.835v-11.513a11.756 11.756 0 0 1 .056-1.406c.056-.506.422-.647.857-.647.478 0 1-.028 1.1.619a12.34 12.34 0 0 1 .07 1.617v23.025c0 .478 0 .942-.014 1.42-.014.745-.506.843-1.111.829-.548-.014-.914-.2-.928-.8-.014-.478-.014-.942-.014-1.42q-.023-5.859-.016-11.724z" transform="translate(15.779 38.141)" style={{fill:'#edefef'}}/>
      <path data-name="Path 5267" d="M252.615 172.551c-1.012 1.42-2.685 1.8-4.091 2.572-.155-3.374-.141-2.825 2.783-2.882a1.687 1.687 0 0 1 1.308.31z" transform="translate(14.023 38.127)" style={{fill:'#eff3f4'}}/>
      <path data-name="Path 5268" d="M228.407 172.322c1.406-.028 2.811-.1 4.217-.07s1.448.056 1.448 1.518c0 4.765-.028 9.531-.042 14.282-1.11 1.588-2.882 2.165-4.526 2.91-.773.351-1.307.042-1.293-.9.111-5.904-.254-11.822.196-17.74z" transform="translate(5.789 38.131)" style={{fill:'#f5f7f7'}}/>
      <path data-name="Path 5269" d="M228.461 172.3c.042 6.115-.084 12.244.084 18.583 2.024-1.04 3.781-1.94 5.538-2.84l.084 11.766-.014.014c-1.546-.028-3.093-.084-4.639-.056-1.012.028-1.476-.309-1.434-1.378.056-1.476.014-2.952.014-4.428 0-6.522-.014-13.031.014-19.553.001-.702-.294-1.489.353-2.108z" transform="translate(5.735 38.153)" style={{fill:'#f1f1f1'}}/>
      <path data-name="Path 5270" d="M248.45 123.836c.014-8.251.014-16.5.042-24.754.014-3.107-.478-2.783 2.98-2.811a1.665 1.665 0 0 1 1.153.267c.309 5.44.112 10.88.112 16.32a90.2 90.2 0 0 1-.169 10.852c-1.364.042-2.713.1-4.077.141a.142.142 0 0 1-.041-.015z" transform="translate(14.014 7.308)" style={{fill:'#eff0f0'}}/>
      <path data-name="Path 5271" d="M254.876 110.032v11.709a11.756 11.756 0 0 1-.056 1.406c-.084.647-.618.6-1.082.6-.422 0-.829-.1-.872-.619s-.056-1.068-.056-1.617V98.505c0-.478 0-.942.014-1.42.014-.59.365-.815.928-.815.59-.014 1.1.1 1.11.829.014.464.014.942.014 1.42z" transform="translate(15.783 7.308)" style={{fill:'#edefef'}}/>
      <path data-name="Path 5272" d="M251.37 123.636V98.868c0-.8.042-1.6.056-2.418a2.346 2.346 0 0 1 .337 1.729v24c.001.501.211 1.106-.393 1.457z" transform="translate(15.198 7.381)" style={{fill:'#ebf1f2'}}/>
      <path data-name="Path 5273" d="M228.342 96.373c1.476-.028 2.938-.042 4.414-.112.97-.042 1.279.408 1.265 1.321-.042 4.822-.042 9.643-.07 14.479a34.017 34.017 0 0 0-4.217 2.305c-1.082.675-1.392.492-1.392-.815-.014-5.721 0-11.457 0-17.178z" transform="translate(5.854 7.303)" style={{fill:'#f5f7f7'}}/>
  </svg>
)
