import styled from 'styled-components';
import { mobileMinWidth } from 'styles/variables';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  > svg {
    align-self: center;
    margin: 24px 0 35px;
  }

  @media (max-width: ${mobileMinWidth}) {
    margin-top: 64px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  span {
    margin: 68px auto 32px auto;
    font-size: 2rem;
    line-height: 38.4px;
    text-align: center;
    color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro};

    @media (max-width: ${mobileMinWidth}) {
      margin: 32px auto 28px auto;
    }
  }

  div {
    min-width: 120px;
  }

  @media (max-width: ${mobileMinWidth}) {
    flex-direction: column;
  }
`;

export const BackButton = styled.div`
  div {
    height: 50px;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  @media (max-width: ${mobileMinWidth}) {
    width: 100%;
    > div {
      justify-content: flex-start;
    }
  }
`;
export const Message = styled.div`
  align-self: center;
  max-width: 460px;
  width: 100%;
  font-size: 0.938rem;
  font-size: 400;
  line-height: 1.5rem;
  margin: 6px;
  text-align: center;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro};

  @media (max-width: ${mobileMinWidth}) {
    margin: 6px 0;
  }
`;

export const CorretorContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 336px;
  align-self: center;

  > div {
    padding: 32px;
    margin-bottom: 50px;
  }
`;

export const CompanyArea = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  h1 {
    font-size: 1.5rem;
  }
  button {
    margin-top: 16px;
    width: 250px;
  }
`;

export default CorretorContainer;
