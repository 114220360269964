/* eslint-disable import/no-unresolved */
/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import { mobileMinWidth } from 'styles/variables';
import { colors } from 'styles/colors';

interface ITooltip {
  tooltipWidth?: string;
}

interface ContainerProps {
  variant: string;
}

export const Container = styled.div`
  width: 100%;
`;

export const Button = styled.button<ContainerProps>`
font-weight: 700;
font-size: 1rem;
height: 48px;
border: 0;
border-radius: 3px;
padding: 15px;
text-align: center;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
cursor: pointer;
position: relative;

${({ variant }) =>
  variant === 'default'
    ? css`
        background-color: ${({ theme }) =>
          theme.colors?.paletaCores?.primaria?.escuro ||
          theme.colors.yellow_3};
        border: solid 2px
          ${({ theme }) =>
            theme.colors?.paletaCores?.primaria?.escuro ||
            theme.colors.yellow_3};
        color: ${({ theme }) =>
          theme.colors?.paletaCores?.primaria?.contrastante ||
          theme.colors.black};
        a {
          color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro};
        }
        &:hover {
          background: ${({ theme }) =>
            theme.colors?.paletaCores?.secundaria?.escuro ||
            theme.colors.blue_4};
          color: ${({ theme }) =>
            theme.colors?.paletaCores?.secundaria?.contrastante ||
            theme.colors.white};
          border: solid 2px
            ${({ theme }) =>
              theme.colors?.paletaCores?.secundaria?.escuro ||
              theme.colors.blue_4};
          a {
            color: ${({ theme }) =>
              theme.colors?.paletaCores?.secundaria?.contrastante ||
              theme.colors.white};
          }
        }

        &:focus {
          background: ${({ theme }) =>
            theme.colors?.paletaCores?.primaria?.medio ||
            theme.colors.blue_4};
          color: ${({ theme }) =>
            theme.colors?.paletaCores?.primaria?.contrastante ||
            theme.colors.white};
          border: solid 2px
            ${({ theme }) =>
              theme.colors?.paletaCores?.primaria?.medio ||
              theme.colors.blue_4};
          a {
            color: ${({ theme }) =>
              theme.colors?.paletaCores?.primaria?.medio ||
              theme.colors.blue_4};
          }
        }
      `
    : variant === 'secondary'
    ? css`
        background-color: ${({ theme }) =>
          theme.colors?.paletaCores?.secundaria?.escuro || theme.colors.white};
        border: solid 2px
          ${({ theme }) =>
            theme.colors?.paletaCores?.secundaria?.escuro ||
            theme.colors.white};
        color: ${({ theme }) =>
          theme.colors?.paletaCores?.secundaria?.contrastante ||
          theme.colors.blue_4};
        a {
          color: ${colors.blue_4};
        }
        &:hover {
          background: ${({ theme }) =>
            theme.colors?.paletaCores?.primaria?.claro ||
            theme.colors.gray_1};
          color: ${({ theme }) =>
            theme.colors?.paletaCores?.primaria?.escuro ||
            theme.colors.blue_4};
          border: solid 2px
            ${({ theme }) =>
              theme.colors?.paletaCores?.primaria?.claro ||
              theme.colors.gray_1};
          a {
            color: ${({ theme }) =>
              theme.colors?.paletaCores?.primaria?.contrastante ||
              theme.colors.gray_1};
          }
        }

        &:focus {
          background: ${({ theme }) =>
            theme.colors?.paletaCores?.primaria?.claro ||
            theme.colors.blue_4};
          color: ${({ theme }) =>
            theme.colors?.paletaCores?.primaria?.escuro ||
            theme.colors.white};
          border: solid 2px
            ${({ theme }) =>
              theme.colors?.paletaCores?.primaria?.claro ||
              theme.colors.blue_5};
          a {
            color: ${({ theme }) =>
              theme.colors?.paletaCores?.primaria?.escuro ||
              theme.colors.blue_5};
          }
        }
      `
    : css`
        background-color: ${({ theme }) => theme.colors.white};
        border: solid 1px ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro};
        color: ${({ theme }) => theme.colors.blue_4};

        &:hover {
          background: ${({ theme }) =>
            theme.colors?.paletaCores?.primaria?.medio ||
            theme.colors.blue_3};
          color: ${({ theme }) =>
            theme.colors?.paletaCores?.primaria?.contrastante ||
            theme.colors.white};
          border: solid 1px ${({ theme }) => theme.colors.blue_3};

          a {
            color: ${({ theme }) => theme.colors.blue_4};
          }
        }
      `}

a {
  text-decoration: none;
  font-weight: 700;
  font-size: 1rem;
}

&:hover > span {
  visibility: visible;
  opacity: 0.9;
  bottom: 58px;
  z-index: 999;
}

${props =>
  props.disabled &&
  css`
    font-weight: 400;
    background-color: ${colors.gray_4};
    border: solid 2px ${colors.gray_4};
    color: ${colors.gray_6};

    &:hover {
      background-color: ${colors.gray_4};
      border: solid 2px ${colors.gray_4};
      color: ${colors.gray_6};
    }
  `}

@media (max-width: ${mobileMinWidth}) {
  width: 100%;
}
`;

export const Tooltip = styled.span<ITooltip>`
  width: ${({ tooltipWidth }) => tooltipWidth};
  position: absolute;
  text-align: center;
  visibility: hidden;
  background-color: ${colors.white};
  font-size: 14px;
  color: ${colors.blue_7};
  padding: 5px 10px;
  border-radius: 3px;
  bottom: 0;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding: 10.9px 16px;

  &::before {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -8px;
    width: 0;
    height: 0;
    border-top: 8px solid ${colors.white};
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }
`;
