import { createGlobalStyle } from 'styled-components';
import { colors } from './colors';
import { mobileMinWidth } from './variables';

const path = '../fonts/';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: Roboto;
    src: url('${path}Roboto-Black.ttf');
    font-weight: 900;
  }
  @font-face {
    font-family: Roboto;
    src: url('${path}Roboto-Bold.ttf');
    font-weight: 700;
  }
  @font-face {
    font-family: Roboto;
    src: url('${path}Roboto-Light.ttf');
    font-weight: 300;
  }
  @font-face {
    font-family: Roboto;
    src: url('${path}Roboto-Medium.ttf');
    font-weight: 500;
  }
  @font-face {
    font-family: Roboto;
    src: url('${path}Roboto-Regular.ttf');
    font-weight: 400;
  }
  @font-face {
    font-family: Roboto;
    src: url('${path}Roboto-Thin.ttf');
    font-weight: 100;
  }

  * {
    font-family: Roboto;
    font-weight: 400;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }

  strong {
    font-weight: 700;
  }

  html {
    font-size: 16px;
    overflow-x: hidden;
  }

  a {
    color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro};
  }

  #root {
    height: 100vh;

    > main {
      min-height: calc(100vh - 422px);

      @media (max-width: ${mobileMinWidth}){
        min-height: calc(100vh - 603px);
      }
    }
  }

  .ReactModalPortal > div {
    z-index: 9999;
  }

  .modal {
    height: 60%;
    width: 900px;
    overflow: hidden;
    max-width: 90% !important;
  }

  .policy-modal {
    overflow: hidden;
    position: relative;
    height: 100%;
  }

  .policy-modal .content {
    padding: 0 30px 0;
    font-size: 16px;
    overflow-y: auto;
    text-align: justify;
    height: calc(100% - 64px);
  }

  .policy-modal .header {
    display: flex;
    padding: 20px 15px 20px 30px;
    border-bottom: 2px solid #f0f0f0;
    justify-content: space-between;
  }

  .policy-modal .header > h3 {
    font-size: 18px;
    color: #1b1247;
    margin: 0;
    font-weight: normal;
    width: 630px;
  }

  .policy-modal .header > span {
    display: flex;
    justify-content: flex-end;
  }

  .policy-modal .header > button {
    background: transparent;
    border: none;
    color: #c0c0c0;
    font-size: 18px;
    cursor: pointer;
  }
  .ReactModalPortal > div {
    z-index: 9999;
  }

  .modal {
    height: 60%;
    width: 900px;
    overflow: hidden;
    max-width: 90% !important;
  }

  .conditions-modal {
    overflow: hidden;
    position: relative;
    height: 100%;
  }

  .conditions-modal .content {
    padding: 0 30px 0;
    font-size: 16px;
    overflow-y: auto;
    text-align: justify;
    height: calc(100% - 84px);
  }

  .conditions-modal .header {
    display: flex;
    padding: 20px 15px 20px 30px;
    border-bottom: 2px solid #f0f0f0;
    justify-content: space-between;
  }

  .conditions-modal .header > h3 {
    font-size: 18px;
    color: #1b1247;
    margin: 0;
    font-weight: normal;
    width: 630px;
  }

  .conditions-modal .header > span {
    display: flex;
    justify-content: flex-end;
  }

  .conditions-modal .header > button {
    background: transparent;
    border: none;
    color: #c0c0c0;
    font-size: 18px;
    cursor: pointer;
  }


  .prefix__cls-3,
  .prefix__cls-8 {
    fill: none;
  }
  .prefix__cls-3 {
    stroke: #78e1e2;
    stroke-miterlimit: 10;
  }
  .prefix__cls-3,
  .prefix__cls-8 {
    stroke-width: 2px;
  }
  .prefix__cls-2 {
    fill: #78e1e2;
  }
  .prefix__cls-3 {
    stroke-dasharray: 3 4;
  }
  .prefix__cls-5,
  .prefix__cls-7 {
    fill: #1b1247;
  }
  .prefix__cls-5 {
    font-size: 14px;
    font-family: Roboto-Bold, Roboto;
    font-weight: 500;
  }
  .prefix__cls-6 {
    font-size: 12px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
  }
  .prefix__cls-8 {
    stroke: #1b1247;
    stroke-linecap: round;
  }


  .radio-error {
      margin-top: -10px;
  }

  #root > main > div > div > div > div.column2.row4 {
    border-bottom: 2px solid #6dd9da;
  }

  .react-calendar {
    z-index: 999;
    position: absolute;
    top: 41px;
    right: 0;
  }

  .react-datepicker__input-container {
    > input {
      width: 100%;
      height: 18px;
      border: 0;
      font-size: 16px;
      font-weight: 100;
      color: ${colors.gray_3};
      background-color: transparent;
    }
  }

  .react-datepicker {
    position: absolute;
    top: 4px;
    left: 228px;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker-popper {
    position: absolute;
    right: 0;
  }

  .react-datepicker__navigation-icon--previous {
    right: 58px;
  }

  .react-datepicker__navigation-icon--next {
    left: 58px;
  }
`;

export default GlobalStyles;
