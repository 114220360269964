/* eslint-disable no-undef */
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

window.dataLayer = window.dataLayer || [];

const googleTagManager = () => {
  const tagManagerArgs = {
    gtmId: 'GTM-TJJ4SLX',
    dataLayer: {
      userId: '001',
      userProject: 'meumomentoauto',
    },
  };

  TagManager.initialize(tagManagerArgs);
  ReactGA.initialize(process.env.NODE_ENV === 'production' ? 'GTM-TJJ4SLX' : 'GTM-TJJ4SLX', {
    debug: !process.env.NODE_ENV === 'production',
    titleCase: false,
    gaOptions: {
      userId: '001',
    },
  });
};

export const googleAnalyticsEvent = params => {
  window.dataLayer.push(params);
};

export default googleTagManager;
