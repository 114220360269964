import React from 'react';
import { SVGProps } from './types';

export const NoSVG = ({ width, height }: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 31.174 31.171"
    width={width}
    height={height}
  >
    <path
      d="m503.018 880.924-1.273-1.273-5.129 5.13-5.313-5.314-1.273 1.273 5.314 5.313-4.808 4.809 1.272 1.272 4.808-4.808 4.624 4.624 1.273-1.273-4.624-4.624z"
      transform="translate(-480.763 -870.204)"      
    />
    <path
      d="M507.377 874.764a15.595 15.595 0 0 0-22.054 22.054c.226.225.458.44.694.649l1.276-1.277a15.576 15.576 0 0 1-.7-.645 13.8 13.8 0 1 1 2.251 1.821l-1.3 1.3a15.593 15.593 0 0 0 19.826-23.9"
      transform="translate(-480.763 -870.204)"      
    />
  </svg>
);
