import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

/* Hide the browser's default checkbox */
export const CheckInput = styled.input.attrs(props => ({
  type: 'checkbox',
  ...props,
}))`
  position: absolute;
  height: 0;
  width: 0;

  cursor: pointer;
  opacity: 0;
`;

interface CheckboxProps {
  disabled?: boolean;
  required?: boolean;
  error?: string;
}

export const LabelContainer = styled.label<CheckboxProps>`
  display: block;
  position: relative;
  margin: 6px 0;
  padding-left: 30px;
  color: #333333;

  cursor: pointer;
  font-size: 16px;
  line-height: 1.5;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 22px;
    width: 22px;

    border-radius: 3px;
    border: 1px solid ${colors.gray_2};

    ${({ disabled }) =>
      disabled &&
      css`
        border: 1px solid ${colors.gray_5};
        background-color: ${colors.gray_4};
      `}

    ${({ error }) =>
      error &&
      css`
        border: 1px solid ${colors.red_4};
      `}

    /* Create the checkmark (hidden when not checked) */
    &:after {
      content: '';
      position: absolute;
      display: none;
    }
  }
  /* On mouse-over, add a border-color */
  &:hover ${CheckInput} + span {
    border: 2px solid ${({ theme }) => theme.colors?.paletaCores?.primaria?.contrastante };
  }

  /* When the checkbox is checked, add a background */
  ${CheckInput}:checked + span {
    border: 2px solid ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
    background-color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
  }

  /* Show the checkmark when checked */
  ${CheckInput}:checked + span:after {
    display: block;
    color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
  }

  /* Style the checkmark */
  span:after {
    top: 1.5px;
    left: 5px;
    width: 5px;
    height: 10px;

    border: solid ${colors.white};
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
