import styled from 'styled-components';
import { colors } from 'styles/colors';

export const ContainerLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 70px;
  background-color: ${colors.white};
  border-top: 2px solid ${colors.gray_4};
`;

export const LogoImg = styled.img`
  height: 56px;
  object-fit: contain;
`;

export const FooterContainer = styled.footer`
  background-color: #fff;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px 0;
  box-shadow: 0 -3px 15px 0 rgba(0, 0, 0, 0.16);
  margin-top: 10px;
`;

export const FooterContent = styled.div`
  width: 100%;
  max-width: 1200px;
  font-size: 11px;
  display: flex;
  align-items: center;

  @media (max-width: 1200px) {
    padding: 0 20px;
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

export const FooterDescription = styled.div`
  font-size: 11px;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro } @media (max-width: 768px) {
    text-align: center;
  }
`;

export const Logo = styled.div`
  margin-right: 20px;
  border-right: 1px solid #707070;

  img {
    padding: 0 59px 0;
  }

  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    border: 0px;
    margin-right: 0px;

    img {
      padding: 0 0 15px 0;
    }
  }
`;
