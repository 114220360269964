import React from 'react';

export const FacebookSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50.003"
    viewBox="0 0 50 50.003"
  >
    <path
      data-name="Path 4553"
      d="M-9.1 48.7a25 25 0 0 1-25 25 25 25 0 0 1-25-25 25 25 0 0 1 25-25 25 25 0 0 1 25 25"
      transform="translate(59.103 -23.699)"
      style={{ fill: '#78e1e1' }}
    />
    <path
      data-name="Path 4554"
      d="M-53.745 37.147h3.839v-3.869a6.543 6.543 0 0 1 1.6-4.445c1.763-1.92 4.138-1.736 7.522-1.418v4.318h-2.5a2.155 2.155 0 0 0-1.545.594 2.7 2.7 0 0 0-.442 1.712v3.109h4.34l-.556 4.81h-3.8v12.387h-4.6V41.932h-3.855z"
      transform="translate(72.262 -15.777)"
      style={{ fill: '#fff' }}
    />
  </svg>
);
