/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
import React, { useCallback, useEffect } from 'react';
import { BrokerCard } from 'components/Card/BrokerCard';
import { useLocation } from 'react-router-dom';

import { LOCAL_BROKER, LOCAL_CONTRACT_COMPLETED, LOCAL_CPF } from 'config';
import { sendBrokerLeadNotification } from 'api/services/broker';
import { formatCPF } from 'helpers/format';
import axios from 'axios';
import { mobileMinWidthInt } from 'styles/variables';
import { detectBrowser } from 'helpers/detectBrowser';
import * as S from './styles';

interface ErrorState {
  serverError?: boolean;
}

export const Error = () => {
  const controller = new AbortController();
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();

  const location = useLocation();
  const { serverError = false } = (location?.state as ErrorState) ?? {};

  const fetchSendBrokerLeadNotification = async () => {
    const brokerData = localStorage.getItem(LOCAL_BROKER)
    const brokerDataParsed = JSON.parse(brokerData!)
    const cpf = localStorage.getItem(LOCAL_CPF)
    const brokerDataLocalStorage = window.localStorage.getItem(LOCAL_BROKER);

    const payload: any = {
      broker: {
        brokerCode: brokerDataParsed.broker.brokerCode,
        brokerBranchCode: brokerDataParsed.broker.brokerBranchCode,
        internalBranchCode: brokerDataParsed.broker.internalBranchCode
      },
      brokerUser: 'enim',
      brokerToken: brokerDataParsed.brokerToken,
      affinity: {
        affinityAccountCode:
          brokerDataParsed.affinity.affinityAccountCode,
        affinityAccountBranchCode:
          brokerDataParsed.affinity.affinityAccountBranchCode,
        affinityCommPlanCode:
          brokerDataParsed.affinity.affinityCommPlanCode
      },
      commercialProductCode: String(
        brokerDataParsed.commercialProductCode
      ),
      documentNumber: formatCPF(cpf!)
    }
    const response = await sendBrokerLeadNotification(payload, controller.signal, source.token)

    if (response.status === 200) {
      window.localStorage.removeItem(LOCAL_CONTRACT_COMPLETED)
      localStorage.removeItem(LOCAL_CPF)
      source.cancel()
      controller.abort()
    }
  }

  const beforeUnload = useCallback((e: any, isMobile: boolean) => {
    e.preventDefault()
    e.stopPropagation()
    e.returnValue = ''

    const contractCompleted = window.localStorage.getItem(
      LOCAL_CONTRACT_COMPLETED
    )

    if (contractCompleted === 'false' && !isMobile) {
      fetchSendBrokerLeadNotification()
    }

    if (contractCompleted === 'false' && isMobile) {
      fetchSendBrokerLeadNotification()
    }
  }, [])

  useEffect(() => {
    const isSafari = detectBrowser().toLowerCase().includes('safari')
    let sended = false

    if (!sended && window.innerWidth > mobileMinWidthInt) {
      sended = true
      window.addEventListener('beforeunload', (e) => beforeUnload(e, false))
    }

    if (!sended && window.innerWidth <= mobileMinWidthInt && !isSafari) {
      sended = true
      window.addEventListener('visibilitychange', (e) => beforeUnload(e, true))
    }

    if (!sended && window.innerWidth <= mobileMinWidthInt && isSafari) {
      sended = true
      window.addEventListener('blur', (e) => beforeUnload(e, true))
    }

    return () => {
      window.removeEventListener('beforeunload', (e) => beforeUnload(e, false))
      window.removeEventListener('blur', (e) => beforeUnload(e, true))
      // window.removeEventListener('visibilitychange', (e) => beforeUnload(e, true))
      source.cancel()
      controller.abort()
    }
  }, [])

  return (
    <S.Container>
      <S.SadEmoji />
      <S.Header>
        <span>
          {serverError
            ? 'Instabilidade no sistema'
            : 'Não foi possível gerar uma cotação'}
        </span>
      </S.Header>
      <S.Message>
        {serverError ? (
          <>
            Lamentamos o ocorrido. Estamos com uma instabilidade no sistema e
            nossa equipe já está trabalhando na correção.
            <br />
            <br />
            Tente novamente mais tarde ou entre em contato com o(a) seu(sua)
            corretor(a).
          </>
        ) : (
          <>
            Infelizmente não foi possível gerar uma cotação com base no seu
            perfil.
            <br />
            <br />
            Entre em contato com o(a) seu(sua) corretor(a) para verificar outras
            opções de seguro.
          </>
        )}
      </S.Message>
      <S.CorretorContainer>
        <BrokerCard />
      </S.CorretorContainer>
    </S.Container>
  );
};
