import React from 'react';

export const TooltipSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.052"
    height="20.052"
    viewBox="0 0 20.052 20.052"
    style={{ position: 'absolute', right: -36 }}
  >
    <g data-name="Group 999">
      <g data-name="Group 997">
        <path
          data-name="Path 296"
          d="M10.026 0A10.026 10.026 0 1 1 0 10.026 10.026 10.026 0 0 1 10.026 0z"
          style={{ fill: '#06748c' }}
        />
        <text
          data-name="?"
          transform="translate(6.026 15.658)"
          style={{
            fill: '#fff',
            fontSize: '16px',
            fontFamily: 'Roboto-Medium, Roboto',
            fontWeight: 500,
          }}
        >
          <tspan x="0" y="0">
            ?
          </tspan>
        </text>
      </g>
    </g>
  </svg>
);
