/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useState, createContext, useContext, useCallback } from 'react';

interface LoadingContextData {
  loading: boolean;
  showLoading(status: boolean): void;
  callLoading: any;
}

const LoadingContext = createContext<LoadingContextData>(
  {} as LoadingContextData
);

const LoadingProvider = ({ children }: any) => {
  let callLoading: any;
  const [loading, setLoading] = useState(false);

  const showLoading = useCallback(
    (status: any) => setLoading(status),
    [setLoading]
  );

  return (
    <LoadingContext.Provider value={{ loading, showLoading, callLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

function useLoading() {
  const context = useContext(LoadingContext);
  if (!context)
    throw new Error(
      'O UseAppContext precisa ser utilizado dentro do AppProvider'
    );

  return context;
}

export { LoadingProvider, useLoading };
