import styled from 'styled-components';
import { mobileMinWidth } from 'styles/variables';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: 0 auto;
  height: 100%;
  max-width: 733px;
  padding: 1rem 0;

  @media (max-width: ${mobileMinWidth}) {
    padding: 32px 16px 38px;
  }
`;
export const Title = styled.div`
  font-size: 2rem;
  font-size: 400;
  line-height: 1.2;
  text-align: center;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro};

  margin: 21px 0 30px;
`;
export const Description = styled.div`
  text-align: center;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro};
`;
