import React from 'react';

export const InstagramSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50.003"
    viewBox="0 0 50 50.003"
  >
    <path
      data-name="Path 4555"
      d="M9.135 48.7a25 25 0 0 1-25 25 25 25 0 0 1-25-25 25 25 0 0 1 25-25 25 25 0 0 1 25 25"
      transform="translate(40.865 -23.699)"
      style={{ fill: '#78e1e1' }}
    />
    <path
      data-name="Path 4556"
      d="M-16.1 27.653h-18.3a2.51 2.51 0 0 0-2.509 2.509v18.3a2.51 2.51 0 0 0 2.509 2.51h18.3a2.507 2.507 0 0 0 2.506-2.506v-18.3a2.507 2.507 0 0 0-2.506-2.513zm-9.148 6.824a4.8 4.8 0 0 1 4.8 4.806 4.8 4.8 0 0 1-4.8 4.806 4.807 4.807 0 0 1-4.806-4.806 4.807 4.807 0 0 1 4.808-4.806zm8.96 12.3a1.493 1.493 0 0 1-1.5 1.493H-32.72a1.492 1.492 0 0 1-1.493-1.493v-9.705h2.043a7.157 7.157 0 0 0-.34 2.2 7.258 7.258 0 0 0 7.26 7.26 7.256 7.256 0 0 0 7.26-7.26 7.369 7.369 0 0 0-.336-2.2h2.039zm.041-13.456A1.174 1.174 0 0 1-17.42 34.5h-1.88a1.174 1.174 0 0 1-1.174-1.177v-1.88a1.176 1.176 0 0 1 1.174-1.178h1.885a1.176 1.176 0 0 1 1.174 1.177z"
      transform="translate(50.252 -14.312)"
      style={{ fill: '#fff' }}
    />
  </svg>
);
