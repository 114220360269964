/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { colors } from 'styles/colors';

export const Container = styled.div`
  display: flex;
  align-items: center;

  height: 18px;
  margin: 8px 2px;

  svg {
    margin: 0;
  }

  span {
    font-size: 0.75rem;
    color: ${colors.red_3};
  }

  svg {
    margin-right: 8px;
  }
`;
