/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { Routes, Route } from 'react-router-dom';

import Home from 'pages/home';
import { Contact } from 'pages/contact';
import { Denied } from 'pages/denied';
import { Faq } from 'pages/faq';
import { Error } from 'pages/error';
import { Success } from 'pages/Success';
import { Questionnaire } from 'pages/questionnaire';
import { ScrollToTop } from 'components/ScrollToTop';
import { Plan } from 'pages/plan';

const CustomRoutes = () => {
  return (
    <>
    <ScrollToTop />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/denied" element={<Denied />} />
      <Route path="/error" element={<Error />} />
      <Route path="/faq" element={<Faq />} />
      <Route path="/health" element={<></>} />
      <Route path="/plan" element={<Plan />} />
      <Route path="/questionnaire" element={<Questionnaire />} />
      <Route path="/success" element={<Success />} />
    </Routes>
  </>
  )
}

export default CustomRoutes;
