/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
import React, { useState, useCallback, SelectHTMLAttributes } from 'react';
import { ControllerFieldState } from 'react-hook-form';

import * as S from './styles';
import { ErrorMessage } from '../ErrorMessage';

export interface IOptionsSelect {
  value: string | number;
  label: React.ReactNode;
}

export interface ISelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string;
  placeholder?: string;
  fieldState?: ControllerFieldState;
  Icon?: React.ReactNode;
  showError?: boolean;
  inputRef?: React.RefCallback<HTMLSelectElement>;
  onCustomBlur?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  options?: IOptionsSelect[];
  defaultValue?: string;
  onClick?: any;
  onFocus?: any;
}

const Select = ({
  label,
  disabled,
  fieldState,
  name,
  placeholder,
  options,
  className,
  inputRef,
  onChange,
  onFocus,
  onCustomBlur,
  ...rest
}: ISelectProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleInputFocus = useCallback(() => setIsFocused(true), []);
  const handleInputBlur = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
    setIsFocused(false)
    onCustomBlur && onCustomBlur(e)
  }, [onCustomBlur]);

  const showLabel = fieldState?.isTouched || !!rest.value;
  const error = fieldState?.error?.message;

  return (
    <S.Container className={className}>
      <S.InputContainer
        isFocused={isFocused}
        isFilled={!!rest.value}
        isErrored={!!error}
        disabled={disabled}
      >
        {showLabel && <label>{label}</label>}
        <select
          onChange={onChange}
          name={name}
          disabled={disabled}
          ref={inputRef}
          {...rest}
          onFocus={() => {
            handleInputFocus();            
          }}
          onBlur={handleInputBlur}
        >
          <option value="">{placeholder}</option>
          {options &&
            options.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
        </select>
      </S.InputContainer>
      <S.Arrow />
      {error && <ErrorMessage name={name} message={error} />}
    </S.Container>
  );
};

export { Select };
