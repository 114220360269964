export const INVALID_CPF = 'Esse número de CPF é inválido';
export const INVALID_CEP = 'Esse número de CEP é inválido';
export const INVALID_EMAIL = 'Esse email é inválido';
export const INVALID_PHONE = 'Esse telefone é inválido';
export const INVALID_DATE = 'Essa data é inválida';
export const REQUIRED_FIELD = 'Campo obrigatório';
export const REQUIRED_GROUP_FIELD =
  'Campo obrigatório, selecione uma das opções acima.';
export const MIN_11_CHARS = 'Deve conter no mínimo 11 dígitos';
export const MAX_11_CHARS = 'Deve conter no máximo 11 dígitos';
export const MIN_8_CHARS = 'Deve conter no mínimo 8 dígitos';
export const MAX_8_CHARS = 'Deve conter no máximo 8 dígitos';
