import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'components/Button';
import { googleAnalyticsEvent } from 'helpers/googleTagManager.js';

import * as S from './styles';

const MainBanner = () => {
  const navigate = useNavigate();

  const sendEventDataGTM = () => {
    googleAnalyticsEvent({
      dataLayer: {
        location: 'Home',
        event: 'home_btn_coteagora_main',
        button: 'Cote agora'
      },
    });
    navigate('/questionnaire');
  }

  return (
    <>
      <S.BackgroundImage />
      <S.Wrapper>
        <S.Banner>
          <S.Title>
            Você e seu carro protegidos <br />
            em poucos cliques
          </S.Title>
          <S.Subtitle>
            O processo de cotação é online,
            <br />
            rápido e fácil. Cote agora e receba
            <br />
            uma cotação em minutos!
          </S.Subtitle>
          <Button theme="default" onClick={sendEventDataGTM}>
            Cote agora
          </Button>
        </S.Banner>
      </S.Wrapper>
    </>
  );
};

export default MainBanner;
