import styled, { css } from 'styled-components';
import { mobileMinWidth } from 'styles/variables';

interface MainProps {
  embbed: boolean;
  pathname?: string;
}

export const Main = styled.main<MainProps>`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: ${({ pathname }) => (pathname === '/' ? '108px 0 0px' : '0')};

  ${({ embbed }) =>
    embbed &&
    css`
      padding: 0 10% 20px;
    `}

  @media (max-width: ${mobileMinWidth}) {
    padding: ${({ pathname }) => (pathname === '/' ? '64px 0 0px' : '0')};
  }
`;
