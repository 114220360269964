import styled, { css } from 'styled-components';
import { colors } from 'styles/colors';

import { TooltipSVG } from './assets/TooltipSVG';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isErrored: boolean;
  disabled: boolean | undefined;
}

export const RS = styled.span`
  margin-right: 3px;
  &:before {
    content: 'R$';
    color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro};
  }
`;

export const DisplayContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: relative;

  .icon {
    width: 20px;
    height: 20px;
    position: absolute;
    left: 10px;
  }
`;

export const InputContainer = styled.div<ContainerProps>`
  background: transparent;
  border-radius: 3px;
  padding: 5px 8px;
  width: 100%;
  height: 50px;

  border: 1px solid ${colors.gray_2};
  outline: 1px solid transparent;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  ${props =>
    props.isFocused &&
    css`
      border: 2px solid ${colors.blue_2};
      outline: 0;
    `}

  ${props =>
    props.disabled &&
    css`
      border: 1px solid ${colors.gray_5};
      background-color: ${colors.gray_4};
    `}

  ${props =>
    props.isErrored &&
    css`
      border: 1px solid ${colors.red_2};
      background-color: ${colors.red_1};
    `}


  & + div {
    margin-top: 8px;
  }

  label {
    font-size: 0.75rem;
    margin-bottom: 4px;
    color: ${colors.gray_3};
  }

  input {
    outline: none;
    width: 100%;
    background: transparent;
    border: 0;
    font-weight: 100;

    font-size: 1rem;
    color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro};

    &::placeholder {
      color: ${colors.gray_3};
    }
  }

  img {
    position: absolute;
    width: 40px;
    height: 40px;
    align-self: flex-end;
    opacity: 0.5;
  }
`;

export const TooltipIcon = styled(TooltipSVG)``;
