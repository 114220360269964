/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */
import React, { useCallback } from 'react';
import { getAssistancyDetailLink } from 'helpers/links';

import { CONTRACT_RULES } from 'config';
import { Alert } from 'components/Modal/Alert';
import { useNavigate } from 'react-router-dom';
import { useModal } from 'contexts/Modal';
import { useWindowSize } from 'helpers/custom-hooks';
import { googleAnalyticsEvent } from 'helpers/googleTagManager';
import { mobileMinWidthInt } from 'styles/variables';
import * as S from './styles';

const assistancies = [
  {
    title: 'Seu carro parou e precisa de guincho?',
    description:
      'Não se preocupe, mandaremos um guincho até o local para te ajudar!',
  },
  {
    title: 'Problemas com a chave do carro?',
    description:
      'Um chaveiro 24h será acionado para realizar a abertura do veículo sem arrombamentos.',
  },
  {
    title: 'Pneu furou no meio do caminho?',
    description:
      'Garantimos a mão de obra especializada para fazer a troca do pneu ou levaremos o veículo para a borracharia mais próxima.',
  },
  {
    title: 'E muito mais!',
    description:
      'Também temos serviços de reboque em caso de pane seca, mecânico para consertos provisórios, transporte alternativo caso o veículo fique imobilizado e muito mais!',
  },
];

const eventTag: any = {
  linkAssistant: {
    button: 'Clique aqui',
    event: 'home_link_regrasassistencias',
  },
  btnQuoteNow: {
    button: 'Cote o seguro agora',
    event: 'home_btn_coteagorabanner',
  }
}

const Assistancy = () => {
  const { showModal } = useModal();
  const redirect = useNavigate();
  const [width] = useWindowSize();
  const isMobile = width <= 1000;

  const handleAssistancyDetail = useCallback((key: string) => {
    googleAnalyticsEvent({
      dataLayer: {
        location: 'Home',
        ...eventTag[key]
      },
    });
  }, []);

  const showRules: any = () =>
    showModal(
      <Alert
        title=""
        subtitle="Regras de Aceitação"
        description={
          <S.List>
            {CONTRACT_RULES.map((item: any, id: any) => (
              <li key={id}>
                <div>{item}</div>
              </li>
            ))}
          </S.List>
        }
      />,
      false,
      'rules'
    );

  return (
    <S.Wrapper>
      {!isMobile && (
        <S.ImageContainer>
          <S.IllustrationPNG />
          <S.AssistancyText>
            * Veja todas as regras e excessões das assistências em:{' '}
            <a
              href={getAssistancyDetailLink()}
              onClick={() => handleAssistancyDetail('linkAssistant')}
              target="#"
            >
              clique&nbsp;aqui
            </a>
          </S.AssistancyText>
        </S.ImageContainer>
      )}
      <S.InfoContainer>
        <S.Title>
          Os principais serviços de Assistência{' '}
          {window.innerWidth > mobileMinWidthInt && <br />} 24 horas do mercado,
          disponíveis para você e seu veículo!
        </S.Title>
        <S.AssistancyContainer>
          {assistancies.map((assistancy, index) => (
            <S.AssistancyCard key={index}>
              <div>
                <S.CheckIcon />
              </div>
              <S.AssistancyInfocontainer>
                <S.AssistancyTitle>{assistancy.title}</S.AssistancyTitle>
                <S.AssistancyDescription>
                  {assistancy.description}
                </S.AssistancyDescription>
              </S.AssistancyInfocontainer>
            </S.AssistancyCard>
          ))}
          {isMobile && (
            <S.AssistancyText>
              * Veja todas as regras e excessões das assistências em:&nbsp;{' '}
              <br />
              <a
                href={getAssistancyDetailLink()}
                onClick={() => handleAssistancyDetail('linkAssistant')}
                target="#"
              >
                clique aqui
              </a>
            </S.AssistancyText>
          )}
          <S.QuotationContainer>
            <S.QuotationText>
              Faça uma cotação e contrate o seguro completo que mais combina com
              você e com o seu automóvel!
            </S.QuotationText>
            <S.QuotationButton
              theme="default"
              onClick={() => {
                redirect('/questionnaire');
                handleAssistancyDetail('btnQuoteNow')
              }}
            >
              Cote o seguro agora
            </S.QuotationButton>
          </S.QuotationContainer>
        </S.AssistancyContainer>
      </S.InfoContainer>
      {isMobile && (
        <S.ImageContainer>
          <S.IllustrationPNG />
        </S.ImageContainer>
      )}
    </S.Wrapper>
  );
};

export default Assistancy;
