/* eslint-disable react/require-default-props */
import React from 'react';
import { SVGProps } from './types';

export const InformationSVG = ({
  stroke,
  fill,
  width,
  height,
  onClick,
  ...props
}: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    stroke={stroke}
    fill={fill}
    width={width}
    height={height}
    onClick={onClick}
    {...props}
  >
    <path
      d="M8 16a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8zm-2.023-4.566v1.248h4.045v-1.248h-1.4V6.348A.625.625 0 0 0 8 5.724H6.127v1.248h1.248v4.462zm1.814-8.4a.817.817 0 1 0 .817.816.818.818 0 0 0-.816-.819z"
      style={{ mixBlendMode: 'multiply', isolation: 'isolate' }}
    />
  </svg>
);
