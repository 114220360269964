import React from 'react';
import * as S from './styles';

const Loading = () => (
  <S.Wrapper data-test-id="loading">
    <S.LoadingIcon thickness="6px" speed="0.5s" emptyColor="#E6E6E6" />
    <span>
      Aguarde... <br /> Isso pode levar alguns segundos
    </span>
  </S.Wrapper>
);

export { Loading };
