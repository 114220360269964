import styled from 'styled-components';
import { mobileMinWidth, tabletMinWidth } from 'styles/variables';

import { AgreementSVG } from '../../../assets/img/agreement';

export const IconAgreementSVG = styled(AgreementSVG)``;

export const IconContainer = styled.div`
  > svg {
    width: 120px;
    height: 113.9px;
    @media (max-width: ${mobileMinWidth}) {
      position: absolute;
      width: 84px;
      height: 79.7px;
      top: -15.7px;
      left: calc(50% - 42px);
    }
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  padding: 37px 0;
  @media (max-width: ${tabletMinWidth}) {
    padding: 37px 0 0;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blue_3};
  border-radius: 5px;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.16);
  padding: 14px 41px 14px 30px;
  > button {
    max-width: 278px;
    width: 100%;
  }
  @media (max-width: ${mobileMinWidth}) {
    flex-direction: column;
    justify-content: center;
    position: relative;
  }
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  font-weight: 300;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro };
  margin: 0 24px 0 12px;
  max-width: 600px;
  > span {
    font-weight: 700;
  }
  @media (max-width: ${mobileMinWidth}) {
    margin: 75px 0 20px 0;
    font-size: 1rem;
    text-align: center;
  }
`;
