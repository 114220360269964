/* eslint-disable no-unused-vars */
/* eslint-disable react/require-default-props */
import { ErrorMessage } from 'components/Input/ErrorMessage';
import React, { ButtonHTMLAttributes, InputHTMLAttributes } from 'react';
import { ControllerFieldState } from 'react-hook-form';
import * as S from './styles';

interface ButtonProps {
  label: string;
  value: string;
  icon: React.ReactNode;
}

interface ButtonElementProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  selected: boolean;
  icon: React.ReactNode;
}

interface ButtonGroupProps extends InputHTMLAttributes<HTMLInputElement> {
  buttons: ButtonProps[];
  selectButton?: (name: string, value: any) => void;
  fieldState?: ControllerFieldState;
  inputRef?: React.RefCallback<HTMLDivElement>;
}

const Button = ({ children, selected, icon, ...rest }: ButtonElementProps) => (
  <S.Button selected={selected} type="button" {...rest}>
    {icon && <span>{icon}</span>}
    {children}
  </S.Button>
);

const ButtonGroup = ({
  buttons,
  fieldState,
  name,
  selectButton,
  inputRef,
  ...rest
}: ButtonGroupProps) => {
  const error = fieldState?.error?.message;

  return (
    <>
      <S.Container qtdItems={buttons.length} isErrored={!!error}>
        {buttons.map(({ value, label, ...buttonRest }) => (
          <Button
            key={value}
            selected={rest.value === value}
            onClick={() => selectButton && selectButton(name!, value)}
            {...buttonRest}
          >
            {label}
          </Button>
        ))}
      </S.Container>
      {error && <ErrorMessage name={name} message={error} />}
    </>
  );
};

export default ButtonGroup;
