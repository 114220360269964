/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import React from 'react';

import { InformationSVG } from 'assets/img/InformationSVG';
import { colors } from 'styles/colors';
import * as S from './styles';

interface ErrorMessageProps {
  message: string;
  name?: string;
  className?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({
  message,
  name,
  className,
}) => (
  <S.Container
    data-test-id={`error-${name}`}
    className={`error-input ${className}`}
  >
    <InformationSVG
      stroke={colors.red_3}
      fill="transparent"
      width="16"
      height="16"
    />
    <span>{message}</span>
  </S.Container>
);

export { ErrorMessage };
