/* eslint-disable react/require-default-props */
/* eslint-disable import/prefer-default-export */
import React from 'react';

interface ICloseIconSVG {
  onClick?: any;
}

export const CloseIconSVG = ({ onClick }: ICloseIconSVG) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24.297"
      height="24.297"
      viewBox="0 0 24.297 24.297"
      onClick={onClick}
    >
      <g>
        <path
          fill="#1a1444"
          d="M8.03 17.18V9.15H0V8.03h8.03V0h1.12v8.03h8.03v1.12H9.15v8.03z"
          transform="rotate(45 6.074 14.664)"
        />
      </g>
    </svg>
  );
};
