import styled from 'styled-components';
import { mobileMinWidth } from 'styles/variables';
import { BackIconSVG } from 'assets/img/BackIconSVG';

export const Content = styled.div`
  max-width: 566px;
  width: 100%;
  margin-bottom: 104px;

  @media (max-width: ${mobileMinWidth}) {
    margin-bottom: 63px;
  }

  h1 {
    text-align: center;
    color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro};
    font-size: 2rem;

    margin-bottom: 69px;

    @media (max-width: ${mobileMinWidth}) {
      margin-bottom: 20px;
    }
  }
`;

export const Container = styled.main`
  display: flex;
  justify-content: center;
  height: 100%;
  margin-top: 40px;

  @media (max-width: ${mobileMinWidth}) {
    padding: 0 16px;
  }
`;
export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 19px 32px;

  border-bottom: 0.5px solid;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro};

  &.opened {
    padding: 19px 32px;
  }

  & div svg {
    width: 12px;
    height: 100%;
    margin-top: 5px;

    transition: 0.3s ease-in-out;
  }

  &.closed svg {
    transform: rotate(-90deg);
  }
  &.opened svg {
    transform: rotate(90deg);
  }

  @media (max-width: ${mobileMinWidth}) {
    padding: 20px 20px;

    &.opened {
      padding: 20px 20px;
    }
  }
`;
export const QuestionTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

export const Question = styled.div`
  font-weight: 300;
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro};
  margin-right: 10px;
  max-width: 90%;

  &:hover {
    font-weight: 400;
  }

  &.opened {
    font-weight: 500;
  }

  @media (max-width: ${mobileMinWidth}) {
    font-size: 1rem;
  }
`;

export const Answer = styled.div`
  margin-top: 32px;
  color: ${({ theme }) => theme.colors?.paletaCores?.primaria?.escuro};

  transform: scaleY(0);
  transition: transform 0.3s;
  transform-origin: top left;

  &.closed {
    height: 0;
    margin-top: 0;
  }
  &.opened {
    transform: scaleY(1);
  }

  @media (max-width: ${mobileMinWidth}) {
    margin-top: 18px;
    font-size: 0.875rem;
  }
`;

export const BackIconIcon = styled(BackIconSVG)``;
