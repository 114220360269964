import React from 'react';

export const PinSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
      <defs>
        <clipPath id="a">
          <path style={{ fill: '#565656' }} d="M0 0h16.943v24H0z" />
        </clipPath>
      </defs>
      <path
        data-name="Rectangle 1948"
        style={{ fill: 'none' }}
        d="M0 0h24v24H0z"
      />
      <g data-name="Group 5394">
        <g
          data-name="Group 5393"
          style={{ clipPath: 'url(#a)' }}
          transform="translate(4)"
        >
          <path
            data-name="Path 4481"
            d="M699.744 298.884a3.532 3.532 0 1 0-3.532 3.532 3.534 3.534 0 0 0 3.532-3.532m-3.532-2.162a2.163 2.163 0 1 1-2.164 2.162 2.165 2.165 0 0 1 2.164-2.162"
            transform="translate(-687.668 -290.475)"
            style={{ fill: '#565656' }}
          />
          <path
            data-name="Path 4482"
            d="M700.818 291.674a8.48 8.48 0 0 0-11.995 0 9.6 9.6 0 0 0-.807 11.845l4.91 7.089.23.332.831-1.2-4.855-7.009a.19.19 0 0 0-.015-.021 8.155 8.155 0 0 1 .671-10.068 7.113 7.113 0 0 1 10.061 0 8.159 8.159 0 0 1 .662 10.076l-.015.021-5.677 8.2-.887 1.112.794 1.138a.425.425 0 0 0 .036-.04l6.852-9.621a9.609 9.609 0 0 0-.8-11.854"
            transform="translate(-686.347 -289.189)"
            style={{ fill: '#565656' }}
          />
        </g>
      </g>
    </svg>
  );
};
