/* eslint-disable prefer-template */
const monthNames = [
  'Jan',
  'Fev',
  'Mar',
  'Abr',
  'Mai',
  'Jun',
  'Jul',
  'Ago',
  'Set',
  'Out',
  'Nov',
  'Dez',
];

export function formatDate(date: string) {
  const splitted = date.split('/');
  return `${splitted[2]}-${splitted[1]}-${splitted[0]}`;
}

export function formatDateToMask(date: string) {
  const splitted = date.split('-');
  return `${splitted[2]}/${splitted[1]}/${splitted[0]}`;
}

export function checkDateTypeAndFormat(date: string) {
  return date.includes('-') ? formatDateToMask(date) : formatDate(date);
}

export const getMonthName = (date: Date) => monthNames[date.getMonth()];

export const dateWithMonthName = (date: string) => {
  const splitted = date.split('/');
  const convertedDate = new Date(
    `${splitted[2]}-${splitted[1]}-${splitted[0]}`
  );
  return `${splitted[1]}/${getMonthName(convertedDate).toLocaleLowerCase()}/${
    splitted[2]
  }`;
};

export const round = (value: any) => Math.floor(value.toFixed(2) / 5000) * 5000;

export const { format: formatPrice } = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL',
});

export const formatNumber = (number: any) =>
  new Intl.NumberFormat('pt-BR').format(number);

export const formatPhone = (phone: string) => phone.includes('-') ?
  phone.replace('_', '') :
  `${phone.substring(0, phone.length - 4)}-${phone.substring(phone.length - 4)}`;

export const formatCPF = (cpf: string) => {
  const cpfWithDash = cpf.split('.')
  const cpfFormatted = cpfWithDash[2].split('-')
  return `${cpfWithDash[0]}${cpfWithDash[1]}${cpfFormatted[0]}${cpfFormatted[1]}`
}
